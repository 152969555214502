import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import SmsAPI from 'api/smsAPI';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import JDInput from 'components/_v2/join/JDInput';
import JDInputSelector from 'components/_v2/join/JDInputSelector';
import RoutePaths from 'consts/RoutePaths';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import { countryList } from 'consts/_v2/join/countryList';
import useToast from 'hooks/useToast';
import { ICheckVerificationCodeRq } from 'interfaces/_v2/profile/IBasicInfoRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { convertMsToMMSS } from 'utils/_v2/timeUtils';

const Frame = styled.div<{ hide: boolean }>`
    width: 100%;
    padding: 16px;

    ${(props) => props.hide && css`
        display: none;
    `}

    ${JDModalButton} {
        left: 50%;
        transform: translate(-50%, 0);
        position: fixed;
        width:90%;
        bottom:calc(24px + env(safe-area-inset-bottom));
    }
`;

const Button = styled.button`
  padding: 8px 0;
  width: 52px;
  border-radius: 4px;
  background-color: ${Colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${Colors.WHITE_100};

  &:active {
    background-color: ${Colors.CG_70};
  }

  &:disabled {
    background-color: ${Colors.CG_40};
    color: ${Colors.CG_60};
  }
`;

const InnerButtonInput = styled.div`
  position: relative;
  width: 100%;
  height: 46px;

  > div.postFix {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
  }
`;

const PhoneBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const VerificationBox = styled.div``;

const Timer = styled.div`
  margin-right: 12px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.ERROR};
`;

interface ILongTermUserVerification {
    hide: boolean;
    login?: Login;
}
const LongTermUserVerification: FC<ILongTermUserVerification> = ({ hide, login = new Login() }) => {
  const useFormed = useFormContext();
  const history = useHistory();
  const { errors, watch, getValues, setError } = useFormed;
  const { setToastObject } = useToast();
  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const token = watch('token');
  const [timer, setTimer] = useState<number>(0);
  const [sendMobile, setSendMobile] = useState<string>('');
  const timerRef = useRef<number>(0);
  const [isDiableSendBtn, setIsDiableSendBtn] = useState<boolean>(false);
  const [isVerificate, setIsVerificate] = useState<boolean>(false);

  const onClickSendBtn = async () => {
    if (countryCode && mobile && !(errors.countryCode || errors.mobile)) {
      try {
        setSendMobile(mobile);
        const res = await SmsAPI.sendVerificationSmsToUser({ countryCode, mobile });
        if (res) {
          window.clearTimeout(timerRef.current);
          setTimer(180000);
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증번호가 발송되었습니다.' });
        } else setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호가 발송에 실패했습니다.' });
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else setToastObject({ isOpen: true, type: 'INFO', message: '필수 값들을 모두 입력해 주세요.' });
  };

  useEffect(() => {
    if (mobile !== sendMobile) {
      setTimer(0);
      setIsDiableSendBtn(false);
      setSendMobile('');
    }
    if (timer && !isVerificate && sendMobile) timerRef.current = window.setTimeout(() => setTimer(timer - 1000), 1000);
  }, [timer, isVerificate, mobile, sendMobile]);

  useEffect(() => {
    if (!(countryCode && mobile && timer < 120000) || isVerificate) {
      setIsDiableSendBtn(true);
    } else {
      setIsDiableSendBtn(false);
    }
  }, [countryCode, mobile, isVerificate, timer]);

  const onClickCheckBtn = async () => {
    if (token.length !== 6) setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호를 확인해 주세요.' });
    else if (token && !errors.token) {
      try {
        const res = await SmsAPI.chackVerificationSmsToUser({ countryCode, mobile, token });
        if (res) setIsVerificate(true);
        else {
          setIsVerificate(false);
          setError('token', { message: '인증에 실패했습니다.' });
          setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.' });
        }
      } catch (e: any) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };

  const onSubmit = async () => {
    try {
      const dataRq = getValues();
      await login.updateLongTermAccount(dataRq as ICheckVerificationCodeRq);
      await AuthorizeUtil.updateAccessToken(true);
      // userInfo 업데이트를 기다림
      await login.loadCurrentUser();

      // 상태 확인 후 리다이렉트
      if (!login.userInfo?.authorities.includes(UserAuthorityType.LONG_TERM_INACTIVE_VERIFICATION_REQUIRED)) {
        window.location.href = RoutePaths.root; // 완전한 페이지 리프레시
        // 또는
        // history.replace(RoutePaths.root);
        // window.location.reload();
      }
    } catch (e: any) {
      const err = e as { response: any };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
    }
  };
  return (
    <Frame hide={hide}>
      <PhoneBox>
        <JDInputSelector name='countryCode' label='국가' options={countryList} width='calc((100% - 12px) * .3)' />
        <InnerButtonInput style={{ width: 'calc((100% - 12px) * .7)' }}>
          <JDInput type='tel' name='mobile' label='휴대폰 번호' maxLength='11' inputMode='tel' />
          <div className='postFix'>
            <Button type='button' disabled={isDiableSendBtn} onClick={onClickSendBtn}>
              { timer === 0 ? '인증' : '재인증' }
            </Button>
          </div>
        </InnerButtonInput>
      </PhoneBox>
      <SpacingBlock size={16} vertical />
      <VerificationBox>
        <InnerButtonInput>
          <JDInput type='string' name='token' label='인증번호' maxLength='6' successText={isVerificate ? '인증이 완료되었어요.' : ''} inputMode='numeric' />
          <div className='postFix'>
            { timer !== 0 && !isVerificate ? <Timer>{ convertMsToMMSS(timer) }</Timer> : null }
            <Button type='button' disabled={timer === 0 || isVerificate} onClick={onClickCheckBtn}>
              확인
            </Button>
          </div>
        </InnerButtonInput>
      </VerificationBox>
      <JDModalButton disabled={!isVerificate} onClick={onSubmit}>
        잡다 시작하기
      </JDModalButton>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(LongTermUserVerification));
