import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import MainNoPosition from 'components/_v2/main/MainNoPosition';
import RoutePaths from 'consts/RoutePaths';
import { MatchingHomeRecommendPositionDto } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import { useMatchHomePositionsRecommend, useV1PositionsBookmark } from 'query/match/useMatchQuery';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MainPopularPositionCard from './MainPopularPositionCard';

const Frame = styled.div`
  padding:0 16px;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 32px 0 16px 0;
  .title-area {
    display: flex;
    align-items: center;
    gap:${SpaceValue.M}px;
    .title {
      flex: none;
      margin-left: ${SpaceValue.XXXS}px;
    }
  }
`;

const MainPositionFrame = styled.div`
    padding: 0 16px;
    width: 100%;
    overflow-x: scroll;

    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .scroll-area {
      display: flex;
      gap: 16px;
      width: fit-content;
      padding-right: 16px;
    }
`;

interface IMainPopularPosition {
  main? : Main
  context?: Context;
  login?:Login
}

interface ExtendedRecommendPositionDto extends MatchingHomeRecommendPositionDto {
  bookmarked: boolean;
}

const MainPopularPosition:FC<IMainPopularPosition> = ({ context = new Context(), main = new Main(), login = new Login() }) => {
  const [jobCode, setJobCode] = useState<number>(-1);
  const [processedPositions, setProcessedPositions] = useState<ExtendedRecommendPositionDto[]>([]);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const useFormed = useForm();
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [selectorKey, setSelectorKey] = useState<string>(`selector-${Date.now()}`);

  const { data: jobMainPositionData, isLoading: isLoadingPositions } = useMatchHomePositionsRecommend(jobCode, 8, jobCode > 0);
  const { data: bookmarkData, isLoading: isLoadingBookmark } = useV1PositionsBookmark(
    jobMainPositionData?.recommendPositions.map((position) => position.positionSn) || [],
    !!login.userInfo,
  );

  const { watch, setValue } = useFormed;

  useEffect(() => {
    const init = () => {
      if (login.userInfo) {
        if (profileConditionsData && profileConditionsData?.jobGroups.length > 0) {
          const jobGroupArray = Array.from(profileConditionsData.jobGroups.values());
          const randomIndex = Math.floor(Math.random() * jobGroupArray.length);
          const randomJobGroup = jobGroupArray[randomIndex];
          setJobCode(randomJobGroup.jobGroupCode);
          setValue('orderType', String(randomJobGroup.jobGroupCode));
          setSelectorKey(`selector-${Date.now()}`); // key 갱신
          setIsFirst(false);
        }
      } else if (context.jobGroupsWithTitles) {
        const jobGroupArray = Array.from(context.jobGroupsWithTitles.values());
        const randomIndex = Math.floor(Math.random() * jobGroupArray.length);
        const randomJobGroup = jobGroupArray[randomIndex];
        setJobCode(randomJobGroup.code);
        setValue('orderType', String(randomJobGroup.code));
        setSelectorKey(`selector-${Date.now()}`); // key 갱신
        setIsFirst(false);
      }
    };
    if (isFirst) {
      init();
    }
  }, [login, jobMainPositionData, context.jobGroupsWithTitles, profileConditionsData]);

  useEffect(() => {
    if (jobMainPositionData) {
      const extendedPositions: ExtendedRecommendPositionDto[] = jobMainPositionData.recommendPositions.map((position) => ({
        ...position,
        bookmarked: login.userInfo
          ? bookmarkData?.bookmarkList?.some(
            (bookmark) => bookmark.positionSn === position.positionSn && bookmark.isBookmark,
          ) || false
          : false,
      }));

      setProcessedPositions(extendedPositions);
    }
  }, [jobMainPositionData, bookmarkData, login.userInfo]);

  const isLoading = isLoadingPositions || (login.userInfo && isLoadingBookmark);

  const [maxSelectorWidth, setMaxSelectorWidth] = useState<number | undefined>(undefined);

  const titleRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (titleRef.current && buttonRef.current) {
      const titleWidth = titleRef.current.getBoundingClientRect().width;
      const buttonWidth = buttonRef.current.getBoundingClientRect().width;
      const padding = 16 * 2;
      const gap = SpaceValue.M;

      const maxWidth = window.innerWidth - (titleWidth + buttonWidth + padding + gap);

      setMaxSelectorWidth(maxWidth);
    }
  }, []);

  return (
    <FormProvider {...useFormed}>
      <Frame>
        <TitleFrame>
          <div className='title-area'>
            {context.jobGroupsWithTitles && context.jobGroupsWithTitles.length > 0
                && (
                <JDSelector
                  selectTitle='직군 선택'
                  key={selectorKey}
                  name='orderType'
                  type={JDSelectorType.MAIN}
                  value={watch('orderType')}
                  style={{ maxWidth: maxSelectorWidth }}
                  onChange={async () => {
                    const value = watch('orderType');
                    setJobCode(value);
                  }}
                >
                  <select>
                    {
                    context.jobGroupsWithTitles.map((jobGroup, groupId) => (
                      <option key={`${groupId}-${jobGroup.code}`} value={jobGroup.code}>{jobGroup.name}</option>
                    ))
                  }
                  </select>
                </JDSelector>
                )}
            <V2Text
              className='title'
              fontStyle={V2FontStyleType.title_3_b}
              color={V2TextColorsType.strong}
            >
              직무 인기 포지션
            </V2Text>
          </div>
          <V2Button
            className='btnViewAll'
            size={V2ButtonOption.size.S}
            fontStyle={V2ButtonOption.fontStyle.body_2_sb}
            color={V2ButtonOption.color.subtle}
            aProps={{ href: RoutePaths.position }}
          >
            전체 보기
          </V2Button>
        </TitleFrame>
      </Frame>

      {processedPositions.length > 0
        ? (
          <MainPositionFrame>
            <div className='scroll-area'>
              {processedPositions.map((position) => (
                <Link
                  to={RoutePaths.position_jd(position.positionSn)}
                  key={`${position.positionSn}-innerSlide`}
                  className='link'
                >
                  <MainPopularPositionCard
                    positionInfo={position}
                    key={`${position.positionSn}-innerSlide`}
                  />
                </Link>
              ))}
            </div>
          </MainPositionFrame>
        )
        : !isLoading ? <MainNoPosition /> : <></>}
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </FormProvider>
  );
};

export default inject(injectStore.context, injectStore.positionListModel, injectStore.login, injectStore.main)(observer(MainPopularPosition));
