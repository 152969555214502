import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileTopTab from 'components/_v2/profile/ProfileTopTab';
import ProfileSkillExamination from 'components/_v2/profile/skill/ProfileSkillExamination';
import ProfileSkillKeyword from 'components/_v2/profile/skill/ProfileSkillKeyword';
import ProfileSkillLicense from 'components/_v2/profile/skill/ProfileSkillLicense';
import { IProfileSkillProps } from 'components/_v2/profile/skill/ProfileSkillView';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import useToast from 'hooks/useToast';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IProfileKnowledgeAndSkillsRs from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import IProfileKnowledgeAndSkillsSaveRq, { IProfileExaminationSaveDto, IProfileLicenseSaveDto, IProfileSkillSaveDto } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsSaveRq';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import useQueryParams from 'hooks/useQueryParams';

const Frame = styled.div`

`;

export const SkillListItem = styled.ul`
  li {
    position:relative;
    padding-left:16px 16px 20px 56px;
    font: ${Fonts.B1_Medium};
    color: ${colors.CG_90};
  }
`;

const defaultValues: Partial<IProfileKnowledgeAndSkillsRs> = { licenses: [], examinations: [], skills: [] };

const ProfileSkill:FC<IProfileSkillProps> = ({ profileSkillModel = new ProfileSkillModel(), isMatching = false }) => {
  const { setToastObject } = useToast();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { sourcePage } = useQueryParams();
  const useFormed = useForm<IProfileKnowledgeAndSkillsRs>({
    mode: 'onSubmit',
    defaultValues,
  });

  const { formState, reset, getValues, setValue, handleSubmit } = useFormed;
  useEffect(() => {
    const init = async () => {
      try {
        await profileSkillModel.loadSkill(isMatching);
        if (profileSkillModel.skill)
          reset(profileSkillModel.skill);
      } catch (e) {
        console.error(e);
        reset(defaultValues);
      }
    };
    init();
  }, [profileSkillModel, reset]);

  useEffect(() => {
    profileSkillModel.isDirty = formState.isDirty;
  }, [formState.isDirty]);

  const onSubmit = useCallback(async (data: any) => {
    try {
      const inputData = getValues();

      const transformed: IProfileKnowledgeAndSkillsSaveRq = {
        ...inputData,
        examinations: inputData.examinations?.map<IProfileExaminationSaveDto>(({ code: examinationCode, gradeCode: examinationGradeCode, score }) => ({ examinationCode, examinationGradeCode, score })),
        licenses: inputData.licenses?.map<IProfileLicenseSaveDto>(({ code }) => ({ code })),
        skills: inputData.skills?.map<IProfileSkillSaveDto>(({ code, level }) => ({ code, level })),
        sourcePage,
      };

      if (transformed.examinations === undefined) transformed.examinations = [];
      if (transformed.licenses === undefined) transformed.licenses = [];
      if (transformed.skills === undefined) transformed.skills = [];
      await profileSkillModel.saveSkill(transformed, isMatching);
      setValue('examIdx', null);
      setValue('skillIdx', null);
      profileSkillModel.isDirty = false;
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B901 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B902 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B903 });
          break;
        default:
          setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 실패하였습니다.' });
      }
      console.error(e);
    }
  }, [sourcePage]);

  const onError = () => {

  };

  const topTabList = [
    {
      code: 0,
      name: '자격증',
    },
    {
      code: 1,
      name: '공인 어학시험',
    },
    {
      code: 2,
      name: '기술 키워드',
    },
  ];

  return (
    <>
      <ProfileEditTitleComponent
        title='보유하고 있는 지식 · 기술을 입력해 주세요.'
        subtitle='기업에서 가장 중요하게 보는 항목 중 하나이므로 최대한 많이 기입해 주세요.'
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <ProfileTopTab topTabList={topTabList} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <ProfileSkillLicense hidden={!(topTabList[0].code === currentTab)} />
            <ProfileSkillExamination hidden={!(topTabList[1].code === currentTab)} />
            <ProfileSkillKeyword hidden={!(topTabList[2].code === currentTab)} />
          </Frame>
          <JDSaveButton type='submit' fixed={false} onSubmit={() => {}} />
        </FormProvider>
      </form>
    </>
  );
};

export default inject(injectStore.profileSkillModel)(observer(ProfileSkill));
