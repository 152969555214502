import colors from '__designkit__/common/colors';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import { IconLogo } from 'consts/assets/icons/iconPages';
import ImgProfile from './imgProfile.png';
import PreferredSelfbrandingList from './PreferredSelfbrandingList';

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: ${colors.WHITE_100};
  z-index: 1;
`;

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .step-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px; 
    margin-top: 24px;
  }

  .profile-img {
    padding: 0 ${SpaceValue.XL}px;
    
    img {
    width: 100%;
    margin: ${SpaceValue.XL}px 0;
  }
}

  /* 버튼 리스트 */
  .btn-list {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    width: 100%;
    padding: 0 ${SpaceValue.L}px;

    .apply-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ${SpaceValue.XL}px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${SpaceValue.M}px;

      .badge {
        display: flex;
        align-items: center;
        gap: ${SpaceValue.XXXS}px;
      }
    }
  }

  .btn-area {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
    background: ${colors.WHITE_100};

    button {
      width: 100%;
    }
  }
`;

const PreferredSelfBranding = () => {
  return (
    <>
      <Frame>
        <Header>
          <IconLogo className='logo' />
        </Header>
        <div className='step-title'>
          <Icon name='person-love' size={40} />
          <V2Text fontStyle={V2TextOption.fontStyle.title_2_b} color={V2TextColorsType.default}>
            잡다에 나를 더 돋보이게 할 공간이 생겼어요!
          </V2Text>
          <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextColorsType.subtle}>
            길고 긴 자기소개는 이제 그만!<br />
            나를 한 눈에 담을 수 있는 사진과<br />
            간단한 소개글로 더 많은 기회를 잡아보세요.
          </V2Text>
        </div>
        <div className='profile-img'>
          <img src={ImgProfile} alt='예시 이미지' />
        </div>

        <PreferredSelfbrandingList />
      </Frame>
    </>
  );
};

export default PreferredSelfBranding;
