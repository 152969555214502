/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
export enum V2FillColorsType {
  brand_default = 'fill_brand_default',
  brand_subtle_default = 'fill_brand_subtle_default',
  brand_strong_default = 'fill_brand_strong_default',
  neutral_default = 'fill_neutral_default',
  neutral_subtle_default = 'fill_neutral_subtle_default',
  neutral_strong_default = 'fill_neutral_strong_default',
  success_default = 'fill_success_default',
  success_strong_default = 'fill_success_strong_default',
  danger_default = 'fill_danger_default',
  danger_strong_default = 'fill_danger_strong_default',
  warning_default = 'fill_warning_default',
  warning_strong_default = 'fill_warning_strong_default',
  information_default = 'fill_information_default',
  information_strong_default = 'fill_information_strong_default',
  accent_default = 'fill_accent_default',
  accent_green_default = 'fill_accent_green_default',
  accent_green_strong_default = 'fill_accent_green_strong_default',
  accent_springgreen_default = 'fill_accent_springgreen_default',
  accent_springgreen_strong_default = 'fill_accent_springgreen_strong_default',
  accent_olivegreen_default = 'fill_accent_olivegreen_default',
  accent_olivegreen_strong_default = 'fill_accent_olivegreen_strong_default',
  accent_yellow_default = 'fill_accent_yellow_default',
  accent_yellow_strong_default = 'fill_accent_yellow_strong_default',
  accent_gold_default = 'fill_accent_gold_default',
  accent_gold_strong_default = 'fill_accent_gold_strong_default',
  accent_orange_default = 'fill_accent_orange_default',
  accent_orange_strong_default = 'fill_accent_orange_strong_default',
  accent_red_default = 'fill_accent_red_default',
  accent_red_strong_default = 'fill_accent_red_strong_default',
  accent_pink_default = 'fill_accent_pink_default',
  accent_pink_strong_default = 'fill_accent_pink_strong_default',
  accent_purple_default = 'fill_accent_purple_default',
  accent_purple_strong_default = 'fill_accent_purple_strong_default',
  accent_violet_default = 'fill_accent_violet_default',
  accent_violet_strong_default = 'fill_accent_violet_strong_default',
  accent_cobaltblue_default = 'fill_accent_cobaltblue_default',
  accent_cobaltblue_strong_default = 'fill_accent_cobaltblue_strong_default',
  accent_skyblue_default = 'fill_accent_skyblue_default',
  accent_skyblue_strong_default = 'fill_accent_skyblue_strong_default',
  accent_cyan_default = 'fill_accent_cyan_default',
  accent_cyan_strong_default = 'fill_accent_cyan_strong_default',
  accent_teal_default = 'fill_accent_teal_default',
  accent_teal_strong_default = 'fill_accent_teal_strong_default',
  accent_gray_default = 'fill_accent_gray_default',
  accent_gray_strong_default = 'fill_accent_gray_strong_default',
  accent_gray_strong = 'fill_accent_gray_strong',
}
