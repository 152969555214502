import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfilePreferentialEditModal from 'components/_v2/profile/preferential/ProfilePreferentialEditModal';
import { DisabilityGradeTagStr, DisabilityTypeStr } from 'consts/_v2/profile/DisabilityType';
import { MilitaryClassStr, MilitaryDischargeTypeStr, MilitaryStatusStr, MilitaryTypeStr } from 'consts/_v2/profile/MilitaryType';
import { VeteranType } from 'consts/_v2/profile/VeteranType';
import { IconCalendar } from 'consts/assets/icons/iconPages';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ContentFrame = styled.div`
    margin-bottom: 32px;

    .title {
      font: ${Fonts.H5_Bold};
      color: ${colors.JOBDA_BLACK}; 
      margin-bottom: 16px;
    }

    .tag-box-frame {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        >span {
          font: ${Fonts.B1_Bold};
          color: ${colors.CG_90};
          margin-left: 8px;
        }
    }

    .military-date-frame {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        >span {
          font: ${Fonts.B2_Medium};
          color: ${colors.JOBDA_BLACK}; 
          margin-left: 4px;
        }
    }

    .militaryClass-dischargeType {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.CG_70};
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin-bottom: 32px;
`;

const BoxFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 6px;
    height: 26px;
    font: ${Fonts.B3_Medium};
    line-height: 14px;
    color: ${colors.CG_70};
    background: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_50};
    border-radius: 4px;
`;

export const TagTextBox:FC<{ text: string }> = ({ text }) => (
  <BoxFrame>
    {text}
  </BoxFrame>
);

const ProfilePreferentialView:FC<IProfileProps> = ({ profileModel = new ProfileModel(), isMatching = false }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { preferential } = profileModel;

  const closeDirtyModal = () => {
    profileModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };

  const handleEditClick = () => {
    setIsEditOpen(true);
  };

  return (
    <Frame>
      <ProfileItemTitle icon='military-tech' text='병역 · 취업 우대' subText='병역, 취업 우대사항을 적어주세요.' onClickEditBtn={handleEditClick} />
      { preferential?.military && (
      <ContentFrame>
        <p className='title'>병역사항</p>
        <div className='tag-box-frame'>
          <TagTextBox text={preferential?.military?.status ? MilitaryStatusStr[preferential.military.status] : ''} />
          <span>{preferential?.military?.type ? MilitaryTypeStr[preferential.military.type] : ''}</span>
        </div>
        { preferential?.military?.startDate && (
        <div className='military-date-frame'>
          <IconCalendar />
          <span>{preferential?.military?.startDate} ~ {preferential?.military?.endDate ? preferential?.military?.endDate : '복무중'}</span>
        </div>
        )}
        <span className='militaryClass-dischargeType'>
          {preferential?.military?.militaryClass ? `${MilitaryClassStr[preferential.military.militaryClass]} ${preferential.military.dischargeType ? '/ ' : ''}` : ''}
          {preferential?.military?.dischargeType ? MilitaryDischargeTypeStr[preferential.military.dischargeType] : ''}
        </span>
      </ContentFrame>
      )}

      { (preferential?.disability || preferential?.veteran) && (
        <>
          { preferential?.military && <Divider /> }
          <ContentFrame>
            <p className='title'>우대사항</p>
            { preferential?.disability && (
            <div className='tag-box-frame'>
              <TagTextBox text={preferential.disability.grade ? DisabilityGradeTagStr[preferential.disability.grade] : ''} />
              <span>{preferential.disability.type ? DisabilityTypeStr[preferential.disability.type] : ''}</span>
            </div>
            )}
            { preferential.veteran && (
            <div className='tag-box-frame'>
              <TagTextBox text={preferential.veteran.status === VeteranType.VETERAN ? '보훈 대상자' : '보훈 비대상'} />
              <span>{preferential.veteran.reason ? preferential.veteran.reason : ''}</span>
            </div>
            )}
          </ContentFrame>
        </>
      )}
      <ProfilePreferentialEditModal
        isOpen={isEditOpen}
        isMatching={isMatching}
        onClose={async () => {
          if (profileModel.isDirty) {
            setIsDirtyModalOpen(true);
            return;
          }
          setIsEditOpen(false);
          await profileModel.loadPreferential(isMatching);
        }}
      />
      <ProfileMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={() => {
          setIsEditOpen(false);
          closeDirtyModal();
        }}
        onClickClose={() => setIsDirtyModalOpen(false)}
      />
    </Frame>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePreferentialView));
