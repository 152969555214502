// 매칭 검사 제출 이력 조회

/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { PhsAccType } from 'consts/MatchingMessageType';
import URIs from 'consts/URIs';
import { IMatchApplicantsAccApplyCodeRq, IMatchApplicantsPaccApplyCodeRq, IMatchApplicantsPstApplyCodeRq, IMatchingApplyCompleteRs } from 'interfaces/_v2/IMatchTotalRqRs';
import { request } from 'utils/request';


export default interface MatchTestRepo{
    // 일성검 역검 full 내역 불러오기 처리
    loadMatchApplicantsAccApplyCode(rq:IMatchApplicantsAccApplyCodeRq):Promise<AxiosResponse>
    // 개성검 개역검 full 내역 불러오기 처리
    loadMatchApplicantsPaccApplyCode(rq:IMatchApplicantsPaccApplyCodeRq):Promise<AxiosResponse>
    // 개구검 개역검 full 내역 불러오기 처리
    loadMatchApplicantsPstApplyCode(rq:IMatchApplicantsPstApplyCodeRq):Promise<AxiosResponse>

    // 제출 이력 조회 API
    loadMatchApplicantsMatchingInformationApply(rq:PhsAccType):Promise<IMatchingApplyCompleteRs>
}

export class RemoteMatchTestRepo implements MatchTestRepo {
  loadMatchApplicantsAccApplyCode(rq:IMatchApplicantsAccApplyCodeRq):Promise<AxiosResponse> {
    return request({
      method: 'put',
      url: URIs.put_v2_match_applicants_matching_information_acc_apply_code,
      data: { accApplyCode: rq.accApplyCode },
    });
  }

  loadMatchApplicantsPaccApplyCode(rq:IMatchApplicantsPaccApplyCodeRq):Promise<AxiosResponse> {
    return request({
      method: 'put',
      url: URIs.put_v2_match_applicants_matching_information_pacc_apply_code,
      data: { paccApplyCode: rq.paccApplyCode },
    });
  }

  loadMatchApplicantsPstApplyCode(rq:IMatchApplicantsPstApplyCodeRq):Promise<AxiosResponse> {
    return request({
      method: 'put',
      url: URIs.put_v2_match_applicants_matching_information_pst_apply_code,
      data: { pstApplyCode: rq.pstApplyCode },
    });
  }

  loadMatchApplicantsMatchingInformationApply(rq:PhsAccType):Promise<IMatchingApplyCompleteRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_match_applicants_matching_information_apply,
      params: { type: rq },
    });
  }
}
