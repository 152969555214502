import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TextUtil from 'utils/TextUtil';

interface IJDALinkProps extends IComponentProps {
  to: string;
}

// TODO: Test Code 작성
// https://www-jobda-im.kr-st-jainwon.com/notice/6
// www.jobda.im/position
// www.naver.com
// https://naver.com
// https://www.jobda.im/position
// /position

const JDALink: FC<IJDALinkProps> = ({ to, children, ...props }) => {
  const [toUrl, setToUrl] = useState<string>(to);
  const [innerJobdaYn, setInnerJobdaYn] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (TextUtil.checkExceptionUrl(to))
        return <a target='_blank' rel='noreferrer' href={TextUtil.formatWithProtocolHttp(to)} {...props}>{children}</a>;

      if (TextUtil.checkIncludeJobdaUrl(to)) setInnerJobdaYn(true);

      let fixUrl = to;
      // 프로토콜 제거
      fixUrl = fixUrl.replace(/^(https?:\/\/)/, '');

      // jobda 도메인 제거
      fixUrl = fixUrl.replace('www.jobda.im', '');
      fixUrl = fixUrl.replace('jobda.im', '');
      fixUrl = fixUrl.replace('st2-www-jobda-im.kr-st-jainwon.com', '');
      fixUrl = fixUrl.replace('st2-m-jobda-im.kr-st-jainwon.com', '');
      fixUrl = fixUrl.replace('www-jobda-im.kr-st-jainwon.com', '');
      fixUrl = fixUrl.replace('www-jobda-im.kr-dv-jainwon.com', '');

      // 시작이 /로 시작하지 않으면 추가
      if (!fixUrl.startsWith('/')) {
        fixUrl = `/${fixUrl}`;
      }

      setToUrl(fixUrl);
    };
    init();
  }, []);

  // 잡다내부 링크가 아니면
  if (!innerJobdaYn)
    return <a target='_blank' rel='noreferrer' href={TextUtil.formatWithProtocolHttp(toUrl)} {...props}>{children}</a>;

  return <Link to={toUrl} {...props}>{children}</Link>; // 잡다 내부 링크
};

export default JDALink;
