import IconBackArrow from 'assets/icon/icon_arrow_back.svg';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';

interface IJDAFullScreenModalProps extends IComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  isDisabledScroll?: boolean;
}
const JDAFullScreenModalFrame = styled.section`
  display:block;
  position:fixed;
  z-index:10002;
  width:100%;
  height:100%;
  background:${colors.WHITE_100};
  top:0;
  left:0;
  overflow-y: auto;
  animation-name: open;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;

  .btn-close {
    position: absolute;
    left:12px;
    top:16px;
    background: none;
  }
  .title-area{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10007;

  }
  &.closing {
    opacity:0;
  }
  @keyframes open {
    0% {
      transform:translateY(100%);
      opacity:1;
    }
    100% {
      transform:translateY(0%);
      opacity:1;
    }
  }

`;

export const MainHeaderFrame = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  height:calc(56px);
  padding:0px 12px 0px 16px;
  background:${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  z-index: 10002;
  transition: top .25s, background-color .25s;
  top: ${(props) => (!(props as any)['data-hide'] ? '0' : '-57')}px;
  button{
    background: transparent;
  }

  .left-frame{
    display:flex;
    align-items: center;
    .btn-back{
      margin-right:12px;
    }

  }
`;
const JDFullScreenBackableModal: FC<IJDAFullScreenModalProps> = ({ isOpen, onClose, className, children, isDisabledScroll = true, title, ...props }) => {
  const [closing, setClosing] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (isOpen)
      setClosing(false);
  }, [isOpen]);

  const handleCloseDrawer = () => {
    if (onClose) {
      onClose();
    }
  };
  const preventGoBack = () => {
    window.history.pushState(null, '', document.location.href);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.history.pushState(null, '', location.pathname + location.search);
      window.addEventListener('popstate', preventGoBack);
      return () => {
        window.removeEventListener('popstate', preventGoBack);
        handleCloseDrawer();
      };
    }
  }, [isOpen]);

  return (
    !isOpen ? <></>
      : (
        <Portal>
          {isDisabledScroll && <DisableScroll />}
          <JDAFullScreenModalFrame {...props} className={`${className ?? ''} ${closing ? 'closing' : ''}`}>
            <MainHeaderFrame data-hide={false}>
              <button
                className='btn-close'
                title='뒤로가기'
                onClick={async () => {
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                <img src={IconBackArrow} alt='arrow' />
              </button>
            </MainHeaderFrame>
            {title && (
              <div className='title-area'>
                <V2Text
                  fontStyle={V2TextOption.fontStyle.title_3_b}
                  color={V2TextOption.color.default}
                >
                  {title}
                </V2Text>
              </div>
            )}
            {children}

          </JDAFullScreenModalFrame>
        </Portal>
      )
  );
};

export default JDFullScreenBackableModal;
