/* eslint-disable react-hooks/exhaustive-deps */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { useEffect, useRef, useState } from 'react';
import Video1 from 'pages/_v2/hxIntroduce/video/videoJd1.mp4';
import Video2 from 'pages/_v2/hxIntroduce/video/videoJd2.mp4';
import Video3 from 'pages/_v2/hxIntroduce/video/videoJd3.mp4';
import Video4 from 'pages/_v2/hxIntroduce/video/videoJd4.mp4';
import Video5 from 'pages/_v2/hxIntroduce/video/videoJd5.mp4';
import styled from 'styled-components';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:64px ${SpaceValue.L}px;
  .text-frame{
    display: flex;
    padding:8px 12px;
    border-radius: ${RadiusValue.S}px;
    &.selected{
        background-color: #F3F3F7;
    }
  }
`;

const TextItem = styled.div`
  display: flex;
  padding: 8px 12px;
  border-radius: ${RadiusValue.S}px;
  cursor: pointer;
  &.selected {
    background-color: #F3F3F7;
  }
`;

interface VideoTextItemProps {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const VideoTextItem = ({ text, isSelected, onClick }: VideoTextItemProps) => {
  return (
    <>
      <TextItem className={isSelected ? 'selected' : ''} onClick={onClick}>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_sb}
          color={isSelected ? V2TextOption.color.default : V2TextOption.color.subtle}
        >
          {text}
        </V2Text>
      </TextItem>
      <SpacingBlock size={4} vertical />
    </>
  );
};

const HxVideoSection = () => {
  const [selectedVideo, setSelectedVideo] = useState<string>(VideoItem[0].videoSrc);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 비디오 컨테이너가 화면에 보이는지 감지하는 IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && videoRef.current) {
          // 비디오가 화면에 보일 때 재생
          videoRef.current.currentTime = 0;
          videoRef.current.play().catch((error) => {
            console.log('자동 재생 실패:', error);
          });
        } else if (videoRef.current) {
          // 화면에서 벗어나면 일시정지
          videoRef.current.pause();
        }
      },
      { threshold: 0.05 }, // 5% 이상 보일 때 감지 (원래 주석은 50%라고 되어 있지만 실제 값은 0.05)
    );

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
    }

    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current);
      }
    };
  }, [selectedVideo]); // selectedVideo가 변경될 때마다 observer 재설정

  useEffect(() => {
    if (videoRef.current) {
      // 영상 소스가 변경될 때마다 처음부터 재생
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch((error) => {
        console.log('자동 재생 실패:', error);
      });
    }
  }, [activeIndex, selectedVideo]);

  const handleVideoSelect = (index: number) => {
    setActiveIndex(index);
    setSelectedVideo(VideoItem[index].videoSrc);
  };

  // 전체화면 방지를 위한 핸들러 추가
  const handleVideoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Frame>
      <V2Text fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.strong}>완벽한 JD 생성</V2Text>
      <SpacingBlock size={16} vertical />
      <V2Text fontStyle={V2TextOption.fontStyle.body_1_m} color={V2TextOption.color.default} styles={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
        {`간단한 검색어만 입력해도 JD(Job Description)을
        자동 생성합니다. 생성된 JD를 자유롭게 수정하여
        나만의 맞춤 인재를 완성하세요.`}
      </V2Text>
      <SpacingBlock size={24} vertical />

      {VideoTextItems.map((item, index) => (
        <VideoTextItem
          key={index}
          text={item.text}
          isSelected={activeIndex === index}
          onClick={() => handleVideoSelect(index)}
        />
      ))}

      <SpacingBlock size={32} vertical />
      <div
        ref={videoContainerRef}
        style={{ width: '343px', height: '260px', objectFit: 'cover', borderRadius: '4px' }}
      >
        <video
          ref={videoRef}
          src={selectedVideo}
          playsInline
          muted
          onClick={handleVideoClick}
          style={{ width: '343px', height: '260px', objectFit: 'cover', borderRadius: '4px' }}
        />
      </div>
    </Frame>
  );
};

export default HxVideoSection;

const VideoItem = [
  { videoSrc: Video2 },
  { videoSrc: Video1 },
  { videoSrc: Video3 },
  { videoSrc: Video4 },
  { videoSrc: Video5 },
];

const VideoTextItems = [
  { text: 'HR 경험이 있는 채용 담당자' },
  { text: '웹 화면 개발이 가능한 신입 개발자' },
  { text: 'SNS 디지털 광고 경험이 있는 마케터' },
  { text: '신규거래처 발굴 경험이 있는 영업사원' },
  { text: '제조업 현장경험이 있는 입출고 담당자' },
];
