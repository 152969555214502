import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import CommonUtils from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import { useLocation } from 'react-router-dom';
import MatchApplySettingsJobSearchStatus from './MatchApplySettingsJobSearchStatus';
import MatchApplyPreferred from './MatchApplyPreferred';
import MatchApplyBlindCompany from '../MatchApplyBlindCompany';

const Frame = styled.div`

`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${V2BorderColors.subtle};
`;
const MatchApplySettings: FC = () => {
  // 여기서 쿼리 왔을때 matchapplypreferred로 스크롤되게 해줘. 기존 ScrollUtil 사용해서
  const matchApplyPreferredRef = useRef<HTMLDivElement>(null);
  const { search } = useLocation();
  const { moveToPreference } = CommonUtils.parseQuery(search);

  useEffect(() => {
    if (moveToPreference) {
      ScrollUtil.scrollTo({
        top: matchApplyPreferredRef.current?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [moveToPreference]);
  return (
    <Frame>
      <MatchApplySettingsJobSearchStatus />
      <Divider />
      <div ref={matchApplyPreferredRef}>
        <MatchApplyPreferred />
      </div>
      <Divider />
      <MatchApplyBlindCompany />
    </Frame>
  );
};

export default inject(injectStore.matchApplyStatusModel)(observer(MatchApplySettings));
