import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import IComponentProps from 'interfaces/props/IComponentProps';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled, { keyframes } from 'styled-components';

const openAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const closeAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
`;

const Frame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
  box-sizing: border-box;
  background-color: ${Colors.WHITE_100};
  animation: ${openAnimation} 0.25s 0s 1 ease-out forwards;
  z-index: 10005;

  // iOS 환경에서 .root-portal 아래 모든 요소에 margin-top, margin-bottom 생기는 코드 방지
  margin: 0;

  &.closing {
    animation: ${closeAnimation} 0.25s 0s 1 ease-out forwards;
  }
`;

const Window = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  .close-area{
    background-color: ${Colors.WHITE_100};
    position: sticky;
    left:0;
    top:0;
    height: 56px;
    z-index: 10006;
    width: 100%;
    padding: 16px;
    justify-content:right;
    display:flex;
    .round-area{
      background-color: #f3f3f7;
      border-radius: 9999px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title-area{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10007;

  }
  
`;

interface IJDFullModalProps extends IComponentProps {
  handleClose?: () => void;
  closeCustom?: boolean;
  title?: string;
  isCloseArea?: boolean;
}

const JDFullModal: FC<IJDFullModalProps> = ({ children, handleClose, closeCustom, title, isCloseArea = true }: IJDFullModalProps) => {
  const history = useHistory<{[key: string]: any}>();
  const [originalUrl] = useState<string>(history.location.pathname + history.location.hash);
  const [willClose, setWillClose] = useState<boolean>(false);
  const { search } = useLocation();

  const onClose = () => {
    // if (!closeCustom) setWillClose(true);
    // 모션 동작 다른 방식으로 해결 필요
    setTimeout(() => {
      if (handleClose) {
        handleClose();
      }
    }, 250);
  };

  useEffect(() => {
    // iOS 스와이프 뒤로가기 이벤트 처리
    const handlePopState = () => {
      if (history.location.pathname + history.location.hash === originalUrl && !closeCustom) {
        // onClose();
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose, originalUrl, history, closeCustom]);
  return (
    <Portal>
      <DisableScroll />
      <Frame className={willClose ? 'closing' : ''}>
        <Window>
          {handleClose
          && (
          <div className='close-area'>
            {
              isCloseArea
                ? (
                  <div className='round-area'>
                    <Icon className='close-btn' name='close' size={24} onClick={onClose} />
                  </div>
                )
                : <Icon className='close-btn' name='close' size={24} onClick={onClose} />
            }

          </div>
          )}
          {title && (
            <V2Text
              className='title-area'
              fontStyle={V2TextOption.fontStyle.title_3_b}
              color={V2TextOption.color.default}
            >
              {title}
            </V2Text>
          )}
          {children}
        </Window>
      </Frame>
    </Portal>
  );
};

export default JDFullModal;
