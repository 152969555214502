/* eslint-disable brace-style */
import AppInduceActionModal from 'components/_v2/_common/modals/AppInduceActionModal';
import AppInduceModal from 'components/_v2/_common/modals/AppInduceModal';
import PasswordChangeModal from 'components/_v2/_common/modals/PasswordChangeModal';
import PopupRouter from 'components/_v2/_common/modals/PopupRouter';
import ResumeNudgeModal from 'components/_v2/_common/modals/ResumeNudgeModal';
import ProfileResumeSavedModal from 'components/_v2/profile/ProfileResumeSavedModal';
import ProfileResumeLoadModal from 'components/_v2/profile/loadResume/ProfileResumeLoadModal';
import MatchServiceMainInduceModal from 'components/_v2/profile/match/MatchServiceMainInduceModal';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useInsightResumeAutoSave, useInsightResumeUnread } from 'query/resume/useResumeQuery';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import useGa4 from 'hooks/useGa4';
import { useMatchApplicantFiles, useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { DialogType } from 'components/_v2/videoRecord/VideoRecordGuideHorizontalModal';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import NoticeDialog from 'components/modals/NoticeDialog';
import { useUserDisplayNotification, usePutUsersDisplayNotification } from 'query/match/useMatchQuery';

import { DisplayNotificationType } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';
import NotificationModel from 'models/_v2/NotificationModel';

interface IOptionModalController{
    login?:Login
    context?:Context
    matchApplyModel?:MatchApplyModel
    notificationModel?:NotificationModel
}
const OptionModalController: FC<IOptionModalController> = ({ login = new Login(), context = new Context(), matchApplyModel = new MatchApplyModel(), notificationModel = new NotificationModel() }) => {
  const [isPriorityVisible, setIsPriorityVisible] = useState(true);
  const [passwordChangeModal, setPasswordChangeModal] = useState<boolean>(false);
  const { data: isResumeExisted } = useInsightResumeUnread(!!login.userInfo);
  const { data: insightResumeAutoSaveData } = useInsightResumeAutoSave(!!login.userInfo);
  const [isResumeRender, setIsResumeRender] = useState<boolean>(false);
  const [isOpenResumeModal, setIsOpenResumeModal] = useState<boolean>(false);
  const [isOpenResumeNudgeModal, setIsOpenResumeNudgeModal] = useState<boolean>(false);
  const [isRequiredExposure, setIsRequiredExposure] = useState<boolean>(true);
  const [isOpenAppInduceModal, setIsOpenAppInduceModal] = useState<boolean>(false);
  const [isProfileResumeSavedModal, setIsProfileResumeSavedModal] = useState<boolean>(false);

  // 성향검사 연동 모달
  const [isOpenNoticeDialog, setIsOpenNoticeDialog] = useState<boolean>(false);
  const [isGeneralText, setIsGeneralText] = useState<string>('');
  const [isDeveloperText, setIsDeveloperText] = useState<string>('');

  // 매칭 프로필 연동 모달
  const [isOpenAutoLinkingNoticeDialog, setIsOpenAutoLinkingNoticeDialog] = useState<boolean>(false);
  const [migrationTime, setMigrationTime] = useState<string>('');

  const history = useHistory();
  const { pathname } = useLocation();
  const { data: profileFilesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO, !!login.userInfo);
  const { data: dailyFilesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO, !!login.userInfo);
  const { data: introductionData } = useMatchApplicantIntroduction(!!login.userInfo);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const { data: userDisplayNotification } = useUserDisplayNotification(!!login.userInfo);
  const { mutate: putUsersDisplayNotification } = usePutUsersDisplayNotification(
    () => {
      // console.log('success');
    },
    (error) => {
      console.log('error', error);
    },
  );
  useGa4();
  useEffect(() => {
    const init = async () => {
      setIsResumeRender(!(await RenderCheckUtil.getRenderCheck('resume-induce')));
      const check = await TodayRenderCheckUtil.getTodayRenderCheck('appInduce');
      const resumeCheck = await TodayRenderCheckUtil.getTodayRenderCheck('resume-check');
      await matchApplyModel.loadMatchingProfile();
      await login.getMatchConfigStatus();
      if (login.userInfo?.authorities.includes(UserAuthorityType.MOBILE_UPDATE_REQUIRED_BY_NULL) && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.jobs_ats_preview) {
        if (window.location.pathname !== RoutePaths.update_mobile) {
          setIsRequiredExposure(true);
          history.push(RoutePaths.update_mobile);
        }
        return;
      }
      if (login.userInfo?.authorities.includes(UserAuthorityType.USER_15_TEEN) && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.jobs_ats_preview) {
        if (window.location.pathname !== RoutePaths.update_birthdate) {
          setIsRequiredExposure(true);
          window.location.href = RoutePaths.update_birthdate;
        }
        return;
      }
      if (login.userInfo?.authorities.includes(UserAuthorityType.LONG_TERM_INACTIVE_VERIFICATION_REQUIRED) && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.jobs_ats_preview) {
        if (window.location.pathname !== RoutePaths.update_account) {
          setIsRequiredExposure(true);
          window.location.href = RoutePaths.update_account;
        }
        return;
      }
      if (login.userInfo?.authorities.includes(UserAuthorityType.PASSWORD_CHANGE_REQUIRED) && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.jobs_ats_preview) {
        setIsRequiredExposure(true);
        setPasswordChangeModal(true);

        // 선호정보는 셋다 입력될떄까지 감옥에 가둔다
        // 셀프브랜딩은 하나라도 입력하면 탈출가능
      } else if (pathname === RoutePaths.home || pathname === RoutePaths.match) {
        if (login.userInfo && profileConditionsData && (profileConditionsData?.jobGroups.length === 0 || profileConditionsData?.locations.length === 0 || profileConditionsData?.salary === null)) {
          history.push(RoutePaths.preferred);
        } else if (profileFilesData?.files.length === 0 && dailyFilesData?.files.length === 0 && introductionData?.title === null) {
          history.push(RoutePaths.selfBranding);
        }
      }
      else
      if (userDisplayNotification?.displayNotifications.some((notification) => notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY || notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY || notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY || notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY)) {
        setIsGeneralText(userDisplayNotification?.displayNotifications.some((notification) => notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY || notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY) ? '역량검사 연동 완료: 2000.00.00' : '');
        setIsDeveloperText(userDisplayNotification?.displayNotifications.some((notification) => notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY || notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY) ? '개발자 역량검사 연동 완료: 2000.00.00' : '');
        putUsersDisplayNotification({
          displayNotificationTypes: userDisplayNotification?.displayNotifications
            .filter((notification) => notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY
              || notification.type === DisplayNotificationType.MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY
              || notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY
              || notification.type === DisplayNotificationType.MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY)
            .map((notification) => notification.type),
        });
        setIsOpenNoticeDialog(true);
      } else if (userDisplayNotification?.displayNotifications.some((notification) => notification.type === DisplayNotificationType.MATCHING_PROFILE_MIGRATION)) {
        setMigrationTime(userDisplayNotification?.displayNotifications.find((notification) => notification.type === DisplayNotificationType.MATCHING_PROFILE_MIGRATION)?.migrationTime || '');
        putUsersDisplayNotification({ displayNotificationTypes: [DisplayNotificationType.MATCHING_PROFILE_MIGRATION] });
        setIsOpenAutoLinkingNoticeDialog(true);
      } else
      if (insightResumeAutoSaveData && !resumeCheck && pathname === RoutePaths.profile && login.userInfo) {
        if (insightResumeAutoSaveData.autoSaved) {
          setIsRequiredExposure(true);
          context.isGlobalModalOpen = true;
          setIsProfileResumeSavedModal(true);
        }
      } else
      if (!passwordChangeModal && isResumeExisted && isResumeRender && login.userInfo && (pathname === RoutePaths.profile || pathname === RoutePaths.home || pathname === RoutePaths.mypage_apply)) {
        setIsRequiredExposure(true);
        context.isGlobalModalOpen = true;
        setIsOpenResumeNudgeModal(true);
      } else if (!passwordChangeModal && !isResumeRender && !isOpenResumeNudgeModal && pathname !== RoutePaths.hx_introduce && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.jobs_ats_preview && pathname !== RoutePaths.matching_ad && pathname !== RoutePaths.roulette && !MobileStore.isMobile && pathname !== RoutePaths.jobflex_login && pathname !== RoutePaths.matchLogin && !check && pathname !== RoutePaths.login) {
        setIsRequiredExposure(true);
        context.isGlobalModalOpen = true;
        setIsOpenAppInduceModal(true);
      } else if (pathname !== RoutePaths.matchingGuide() && pathname !== RoutePaths.matchingGuideIntoduce() && pathname !== RoutePaths.matchingGuideUpload())
      {
        context.isGlobalModalOpen = false;
        setIsRequiredExposure(false);
        setIsPriorityVisible(false);
      }
    };
    init();
  }, [login.userInfo, isResumeExisted, pathname, insightResumeAutoSaveData, profileConditionsData, profileFilesData, dailyFilesData, introductionData, matchApplyModel, login, passwordChangeModal, isResumeRender, isOpenResumeNudgeModal, history, context, userDisplayNotification, notificationModel, putUsersDisplayNotification]);
  return (
    <>
      <PasswordChangeModal isOpen={passwordChangeModal} handleClose={() => setPasswordChangeModal(false)} />
      { isOpenResumeModal && (
      <ProfileResumeLoadModal
        isByNudgeModal
        onClickClose={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeModal(false);
        }}
      />
      ) }
      {isOpenAppInduceModal && !isOpenResumeNudgeModal && <AppInduceActionModal isOpenAppInduceActionModal={isOpenAppInduceModal} setIsOpenAppInduceActionModal={setIsOpenAppInduceModal} />}
      { isProfileResumeSavedModal && <ProfileResumeSavedModal onClickClose={() => setIsProfileResumeSavedModal(false)} /> }
      {/* {isOpenServiceErrorModal && <ServiceErrorModal isOpenServiceModal={isOpenServiceErrorModal} setIsOpenServiceModal={setIsOpenServiceErrorModal} />} */}
      { isOpenResumeNudgeModal && (
      <ResumeNudgeModal
        matchDashboard
        onClickClose={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeNudgeModal(false);
        }}
        afterFailLogic={async () => {
          context.isGlobalModalOpen = false;
          setIsResumeRender(false);
        }}
        afterLogic={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeModal(true);
        }}
      />
      ) }

      {
        !isRequiredExposure && pathname !== RoutePaths.matchingGuide() && pathname !== RoutePaths.matchingGuideIntoduce() && pathname !== RoutePaths.matchingGuideUpload() && (
          <>
            <MatchServiceMainInduceModal isRequiredExposure={isRequiredExposure} setIsPriorityVisible={setIsPriorityVisible} />
            {
              !isPriorityVisible && <PopupRouter isRequiredExposure={isRequiredExposure} />
            }

          </>
        )
      }

      {/* 성향검사 연동 모달 */}
      <NoticeDialog
        isOpen={isOpenNoticeDialog}
        title='성향검사 자동 연동 안내'
        content='최근 응시한 역량검사가 성향검사로 자동 연동되었습니다. 성향검사를 응시한 이력이 없을 경우, 최초 1회 자동 연동됩니다.'
        subContent={(
          <>
            {isGeneralText && (
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtlest}
            >{isGeneralText}
            </V2Text>
            )}
            {isDeveloperText && (
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtlest}
            >{isDeveloperText}
            </V2Text>
            )}
          </>
        )}
        isSecondButtonVisible={false}
        onOk={() => { setIsOpenNoticeDialog(false); }}
        onCancel={() => {}}
        dialogType={DialogType.NOTICE}
      />

      <NoticeDialog
        isOpen={isOpenAutoLinkingNoticeDialog}
        title='매칭 프로필 자동 연동 안내'
        content='내 프로필이 매칭 프로필로 자동 연동되었습니다. 매칭 프로필에 입력한 정보가 없는 경우, 최초 1회 자동 연동됩니다.'
        subContent={(
          <>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtlest}
              styles={{ marginTop: 8 }}
            >
              {`매칭 프로필 연동 완료:${DateFormatYYYYMMDDWithDot(migrationTime)}`}
            </V2Text>
          </>
        )}
        isSecondButtonVisible={false}
        onOk={() => { setIsOpenAutoLinkingNoticeDialog(false); }}
        onCancel={() => {}}
        dialogType={DialogType.NOTICE}
      />
    </>
  );
};
export default inject(injectStore.login, injectStore.context, injectStore.matchApplyModel, injectStore.notificationModel)(observer(OptionModalController));
