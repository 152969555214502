import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import DisableScroll from 'components/common/DisableScroll';
import { IconCloseW } from 'consts/assets/icons/iconPages';
import { absoluteCenterCenter, dimmer } from 'consts/style/mixins';
import colors from '__designkit__/common/colors';
import shadows from 'consts/style/shadows';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import Portal from '../common/Portal';

const StyledContent = styled.div`
  background: ${colors.WHITE_100};
  ${absoluteCenterCenter()};
  ${shadows.xlarge};
  border-radius: 8px;
  overflow: hidden;
`;

export const ModalBaseFrame = styled.div<{ dimmed?: boolean; horizonMode?: boolean }>`
  position: relative;
  ${dimmer()};
  z-index: 10007;
  opacity: 0;
  pointer-events: none;
  animation-duration: 0.25s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  
  ${(props) => !props.dimmed
    && css`
      background: transparent;
    `}

  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${(props) => (props.horizonMode ? 'rotate(270deg)' : 'none')};
    animation-duration: 0.25s;
    animation-name: ${(props) => (props.horizonMode ? 'fadeIn' : 'scaleUp')};
    animation-fill-mode: forwards;
    transform-origin: center;
  }

  .close-btn {
    z-index: 1;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    background: none;
  }

  &.active {
    pointer-events: inherit;
    animation-name: fadeIn;
  }

  @keyframes scaleUp {
    from {
      transform: translate(-50%, -50%) scale(0);
    }
    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes scaleDown {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const JDHorizonModal: FC<IModalFrameProps> = (props) => {
  const {
    children,
    horizonMode = false,
    isOpen,
    isCloseBtn,
    isFrame = true,
    isDimClickClose = false,
    onClickClose,
    onDidDismiss,
    onDidPresent,
    onWillDismiss,
    onWillPresent,
    dimmed = true,
  } = props;
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);

  useEffect(() => {
    if (isOpen) {
      if (isOpenState) {
        onDidPresent && onDidPresent();
      } else {
        onWillPresent && onWillPresent();
      }
    } else if (isOpenState) {
      onWillDismiss && onWillDismiss();
    } else {
      onDidDismiss && onDidDismiss();
    }
  }, [isOpen, isOpenState, onWillPresent, onDidPresent, onWillDismiss, onDidDismiss]);

  const animationEndHandler = () => {
    setIsOpenState(isOpen);
  };

  if (isOpen || isOpenState)
    return (
      <Portal>
        <DisableScroll />
        <ModalBaseFrame
          horizonMode={horizonMode}
          dimmed={dimmed}
          className={isOpen ? 'active' : ''}
          onAnimationEnd={animationEndHandler}
          onClick={() => {
            if (isDimClickClose && onClickClose) onClickClose();
          }}
        >
          {isCloseBtn && (
            <button className='close-btn' onClick={onClickClose}>
              <IconCloseW />
            </button>
          )}
          <div className='content-wrapper'>
            {isFrame ? <StyledContent>{children}</StyledContent> : <>{children}</>}
          </div>
        </ModalBaseFrame>
      </Portal>
    );
  return <></>;
};

export default JDHorizonModal;
