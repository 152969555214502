import React from 'react';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import classnames from 'classnames/bind';
import styles from './matchingSecond.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const MatchingSecond = ({ fadeIn }: FlowStoryProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    {/* 매칭 내용 두가지 */}
    <div className={cx('matchingInner')}>
      <div className={cx('matchingBox', 'firstBox')}>
        <div className={cx('contentsInner')}>
          <div className={cx('textInner')}>
            <p className={cx('subText')}>길고 긴 자기소개서는 필요 없어요</p>
            <div className={cx('mainTitle')}>
              15분의 성향검사와
              <br />셀프 브랜딩만으로<span className={cx('light')}> 입사 제안</span>
            </div>
          </div>
          <div className={cx('contents')}>
            나를 타나낼 수 있는 짧은 소개와
            <br />
            프로필 사진과 일상 사진으로
            <br />
            나의 차별점과 강점을 강조해요.
          </div>
          <JDALink
            to={RoutePaths.match_profile}
            className={cx('btnBasic')}
          >
            매칭 프로필 채우기
          </JDALink>
        </div>
      </div>

      <div className={cx('matchingBox', 'secondBox')}>
        <div className={cx('contentsInner')}>
          <div className={cx('textInner')}>
            <p className={cx('subText')}>프로필 선호 정보만 입력하면</p>
            <div className={cx('mainTitle')}>
              <p className={cx('light')}>인사담당자가 직접 입사 제안</p>
              5분 만에 취업 준비 완료!
            </div>
          </div>
          <div className={cx('contents')}>
            자소서도, 입사지원도 필요 없어요
            <br />
            프로필만 입력하면 직접 입사 제안을 드려요!
          </div>
          <JDALink
            to={RoutePaths.profile}
            className={cx('btnBasic')}
          >
            프로필 작성하기
          </JDALink>
        </div>
      </div>
    </div>
  </div>
);

export default MatchingSecond;
