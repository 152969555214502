import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IProfileProjectDto } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileProjectModel from 'models/_v2/profile/ProfileProjectModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import ProfileListCard from '../ProfileListCard';
import ProfileItemTitle from '../ProfileItemTitle';
import ProfileProject from './ProfileProject';

const Frame = styled.div`
`;

const DescriptionCard = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  >h1{
    font: ${Fonts.B2_Bold};
    color:${colors.CG_90};
    margin-bottom: 12px;
  }
  >h2{
    width: 100%;
    display: inline-block;
    white-space: pre-line;
    font: ${Fonts.B2_Medium_P};
    color:${colors.CG_70};
  }
`;
export interface IProfileProjectView{
  profileProjectModel? : ProfileProjectModel
  isMatching?: boolean;
}

const ProfileProjectView:FC<IProfileProjectView> = ({ profileProjectModel = new ProfileProjectModel(), isMatching = false }) => {
  const [openProject, setOpenProject] = useState<boolean>(false);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  const handleEditClick = () => {
    setOpenProject(true);
  };
  const checkAudit = (caseType:ProfileAuditItemType, index:number) => {
    if (profileProjectModel.projects === null || profileProjectModel.projects === undefined || profileProjectModel.projects.length === 0) return false;
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_PROJECT_START_DATE:
        return profileProjectModel.projects[index].auditItems?.includes(ProfileAuditItemType.PROFILE_PROJECT_START_DATE);
      case ProfileAuditItemType.PROFILE_PROJECT_END_DATE:
        return profileProjectModel.projects[index].auditItems?.includes(ProfileAuditItemType.PROFILE_PROJECT_END_DATE);
      default:
        return false;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='project-light' text='프로젝트' subText='참여했던 개인/팀 프로젝트를 적어주세요.' onClickEditBtn={handleEditClick} />
      { profileProjectModel.importedProjects !== null && profileProjectModel.importedProjects.map((card:IProfileProjectDto, index:number) => (
        <ProfileListCard
          key={`card-list-${card.title}`}
          institution={card.title}
          date={`${card.startDate} ~  ${card.endDate}`}
          editDate={checkAudit(ProfileAuditItemType.PROFILE_PROJECT_START_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_PROJECT_END_DATE, index)}
          description={(
            <DescriptionCard>
              <h1>{card.institution}</h1>
              <h2>{card.performance}</h2>
            </DescriptionCard>
            )}
          index={index}
          length={profileProjectModel.importedProjects?.length || 0}
          team={card.teamType}
          contribution={card.contribution}
          skills={card.skills}
        />
      ))}
      <ProfileProject
        isOpen={openProject}
        isMatching={isMatching}
        onClose={async () => {
          await profileProjectModel.loadProjects(isMatching);
          refetchAutoSave();
          profileProjectModel.recommendSkills = [];
          setOpenProject(false);
        }}
      />
    </Frame>
  );
};

export default inject(injectStore.profileProjectModel)(observer(ProfileProjectView));
