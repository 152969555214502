import React, { useState } from 'react';
import classnames from 'classnames/bind';
import Footer from 'components/_v2/_common/Footer';
import useIO from '__pc__/utils/hooks/useIO';
import MainVisual from './mainVisual';
import AlarmVisual from './alarmVisual';
import Interview from './interview';
import LogoFlow from './logoFlow';
import Video from './video';
import LogoList from './logoList';
import FAQ from './faq';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const MatchingAd = () => {
  const [fadeInFirst, setFadeInFirst] = useState(false);
  const [fadeInSecond, setFadeInSecond] = useState(false);
  const [fadeInThird, setFadeInThird] = useState(false);
  const [fadeInFourth, setFadeInFourth] = useState(false);
  const [fadeInFifth, setFadeInFifth] = useState(false);
  const [popSection, setPopSection] = useState(false);

  // Intersection Observer handlers
  const handleIntersectFirst = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFirst(true);
    } else {
      setFadeInFirst(false);
    }
  };

  const handleIntersectSecond = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) setFadeInSecond(true);
  };

  const handleIntersectThird = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) setFadeInThird(true);
  };

  const handleIntersectFourth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) setFadeInFourth(true);
  };

  const handleIntersectFifth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) setFadeInFifth(true);
  };

  const handleIntersectPop = (entries: IntersectionObserverEntry[]) => {
    setPopSection(entries[0].isIntersecting);
  };

  // useIO hooks
  const setFirstSectionRef = useIO({ onIntersect: handleIntersectFirst, threshold: 0.2 });
  const setSecondSectionRef = useIO({ onIntersect: handleIntersectSecond, threshold: 0.2 });
  const setThirdSectionRef = useIO({ onIntersect: handleIntersectThird, threshold: 0.2 });
  const setFourthSectionRef = useIO({ onIntersect: handleIntersectFourth, threshold: 0.2 });
  const setFifthSectionRef = useIO({ onIntersect: handleIntersectFifth, threshold: 0.2 });
  const setPopRef = useIO({ onIntersect: handleIntersectPop, threshold: 0.2 });

  return (
    <div className={cx('wrap')}>
      <MainVisual />

      <div className={cx('floatingBtnArea')}>
        <div ref={setFirstSectionRef}>
          <LogoFlow fadeIn={fadeInFirst} />
        </div>
        <div ref={setSecondSectionRef}>
          <Video fadeIn={fadeInSecond} />
        </div>
        <div ref={setPopRef}>
          <AlarmVisual fadeIn={popSection} />
        </div>
        <div ref={setThirdSectionRef}>
          <Interview fadeIn={fadeInThird} />
        </div>
        <div ref={setFourthSectionRef}>
          <LogoList fadeIn={fadeInFourth} />
        </div>
        <div ref={setFifthSectionRef}>
          <FAQ fadeIn={fadeInFifth} />
        </div>
      </div>

      <Footer isNavi={false} corpInfoFoled={false} />
    </div>
  );
};

export default MatchingAd;
