/* eslint-disable react/jsx-closing-tag-location */
import { ErrorMessage } from '@hookform/error-message';
import { ReactComponent as IconPlus } from 'assets/_v2/_common/input/icon_plus_24_Regular.svg';
import Portal from 'components/common/Portal';
import { IconClose, IconClose20, IconSearch } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { dimmer } from 'consts/style/mixins';
import Fonts from '__designkit__/common/fonts';
import { JDBaseInputMixin, JDInputMessage } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import { INameCodeRs, INameSnRs } from 'interfaces/rqrs/ICommonRqRs';
import { IMajorAliasListGetDto } from 'interfaces/rqrs/IMajorAliasListGetRs';
import React, { forwardRef, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { RegisterOptions, useFormContext, useController } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ISkillDto } from 'interfaces/rqrs/ISkillListRs';

const Frame = styled.div<{disabled?:boolean}>`
  ${JDBaseInputMixin()};
  ${(props) => (props.disabled && 'opacity:0.5;')}
  .input-frame{
    // 돋보기 바깥 padding
    padding:0 16px;
    border-radius:8px;
    display:flex;
    align-items:center;
    background:#fff;
    // 돋보기 내부 input
    input{
      padding: 0;
      margin-left:8px;
    }

    img{
      margin-right:6px;
      object-fit: none;
    }

    .close-btn {
      display: none;
      margin-right: 0;
    }

    .close-btn.active {
      display: block;
    }
    &.false{
    outline: 1px solid ${colors.ERROR};
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }
  }
  .message{
   ${JDInputMessage()};
  }
  
`;

const StyledSearchHeaderFrame = styled.div`
  background-color:${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:1;
  
  .header{
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-between;
   height:calc(48px + env(safe-area-inset-top));
   padding-top:calc(17px + env(safe-area-inset-top));
   padding-left:18px;
   padding-bottom:18px;
   padding-right:18px;
  
   >span{
    font: ${Fonts.B1_Bold};
    color:${colors.CG_90};
   }
  }

  // 모달로 띄어지는 입력창 (외부)
  .inner.input-frame{
    display: block;
    width: 100%;
    height:48px;
    padding: 0px 16px;
    color: ${colors.CG_90};
    display:flex;
    align-items:center;
    box-shadow: 0px 1px 1px ${colors.CG_50};
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colors.JOBDA_WHITE} inset !important;
    }
    input:-webkit-autofill:focus {
     -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
    }

    input {
      background: inherit;
      font: ${Fonts.B1_Medium};
      height: 100%;
      border: none;
      outline: none;
      ::placeholder {
        color: ${colors.CG_60};
      }
    }
    img{
      margin-right:6px;
      object-fit: none;
    }

    >svg {
      margin-right: 16px;
    }
  }
`;

const StyledSearchResultFrame = styled.div`
  background-color:${colors.WHITE_100};
  li{
    position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    height:48px;
    font: ${Fonts.B1_Medium};
    color:${colors.CG_90};
    padding: 12px 16px;
    svg{
      margin-right:16px;
      object-fit: none;
    }
  }
`;

const StyledSearchFrame = styled.div`
 ${dimmer()};
  z-index:100002;
  display:flex;
  flex-direction:column;
  opacity: 1;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
  overflow-y:auto;
  background-color: ${colors.WHITE_100};
`;

interface IJDSearchInput extends IComponentProps {
  textName: string;
  codeName: string;
  title?: string;
  customValidator?: RegisterOptions;
  onSelectItem?: () => void;
  onSearch: (searchText: string) => void;
  searchList: INameCodeRs[] | IMajorAliasListGetDto[] | INameSnRs[] | ISkillDto[];
  defaultTextName?: string; // default 텍스트값;
  defaultCodeName?: string; // default code;
  isDirectItem?: boolean; // 직접입력 아이템 사용
  searchInputCustomPattern?: RegExp;
  listItemIcon?: ReactNode;
  forForeign?:boolean
  removeInputValue?:boolean
}

const JDSearchInput = forwardRef((props: IJDSearchInput) => {
  const { fieldRef, textName, codeName, customValidator, className, placeholder, onSearch, searchList, searchInputCustomPattern, onSelectItem, defaultTextName, defaultCodeName, isDirectItem, disabled = false, title, listItemIcon, forForeign, removeInputValue } = props;
  const useFormed = useFormContext();
  const { register, errors, watch, setValue, control, trigger } = useFormed;
  const frameRef = useRef<HTMLDivElement>(null);
  const innerInputRef = useRef<HTMLInputElement>(null);
  const [openSearchView, setOpenSearchView] = useState<boolean>(false);
  const [searchLis, setSearchLis] = useState<JSX.Element>(<></>);
  const [searchText, setSearchText] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');

  const { field: { ref: BaseRef }, meta } = useController({ name: textName, control });

  const instanceOfINameCodeRs = (object: any): object is INameCodeRs => {
    if (object === undefined) return false;
    return ('code' in object);
  };

  const instanceOFIKeywordCodeRs = (object:any): object is ISkillDto => {
    if (object === undefined) return false;
    return ('keyword' in object);
  };
  const instanceOfIMajorAliasListGetDto = (object: any): object is IMajorAliasListGetDto => {
    if (object === undefined) return false;
    return ('majorCode' in object);
  };

  const instanceOfINameSnRs = (object: any): object is INameSnRs => {
    if (object === undefined) return false;
    return ('sn' in object);
  };

  const onClose = () => {
    setSearchText('');
    setSearchLis(<></>);
  };

  const DirectItem = () => (
    isDirectItem && (
      <li
        role='presentation'
        onClick={() => {
          setInputText(searchText);
          setValue(textName, `${searchText}`, { shouldDirty: true, shouldValidate: true });
          setOpenSearchView(false);
          if (onSelectItem) onSelectItem();
          if (removeInputValue) setInputText('');
          onClose();
        }}
      >
        {listItemIcon ?? <IconPlus />}<span>직접 입력</span>
      </li>
    )
  );

  useEffect(() => {
    if (removeInputValue) setInputText('');
    if (frameRef.current && searchText !== '' && searchList !== undefined) {
      if (instanceOFIKeywordCodeRs(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as ISkillDto[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setInputText(data.keyword);
                    setValue(textName, data.keyword, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.code), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();

                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconPlus />}<span>{data.keyword}</span>
                </li>
              ))
            }
            {DirectItem()}
          </ul>
          ),
        );
      } else
      if (instanceOfINameCodeRs(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as INameCodeRs[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setInputText(data.name);
                    setValue(textName, data.name, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.code), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();

                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconPlus />}<span>{data.name}</span>
                </li>
              ))
            }
            {DirectItem()}
          </ul>
          ),
        );
      } else
      if (instanceOfIMajorAliasListGetDto(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as IMajorAliasListGetDto[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setInputText(data.name);
                    setValue(textName, data.name, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.majorCode), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();
                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconPlus />}<span>{data.name}</span>
                </li>
              ))
            }
            {DirectItem()}
          </ul>
          ),
        );
      } else
      if (instanceOfINameSnRs(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as INameSnRs[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setInputText(data.name);
                    setValue(textName, data.name, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.sn), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();

                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconPlus />}<span>{data.name}</span>
                </li>
              ))
            }
            {DirectItem()}
          </ul>
          ),
        );
      }
      if (searchList.length === 0 || searchList === null) {
        setSearchLis(<ul>{DirectItem()}</ul>);
      }
    } else {
      setSearchLis(<ul>{DirectItem()}</ul>);
    }

    return (() => {
      setSearchLis(<ul />);
    });
  }, [searchList, removeInputValue]);

  useEffect(() => {
    if (!openSearchView) {
      onClose();
    }
  }, [openSearchView]);

  // 국가명 지워지지 않는 현상
  useEffect(() => {
    if (forForeign) {
      if (!watch(codeName)) {
        setValue(textName, '');
      }
    }
  }, [textName, codeName]);

  useEffect(() => {
    if (watch(textName) && !inputText) {
      setInputText(watch(textName));
    }
  }, [removeInputValue]);

  return (
    <Frame disabled={disabled} className={`jda-input ${className || ''} ${!meta.invalid}`}>
      <div className={`input-frame ${errors[textName] === undefined}`}>
        <IconSearch className='search-icon' />
        <input
          ref={BaseRef}
          name={textName}
          placeholder={placeholder}
          value={inputText}
          disabled={disabled}
          autoComplete='off'
          onFocus={(e) => e.currentTarget.blur()}
          onClick={() => {
            setOpenSearchView(true);
            setTimeout(() => innerInputRef.current?.focus(), 200);
          }}
          defaultValue={defaultTextName || ''}
        />
        <input
          style={{ display: 'none' }}
          ref={register()}
          name={codeName}
          defaultValue={defaultCodeName || ''}
        />
        {watch(textName)
          && (
            <IconClose20
              className={`close-btn ${watch(textName) ? 'active' : ''}`}
              onClick={() => {
                setValue(textName, '');
                setValue(codeName, '');
                setInputText('');
                trigger(textName);
              }}
            />
          )}
      </div>
      <Portal>
        <StyledSearchFrame ref={frameRef} style={{ display: openSearchView ? 'block' : 'none' }}>
          <StyledSearchHeaderFrame>
            <div className='header'><span>{title ?? placeholder}</span><IconClose onClick={() => setOpenSearchView(false)} /></div>
            <div className={`inner input-frame ${errors[textName] === undefined}`}>
              <IconSearch className='search-icon' />
              <input
                ref={innerInputRef}
                type='text'
                placeholder={placeholder}
                onChange={(e) => {
                  if (searchInputCustomPattern && !searchInputCustomPattern.test(e.currentTarget.value)) {
                    e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                  }
                  setSearchText(e.currentTarget.value);
                  if (onSearch) onSearch(e.currentTarget.value);
                }}
              />
            </div>
          </StyledSearchHeaderFrame>
          {searchText && (
            <StyledSearchResultFrame>
              {searchLis}
            </StyledSearchResultFrame>
          )}
        </StyledSearchFrame>
      </Portal>
      <ErrorMessage
        errors={errors}
        name={textName}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </Frame>
  );
});

export default JDSearchInput;
