import { FC } from 'react';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import styled from 'styled-components';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import ImgKnowledgeGraph from './imgKnowledgeGraph.png';

import JDFullModal from '../_common/modals/JDFullModal';

const Frame = styled.div`
display: flex;
flex-direction: column;
padding:${SpaceValue.XXL}px ${SpaceValue.L}px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${V2BorderColors.default};
`;
interface IHxDetailModalProps {
    isOpen: boolean;
    handleClose: () => void;
}

const TitleText = ({ title }: { title: string }) => {
  return (
    <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.strong}>
      {title}
    </V2Text>
  );
};
const SecondText = ({ text }: { text: string }) => {
  return (
    <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.strong}>
      {text}
    </V2Text>
  );
};
const ThirdText = ({ text }: { text: string }) => {
  return (
    <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_2_sb} color={V2TextOption.color.strong}>
      {text}
    </V2Text>
  );
};
const FourthStrongText = ({ text }: { text: string }) => {
  return (
    <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.default}>
      {text}
    </V2Text>
  );
};
const FourthText = ({ text, isIndented = false }: { text: string, isIndented?: boolean }) => {
  return (
    <V2Text styles={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', marginLeft: isIndented ? '20px' : '0px' }} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
      {text}
    </V2Text>
  );
};

const HxDetailModal:FC<IHxDetailModalProps> = ({ isOpen, handleClose }) => {
  if (!isOpen) return null;
  return (
    <JDFullModal isCloseArea handleClose={handleClose}>
      <Frame>
        <TitleText title='H.X : LLM-based Technical Overview for B2B Clients' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='1.Overview' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='What is H.X' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthText text=' H.X는 기업의 채용 공고를 정밀하게 해석하고, 수많은 구직자 중 가장 적합한 인재를 자동으로 추천하는 AI 기반 인재추천
솔루션입니다. 기존 키워드 기반 필터링과 달리, 포지션의
맥락을 이해하고 인재의 경험을 의미 단위로 분석하여 정교한 추천을 수행합니다.'
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='기술 요약' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthText text={` • 채용 공고문을 LLM을 활용해 정제하고, 개념 중심의 Knowledge Graph(KG)로 구조화
 • 구직자 프로필과 경험을 KG와 연결해 적합도 자동 계산
 • 포지션별 요구 조건을 LLM이 직접 분석하고 평가 기준표(Rubric) 생성
 • 평가 기준표(Rubric) 기반, 구직자 적합도 또한 LLM을 통한 분석 및 레포트 제공`}
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='2.System Architecture' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='주요 기술 구성' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthStrongText text='• Knowledge Graph Builder' />
        <SpacingBlock size={SpaceValue.XS} vertical />
        <FourthText
          text={`• 채용 공고문에서 핵심 개념 및 관계를 LLM으로 추출해 KG 형태로 구조화`}
          isIndented
        />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthStrongText text='• Position Processor' />
        <SpacingBlock size={SpaceValue.XS} vertical />
        <FourthText
          text={`• 입력된 공고 정보를 자동 해석하여, 관련 기술/역량 노드들과의 연결 생성
• 적합도 평가에 필요한 기준 정보(Rubric) 생성에 활용됨
• 공고에서 특수하게 요구되는 정보에 LLM의 일반 지식 및 미국 노동부의 O*NET 기준을 참조해 일반적 맥락을 반영`}
          isIndented
        />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthStrongText text='• Candidate Evaluator' />
        <SpacingBlock size={SpaceValue.XS} vertical />
        <FourthText
          text={`• 구직자 이력과 경험을 분석해 KG에 연결하고, 포지션 기준과 비교하여 적합도 점수화
• 평가의 기준은 Anchor 인재(A/B/C 등급)의 예시 데이터를 기반으로 LLM이 판단`}
          isIndented
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='3. H.X Workflow' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='1. 채용공고 수집 및 LLM 기반 분석' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='2. Knowledge Graph로 정제 및 등록' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <img src={ImgKnowledgeGraph} alt='knowledgeGraph' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <V2Text fontStyle={V2TextOption.fontStyle.caption_1_m} color={V2TextOption.color.subtlest}>
          Knowledge Graph 노드 연결 예시 (채용 공고문 → 기술 역량 매핑)
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='3. 포지션 평가 기준표 자동 생성 (Rubric)' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='4. KG 기반 후보 인재(n명) 도출' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='5. LLM으로 직무 적합도 정밀 평가' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='6. 최종 인재 추천 및 설명 가능한 리포트 제공' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='4. H.X 알고리즘 상세 설명' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='Knowledge Graph 기반 추천' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthText
          text='• 포지션과 인재를 동일한 개념 체계 내에서 비교함으로써 의미 중심의 연결 판단
• 다양한 Edge 타입 활용: HasUse, IsA, Requires, AlsoKnownAs 등'
          isIndented
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='LLM 기반 직무 적합도 평가' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthText
          text='• 포지션에 대한 정량/정성 정보 입력 → 평가 기준표(Rubric) 생성
• 평가 기준에 따라 인재 프로필을 분석하고, Anchor 인재와 비교해 점수 부여'
          isIndented
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='5. 정확도 검증 및 효과성' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='• 외부 전문가(Human-rater) 기준의 평가 데이터와 비교' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='• 포지션-인재 추천 상관계수 (평균 기준)' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <FourthText
          text='• 기존 추천 솔버: 0.27
• Knowledge Graph 예측: 0.43
• Full LLM 기반 예측: 0.49 → 전문가 수준에 근접한 성능 (전문가 사이 상관관계는 0.3~0.4 수준)'
          isIndented
        />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <SecondText text='6. 향후 개발 방향' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <ThirdText text='• KG의 다층화 및 관계 강화: 기술 ↔ 행동 ↔ 경험 간 연결 고도화' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='• Hybrid 추천 구조 도입: KG 기반 1차 필터 + LLM 기반 정밀 평가' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <ThirdText text='• Explainability 기능 확대: "왜 이 인재인가?"를 설명하는 리포트 자동 생성 및 시각화' />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <Divider />
        <SpacingBlock size={SpaceValue.XL} vertical />
        <FourthText text='제공처 : H.X Team' />
        <SpacingBlock size={SpaceValue.XS} vertical />
        <FourthText text='문서버전 : Client-facing v1.0' />
        <SpacingBlock size={SpaceValue.XS} vertical />
        <FourthText text='작성일 : 2025.03.28' />

      </Frame>
    </JDFullModal>
  );
};

export default HxDetailModal;
