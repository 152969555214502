import MatchingGuideMainPage from 'components/_v2/matchingGuide/MatchingGuideMainPage';
import styled from 'styled-components';

const MatchingGuideWrap = styled.div`
`;

const MatchingGuide = () => {
  return (
    <MatchingGuideWrap>
      <MatchingGuideMainPage />
    </MatchingGuideWrap>
  );
};

export default MatchingGuide;
