import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ImgTutorialCardOne from 'assets/_v2/main/img_tutorial_one.png';
import ImgTutorialCardTwo from 'assets/_v2/main/img_tutorial_two.png';
import ImgTutorialCardThree from 'assets/_v2/main/img_tutorial_three.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2ButtonOption, V2ButtonSizeType, V2ButtonType } from '__pc__/constant/v2Design/V2ButtonType';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { useMatchHomeDashboard } from 'query/match/useMatchQuery';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useLocation } from 'react-router-dom';
import CommonUtils from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import colors from '__designkit__/common/colors';
import MainTutorialIndicator from 'components/_v2/main/tutorial/MainTutorialIndicator';
import ScrollUtil from 'utils/ScrollUtil';
import DisableScroll from 'components/common/DisableScroll';

const Frame = styled.div<{isFocusModalVisible?:boolean}>`
    display: flex;
    flex-direction: column;
    padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
    z-index:${(props) => (props.isFocusModalVisible ? 10006 : 0)};
  position:relative;
  background-color: ${colors.WHITE_100};
`;

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;
const Dim = styled.div<{backgroundColor:V2BackgroundColorsType, zIndex:number}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: ${(props) => props.zIndex};
`;
const CardFrame = styled.div`
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .scroll-area {
      display: flex;
      gap: 16px;
      width: fit-content;
      margin: 0 16px 0 0;
      .info-action-card {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 283px;
        height: fit-content;
        border-radius: 8px;
        border: 1px solid ${V2BorderColors.default};
        .img-area{  
          width: 100%;
          height: 159px;
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .botom-frame{
            display: flex;
            padding:${SpaceValue.L}px;
            flex-direction: column;
            gap:8px;
            .title-frame{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left-text{
                    display: flex;
                    gap:8px;
                }
                .right-not-badge{
                    display: flex;
                    padding: 4px 8px;
                    border-radius: 4px;
                    background-color: ${V2BackgroundColors.accent_red_subtle};
                }
                .right-badge{
                    display: flex;
                    padding: 4px 8px;
                    border-radius: 4px;
                    background-color: ${V2BackgroundColors.accent_green_subtle};
                }
            }
        }
      }
    }

`;
interface IMatchHomeRequiredInfo{
  login?:Login
}
const MatchHomeRequiredInfo:FC<IMatchHomeRequiredInfo> = ({ login = new Login() }) => {
  const { data: dashboardData, refetch } = useMatchHomeDashboard();
  const [isTestAvailable, setIsTestAvailable] = useState<boolean | null>(null);
  const [isFocusModalVisible, setIsFocusModalVisible] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const matchApplyRequiredInfoRef = useRef<HTMLDivElement>(null);
  const [modalZIndex, setDimZIndex] = useState<number>(-1);
  const [isDisabledScroll, setIsDisabledScroll] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      const check = await TodayRenderCheckUtil.getTodayRenderCheck('requiredInfo');
      if (isTestAvailable !== null && login.matchConfigStatus && !login.matchConfigStatus?.matchingConfigYn && matchApplyRequiredInfoRef.current && !check && !isTestAvailable && !login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn) {
        setIsFocusModalVisible(true);
        setBackgroundColor(V2BackgroundColorsType.dimmer);
        setDimZIndex(10005);
        ScrollUtil.scrollTo({
          top: matchApplyRequiredInfoRef.current?.offsetTop - 200,
          // behavior: 'smooth',
        });
        setIsDisabledScroll(true);

        // setTimeout(() => {
        // }, 500); // 스크롤 애니메이션 시간에 맞게 조정 (일반적으로 smooth 스크롤은 300-500ms 소요)
      } else {
        setIsFocusModalVisible(false);
        setBackgroundColor(V2BackgroundColorsType.transparent);
        setDimZIndex(-1);
        setIsDisabledScroll(false);
      }
    };

    init();
  }, [login.matchConfigStatus, login.matchConfigStatus?.matchingConfigYn, login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn, isTestAvailable]);

  useEffect(() => {
    const init = async () => {
      await login.getMatchConfigStatus();
      if (login.matchConfigStatus) {
        const generalAvailable = login.matchConfigStatus.generalYn && login.matchConfigStatus.matchingConfigCondition.accPersonalityCompleteYn;
        const developerAvailable = (login.matchConfigStatus.developerYn && login.matchConfigStatus.matchingConfigCondition.paccPersonalityCompleteYn) || !login.matchConfigStatus.developerYn;
        setIsTestAvailable(generalAvailable && developerAvailable);
      }
    };
    init();
  }, []);
  return (
    <>
      <Dim backgroundColor={backgroundColor} zIndex={modalZIndex} />
      {isDisabledScroll && <DisableScroll />}
      {
        isFocusModalVisible && (
          <MainTutorialIndicator
            titleText='잡다 매칭 참여하기'
            descText='매칭에 필요한 정보 입력하고, 잡다매칭 시작하세요!
750개+ 기업에서 도착할 제안이 기다리고 있습니다.'
            stepText='-1'
            buttonText='확인'
            position='top'
            onClickNext={() => {
              TodayRenderCheckUtil.setTodayRenderCheck('requiredInfo');
              setIsFocusModalVisible(false);
              setBackgroundColor(V2BackgroundColorsType.transparent);
              setDimZIndex(-1);
              setIsDisabledScroll(false);
            }}
            visible={isFocusModalVisible}
          />
        )
      }
      <Frame ref={matchApplyRequiredInfoRef} isFocusModalVisible={isFocusModalVisible}>
        <V2Text
          fontStyle={V2TextOption.fontStyle.title_3_b}
          color={V2TextColorsType.default}
        >
          {
            isTestAvailable && login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn ? (
              '업데이트하면 더 정확한 제안을 받을 수 있어요.'
            ) : (
              '매칭에 필요한 정보를 입력해주세요.'
            )
          }
        </V2Text>
        <SpacingBlock size={SpaceValue.L} vertical />
        <CardFrame>
          <div className='scroll-area'>
            <JDALink
              to={RoutePaths.match_profile}
              onClick={() => {
                setIsFocusModalVisible(false);
                setBackgroundColor(V2BackgroundColorsType.transparent);
                setDimZIndex(-1);
                setIsDisabledScroll(false);
                TodayRenderCheckUtil.setTodayRenderCheck('requiredInfo');
              }}
              className='info-action-card'
            >
              <img src={ImgTutorialCardTwo} alt='img' className='img-area' />
              <div className='botom-frame'>
                <div className='title-frame'>
                  <div className='left-text'>
                    <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.default}>매칭 프로필</V2Text>
                    <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn ? V2TextOption.color.brand : V2TextOption.color.subtlest}>{dashboardData?.matchingProfilePercent}%</V2Text>
                  </div>
                  {
                  login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn ? (
                    <div className='right-badge'>
                      <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_green_default}>완료</V2Text>
                    </div>
                  ) : (
                    <div className='right-not-badge'>
                      <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_red_default}>미완료</V2Text>
                    </div>
                  )
                }
                </div>
                <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
                  {`최신 정보로 업데이트하면\n더 정확한 제안 받을 수 있어요.`}
                </V2Text>
                <V2Button
                  type={V2ButtonOption.type.OutLine}
                  size={V2ButtonOption.size.M}
                  fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                  color={V2ButtonOption.color.subtle}
                  fill={V2ButtonOption.fillType.neutral_subtle_default}
                >
                  작성하기
                </V2Button>
              </div>
            </JDALink>
            <JDALink
              to={RoutePaths.match_test}
              onClick={() => {
                setIsFocusModalVisible(false);
                setBackgroundColor(V2BackgroundColorsType.transparent);
                setDimZIndex(-1);
                setIsDisabledScroll(false);
                TodayRenderCheckUtil.setTodayRenderCheck('requiredInfo');
              }}
              className='info-action-card'
            >
              <img src={ImgTutorialCardThree} alt='img' className='img-area' />
              <div className='botom-frame'>
                <div className='title-frame'>
                  <div className='left-text'>
                    <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.default}>매칭 검사</V2Text>
                  </div>
                  {
                  isTestAvailable ? (
                    <div className='right-badge'>
                      <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_green_default}>완료</V2Text>
                    </div>
                  ) : (
                    <div className='right-not-badge'>
                      <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_red_default}>미완료</V2Text>
                    </div>
                  )
                }
                </div>
                <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
                  {`검사 응시하고 나에게 딱 맞는 제안을 위한 첫걸음을 시작해 보세요.`}
                </V2Text>
                <V2Button
                  type={V2ButtonOption.type.OutLine}
                  size={V2ButtonOption.size.M}
                  fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                  color={V2ButtonOption.color.subtle}
                  fill={V2ButtonOption.fillType.neutral_subtle_default}
                >
                  응시하기
                </V2Button>
              </div>
            </JDALink>
          </div>
        </CardFrame>

      </Frame>

    </>
  );
};

export default inject(injectStore.login)(observer(MatchHomeRequiredInfo));
