/* eslint-disable no-await-in-loop */
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useMatchApplicantFiles, useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
/* eslint-disable prefer-destructuring */
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import IconChange from 'assets/_v2/match/icon_change.svg';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Login from 'models/Login';
import MatchApplySelfBranding from '../matchApplySelfBranding/MatchApplySelfBranding';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  .file-wrap{
    display: flex;
    gap: ${SpaceValue.L}px;
    width:100%;
    overflow-x:scroll;
    overflow-y:hidden;
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .file-upload-container{
      display: flex;
      flex-direction: column;
      gap: ${SpaceValue.L}px;
    }
    .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XL}px 0 ${SpaceValue.L}px 0;
    height: 240px;
    width: 216px;
    border: 1px solid ${V2BorderColors.default};
    border-radius: ${RadiusValue.L}px;
    background-color: white;
    overflow: hidden;

    &.has-upload {
      background-color: black;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .description-background{
      width: 100%;
      height: 110px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      .description-overlay {
      position: absolute;
      bottom: 12px;
      left: 12px;
      right: 12px;
      padding: 8px 12px;
      border-radius: ${RadiusValue.S}px;
      z-index: 1;
      max-height: 80px;
      overflow-y: auto;
      
      /* 스크롤바 숨기기 */
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
    }

    }
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .file-change-icon {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }
  
  .input-container {
    display: none; /* 기존 컨테이너 숨김 처리 */
  }
}
`;

export interface IMatchApplyProfileSelfBranding {
  isOpenSelfIntroduction?: boolean;
  login?: Login;
}

const MatchApplyProfileSelfBranding: FC<IMatchApplyProfileSelfBranding> = ({ isOpenSelfIntroduction = false, login = new Login() }) => {
  const [isOpenSelfBranding, setIsOpenSelfBranding] = useState<boolean>(false);
  const { data: introductionData } = useMatchApplicantIntroduction(!!login.userInfo);
  const { data: filesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO, !!login.userInfo);
  const [photos, setPhotos] = useState<(File | null)[]>([null, null, null]);
  const [previewUrls, setPreviewUrls] = useState<(string | null)[]>([null, null, null]);
  const [descriptions, setDescriptions] = useState<string[]>(['', '', '']);
  const [uploadFileUid, setUploadFileUid] = useState<(string | null)[]>([null, null, null]);
  useEffect(() => {
    const init = async () => {
      try {
        if (filesData?.files) {
          const newPhotos = [...photos];
          const newUrls = [...previewUrls];
          const newDescriptions = [...descriptions];
          const newUploadFileUid = [...uploadFileUid];
          for (let i = 0; i < filesData.files.length; i++) {
            const file = filesData.files[i];
            const { fileUrl, fileUid } = file;
            // const { fileName } = file;
            // const fileExt = fileName.split('.').pop()?.toLowerCase();
            // const mimeType = fileExt === 'png' ? 'image/png'
            //   : fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg'
            //     : 'image/jpeg';

            // const existingFile = await FileUtil.urlToFile(fileUrl, fileName, mimeType);
            // newPhotos[i] = existingFile;
            newUrls[i] = fileUrl;
            newDescriptions[i] = file.fileDescription || '';
            newUploadFileUid[i] = fileUid;
          }

          setPhotos(newPhotos);
          setPreviewUrls(newUrls);
          setDescriptions(newDescriptions);
          setUploadFileUid(newUploadFileUid);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [filesData]);

  useEffect(() => {
    if (isOpenSelfIntroduction) {
      setIsOpenSelfBranding(true);
    }
  }, [isOpenSelfIntroduction]);
  return (
    <Frame>
      <ProfileItemTitle icon='user' text='셀프 브랜딩' subText='간단한 문장과 사진으로 나를 소개하세요.' onClickEditBtn={() => { setIsOpenSelfBranding(true); }} required />
      {
            introductionData && (
            <>
              <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>
                {introductionData.title}
              </V2Text>
              <SpacingBlock vertical size={SpaceValue.XS} />
              <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
                {introductionData.content}
              </V2Text>
            </>
            )
        }
      {
            filesData && (
            <div className='file-wrap'>
              {[0, 1, 2].map((index) => (
                <div className='file-upload-container' key={index}>
                  <div className={`file-upload ${previewUrls[index] ? 'has-upload' : ''}`}>
                    {previewUrls[index] ? (
                      <>
                        <img src={previewUrls[index] ?? undefined} alt='업로드된 이미지' />
                        {descriptions[index] && (
                          <div className='description-background'>
                            <div className='description-overlay'>
                              <V2Text
                                styles={{ whiteSpace: 'break-spaces' }}
                                fontStyle={V2FontStyleType.caption_1_m}
                                color={V2TextColorsType.inverse}
                              >
                                {descriptions[index]}
                              </V2Text>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <V2Icon name='add' />
                        <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
                          사진 없음
                        </V2Text>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
            )
        }
      {
            isOpenSelfBranding
            && (
            <JDFullModal isOpen={isOpenSelfBranding} handleClose={() => setIsOpenSelfBranding(false)} title='셀프 브랜딩'>
              <MatchApplySelfBranding setIsOpenSelfBranding={setIsOpenSelfBranding} />
            </JDFullModal>
            )
        }

    </Frame>
  );
};

export default inject(injectStore.profileCareersModel, injectStore.login)(observer(MatchApplyProfileSelfBranding));
