import React from 'react';
import classnames from 'classnames/bind';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import contentVideo1 from '../img/video1.mp4';
import contentVideo2 from '../img/video2.mp4';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface FlowItemProps {
  duration: string;
  mainText: string[];
  highlightText: string;
  videoSrc: string;
  buttonText: string;
  buttonLink: string;
}

const FlowItem = ({ duration, mainText, highlightText, videoSrc, buttonText, buttonLink }: FlowItemProps) => {
  const [isVideoPlay, setIsVideoPlay] = React.useState(false);
  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('badge')}>
        <V2Icon name='time' size={V2IconOption.size.XS} fill={V2IconOption.fill.subtle} />
        <V2Text fontStyle={V2TextOption.fontStyle.body_3_sb} color={V2TextOption.color.subtle}>
          {duration}분이면 완료!
        </V2Text>
      </div>
      <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.subtlest}>
        {mainText.map((text, index) => (
          <React.Fragment key={index}>
            {text}
            {index < mainText.length - 1 && <br />}
          </React.Fragment>
        ))}
      </V2Text>
      <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.default}>
        {highlightText}
      </V2Text>
      <button id={duration === '5' ? 'eventBtn-3' : 'eventBtn-4'} className={cx('btnApply')} onClick={moveToMatching}>
        <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
          {buttonText}
        </V2Text>
      </button>

      <div className={cx('videoWrapper')}>
        <video
          className={cx(`main_video`, isVideoPlay && 'play')}
          autoPlay
          playsInline
          muted
          loop
          onPlay={() => {
            setIsVideoPlay(true);
          }}
        >
          <source src={videoSrc} type='video/mp4' />
        </video>
      </div>
    </div>
  );
};

interface AnimationProps {
  fadeIn: boolean;
}

const LogoFlow = ({ fadeIn }: AnimationProps) => {
  return (
    <div className={cx('commonBox', 'wrapper', { on: fadeIn })}>
      <FlowItem
        duration='5'
        mainText={['프로필 사진 넣고', '자기소개 작성하면']}
        highlightText='매칭 준비 끝.'
        videoSrc={contentVideo1}
        buttonText='프로필 작성하러 가기'
        buttonLink={RoutePaths.match}
      />
      <FlowItem
        duration='15'
        mainText={['매칭 검사 보면']}
        highlightText='매칭 준비 끝.'
        videoSrc={contentVideo2}
        buttonText='매칭 검사 응시하러가기'
        buttonLink={RoutePaths.match}
      />
    </div>
  );
};

export default LogoFlow;
