import URIs from 'consts/URIs';
import { IGetCareersRs, IPostCareerRq, IPutCareerRq, IPutNhisCareersRq } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import { request } from 'utils/request';

const ProfileAPI = {
  // 경력 리스트 조회
  getCareers: async (isMatching?: boolean) => {
    try {
      const url = isMatching ? URIs.get_v1_match_profile_career : URIs.get_profiles_career;
      const res = await request<IGetCareersRs>({
        method: 'GET',
        url,
      });
      return res;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 추가
  postCareer: async (data: IPostCareerRq, isMatching?: boolean) => {
    try {
      const url = isMatching ? URIs.post_v1_match_profile_career : URIs.post_profiles_career;
      await request<void>({
        method: 'POST',
        url,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 수정
  putCareer: async (sn: number, data: IPutCareerRq, isMatching?: boolean) => {
    try {
      const url = isMatching
        ? URIs.put_v1_match_profile_career_sn(sn)
        : URIs.put_profiles_career(sn);
      await request<void>({
        method: 'PUT',
        url,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 삭제
  deleteCareer: async (sn: number, isMatching?: boolean) => {
    try {
      const url = isMatching
        ? URIs.delete_v2_match_profile_career_sn(sn)
        : URIs.delete_profiles_career(sn);
      await request<void>({
        method: 'DELETE',
        url,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // nhis 연동 경력 추가
  putNhisCareer: async (data: IPutNhisCareersRq, isMatching?: boolean) => {
    try {
      const url = isMatching ? URIs.put_v2_match_profile_career_nhis : URIs.put_v2_profiles_career_nhis;
      await request<void>({
        method: 'PUT',
        url,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default ProfileAPI;
