import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import V2Button from '__pc__/components/common/v2Design/button';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { useMatchApplicantFiles, useSaveMatchApplicantIntroduction, useUploadMatchApplicantProfilePhoto, useUploadMatchApplicantPhotosSelfExpression, useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import Login from 'models/Login';

import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchApplySelfBrandingExample from './MatchApplySelfBrandingExample';
import MatchApplySelfBrandingIntroduce from './MatchApplySelfBrandingIntroduce';
import MatchApplySelfBrandingDailyPhotos from './MatchApplySelfBrandingDailyPhotos';

const Frame = styled.div`
`;

const ButtonFrame = styled.div`
  
  width: 100%;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  background-color: ${V2BackgroundColors.surface_default};
`;

interface MatchApplySelfBrandingProps {
  setIsOpenSelfBranding: (isOpen: boolean) => void;
  login?: Login;
}

const MatchApplySelfBranding = ({ setIsOpenSelfBranding, login = new Login() }: MatchApplySelfBrandingProps) => {
  // Profile 관련 state
  const [profileImage, setProfileImage] = useState<File | null>(null);

  // Introduce 관련 state
  const [introduceTitle, setIntroduceTitle] = useState('');
  const [introduceContent, setIntroduceContent] = useState('');

  // DailyPhotos 관련 state
  const [dailyPhotos, setDailyPhotos] = useState<(File | null)[]>([null, null, null]);
  const [uploadFileUid, setUploadFileUid] = useState<(string | null)[]>([null, null, null]);
  const [photoDescriptions, setPhotoDescriptions] = useState<string[]>(['', '', '']);

  const [isLoading, setIsLoading] = useState(false);
  const { data: introductionData, refetch: refetchIntroduction } = useMatchApplicantIntroduction(!!login.userInfo);
  const { data: filesData, refetch: refetchFiles } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO, !!login.userInfo);
  // API mutations
  const { mutate: saveIntroduction } = useSaveMatchApplicantIntroduction();
  const { mutate: uploadProfilePhoto } = useUploadMatchApplicantProfilePhoto();
  const { mutate: uploadDailyPhotos } = useUploadMatchApplicantPhotosSelfExpression(
    async () => {
      await refetchIntroduction();
      await refetchFiles();
      setIsOpenSelfBranding(false);
    },
    (error) => {
      console.error('Failed to upload daily photos:', error);
    },
  );
  const isAllInputsCompleted = () => {
    // 프로필 사진 체크
    // 소개 체크
    const isIntroduceCompleted = !!introduceTitle && !!introduceContent;

    // 일상 사진 체크
    const isPhotosCompleted = uploadFileUid.every((photo) => photo !== null)
      && photoDescriptions.every((desc) => desc.trim() !== '');

    return isIntroduceCompleted && isPhotosCompleted;
  };

  const handleSaveAll = async () => {
    setIsLoading(true);
    try {
      // 소개 저장
      await saveIntroduction({
        title: introduceTitle,
        content: introduceContent,
      });

      // 일상 사진 저장
      if (uploadFileUid.every((uid) => uid !== null)
        && photoDescriptions.length > 0
        && photoDescriptions.every((desc) => desc.trim() !== '')) {
        await uploadDailyPhotos({
          photos: photoDescriptions.map((desc, index) => ({
            fileUid: uploadFileUid[index] as string,
            description: desc,
          })),
        });
      }
    } catch (error) {
      console.error('Failed to save:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (

    <Frame>
      <MatchApplySelfBrandingExample />
      {/* <MatchApplySelfBrandingProfile
        onImageChange={setProfileImage}
      /> */}
      <MatchApplySelfBrandingIntroduce
        title={introduceTitle}
        content={introduceContent}
        onTitleChange={setIntroduceTitle}
        onContentChange={setIntroduceContent}
      />
      <MatchApplySelfBrandingDailyPhotos
        photos={dailyPhotos}
        descriptions={photoDescriptions}
        onPhotosChange={setDailyPhotos}
        onDescriptionsChange={setPhotoDescriptions}
        uploadFileUid={uploadFileUid}
        setUploadFileUid={setUploadFileUid}
      />
      <ButtonFrame>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          isLoading={isLoading}
          buttonProps={{
            onClick: handleSaveAll,
            disabled: !isAllInputsCompleted(),
          }}
        >
          저장
        </V2Button>
      </ButtonFrame>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApplySelfBranding));
