// 성향 검사

import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2IconSizeType } from '__pc__/constant/v2Design/V2IconType';

import styled from 'styled-components';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { FC, useEffect, useState } from 'react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { observer, inject } from 'mobx-react';
import { PhsAccType } from 'consts/MatchingMessageType';
import MatchApplyExamFrame from '../MatchApplyExamFrame';

const Frame = styled.div`
display: flex;
flex-direction: column;
`;
const HeaderFrame = styled.div`
display: flex;
padding:4px 16px;
`;
const Header = styled.div`
display: flex;
padding: ${SpaceValue.L}px ${SpaceValue.XL}px;
background-color: ${V2BackgroundColors.accent_cobaltblue_subtle};
border-radius: 8px;
width: 100%;
align-items: center;
gap:4px;

`;
const GuideFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SpaceValue.XS}px;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  background-color: ${V2BackgroundColors.accent_gray_subtle};
  border-radius: ${RadiusValue.M}px;
`;

const PaddingFrame = styled.div`
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
`;

interface IMatchApplyTestProps{
  login?:Login;
}
const MatchApplyTest:FC<IMatchApplyTestProps> = ({ login = new Login() }) => {
  const [isGeneral, setIsGeneral] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);

  useEffect(() => {
    const init = async () => {
      await login.getMatchConfigStatus();
      setIsGeneral(login.matchConfigStatus?.generalYn || false);
      setIsDeveloper(login.matchConfigStatus?.developerYn || false);
    };
    init();
  }, [login]);
  return (
    <Frame>
      {isGeneral && <MatchApplyExamFrame type={PhsAccType.ACC} />}
      {isDeveloper && <MatchApplyExamFrame type={PhsAccType.PACC} />}
      {isDeveloper && <MatchApplyExamFrame type={PhsAccType.PST} />}
      <PaddingFrame>
        <GuideFrame>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_sb}
            color={V2TextOption.color.subtle}
          >
            응시 주의사항
          </V2Text>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtlest}
            styles={{ whiteSpace: 'pre-line' }}
          >
            {`• 선호 직군에 따라 매칭에 필요한 검사가 다릅니다.
            • 응시한 검사 결과가 있다면 불러올 수 있습니다.
            • 성향검사는 최근에 응시한 결과가 매칭에 반영됩니다.
            • 성향검사는 이전 응시 내역을 확인할 수 없습니다.`}
          </V2Text>
        </GuideFrame>
      </PaddingFrame>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApplyTest));
