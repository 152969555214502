import { AxiosError } from 'axios';
import { JobSearchStatusType } from 'consts/MatchType';
import { IUserDisplayNotificationRq } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { useMutation, useQuery } from 'react-query';
import { RemoteMatchRepo } from 'repository/match/MatchRepo';

const matchRepo = new RemoteMatchRepo();

const fetchMatchHomeDashboard = async () => {
  const data = await matchRepo.loadMatchHomeDashboard();
  return data;
};

export function useMatchHomeDashboard() {
  return useQuery(['match', 'home', 'dashboard'], fetchMatchHomeDashboard, {
    onError: (error) => {
      console.error('Failed to fetch match home dashboard:', error);
    },
  });
}

const fetchMatchHomeDashboardMatchingSetting = async (rq: any) => {
  const data = await matchRepo.updateMatchHomeDashboardMatchingSetting(rq);
  return data;
};

export function useMatchHomeDashboardMatchingSetting(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeDashboardMatchingSetting, {
    onSuccess,
    onError,
  });
}

const fetchMatchHomeJobPostingsAcc = async (pageSize: number) => {
  const data = await matchRepo.loadMatchHomeJobPostingsAcc(pageSize);
  return data;
};

export function useMatchHomeJobPostingsAcc(pageSize: number) {
  return useQuery(['match', 'home', 'acc', pageSize], () => fetchMatchHomeJobPostingsAcc(pageSize), {
    onError: (error) => {
      console.error('Failed to fetch match home job postings acc:', error);
    },
  });
}

const fetchMatchHomeJobPostingsNow = async (pageSize: number) => {
  const data = await matchRepo.loadMatchHomeJobPostingsNow(pageSize);
  return data;
};

export function useMatchHomeJobPostingsNow(pageSize: number) {
  return useQuery(['match', 'home', 'now', pageSize], () => fetchMatchHomeJobPostingsNow(pageSize), {
    onError: (error) => {
      console.error('Failed to fetch match home job postings now:', error);
    },
  });
}

const fetchMatchHomeMatchingCompany = async () => {
  const data = await matchRepo.loadMatchHomeMatchingCompany();
  return data;
};

export function useMatchHomeMatchingCompany() {
  return useQuery(['match', 'home', 'matching-company'], fetchMatchHomeMatchingCompany, {
    onError: (error) => {
      console.error('Failed to fetch match home matching company:', error);
    },
  });
}

const fetchMatchHomeMatchingPositions = async () => {
  const data = await matchRepo.loadMatchHomeMatchingPositions();
  return data;
};

export function useMatchHomeMatchingPositions() {
  return useQuery(['match', 'home', 'matching-positions'], fetchMatchHomeMatchingPositions, {
    onError: (error) => {
      console.error('Failed to fetch match home matching positions:', error);
    },
  });
}

const fetchMatchHomeMatchingQuestStatus = async () => {
  const data = await matchRepo.loadMatchHomeMatchingQuestStatus();
  return data;
};

export function useMatchHomeMatchingQuestStatus(isEnabled:boolean) {
  return useQuery(['match', 'home', 'matching-quest-status'], fetchMatchHomeMatchingQuestStatus, {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch match home matching quest status:', error);
    },
  });
}

const fetchMatchHomeMatchingTutorialComplete = async () => {
  const data = await matchRepo.saveMatchHomeMatchingTutorialComplete();
  return data;
};

export function useMatchHomeMatchingTutorialComplete(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeMatchingTutorialComplete, {
    onSuccess,
    onError,
  });
}

const fetchMatchHomeMatchingUserCount = async () => {
  const data = await matchRepo.loadMatchHomeMatchingUserCount();
  return data;
};

export function useMatchHomeMatchingUserCount() {
  return useQuery(['match', 'home', 'matching-user-count'], fetchMatchHomeMatchingUserCount, {
    onError: (error) => {
      console.error('Failed to fetch match home matching user count:', error);
    },
  });
}

const fetchMatchHomePositionsRecommend = async (jobGroupCode: number, pageSize: number) => {
  const data = await matchRepo.loadMatchHomePositionsRecommend(jobGroupCode, pageSize);
  return data;
};

export function useMatchHomePositionsRecommend(jobGroupCode: number, pageSize: number, isEnabled:boolean) {
  return useQuery(['match', 'home', 'positions', 'recommend', jobGroupCode, pageSize], () => fetchMatchHomePositionsRecommend(jobGroupCode, pageSize), {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch match home positions recommend:', error);
    },
  });
}

const fetchMatchHomeMatchingTutorialAlarm = async () => {
  const data = await matchRepo.sendMatchHomeMatchingTutorialAlarm();
  return data;
};

export function useMatchHomeMatchingTutorialAlarm(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchHomeMatchingTutorialAlarm, {
    onSuccess,
    onError,
  });
}

const fetchV1JobPostingsBookmark = async (jobPostingSns: number[]) => {
  const data = await matchRepo.loadV1JobPostingsBookmark(jobPostingSns);
  return data;
};

export function useV1JobPostingsBookmark(jobPostingSns: number[], isEnabled: boolean) {
  return useQuery(['match', 'home', 'v1-job-postings-bookmark', jobPostingSns], () => fetchV1JobPostingsBookmark(jobPostingSns), {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch v1 job postings bookmark:', error);
    },
  });
}

const fetchV1PositionsBookmark = async (positionSns: number[]) => {
  const data = await matchRepo.loadV1PositionsBookmark(positionSns);
  return data;
};

export function useV1PositionsBookmark(positionSns: number[], isEnabled: boolean) {
  return useQuery(['match', 'home', 'v1-positions-bookmark', positionSns], () => fetchV1PositionsBookmark(positionSns), {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch v1 positions bookmark:', error);
    },
  });
}

const fetchMatchJobSearchStatus = async (jobSearchStatus: JobSearchStatusType) => {
  const data = await matchRepo.updateMatchJobSearchStatus(jobSearchStatus);
  return data;
};

export function useMatchJobSearchStatus(jobSearchStatus: JobSearchStatusType, onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchMatchJobSearchStatus, {
    onSuccess,
    onError,
  });
}

const fetchV2MatchingHome = async () => {
  const data = await matchRepo.loadV2MatchingHome();
  return data;
};

export function useV2MatchingHome(isEnabled: boolean) {
  return useQuery(['match', 'home', 'v2-matching-home'], fetchV2MatchingHome, {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch v2 matching home:', error);
    },
  });
}

const fetchUserDisplayNotification = async () => {
  const data = await matchRepo.loadUserDisplayNotification();
  return data;
};

export function useUserDisplayNotification(isEnabled: boolean) {
  return useQuery(['match', 'home', 'user-display-notification'], fetchUserDisplayNotification, {
    enabled: isEnabled,
    onError: (error) => {
      console.error('Failed to fetch user display notification:', error);
    },
  });
}

const postProfilesFromMatchingProfile = async () => {
  const data = await matchRepo.postProfilesFromMatchingProfile();
  return data;
};

export function usePostProfilesFromMatchingProfile(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(postProfilesFromMatchingProfile, {
    onSuccess,
    onError,
  });
}

const postMatchProfileFromProfile = async () => {
  const data = await matchRepo.postMatchProfileFromProfile();
  return data;
};

export function usePostMatchProfileFromProfile(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(postMatchProfileFromProfile, {
    onSuccess,
    onError,
  });
}

const putUsersDisplayNotification = async (rq: IUserDisplayNotificationRq) => {
  const data = await matchRepo.putUsersDisplayNotification(rq);
  return data;
};

export function usePutUsersDisplayNotification(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(putUsersDisplayNotification, {
    onSuccess,
    onError,
  });
}
