import Colors from '__designkit__/common/colors';
import styled from 'styled-components';
import { IconLogo } from 'consts/assets/icons/iconPages';
import Fonts from '__designkit__/common/fonts';
import Lottie from 'lottie-react';
import fireCrackerLottie from '__designkit__/assets/lottie/lottie_firecracker.json';
import RoutePaths from 'consts/RoutePaths';
import JDALink from 'components/JDALink';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .lottie {
    margin-top: 120px;
    width: 120px;
    height: 120px;
  }

  .title {
    margin-top: 12px;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }

  .desc {
    margin-top: 12px;
    text-align: center;
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.CG_70};
  }

  .btnArea {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    
    .btn-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      background:#02C551;
      font: ${Fonts.B2_Bold};
      color: ${Colors.WHITE_100};
      border-radius: 8px;
    }
  }
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: ${Colors.WHITE_100};
  border-bottom: 4px solid #F1F3F5;
`;

const Welcome = () => {
  return (
    <Frame>
      <Header>
        <IconLogo className='logo' />
      </Header>

      <div className='lottie'>
        <Lottie animationData={fireCrackerLottie} />
      </div>
      <h1 className='title'>JOBDA 회원가입을 축하합니다!</h1>
      <span className='desc'>쉽고 빠르게 선호정보를 입력하고<br />
        간단한 소개 입력만으로 기업에게 매칭 제안 받아보세요!
      </span>

      {/* 버튼 영역 */}
      <div className='btnArea'>
        <JDALink to={RoutePaths.preferred} className='btn-link'>
          다음
        </JDALink>
      </div>
    </Frame>
  );
};

export default Welcome;
