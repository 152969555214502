/* eslint-disable brace-style */
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const HxTextVisual = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(1);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerTop = containerRect.top;
      const viewportHeight = window.innerHeight;

      // 컨테이너의 상대적 위치 계산 (0~1 범위)
      const relativePosition = containerTop / viewportHeight;

      // 화면에 처음 진입할 때는 완전 불투명하게 시작
      if (relativePosition >= 1) {
        setIsFixed(false);
        setOpacity(1);
      }
      // 화면 중앙에 도달했을 때 고정
      else if (relativePosition <= 0 && relativePosition >= -1.2) {
        setIsFixed(true);
        setOpacity(1);
      }
      // 화면 중앙을 지나 아래로 스크롤할 때 서서히 투명해짐
      else if (relativePosition < -1.2 && relativePosition >= -4.0) {
        setIsFixed(true);
        // -0.1에서 -0.5 사이에서 opacity를 1에서 0으로 변경
        const fadeOutOpacity = 1 - (Math.abs(relativePosition) - 0.1) / 0.4;
        setOpacity(Math.max(0, Math.min(1, fadeOutOpacity)));
      }
      // 완전히 투명해진 후에는 fixed 상태 해제
      else if (relativePosition < -4.0) {
        setIsFixed(false);
        setOpacity(0);
      }
      // 화면 상단에서 내려올 때 서서히 불투명해짐
      else if (relativePosition > 0 && relativePosition < 3) {
        setIsFixed(false);
        setOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // 초기 로드 시 실행

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div ref={containerRef} className={cx('textVisualWrapper')}>
      <div className={cx('textVisualContent')}>
        <div className={cx('container')}>
          <div
            className={cx('textImg')}
            style={{
              opacity,
              transition: 'opacity 0.3s ease-out',
              position: isFixed ? 'fixed' : 'relative',
              top: isFixed ? '50%' : 'auto',
              left: isFixed ? '50%' : 'auto',
              transform: isFixed ? 'translate(-50%, -50%)' : 'none',
              zIndex: isFixed ? 10 : 'auto',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HxTextVisual;
