import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import IconChange from 'assets/_v2/match/icon_change.svg';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import MatchServiceToggle from 'components/_v2/profile/match/MatchServiceToggle';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { JobSearchStatus, JobSearchStatusType } from 'consts/MatchType';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useMatchApplicantFiles } from 'query/match/applicants/useMatchApplicantsQuery';
import { useV2MatchingHome } from 'query/match/useMatchQuery';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import TextUtil from 'utils/TextUtil';

const Frame = styled.div`
    display: flex;
    gap:${SpaceValue.XL}px;
    justify-content: space-between;
    .user-info{
        display: flex;
        flex-direction: column;
      
        .first-line{
            display: flex;
            gap: ${SpaceValue.XS}px;
            align-items: center;
        }
        .second-line{
            display: flex;
            gap: ${SpaceValue.XXXS}px;
        }
        .third-line{
            display: flex;
            gap: ${SpaceValue.XXXS}px;
        }

 
    }
    .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid ${V2BorderColors.default};

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
    .icon-camera{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: ${V2BackgroundColors.accent_green_default};
      border-radius: 50%;
    }
  }

  .file-change {
    position: absolute;
    right: 0;
    bottom: 0;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .icon-change {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

const BadgeFrame = styled.div<{JobSearchStatus: JobSearchStatusType}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 4px 6px;
  border-radius: 4px;
  // 세가지 케이스가 다 나와야함
  background-color:${(props) => (props.JobSearchStatus === JobSearchStatus.ACTIVE_SEARCH ? V2BackgroundColors.accent_green_subtle : props.JobSearchStatus === JobSearchStatus.OPEN_TO_OFFERS ? V2BackgroundColors.accent_skyblue_subtle : V2BackgroundColors.accent_gray_subtle)};
`;
const SmallDivider = styled.div`
    width: 1px;
    height: 8px;
    background-color: ${V2BorderColors.subtle};
`;
interface IMatchApplyHomeUserInfo {
    login?:Login;
}
const MatchApplyHomeUserInfo:FC<IMatchApplyHomeUserInfo> = ({ login = new Login() }) => {
  const { data: filesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const { data: matchingHomeData } = useV2MatchingHome(true);
  const [totalCareers, setTotalCareers] = useState<string>('');
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [schoolInfo, setSchoolInfo] = useState<{schoolName: string, majorName: string}>({ schoolName: '', majorName: '' });
  const [afterToggle, setAfterToggle] = useState<boolean>(false);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      await login.loadCurrentUser();
    };
    init();
  }, []);

  useEffect(() => {
    if (matchingHomeData?.matchingHomeCareers && matchingHomeData.matchingHomeCareers.length > 0) {
      // 총 경력 계산
      let totalCareerPeriod = 0;
      matchingHomeData.matchingHomeCareers.forEach((career) => {
        const endDate = career.employmentStatus === 'WORK' && !career.endDate ? new Date() : new Date(career.endDate);
        totalCareerPeriod += DateUtil.getMonthPeriod(new Date(career.startDate), endDate);
      });
      setTotalCareers(DateUtil.getTotalPeriodTextYM(totalCareerPeriod));

      // 현재 재직 중인 회사 찾기
      const workingCareer = matchingHomeData.matchingHomeCareers.find((career) => career.employmentStatus === 'WORK');
      if (workingCareer) {
        setCurrentCompany(`${workingCareer.companyName} 재직 중`);
      } else if (matchingHomeData.matchingHomeCareers.length > 0) {
        // 재직 중인 회사가 없으면 가장 최근 회사
        const latestCareer = matchingHomeData.matchingHomeCareers.sort(
          (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime(),
        )[0];
        setCurrentCompany(latestCareer.companyName);
      }
    } else {
      setTotalCareers('신입');
      setCurrentCompany('');
    }

    // 학교 정보 설정
    if (matchingHomeData?.education) {
      setSchoolInfo({
        schoolName: matchingHomeData.education.schoolName || '',
        majorName: matchingHomeData.education.profileMajors?.[0]?.majorName || '',
      });
    }
  }, [matchingHomeData]);

  useEffect(() => {
    const init = async () => {
      try {
        if (filesData?.files[0].fileUrl) {
          const { fileUrl } = filesData.files[0];
          const { fileName } = filesData.files[0];

          setProfileImageUrl(fileUrl);
          setPreviewUrls([fileUrl]);
          const fileExt = fileName.split('.').pop()?.toLowerCase();
          const mimeType = fileExt === 'png' ? 'image/png'
            : fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg'
              : 'image/jpeg';

          const existingFile = await FileUtil.urlToFile(fileUrl, fileName, mimeType);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [filesData]);
  useEffect(() => {
    if (afterToggle) {
      setIsOpenConditionInductionModal(true);
      setAfterToggle(false);
    }
  }, [afterToggle]);

  const renderJobSearchStatus = () => {
    if (login.matchConfigStatus?.matchingConfigYn) {
      if (login.matchConfigStatus?.jobSearchStatus === JobSearchStatus.ACTIVE_SEARCH) {
        return (
          <BadgeFrame JobSearchStatus={login.matchConfigStatus?.jobSearchStatus}>
            <V2Text
              fontStyle={V2TextOption.fontStyle.caption_1_sb}
              color={V2TextOption.color.accent_green_default}
            >
              적극적으로 구직 중
            </V2Text>
          </BadgeFrame>
        );
      } if (login.matchConfigStatus?.jobSearchStatus === JobSearchStatus.OPEN_TO_OFFERS) {
        return (
          <BadgeFrame JobSearchStatus={login.matchConfigStatus?.jobSearchStatus}>
            <V2Text
              fontStyle={V2TextOption.fontStyle.caption_1_sb}
              color={V2TextOption.color.accent_skyblue_default}
            >
              맞춤 제안만 받는 중
            </V2Text>
          </BadgeFrame>
        );
      }
      return (
        <BadgeFrame JobSearchStatus={login.matchConfigStatus?.jobSearchStatus}>
          <V2Text
            fontStyle={V2TextOption.fontStyle.caption_1_sb}
            color={V2TextOption.color.subtlest}
          >
            제안 설정 필요
          </V2Text>
        </BadgeFrame>
      );
    }
  };
  return (
    <Frame>
      <div className='user-info'>
        <div className='first-line'>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_b}
            color={V2TextOption.color.default}
          >
            {login.userInfo?.name}님
          </V2Text>
          {
            !login.matchConfigStatus?.matchingConfigYn ? (
              <>
                <SmallDivider />

                <V2Text
                  fontStyle={V2TextOption.fontStyle.body_1_sb}
                  color={V2TextOption.color.disabled}
                >
                  매칭 OFF
                </V2Text>
                <MatchServiceToggle setAfterToggle={setAfterToggle} />
              </>

            ) : (
              <div onClick={() => setIsOpenConditionInductionModal(true)} role='button'>
                {renderJobSearchStatus()}
              </div>
            )
          }
        </div>
        <SpacingBlock size={SpaceValue.M} vertical />
        <div className='second-line'>
          {totalCareers === '신입' ? (
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_3_m}
              color={V2TextOption.color.subtle}
            >
              {totalCareers}
            </V2Text>
          ) : (
            <>
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_3_m}
                color={V2TextOption.color.subtle}
                styles={{ maxWidth: 110 }}
                ellipsis={1}
              >
                경력 {totalCareers}
              </V2Text>
              {currentCompany && (
                <>
                  <V2Text
                    fontStyle={V2TextOption.fontStyle.body_3_m}
                    color={V2TextOption.color.subtle}
                  >
                    •
                  </V2Text>
                  <V2Text
                    fontStyle={V2TextOption.fontStyle.body_3_m}
                    color={V2TextOption.color.subtle}
                    styles={{ maxWidth: 110 }}
                    ellipsis={1}
                  >
                    {TextUtil.removeParenthesesContent(currentCompany)}
                  </V2Text>
                </>
              )}
            </>
          )}
        </div>
        <SpacingBlock size={SpaceValue.XXS} vertical />
        {(schoolInfo.schoolName || schoolInfo.majorName) && (
          <div className='third-line'>
            {schoolInfo.schoolName && (
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_3_m}
                color={V2TextOption.color.subtle}
                styles={{ maxWidth: 110 }}
                ellipsis={1}
              >
                {schoolInfo.schoolName}
              </V2Text>
            )}
            {schoolInfo.schoolName && schoolInfo.majorName && (
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_3_m}
                color={V2TextOption.color.subtle}
              >
                •
              </V2Text>
            )}
            {schoolInfo.majorName && (
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_3_m}
                color={V2TextOption.color.subtle}
                styles={{ maxWidth: 110 }}
                ellipsis={1}
              >
                {schoolInfo.majorName}
              </V2Text>
            )}
          </div>
        )}
      </div>
      <div
        className='file-upload'
        role='button'
        onClick={() => {
          history.push(`${RoutePaths.match_profile}?moveToBasicInfo=true`);
        }}
      >
        {previewUrls.length > 0 ? (
          <img src={previewUrls[0]} alt='Uploaded Preview' />
        ) : (
          <>
            <img src={IconDumy} alt='Uploaded Preview' />
            <div className='icon-camera'>
              <V2Icon name={V2IconOption.name.settings} fill={V2IconOption.fill.inverse} size={V2IconOption.size.S} />
            </div>
          </>
        )}
        {previewUrls.length > 0 && (
          <div className='file-change'>
            <img className='icon-change' src={IconChange} alt='사진 수정' />
          </div>
        )}
      </div>
      <MatchServiceConditionInductionModal
        isOpen={isOpenConditionInductionModal}
        isAlreadyMatched={login.matchConfigStatus?.matchingConfigYn}
        onClickClose={async () => {
          setIsOpenConditionInductionModal(false);
        }}
      />
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApplyHomeUserInfo));
