import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { useState } from 'react';
import styled from 'styled-components';
import IconInfo from './icon_circle_for_banner.png';

const Frame = styled.div`

`;

const ExampleFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: ${V2BackgroundColors.surface_alternative};

    .rolling-space{
      display: flex;
      align-items: center;
      gap: 8px;

      .banner-container{
        display: flex;
        align-items: center;
        width: 75vw;
        height: 18px;
        
        .banner-item {
          display: flex;
          align-items: center;
          gap: 8px;

          .banner-text {
            width: 280px;
          }
          
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
`;

const MatchApplyHomeRolling = () => {
  // 첫 렌더링 시에만 랜덤으로 배너 인덱스 선택
  const [bannerIndex] = useState(() => {
    return Math.floor(Math.random() * RollingBannerText.length);
  });

  return (
    <Frame>
      <ExampleFrame>
        <div className='rolling-space'>
          <div className='banner-container'>
            <div className='banner-item'>
              <img src={IconInfo} alt='info' />
              <V2Text
                className='banner-text'
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtle}
                ellipsis={1}
              >
                {RollingBannerText[bannerIndex].text}
              </V2Text>
            </div>
          </div>
        </div>
      </ExampleFrame>
    </Frame>
  );
};

export default MatchApplyHomeRolling;
const RollingBannerText = [
  { text: '매칭은 일반 지원보다 채용 확률이 2.4배 높아요.' },
  { text: '제안 받은 지원자는 20% 더 빨리 채용돼요.' },
  { text: '한 번의 응답이 더 많은 제안으로 이어져요.' },
  { text: '인사담당자는 최신 정보에 가장 눈길이 가요.' },
  { text: '잡다매칭은 당신의 취업을 진심으로 응원합니다.' },
  { text: '거절 사유가 더 정확한 제안으로 이어져요.' },
];
