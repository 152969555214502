import IconAll from 'assets/_v2/positionList/icon_position_curation_all.svg';
import IconRecommend from 'assets/_v2/positionList/icon_position_curation_recommend.svg';
import PostionRecommendErrorModal from 'components/_v2/position/curation/PostionRecommendErrorModal';
import { IPositionList } from 'components/_v2/position/PositionList';
import { ErrorCodes } from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import Fonts from '__designkit__/common/fonts';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { IPositionCuration } from 'interfaces/_v2/positionList/IPositionCuration';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import PositionCurationModel, { CurationDefaultType } from 'models/_v2/PositionCurationModel';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, forwardRef, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';
import TextUtil from 'utils/TextUtil';
import RecruitTab from 'components/_v2/calendar/RecruitTab';

const StyledPositionCurationTab = styled.div`
  width: 100%;
  overflow: hidden;
  
  >div{
    padding: 0px 16px 0px 16px;
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
    ${ScrollHiddenMixin()}
    >*{

    }
}

  .item-inner {
    margin-top: 16px;
  }
`;

const StyledCurationItem = styled.div<{ selected: boolean }>`
  ${(props) => (props.selected ? ' opacity: 1;' : '  opacity: 0.6;')};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >img{
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-bottom: 8px;
  }

  >h1{
    width: 100%;
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
    text-align: center;
    margin-bottom: 5px;
    white-space: pre;
  }

  span{
    width: 12px;
    height: 2px;
    background-color: ${colors.JOBDA_BLACK};
  }

  padding-right:20px;

`;

export interface IPositionCurationTab {
  positionCurationModel?: PositionCurationModel;
  login?: Login;
}

interface ICurationItem {
  item: Partial<IPositionCuration>;
  selected: boolean;
  onClick: any;
}

const CurationItem = forwardRef<HTMLDivElement, ICurationItem>(({ item, selected, onClick }, ref) => (
  <StyledCurationItem ref={ref} role='button' onClick={onClick} selected={selected}>
    <img src={item.iconAttachFile!.fileUrl} alt={`icon-${item.definition}`} />
    {item.title && <h1>{TextUtil.textLengthEllipsis(item.title, 9)}</h1>}
    {selected && <span />}
  </StyledCurationItem>
));

const PositionCurationTab: FC<IPositionList & IPositionCurationTab> = ({ login = new Login(), positionCurationModel = new PositionCurationModel(), positionListModel = new PositionListModel() }) => {
  const { curationSelect, curations } = positionCurationModel;
  const stepFrameRef = useRef<HTMLDivElement>(null);
  const curationItemRefs = useRef<Array<HTMLDivElement | null>>([]);
  const history = useHistory();

  useEffect(() => {
    if (curationSelect !== CurationDefaultType.RECOMMEND) positionCurationModel.recommendPositionError = '';
  }, [curationSelect]);

  useEffect(() => {
    positionCurationModel.loadCurations();
  }, []);

  return (
    <StyledPositionCurationTab>
      <RecruitTab />
      <div ref={stepFrameRef} className='item-inner'>
        <CurationItem
          ref={(ref) => { curationItemRefs.current[0] = ref; }}
          item={{ title: '전체', iconAttachFile: { fileUrl: String(IconAll), fileType: '' } }}
          selected={curationSelect === CurationDefaultType.ALL}
          onClick={() => {
            if (curationSelect === CurationDefaultType.ALL) return;
            positionListModel.resetAll();
            positionCurationModel.curationSelect = CurationDefaultType.ALL;
            history.replace(RoutePaths.position);
          }}
        />
        {
          curations.map((item, index) => (
            <CurationItem
              key={item.sn}
              ref={(ref) => { curationItemRefs.current[index + 2] = ref; }}
              item={item}
              selected={curationSelect === (index)}
              onClick={() => {
                if (curationSelect === index) return;
                positionListModel.searchFilters.orderType = PositionOrderType.POPULAR;
                positionCurationModel.loadCurationsPositions(item.sn, positionListModel.searchFilters);
                positionCurationModel.curationSelect = (index);
                ScrollUtil.scrollTo({ top: (curationItemRefs.current[0]?.offsetTop || 0) - 30, behavior: 'smooth' });
              }}
            />
          ))
        }
      </div>
      <PostionRecommendErrorModal />
    </StyledPositionCurationTab>
  );
};
export default inject(injectStore.login, injectStore.positionCurationModel, injectStore.positionListModel)(observer(PositionCurationTab));
