/* eslint-disable no-unused-expressions */
// 일성검, 개성검, 개구검

import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2IconColorsType } from '__pc__/constant/v2Design/Colors/V2IconColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2IconOption, V2IconSizeType } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { PhsAccType } from 'consts/MatchingMessageType';
import useToast from 'hooks/useToast';
import { MatchApplyStatus } from 'interfaces/_v2/IMatchTotalRqRs';
import { useMatchApplicantsMatchingInformationApply } from 'query/match/test/useMatchTestQuery';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';
import { TOAST_TYPE } from '../_common/toast/JDToast';
import MatchApplySubmitModal from './MatchApplySubmitModal';
import IconTestCodingViolet from './matchApplyTest/icon_test_coding_violet.png';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  border-bottom: 1px solid ${V2BorderColors.default};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SpaceValue.L}px;
  
  .header-title {
    display: flex;
    gap: ${SpaceValue.L}px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .exam-incompleted-badge{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      background-color: ${V2BackgroundColors.accent_red_subtle};
      border-radius: 4px;

    }
    .exam-completed-frame{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 6px;
      .exam-completed-badge{
        display: flex;
      align-items: center;
      padding: 4px 8px;
      background-color: ${V2BackgroundColors.accent_green_subtle};
      border-radius: 4px;
      width: fit-content;
      align-self: flex-end;
      }
    }
    .header-small-frame{
  display: flex;
  align-items: center;
  gap:8px;
      .header-title-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
    }
  }

  .exam-description{
    white-space: pre-wrap;
  }
  
  .icon {
    width: 56px;
    height: 56px;
  }

  .info-container {
    display: flex;
    gap: ${SpaceValue.L}px;
  }
  .info-divider{
    position: relative;
    top: 50%;
    transform: translateY(100%);
    width: 1px;
    height: 8px;
    background-color: ${V2BorderColors.default};
  }
  .info-item {
    display: flex;
    align-items: center;
    gap: ${SpaceValue.XS}px;
  }
`;
const ExamCompleteFrame = styled.div`
display: flex;
justify-content: space-between;
background-color: ${V2BackgroundColors.accent_green_subtle};
padding: ${SpaceValue.M}px ${SpaceValue.L}px;
border-radius: 8px;
align-items: center;
.icon-text{
    display: flex;
    gap: ${SpaceValue.XS}px;
    align-items: center;
}
`;

const ExamRunningFrame = styled.div`
display: flex;
background-color: ${V2BackgroundColors.accent_gray_subtle};
padding: ${SpaceValue.M}px ${SpaceValue.L}px;
border-radius: 8px;
align-items: center;
.icon-text{
    display: flex;
    gap: ${SpaceValue.XS}px;
    align-items: center;
}
`;

export enum MatchApplyExamType {
    personality = 'personality',
    devPersonality = 'devPersonality',
    pst = 'pst',
}

interface IExamInfo {
  title: string;
  description: string;
  time: string;
  testcase: string;
  enrollText: string;
  enrollButtonText: string;
  testDisabledButton: boolean;
}

const EXAM_INFO: Record<PhsAccType, IExamInfo> = {
  [PhsAccType.ACC]: {
    title: '성향 검사',
    description: `다양한 직군에서 필요한 성향을 진단하고,\n그 결과를 활용한 맞춤형 매칭을 제공합니다.`,
    time: '15분',
    testcase: '나 알아보기, 여러개 선택하기',
    enrollText: '응시 완료',
    enrollButtonText: '불러오기',
    testDisabledButton: true,
  },
  [PhsAccType.PACC]: {
    title: '개발자 성향 검사',
    description: `개발 업무에 필요한 성향을 진단하고,\n그 결과를 활용한 맞춤형 매칭을 제공합니다.`,
    time: '15분',
    testcase: '나 알아보기, 여러개 선택하기',
    enrollText: '응시 완료',
    enrollButtonText: '불러오기',
    testDisabledButton: true,
  },
  [PhsAccType.PST]: {
    title: '개발 구현 능력 검사',
    description: `개발을 통해 문제를 해결하는 능력을 진단하고,\n그 결과를 활용한 맞춤형 매칭을 제공합니다.`,
    time: '100분',
    testcase: '추가 조건 제시형',
    enrollText: '등록 완료',
    enrollButtonText: '결과표 등록하기',
    testDisabledButton: false,
  },
};

interface IMatchApplyExamFrame {
    type: PhsAccType;
}

const MatchApplyExamFrame: FC<IMatchApplyExamFrame> = ({ type }) => {
  const examInfo = EXAM_INFO[type];
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);
  const [currenSubmitType, setCurrentSubmitType] = useState<PhsAccType>(PhsAccType.ACC);
  const [examCompletedTime, setExamCompletedTime] = useState<string>('');
  const [examRunning, setExamRunning] = useState<boolean>(false);
  const [examCompleted, setExamCompleted] = useState<boolean>(false);
  const { data: applyStatus } = useMatchApplicantsMatchingInformationApply(type);
  const { setToastObject } = useToast();
  useEffect(() => {
    if (applyStatus) {
      setExamCompletedTime(applyStatus.submitTime || '');
      setExamRunning(!!applyStatus.progressCode);
      setExamCompleted(!!applyStatus.applicationCode);
    }
  }, [applyStatus]);

  return (
    <Frame>
      <Header>
        <div className='header-title'>
          <div className='header-small-frame'>
            {type === PhsAccType.ACC
              ? <Icon name='test-acca' size={56} />
              : <img src={IconTestCodingViolet} alt='test-coding-violet' className='icon' />}
            <div className='header-title-text'>
              <V2Text
                fontStyle={V2TextOption.fontStyle.title_3_sb}
                color={V2TextOption.color.default}
              >
                {examInfo.title}
              </V2Text>
            </div>
          </div>
          {
                examCompleted
                  ? (
                    <div className='exam-completed-frame'>
                      <div className='exam-completed-badge'>
                        <V2Text fontStyle={V2FontStyleType.caption_1_sb} color={V2TextOption.color.accent_green_default}>완료</V2Text>
                      </div>
                      <V2Text fontStyle={V2FontStyleType.caption_1_sb} color={V2TextOption.color.accent_green_default}>{DateFormatYYYYMMDDHHMMWithDot(examCompletedTime)}</V2Text>

                    </div>
                  )
                  : (
                    <div className='exam-incompleted-frame'>
                      <div className='exam-incompleted-badge'>
                        <V2Text fontStyle={V2FontStyleType.caption_1_sb} color={V2TextOption.color.accent_red_default}>미완료</V2Text>
                      </div>
                    </div>
                  )
              }
        </div>
        <V2Text
          className='exam-description'
          fontStyle={V2TextOption.fontStyle.body_1_m}
          color={V2TextOption.color.subtle}
        >
          {examInfo.description}
        </V2Text>
        <div className='info-container'>
          <div className='info-item'>
            <V2Icon name='time' fill={V2IconColorsType.subtlest} size={V2IconSizeType.S} />
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtle}
            >
              {examInfo.time}
            </V2Text>
          </div>
          <div className='info-divider' />
          <div className='info-item'>
            <V2Icon name='assignment' fill={V2IconColorsType.subtlest} size={V2IconSizeType.S} />
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtle}
            >
              {examInfo.testcase}
            </V2Text>
          </div>
        </div>
        {
              examRunning && (
                <ExamRunningFrame>
                  <div className='icon-text'>
                    <V2Icon name={V2IconOption.name.circleInfo} size={V2IconOption.size.S} fill={V2IconOption.fill.information} />
                    <V2Text fontStyle={V2FontStyleType.body_2_sb} color={V2TextColorsType.default}>응시 중인 성향검사가 있습니다.</V2Text>
                  </div>
                </ExamRunningFrame>
              )
        }
        {
            examInfo.testDisabledButton && (
            <V2Button
              type={V2ButtonOption.type.Fill}
              size={V2ButtonOption.size.L}
              color={V2ButtonOption.color.disabled}
              fill={V2ButtonOption.fillType.accent_cobaltblue_default}
              buttonProps={{ disabled: true }}
            >
              PC에서 응시 가능
            </V2Button>
            )
        }
        {
            type === PhsAccType.PST ? (
              <V2Button
                type={V2ButtonOption.type.Fill}
                size={V2ButtonOption.size.L}
                color={V2ButtonOption.color.inverse}
                fill={V2ButtonOption.fillType.brand_strong_default}
                fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                buttonProps={{ onClick: () => setIsOpenSubmitModal(true) }}
              >
                결과표 등록하기
              </V2Button>
            )
              : (
                <V2Button
                  type={V2ButtonOption.type.OutLine}
                  size={V2ButtonOption.size.L}
                  color={V2ButtonOption.color.subtle}
                  fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                  fill={V2ButtonOption.fillType.neutral_subtle_default}
                  buttonProps={{ onClick: () => setIsOpenSubmitModal(true) }}
                >
                  불러오기
                </V2Button>
              )
}
      </Header>
      <MatchApplySubmitModal
        submitType={type}
        isOpen={isOpenSubmitModal}
        onClickClose={() => setIsOpenSubmitModal(false)}
        checkIfNewApply={applyStatus?.isNewApply && !applyStatus.progressCode}
        onClickSuccess={() => {
          setIsOpenSubmitModal(false);
          type === PhsAccType.ACC
            ? setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '역량검사 결과로 성향검사를 완료했습니다.' })
            : type === PhsAccType.PACC
              ? setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '개발자 역량검사 결과로 성향검사를 완료했습니다.' })
              : setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '결과표가 성공적으로 등록됐습니다.' });
        }}
      />
    </Frame>
  );
};

export default MatchApplyExamFrame;
