import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import styled from 'styled-components';
import ImgNotice8 from './imgNotice8.png';

const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  padding: 0 16px;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;

  .order {
    display: flex;
    gap: 8px;
    margin-bottom: ${SpaceValue.XL}px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${V2BackgroundColors.disabled};
      color: ${V2TextColors.disabled};

      &.order-on {
        background-color: ${V2BackgroundColors.accent_green_default};
        color: ${V2TextColors.inverse};
      }
    }
  }

  .guide-text {
    white-space: break-spaces;
  }

  .notice-img {
    width: fit-content;
    height: auto;
    object-fit: contain;
  }
`;

const NoticeFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;

  > div {
    border-bottom: 1px solid ${V2BorderColors.default};

    &:last-child {
      border-bottom: none;
    }

    &:not(:first-child) {
      padding-top: ${SpaceValue.XXXL}px;
    }
  }
`;
const BtnFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  width: 100vw;
  padding: 0 ${SpaceValue.XL}px ${SpaceValue.XL}px ${SpaceValue.XL}px;
  background-color: ${V2BackgroundColors.surface_default};
`;

const GradientFrame = styled.div`
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 72px); // BtnFrame 높이(버튼 + 패딩)만큼 위로
  left: 0;
  width: 100%;
  height: 24px; // 그라데이션 효과 높이
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
pointer-events: none; // 아래 컨텐츠 클릭 가능하도록
`;
interface VideoRecordNoticeProps {
  subTitle: string;
  guideText: string;
  img: string;
}

const PhotoNotice = ({ subTitle, guideText, img }: VideoRecordNoticeProps) => {
  return (
    <Frame>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        {subTitle}
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
        className='guide-text'
      >
        {guideText}
      </V2Text>
      <SpacingBlock size={SpaceValue.XL} vertical />
      <img className='notice-img' src={img} alt='영상 가이드 이미지' />
    </Frame>
  );
};
interface MatchApplySelfBrandingProfileExampleModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const MatchApplySelfBrandingProfileExampleModal = ({ isOpen, onClose }: MatchApplySelfBrandingProfileExampleModalProps) => {
  if (!isOpen) return null;
  return (
    <JDFullModal isOpen={isOpen} handleClose={onClose}>
      <NoticeFrame>
        <PhotoNotice
          subTitle='매칭 프로필 사진 가이드'
          guideText={`첫 인상을 결정하는 매칭 프로필 사진!\n깔끔하고 자신감이 느껴지는 사진을 추천해요.`}
          img={ImgNotice8}
        />
      </NoticeFrame>
      <GradientFrame />
      <BtnFrame>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          buttonProps={{ onClick: onClose }}
          styles={{ width: '100%' }}
        >
          확인
        </V2Button>
      </BtnFrame>
    </JDFullModal>
  );
};

export default MatchApplySelfBrandingProfileExampleModal;
