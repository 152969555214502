import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import { LazyImageLogo } from 'components/common/LazyImage';
import RoutePaths from 'consts/RoutePaths';
import { MatchApplyStatusType, MatchResponseType } from 'consts/_v2/match/MatchType';
import { lineClampBox } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import { IMatchingStatusDto } from 'interfaces/_v2/matchApply/IMatchingStatusListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDWithDot, getDDayString, str2DateTime } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
    background-color: ${colors.WHITE_100};
    gap: ${SpaceValue.XL}px;
`;
const FrameHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${SpaceValue.L}px;
    .company_info{
        display: flex;
        flex-direction: column;
    }
    .logo{
        width: 56px;
        height: 56px;
        overflow: hidden;
        border: 1px solid #DFE0E2;
    }
`;
const FrameContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .desc {
        display: flex;
        align-items: center;

        .date {
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_70};
            margin-right: 16px;
        }

    }
`;
interface IMatchApplyStatusCardProps {
    matchItem: IMatchingStatusDto;
    matchMessageStore?: MatchMessageStore;
    matchApplyStatusModel?: MatchApplyStatusModel;
    firstToolTip: boolean;
  }

const getStatusMessage = (statusType: MatchApplyStatusType, responseType?: MatchResponseType): string => {
  if (statusType === MatchApplyStatusType.PROFILE_OPEN) {
    return '프로필 열람';
  } if (statusType === MatchApplyStatusType.RECOMMEND) {
    return '추천됨';
  } if (responseType === MatchResponseType.UNANSWERED) {
    return '제안 미응답';
  } if (statusType === MatchApplyStatusType.OFFER) {
    if (responseType === MatchResponseType.ACCEPTED) {
      return '수락 완료';
    } if (responseType === MatchResponseType.REJECTED) {
      return '제안 거절';
    }
    return '응답 대기중';
  } if (statusType === MatchApplyStatusType.OFFER_CLOSED) {
    return '제안 마감';
  }
  return '';
};

const getStatusActive = (statusType: MatchApplyStatusType, responseType?: MatchResponseType) => statusType === MatchApplyStatusType.OFFER && (!responseType || responseType === MatchResponseType.ACCEPTED);

const MatchApplyStatusCard: FC<IMatchApplyStatusCardProps> = ({ matchItem, matchMessageStore = new MatchMessageStore(), matchApplyStatusModel = new MatchApplyStatusModel(), firstToolTip }) => {
  const history = useHistory();
  const cardDisabled = matchItem.statusType === MatchApplyStatusType.OFFER_CLOSED || matchItem.responseType === MatchResponseType.REJECTED || matchItem.responseType === MatchResponseType.UNANSWERED;
  const [like, setLike] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const [showTooltip, setShowTooltip] = useState(firstToolTip);
  const dDay = matchItem.decisionDueDateTime ? getDDayString(str2DateTime(matchItem.decisionDueDateTime)) : '';

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!cardDisabled) {
      if (matchItem.statusType === MatchApplyStatusType.OFFER) {
        matchMessageStore.messageAccessPath = MessageAccessPath.MATCHING_CARD;
        matchMessageStore.open(matchItem.positionSn);
        matchApplyStatusModel.readMatchCard(matchItem.matchingSn);
      } else {
        history.push(RoutePaths.position_jd(matchItem.positionSn));
      }
    }
  }, [cardDisabled, matchItem, matchMessageStore, matchApplyStatusModel]);

  const handleCompanyClick = useCallback((e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.stopPropagation();
    if (cardDisabled) return;
    history.push(RoutePaths.company_details(matchItem.companySn));
  }, [cardDisabled, history, matchItem.companySn]);

  return (
    <Frame onClick={handleClick}>
      <FrameHeader>
        <button className='logo' onClick={handleCompanyClick}>
          <LazyImageLogo src={matchItem.companyLogoUrl} />
        </button>
        <div className='company_info'>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_sb}
            color={V2TextOption.color.default}
          >
            {matchItem.companyName}
          </V2Text>
          <SpacingBlock size={SpaceValue.XXXS} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_sb}
            color={V2TextOption.color.subtle}
          >
            {matchItem.positionTitle}
          </V2Text>
        </div>
      </FrameHeader>
      <FrameContent>
        <div className='desc'>
          {
              matchItem.statusType === MatchApplyStatusType.OFFER && dDay && !matchItem.responseType
                ? (
                  <div className='d_day_frame' aria-selected={dDay === 'D-day'}>
                    <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.default} element={V2TextOption.element.span}>{matchItem.decisionDueDateTime && `${dDay}`}</V2Text>
                  </div>
                )
                : (
                  <div className='date'>{DateFormatYYYYMMDDWithDot(matchItem.matchingStatusLastModifiedDateTime)}</div>
                )
            }
        </div>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_sb}
          color={getStatusActive(matchItem.statusType, matchItem.responseType) ? V2TextOption.color.brand : V2TextOption.color.subtle}
        >
          {getStatusMessage(matchItem.statusType, matchItem.responseType)}
        </V2Text>
      </FrameContent>
    </Frame>
  );
};

export default inject(injectStore.matchMessageStore, injectStore.matchApplyStatusModel)(observer(MatchApplyStatusCard));
