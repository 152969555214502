import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const cardTitles = ['카카오뱅크', 'HD그룹', 'SPC그룹', '넥센타이어', '대웅제약', '에스오일'];
const titleColors = ['#FFE300', '#0CCC55', '#FFFFFF', '#E759FF', '#FF8400', '#0FA964'];

const MainVisual = () => {
  const boxRefs = useRef<(HTMLDivElement | null)[]>([]);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setIsFirstRender(false);
        setCurrentIndex((prev) => (prev + 1) % cardTitles.length);
      }, 1500);
      return () => {
        clearInterval(interval);
      };
    });

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    boxRefs.current.forEach((box, index) => {
      if (!box) return;

      const isActive = index === currentIndex;
      const isPrev = !isFirstRender && (index === ((currentIndex - 1 + cardTitles.length) % cardTitles.length));
      const relativeIndex = (index - currentIndex + cardTitles.length) % cardTitles.length;
      const baseTranslateY = relativeIndex * 23;
      const scale = Math.max(1 - (relativeIndex * 0.05), 0.8);

      if (isActive) {
        box.style.transform = `translate(0, 0) scale(${scale})`;
        box.style.opacity = '1';
        box.style.zIndex = '20';
        box.style.transition = 'all 0.8s ease-in-out';
      } else if (isPrev) {
        box.style.transform = `translate(150px, -${baseTranslateY - 50}px) rotate(15deg) scale(0.8)`;
        box.style.opacity = '0';
        box.style.zIndex = '1';
        box.style.transition = 'all 1s ease-in-out';
      } else {
        box.style.transform = `translate(0, ${baseTranslateY}px) rotate(0deg) scale(${scale})`;
        box.style.opacity = '1';
        box.style.zIndex = String(cardTitles.length - relativeIndex);
        box.style.transition = 'all 0.8s ease-in-out';
      }
    });

    titleRefs.current.forEach((titleEl, index) => {
      if (!titleEl) return;

      const isActive = index === currentIndex;
      const isPrev = (index === ((currentIndex - 1 + cardTitles.length) % cardTitles.length));
      const isNext = (index === ((currentIndex + 1) % cardTitles.length));

      if (isActive) {
        titleEl.style.transform = 'translateY(0)';
        titleEl.style.opacity = '1';
      } else if (isPrev) {
        titleEl.style.transform = 'translateY(-100%)';
        titleEl.style.opacity = '0';
      } else if (isNext) {
        titleEl.style.transform = 'translateY(100%)';
        titleEl.style.opacity = '0';
      } else {
        titleEl.style.transform = 'translateY(0)';
        titleEl.style.opacity = '0';
      }
    });
  }, [currentIndex, isFirstRender]);

  return (
    <div ref={wrapperRef} className={cx('wrapper')}>
      <div className={cx('mainVisual')}>
        <div className={cx('mainInner')}>
          <div className={cx('mainTitle')}>
            <div className={cx('titleList')}>
              {cardTitles.map((title, index) => (
                <div
                  key={`title-${index}`}
                  ref={(el) => (titleRefs.current[index] = el)}
                  className={cx('titleItem')}
                  style={{ color: titleColors[index] }}
                >
                  {title}
                </div>
              ))}
            </div>
            <V2Text
              className={cx('mainTitle2')}
              fontStyle={V2TextOption.fontStyle.heading_2_b}
              color={V2TextOption.color.strong}
            >
              팀장님이 내 프로필을
              <br />
              주목하고 있어요!
            </V2Text>
          </div>
          <V2Text
            className={cx('mainTitle3')}
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.subtle}
            styles={{ marginTop: '12px' }}
          >
            신입부터 경력까지.<br />내 역량만으로 쏟아지는 입사제안!
          </V2Text>
        </div>
        <div className={cx('contentWrapper')}>
          <div className={cx('visualWrap')}>
            {cardTitles.map((title, index) => (
              <div
                key={`box-${index}`}
                ref={(el) => (boxRefs.current[index] = el)}
                className={cx('box', `box${index + 1}`)}
              />
            ))}
          </div>
        </div>
        <button id='eventBtn-1' className={cx('btnApply')} onClick={moveToMatching} style={{ marginTop: '120px' }}>
          <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
            잡다 매칭으로 쉽게 취업 하기
          </V2Text>
        </button>
      </div>
    </div>
  );
};

export default MainVisual;
