import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { JDAModalUnderlineText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

//* * 일림관련 모달 */

export enum DialogType {
  NOTICE = 'NOTICE',
  CONFIRM = 'CONFIRM',
  DEFAULT = 'DEFAULT',
  ALERT = 'ALERT'
}

export interface INoticeDialogProps extends IModalFrameProps {
  title:string|ReactElement;
  content:string|ReactElement;
  subContent?:string|ReactElement;
  onOk:() => void;
  onCancel:() => void;
  firstButtonText?:string;
  secondButtonText?:string;
  dialogType:DialogType;
  isLoading?:boolean;
  isSecondButtonVisible?:boolean;
}
const DialogFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap:8px;
    width: 100%;
    margin-top: 24px;
`;
const ModalFrame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  width: 328px;
  padding:${SpaceValue.XL}px ${SpaceValue.XL}px ${SpaceValue.L}px ${SpaceValue.XL}px;
  color:${colors.CG_80};
  z-index: 100008;
  .frame-title {
   display : flex ;
    width:100%;
   gap:8px;
   align-items: center;
  }
  .btn-ok {
    margin-top:32px;
    min-height:44px;
  }
  .btn-cancel {
    margin-top:15px;
  }
  .content {
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
    margin-top:16px;
    text-align:center;
  }
`;

const NoticeDialog:FC<INoticeDialogProps> = ({ isLoading = false, title, content, subContent, onOk, onCancel, firstButtonText, secondButtonText, dialogType, isSecondButtonVisible = true, ...props }) => (
  <JDAModalFrame isFrame {...props}>
    <ModalFrame>
      <div className='frame-title'>
        {
            dialogType === DialogType.ALERT && (
            <V2Icon name={V2IconOption.name.errorFilled} size={V2IconOption.size.M} fill={V2IconOption.fill.accent_red} />
            )

        }
        {
            dialogType === DialogType.NOTICE && (
            <V2Icon name={V2IconOption.name.circleInfo} size={V2IconOption.size.M} fill={V2IconOption.fill.information} />
            )

        }
        {
            dialogType === DialogType.CONFIRM && (
            <V2Icon name={V2IconOption.name.checkCircleFilled} size={V2IconOption.size.M} fill={V2IconOption.fill.primary} />
            )

        }
        <V2Text
          fontStyle={V2TextOption.fontStyle.title_2_b}
          color={V2TextOption.color.default}
        >
          {title}
        </V2Text>
      </div>
      <SpacingBlock vertical size={12} />
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_1_m}
        color={V2TextOption.color.subtle}
        styles={{ whiteSpace: 'pre-line' }}
      >
        {content}
      </V2Text>
      {subContent && (
        subContent
      )}

      <DialogFooter>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.M}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          fill={V2ButtonOption.fillType.brand_strong_default}
          color={V2ButtonOption.color.inverse}
          buttonProps={{ onClick: onOk }}
          isLoading={isLoading}
        >
          {firstButtonText || '확인'}
        </V2Button>
        {isSecondButtonVisible && (
          <V2Button
            type={V2ButtonOption.type.OutLine}
            size={V2ButtonOption.size.M}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.subtle}
            fill={V2ButtonOption.fillType.neutral_subtle_default}
            buttonProps={{ onClick: onCancel }}
          >
            {secondButtonText || '끄기'}
          </V2Button>
        )}
      </DialogFooter>
    </ModalFrame>
  </JDAModalFrame>
);

export default NoticeDialog;
