import React, { FC, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import { IconArrowUpDown } from 'consts/assets/icons/iconPages';
import { keyFrameFadeIn, keyFrameFadeInUp } from 'consts/style/mixins';

const faqInfoList = [
  {
    question: 'Q. JOBDA 매칭이 무엇인가요?',
    answer: (
      <>
        역량 중심의 채용을 하고 있는 기업에서
        <br />
        취준생분들의 역량을 사전에 확인하고, 직접 입사 제안을 하는 서비스에요.
      </>
    ),
    type: 'type3',
  },
  {
    question: 'Q. 기업들의 제안은 어떻게 받을 수 있나요?',
    answer: (
      <>
        매칭 서비스 이용을 위한 필수조건을 완료하신 분들에 한하여 기업에게 지원서가 공개됩니다.
        <br />
        공개된 지원서는 기업에서 확인 후 직접 입사 제안 메세지를 발송합니다.
      </>
    ),
    type: 'type4',
  },
  {
    question: 'Q. 제안을 받는 서비스는 무료인가요?',
    answer: <>취준생분들의 역량기반의 취업준비를 위해 JOBDA 매칭 서비스는 무료로 제공되고 있습니다.</>,
    type: 'type2',
  },
  {
    question: 'Q. 재직 중인 회사에서 제가 구직중인 것을 알 수 있나요?',
    answer: <>회원님이 ‘재직 중’으로 선택한 경력의 회사에서는 프로필을 검색하거나 열람할 수 없습니다. 또한 전직장 등에서 열람할 수 없도록 프로필 열람을 차단할 회사를 직접 선택하실 수 있습니다.</>,
    type: 'type2',
  },
  {
    question: 'Q. 기업에서 제안을 받이 받을 수 있는 노하우가 있나요?',
    answer: (
      <>
        공개된 지원서는 기업에서 직접 확인하기 때문에, 성실히 작성하는 것이 좋습니다.
        <br />
        JOBDA 지원서는 단순 텍스트 기반의 지원서가 아니기 때문에 나를 더 풍성히 표현할 수 있습니다.
        <br />
        자기소개 문구, 나를 표현할 수 있는 사진 등을 활용해 내가 갖고 있는 역량을 충분히 보여주세요 !
      </>
    ),
    type: 'type6',
  },
];

const Frame = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCategoryFrame = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  background: #333436;
  border-radius: 12px;
  padding: 24px 32px;
  margin-bottom: 16px;

  > div {
    display: flex;
    justify-content: space-between;
  }

  .btnDown {
    position: absolute;
    z-index: 1;
    right: 32px;
  }

  .question {
    font: ${Fonts.B1_Bold_P};
    color: #FFFFFF;
    margin-bottom: ${(props) => (props.isOpen ? '16px' : '0')};
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .answer {
    display: none;
    font: ${Fonts.B3_Medium_P};
    color: #ACAEB0;
    word-break: keep-all;

    ${(props) => props.isOpen
      && css`
        display: block;
        animation: fadein 0.4s forwards;
        ${keyFrameFadeIn};
      `}
  }

  &.visible {
    animation: fadeinup10 0.4s forwards;
    animation-timing-function: ease-out;
    ${keyFrameFadeInUp};
  }
`;

const Faq: FC<{ question: string; answer: ReactNode; index: number }> = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <StyledCategoryFrame isOpen={isOpen} className={`${visible ? 'visible' : ''}`}>
      <div role='button' onClick={() => setIsOpen(!isOpen)}>
        <h1 className='question'>{question}</h1>
        <div className='btnDown'>
          <IconArrowUpDown active={isOpen} />
        </div>
      </div>
      <div className='answer'>{answer}</div>
    </StyledCategoryFrame>
  );
};

const FaqList: FC = () => {
  return (
    <Frame>
      {faqInfoList.map((item, i) => (
        <Faq key={i} index={i} question={item.question} answer={item.answer} />
      ))}
    </Frame>
  );
};

export default FaqList;
