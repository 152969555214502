import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

const SelfIntroduceFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .wrap {
    display: flex;
    flex-direction: column;
    padding:${SpaceValue.XL}px ${SpaceValue.L}px;
    width: 100%;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${SpaceValue.XS}px;
      text-align: center;
    }

    /* 한문장 소개 */
    .input-container {
      position: relative;
      width: 100%;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
    }

    .custom-input {
      width: 100%;
      height: 48px;
      padding: ${SpaceValue.M}px;
      padding-right: 40px; /* 버튼 공간 확보 */
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }

    .clear-button {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #888;
    }

    /* 자기소개 */
    .text-area-container {
      position: relative;
      width: 100%;
      height: 204px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
    }

    .custom-textarea {
      width: 100%;
      height: calc(100% - 24px);
      padding: ${SpaceValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      border-radius: ${RadiusValue.M}px;
      resize: none;
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }

    .length-title {
      position: absolute;
      right: 0;
      bottom: -28px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }

    .length-text {
      position: absolute;
      right: 12px;
      bottom: 7px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }
  }
`;

const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${V2BackgroundColors.surface_default};
  z-index: 1;

  .btn-back {
    position: absolute;
    display: block;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 40px;
  }
`;

const ButtonFrame = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  background-color: ${V2BackgroundColors.surface_default};
`;

interface Props {
  title: string;
  content: string;
  onTitleChange: (title: string) => void;
  onContentChange: (content: string) => void;
  login?: Login;
}

const MatchApplySelfBrandingIntroduce: FC<Props> = ({
  title,
  content,
  onTitleChange,
  onContentChange,
  login = new Login(),
}) => {
  const maxLength = 300;
  const titleMaxLength = 40;
  const { data: introductionData } = useMatchApplicantIntroduction(!!login.userInfo);

  useEffect(() => {
    if (introductionData) {
      if (introductionData.title) {
        onTitleChange(introductionData.title);
      }
      if (introductionData.content) {
        onContentChange(introductionData.content);
      }
    }
  }, [introductionData]);

  const handleClear = () => {
    onTitleChange('');
  };

  return (
    <SelfIntroduceFrame>
      {/* 자기소개 */}
      <div className='wrap'>

        <V2Text
          fontStyle={V2FontStyleType.body_1_sb}
          color={V2TextColorsType.default}
        >
          한 줄 소개
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <div className='input-container'>
          <input
            type='text'
            className='custom-input'
            placeholder='안녕하세요! 긍정의 스위치, 김조이입니다.'
            value={title}
            onChange={(e) => onTitleChange(e.target.value)}
            maxLength={titleMaxLength}
          />
          {title && <button className='clear-button' onClick={handleClear}>✕</button>}
          <div className='length-title'>{title.length}/{titleMaxLength}</div>
        </div>

        {/* 자기소개 */}
        <SpacingBlock size={SpaceValue.XXL} vertical />
        <V2Text
          fontStyle={V2FontStyleType.body_1_sb}
          color={V2TextColorsType.default}
        >
          간단 소개글
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <div className='text-area-container'>
          <textarea
            className='custom-textarea'
            placeholder='언제나 밝은 에너지로 주변을 환하게 만드는 사람입니다.
어떤 일이든 해결 방법을 찾으며, 도전을 기회로 삼아요.
함께하는 팀과 긍정적인 변화를 만들어가는 것이 제 목표입니다.

'
            value={content}
            onChange={(e) => onContentChange(e.target.value)}
            maxLength={maxLength}
          />
          <div className='length-text'>{content.length}/{maxLength}</div>
        </div>
      </div>

      {/* <ButtonFrame>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          buttonProps={{
            onClick: () => {
              // Implementation of onSubmitIntroduce
            },
            disabled: !title || !content,
          }}
        >
          저장
        </V2Button>
      </ButtonFrame> */}
    </SelfIntroduceFrame>
  );
};

export default inject(injectStore.login)(observer(MatchApplySelfBrandingIntroduce));
