import React from 'react';

import classnames from 'classnames/bind';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import styles from './faq.module.scss';
import FaqList from './FaqList';

const cx = classnames.bind(styles);

interface AnimationProps {
  fadeIn: boolean;
}

const FAQ = ({ fadeIn }: AnimationProps) => {
  return (
    <div className={cx('commonBox', 'faqArea', { on: fadeIn })}>
      <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.inverse}>
        자주하는 질문
      </V2Text>
      <div className={cx('faqInner')}>
        <FaqList />
      </div>
    </div>
  );
};

export default FAQ;
