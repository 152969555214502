import PositionCard from '__designkit__/card/PositionCard';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import spinner from 'assets/lottie/pacc_gate_banner.json';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { StyledMainCurationChip, StyledMainCurationTab } from 'components/_v2/main/MainContents';
import PaccNoPosition from 'components/_v2/phs/gate/PaccNoPositions';
import { Divider12B } from 'components/divider/Divider';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import Lottie from 'lottie-react';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import PaccGateModel from 'models/_v2/PaccGateModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';
import { IPaccGateProps } from './PaccGateService';

const PaccFrame = styled.div``;
const Frame = styled.div`
  padding: 32px 16px;
  > h1 {
    font: ${Fonts.H5_Bold};
    color: ${Colors.JOBDA_WHITE};
    margin-bottom: 16px;
  }

  .custom-position-card {
    width: 158px;
    .card {
      width: 158px;
      height: 118px;
    }
  }
`;

const CustomStyledMainCurationTab = styled(StyledMainCurationTab)` 
.curation-frame{
    padding: 0px;
}
`;
const DarkModeStyledMainCurationChip = styled(StyledMainCurationChip)`
    background-color: ${(props) => (props.selected ? `${Colors.JOBDA_WHITE}` : `${Colors.CG_90}`)} ;
    border: none;
    .curation-text{
        color:${(props) => (props.selected ? `${Colors.CG_80}` : `${Colors.CG_60}`)} ;
    }
`;

const BannerFrame = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: ${Colors.CG_90};
    border-radius: 8px;
    height: 128px;
    align-items: center;
    margin-top: 8px;
    .text-frame{
        margin-left: 24px;
        font: ${Fonts.B1_Medium};
        color:${Colors.JOBDA_WHITE};
        >em{
            font: ${Fonts.B1_Bold};
        }
    }
    .spinner{
        width:56px;
        height:56px;
        margin-right: 16px;
    }
`;
interface IPaccGateDevPosition extends IPaccGateProps {
  context?:Context;
  paccGateModel?:PaccGateModel;
}
const PaccGateDevPosition: FC<IPaccGateDevPosition> = ({ context = new Context(), paccGateModel = new PaccGateModel() }) => {
  const [filteredItems, setFilteredItems] = useState<INameCodeRs[]>([]);

  const options = {
    animationData: spinner,
    loop: true,
    autoplay: true,
  };

  const DeveloperCode = 13;
  useEffect(() => {
    const init = async () => {
      await paccGateModel.loadPhsCenterPosition();
      const filtered = context.jobGroupsWithTitles.filter((item) => item.code === DeveloperCode);
      const jobTitles = [{ code: -1, name: '전체' }, ...filtered[0].jobTitles];

      setFilteredItems(jobTitles);
    };
    init();
  }, []);

  const handleTabSelect = (code:number) => {
    paccGateModel.selectedJobTitle = code;
    if (code === -1) {
      paccGateModel.loadPhsCenterPosition();
    } else {
      paccGateModel.loadPhsCenterPosition(code);
    }
  };
  return (
    <PaccFrame>
      <Frame>
        <h1>잡다에서 모집 중인 개발자 포지션</h1>
        <CustomStyledMainCurationTab>
          <div className='curation-frame'>
            {filteredItems && filteredItems.map((item) => (
              <DarkModeStyledMainCurationChip key={item.code} selected={paccGateModel.selectedJobTitle === item.code} onClick={() => handleTabSelect(item.code)}>
                <div className='curation-text'>
                  {item.name}
                </div>
              </DarkModeStyledMainCurationChip>
            ))}

          </div>
        </CustomStyledMainCurationTab>
        {paccGateModel.phsPositions && paccGateModel.phsPositions.positions.length > 0 ? (
          <JDSwiper className='custom-position-swiper' slidesPerView='auto' spaceBetween={12}>
            {paccGateModel.phsPositions.positions.map((item) => (
              <JDSwiperSlide key={`card-item-${item.sn}`} className='custom-position-card'>
                <PositionCard
                  darkMode
                  item={{
                    positionSn: item.sn,
                    positionName: item.title,
                    recruitmentType: item.recruitmentType,
                    closingDateTime: item.closingDateTime,
                    locationCode: item.locationCode,
                    companyName: item.companyName,
                    logoImageUrl: item.logoImageUrl,
                    jobTitleNames: item.jobTitleNames,
                    bookmarkYn: item.bookmarkYn,
                    minSalary: item.minSalary,
                    maxSalary: item.maxSalary,
                    jobPosting: item.jobPosting,
                    teamImageUrl: item.thumbnailImageUrl,
                  }}
                />
              </JDSwiperSlide>
            ))}
          </JDSwiper>
        ) : (
          <PaccNoPosition />
        )}
        <BannerFrame>
          <div className='text-frame'>
            <em>개발자 검사</em>는 <br />
            개발자 역량을 정확히 진단하고, <br />
            취업으로 이어지는 길을 안내합니다.
          </div>
          <Lottie className='spinner' key={uuidV4()} {...options} />
        </BannerFrame>
      </Frame>

    </PaccFrame>
  );
};

export default inject(injectStore.context, injectStore.paccGateModel)(observer(PaccGateDevPosition));
