import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import styled from 'styled-components';
import ImgReservation from 'pages/_v2/hxIntroduce/img/imgReservation.png';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';

const Frame = styled.div`
display: flex;
flex-direction: column;
padding:${SpaceValue.XXL}px ${SpaceValue.L}px;

`;
const Divider = styled.div`
width: 100%;
height: 1px;
background-color: ${V2BorderColors.default};
margin:${SpaceValue.L}px 0;
`;
const PriceFrame = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border:1px solid ${V2BorderColors.default};
border-radius: ${RadiusValue.M}px;
overflow: hidden;
.bottom-frame{
    padding:${SpaceValue.XL}px;
}
.price-discount{
    display: flex;
    align-items: end;
}
`;
const ButtonFrame = styled.div`
display: flex;
align-items: center;
padding:${SpaceValue.M}px ${SpaceValue.L}px;
background-color: #18191B;
border-radius: ${RadiusValue.M}px;
color:${V2TextColorsType.inverse};
justify-content: center;
width: 100%;

`;
const CheckFrame = styled.div`
display: flex;
align-items: center;
gap:${SpaceValue.XS}px;
`;

const TextWithCheck = ({ text }: { text: string }) => {
  return (
    <CheckFrame>
      <V2Icon name={V2IconOption.name.check} size={V2IconOption.size.XS} fill={V2IconOption.fill.primary} />
      <V2Text fontStyle={V2TextOption.fontStyle.body_1_m} color={V2TextOption.color.default}>
        {text}
      </V2Text>
    </CheckFrame>
  );
};
const HxReservation = () => {
  return (
    <Frame>
      <V2Text styles={{ textAlign: 'center' }} fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.strong}>사전 예약</V2Text>
      <SpacingBlock size={SpaceValue.L} vertical />
      <V2Text styles={{ textAlign: 'center', whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_1_m} color={V2TextOption.color.default}>
        {`채용이 마주할 미래.
        기다리지 말고 가장 먼저 만나보세요.`}
      </V2Text>
      <SpacingBlock size={32} vertical />
      <PriceFrame>
        <img src={ImgReservation} alt='img_reservation' />
        <div className='bottom-frame'>
          <V2Text fontStyle={V2TextOption.fontStyle.title_3_sb} color={V2TextOption.color.strong}>
            제공 서비스
          </V2Text>
          <Divider />
          <TextWithCheck text='무제한 인재 검색' />
          <SpacingBlock size={SpaceValue.XS} vertical />
          <TextWithCheck text='무제한 인재 프로필 열람' />
          <SpacingBlock size={SpaceValue.XS} vertical />
          <TextWithCheck text='무제한 인재 면접 제안' />
          <SpacingBlock size={SpaceValue.XS} vertical />
          <TextWithCheck text='무제한 팀 리더 계정 발급' />
          <SpacingBlock size={SpaceValue.XXL} vertical />
          <div className='price-discount'>
            <V2Text fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.disabled} styles={{ textDecorationLine: 'line-through' }}>
              80만원
            </V2Text>
            <SpacingBlock size={SpaceValue.M} horizontal />
            <V2Text fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.default}>
              0원
            </V2Text>
            <SpacingBlock size={SpaceValue.XXXS} horizontal />
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_m} color={V2TextOption.color.subtlest}>
              / 월
            </V2Text>
          </div>
          <SpacingBlock size={SpaceValue.XL} vertical />
          <ButtonFrame onClick={() => windowOpenUtils('https://share.hsforms.com/1r0nqAkLIRPuU2znmznX7_Qcvjg5', '_blank')}>
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.inverse}>무료로 사용하기</V2Text>
          </ButtonFrame>
        </div>
      </PriceFrame>
    </Frame>
  );
};

export default HxReservation;
