import UploadStep from 'components/_v2/videoRecord/UploadStep';
import styled from 'styled-components';

const UploadWrap = styled.div`
`;

const Upload = () => {
  return (
    <UploadWrap>
      <UploadStep />
    </UploadWrap>
  );
};

export default Upload;
