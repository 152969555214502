/* eslint-disable camelcase */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import Icon from '__designkit__/icon/Icon';
import Loading from 'components/Loading';
import { ProfileMetaTag } from 'components/_v2/_common/meta/MetaTag';
import JDDeleteModal from 'components/_v2/_common/modals/JDDeleteModal';
import TalentpoolMissionModal from 'components/_v2/_common/modals/TalentpoolMissionModal';
import MatchTermsModal from 'components/_v2/_common/terms/MatchTermsModal';
import ProfileBanner from 'components/_v2/profile/ProfileBanner';
import ProfileFloatingBanner from 'components/_v2/profile/ProfileFloatingBanner';
import ProfileResumeSavedModal from 'components/_v2/profile/ProfileResumeSavedModal';
import ProfileSNB from 'components/_v2/profile/ProfileSNB';
import ProfileAttachedFileView from 'components/_v2/profile/attachedFile/ProfileAttachedFileView';
import ProfileBasicInfoView from 'components/_v2/profile/baseInfo/ProfileBasicInfoView';
import NHISCareerAddModal from 'components/_v2/profile/careers/NHISCareerAddModal';
import ProfileCareersView from 'components/_v2/profile/careers/ProfileCareersView';
import ProfileConditionView from 'components/_v2/profile/condition/ProfileConditionView';
import ProfileEducationView from 'components/_v2/profile/education/ProfileEducationView';
import ProfileExperienceView from 'components/_v2/profile/experience/ProfileExperienceView';
import ProfileResumeLoadModal from 'components/_v2/profile/loadResume/ProfileResumeLoadModal';
import MatchTab from 'components/_v2/profile/match/MatchTab';
import ProfilePreferentialView from 'components/_v2/profile/preferential/ProfilePreferentialView';
import ProfilePrizeView from 'components/_v2/profile/prize/ProfilePrizeView';
import ProfileProjectView from 'components/_v2/profile/project/ProfileProjectView';
import ProfileSkillView from 'components/_v2/profile/skill/ProfileSkillView';
import { ProfileItemType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import useVerification from 'hooks/useVerification';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { IIdentificationRq } from 'interfaces/rqrs/IJoinRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import TermsModel from 'models/TermsModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import store, { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { useInsightResumeAutoSave, useInsightResumeUnread } from 'query/resume/useResumeQuery';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import ScrollUtil from 'utils/ScrollUtil';
import MainBanner1 from 'assets/_v2/profile/img_load_match_profile.png';
import MainBanner2 from 'assets/_v2/profile/img_with_documents.png';
import MainBanner3 from 'assets/_v2/profile/img_company_logo_flow.png';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import JDSwiper, { JDFractions, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import ProfileSelectSnb from 'components/_v2/profile/ProfileSelectSnb';
import NoticeDialog from 'components/modals/NoticeDialog';
import { DialogType } from 'components/_v2/videoRecord/VideoRecordGuideHorizontalModal';
import { usePostProfilesFromMatchingProfile } from 'query/match/useMatchQuery';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'consts/RoutePaths';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 16px 64px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.CG_40};
`;

const PaddingFrame = styled.div`
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
`;

const StyledSNB = styled.div`
  position: fixed;
  bottom: calc(81px + env(safe-area-inset-bottom));
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${Colors.G_150};
  filter: ${Shadows.Shadow_300};
`;

const ResumeCheckFrame = styled.div`
  width:100%;
  padding:24px 16px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${Colors.Y_100};
  border-bottom: ${Colors.Y_100};
  background-color: ${Colors.Y_30};
  .text-frame{
    font:${Fonts.B2_Bold};
    color:${Colors.JOBDA_BLACK};
    span{
      background-color:${Colors.Y_100};
    }
  }
  .confirm-btn{
    display:flex;
    padding:9px 18px;
    border-radius: 17px;
    background-color:${Colors.JOBDA_BLACK};
    justify-content: center;
    align-items: center;
    font:${Fonts.B3_Medium};
    color:${Colors.WHITE_100};
  }
`;

const HeaderBannerFrame = styled.div`
  padding:${SpaceValue.XS}px 0;
`;

const HeaderUpdateFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding:${SpaceValue.L}px;
  gap:${SpaceValue.XS}px;

  .title-frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  border-bottom: 1px solid ${V2BorderColors.default};

`;
const BannerFrame = styled.div<{backgroundColor:string}>`
  width: calc(100vw - 32px);
  height: 88px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding:21px ${SpaceValue.XL}px;
  margin-left: 16px;;
  overflow: hidden;
  position: relative;
  .subtitle-frame{
    display: flex;
    gap:2px;

  }
  .profile-banner-img{
    width: 140px;
    height: auto;
    position: absolute;
    right:5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface IProfileTotalProps extends IProfileProps {
  login?: Login
  profileModel?: ProfileModel;
  termsModel?: TermsModel;
  context?:Context;
  profileBasicInfoModel?: ProfileBasicInfoModel;
}

const Profile:FC<IProfileTotalProps> = ({ context = new Context(), login = new Login(), profileModel = new ProfileModel(), termsModel = new TermsModel(), profileBasicInfoModel = new ProfileBasicInfoModel() }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenSNB, setIsOpenSNB] = useState<boolean>(false);
  const [suggestionModalCode, setSuggestionModalCode] = useState<number>(0);
  const { sourcePage, anchor, MDL_TKN, mdlTkn, mdl_tkn } = useQueryParams();
  const [isOpenNHISCareerAddModal, setIsOpenNHISCareerAddModal] = useState<boolean>(false);
  const [isOpenTalentpoolMissionModal, setIsOpenTalentpoolMissionModal] = useState<boolean>(true);
  const [isCompleteProfile, setIsCompleteProfile] = useState<boolean>(false);
  const [isCompleteAcc, setIsCompleteAcc] = useState<boolean>(false);
  const [isRender, setIsRender] = useState<boolean>(false);
  const [isOpenResumeModal, setIsOpenResumeModal] = useState<boolean>(false);
  const [isByNudgeModal, setIsByNudgeModal] = useState<boolean>(false);
  const [isProfileResumeSavedModal, setIsProfileResumeSavedModal] = useState<boolean>(true);
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
  const [isCheckSavedResume, setIsCheckSavedResume] = useState<boolean>(false);
  const { data: isResumeExisted } = useInsightResumeUnread(!!login.userInfo);
  const { data: profileConditionsData, refetch: refetchCondition } = useProfileConditions(!!login.userInfo);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(!!login.userInfo);

  const verify = useVerification();
  const { setToastObject } = useToast();

  const { mutate: postProfilesFromMatchingProfile } = usePostProfilesFromMatchingProfile(
    async () => {
      await profileModel.checkEssential(true);
      await profileModel.checkAdditional(true);
      await profileBasicInfoModel.loadBaseInfo();
      setToastObject({ isOpen: true, message: '프로필을 업데이트했습니다.', type: TOAST_TYPE.SUCCESS });
      setIsUpdateModal(false);
    },
  );

  useEffect(() => {
    const init = async () => {
      const isParam = mdlTkn || MDL_TKN || mdl_tkn;

      if (verify && isParam) {
        try {
          const data: IIdentificationRq = {
            token: verify.identificationToken,
            name: verify.name,
            mobile: verify.telephoneNumber ? verify.telephoneNumber : (login.userDefault?.mobile || null),
            birthDate: verify.birthday,
          };
          await login.putSaveIdentity(data);
          await login.loadCurrentUser();
          await profileModel.checkEssential();

          setToastObject({ isOpen: true, message: '본인인증이 완료되었습니다.', type: 'SUCCESS' });
        } catch (e: any) {
          if (e.response.data.errorCode === 'B901') {
            setToastObject({ isOpen: true, message: '본인인증 세션이 만료되었습니다.', type: 'ERROR' });
          } else if (e.response.data.errorCode === 'B902') {
            setToastObject({ isOpen: true, message: '동일한 인증정보를 가지는 다른 계정이 존재합니다.', type: 'ERROR' });
          } else {
            setToastObject({ isOpen: true, message: '본인인증에 실패했습니다.', type: 'ERROR' });
          }
        }
      }
    };
    init();
  }, [verify, mdlTkn, MDL_TKN, mdl_tkn]);
  // 플로팅 배너 클릭 시, 약관 동의 여부에 따라 동작하는 함수
  const onClickFolatingBanner = async () => {
    if (!profileModel.snbCheckEssential?.basicInfo.completeYn) setSuggestionModalCode(1);
    else if (!profileModel.snbCheckEssential?.preferInfo.completeYn) setSuggestionModalCode(2);
    else if (!profileModel.snbCheckEssential?.eduInfo.completeYn) setSuggestionModalCode(3);
    setSuggestionModalCode(0);
  };

  // 스크롤 로직
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  const maxScroll = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  ) - window.innerHeight;
  const scrollToRef = (idx:number) => {
    const ref = itemsRef.current[idx];
    const refOffSetTop = ref?.offsetTop;
    if (refOffSetTop) {
      if (refOffSetTop <= maxScroll || maxScroll === 0) {
        ScrollUtil.scrollTo(0, refOffSetTop ? refOffSetTop - 100 : 0);
      } else {
        ScrollUtil.scrollTo(0, maxScroll || 0);
      }
    }
  };

  useEffect(() => {
    scrollToRef(profileModel.scrollPosition);
  }, [profileModel.scrollPosition]);

  useEffect(() => {
    profileModel.sourcePage = sourcePage;
  }, [sourcePage]);

  useEffect(() => {
    setTimeout(() => {
      if (anchor && itemsRef && !isLoading) {
        if (anchor === ProfileItemType.EDUCATION) scrollToRef(2);
        else if (anchor === ProfileItemType.CAREER) profileModel.setScrollPosition(3);
        else if (anchor === ProfileItemType.PROJECT) profileModel.setScrollPosition(4);
        else if (anchor === ProfileItemType.KNOWLEDGE_AND_SKILL) profileModel.setScrollPosition(5);
        else if (anchor === ProfileItemType.EXPERIENCE) profileModel.setScrollPosition(6);
        else if (anchor === ProfileItemType.PRIZE) profileModel.setScrollPosition(7);
        else if (anchor === ProfileItemType.PREFERENTIAL) profileModel.setScrollPosition(8);
        else if (anchor === ProfileItemType.FILE) profileModel.setScrollPosition(9);
      }
    }, 0);
  }, [anchor, itemsRef, isLoading]);

  // 필수 입력 정보가 없으면 플로팅 배너 띄우기
  // 추가 입력 정보에 대해 미리 로드 (SNB에서 더 빠르게 렌더링하기 위함)
  useEffect(() => {
    (async () => {
      if (!login.userDefault) await login.loadUserDefault();
      if (login.userInfo) {
        await profileModel.checkEssential();
        await profileModel.checkAdditional();
        await profileModel.checkUpdateDate();
        refetchAutoSave();
        if (profileConditionsData) {
          if (profileConditionsData.jobGroups.length > 0 && profileConditionsData.locations.length > 0 && profileConditionsData.salary > 0 && profileConditionsData.welfare.length > 0) store.profileModel.snbCheckEssential.preferInfo.completeYn = true;
          else store.profileModel.snbCheckEssential.preferInfo.completeYn = false;
        }
      }
      setIsLoading(false);
    })();
  }, [login, profileModel, profileConditionsData]);

  useEffect(() => {
    (async () => {
      if (!login.matchConfigStatus) await login.getMatchConfigStatus();
      setIsRender(!(await RenderCheckUtil.getRenderCheck('talentpool-induce')));
      setIsCompleteProfile(login.matchConfigStatus!.matchingConfigCondition.matchingProfileRequiredCompleteYn);

      // 개발자와 일반직군 모두 가능한 경우
      if (login.matchConfigStatus!.generalYn && login.matchConfigStatus!.developerYn) {
        // 둘 다 되어야지만
        setIsCompleteAcc(
          login.matchConfigStatus!.matchingConfigCondition.accCompleteYn
          && login.matchConfigStatus!.matchingConfigCondition.paccCompleteYn,
        );
      } else if (login.matchConfigStatus!.generalYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.accCompleteYn);
      } else if (login.matchConfigStatus!.developerYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.paccCompleteYn);
      }
    })();
  }, [login]);
  useEffect(() => {
    let talentVisible = false;
    const condition1 = isCompleteProfile && !isCompleteAcc;
    const condition2 = isCompleteProfile && isCompleteAcc && !login.matchConfigStatus?.matchingConfigYn;
    if ((condition1 || condition2) && isRender && !context.isGlobalModalOpen) talentVisible = true;
    if (talentVisible) {
      setIsOpenTalentpoolMissionModal(true);
    } else {
      setIsOpenTalentpoolMissionModal(true);
    }
  }, [isCompleteProfile, isCompleteAcc, isRender, login, isResumeExisted, context]);

  const handleCheckComplete = async () => {
    await profileModel.saveProfileConfirmAll();
    await profileModel.checkAdditional();
    await profileModel.checkEssential();
    refetchAutoSave();
    refetchCondition();
  };

  const ProfileBannerList = (item:IMainBanner) => {
    const history = useHistory();
    const handleClickBanner = () => {
      if (item.key === 0) setIsUpdateModal(true);
      else if (item.key === 1) setIsOpenResumeModal(true);
      else if (item.key === 2) history.push(RoutePaths.match_introduce);
    };
    return (
      <BannerFrame backgroundColor={item.backgroundColor} onClick={handleClickBanner}>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_b}
          color={V2TextOption.color.inverse}
        >
          {item.title}
        </V2Text>
        <div className='subtitle-frame'>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.inverse}
          >
            {item.subTitle}
          </V2Text>
          <V2Icon name={V2IconOption.name.arrowRight} size={V2IconOption.size.XS} fill={V2IconOption.fill.inverse} />
        </div>
        <img src={item.imageUrl} alt='profile-banner' className='profile-banner-img' />
      </BannerFrame>
    );
  };
  return (isLoading ? <Loading />
    : (
      <>
        <ProfileMetaTag />

        <HeaderBannerFrame>
          <JDSwiper
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={JDFractions}
            loop
          >
            {
              MainBannerList.map((item, index) => (
                <JDSwiperSlide key={index} className='main-custom-slide'>
                  {ProfileBannerList(item)}
                </JDSwiperSlide>
              ))
            }

          </JDSwiper>

        </HeaderBannerFrame>
        <HeaderUpdateFrame>
          <div className='title-frame'>
            <V2Text
              fontStyle={V2TextOption.fontStyle.title_3_sb}
              color={V2TextOption.color.default}
            >
              프로필
            </V2Text>
            <V2Text
              fontStyle={V2TextOption.fontStyle.caption_1_m}
              color={V2TextOption.color.subtlest}
            >
              {profileModel.lastUpdatedDate && `${DateFormatYYYYMMDDWithDot(profileModel.lastUpdatedDate)} 업데이트`}
            </V2Text>
          </div>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtle}
          >
            잡다에서 입사 지원할 때 사용하는 기본 프로필입니다.
          </V2Text>
        </HeaderUpdateFrame>
        <PaddingFrame>
          <div ref={(el) => { itemsRef.current[0] = el; }}>
            <ProfileBasicInfoView suggestionModalCode={suggestionModalCode} />
          </div>
        </PaddingFrame>
        {
          insightResumeAutoSaveData?.autoSaved && (
          <ResumeCheckFrame>
            <div className='text-frame'>
              확인이 필요한 정보가 <span>{insightResumeAutoSaveData.auditCount}개</span> 있습니다.
            </div>
            <div role='button' onClick={() => setIsCheckSavedResume(true)} className='confirm-btn'>
              확인 완료
            </div>
          </ResumeCheckFrame>
          )
        }

        <Frame>
          <div ref={(el) => { itemsRef.current[2] = el; }}>
            <Divider />
            <ProfileEducationView suggestionModalCode={suggestionModalCode} />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[3] = el; }}>
            <ProfileCareersView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[4] = el; }}>
            <ProfileProjectView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[5] = el; }}>
            <ProfileSkillView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[6] = el; }}>
            <ProfileExperienceView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[7] = el; }}>
            <ProfilePrizeView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[8] = el; }}>
            <ProfilePreferentialView />
            <Divider />
          </div>
          <div ref={(el) => { itemsRef.current[9] = el; }}>
            <ProfileAttachedFileView />
          </div>
          <StyledSNB onClick={() => setIsOpenSNB((prevState) => !prevState)}>
            <Icon
              name={isOpenSNB ? 'close' : 'add'}
              size={32}
              color={Colors.WHITE_100}
              className='icon'
            />
          </StyledSNB>
          <ProfileSelectSnb
            onClickSuccess={() => {
              setIsUpdateModal(true);
            }}
            isOpen={isOpenSNB}
            onClickClose={() => setIsOpenSNB(false)}
            onClickResume={() => {
              setIsOpenResumeModal(true);
            }}
          />
          <NoticeDialog
            dialogType={DialogType.NOTICE}
            title='매칭 프로필 불러오기'
            content='현재 입력된 매칭 프로필 정보를 불러와 내 프로필을 업데이트하시겠습니까?'
            isOpen={isUpdateModal}
            onOk={() => {
              postProfilesFromMatchingProfile();
            }}
            onCancel={() => setIsUpdateModal(false)}
            firstButtonText='불러오기'
            secondButtonText='취소'
          />
          { isOpenTalentpoolMissionModal && <TalentpoolMissionModal onClickClose={() => setIsOpenTalentpoolMissionModal(false)} isCompleteProfile={isCompleteProfile} isCompleteAcc={isCompleteAcc} /> }
          { isOpenResumeModal && <ProfileResumeLoadModal isByNudgeModal={isByNudgeModal} onClickClose={() => setIsOpenResumeModal(false)} /> }
          { isOpenNHISCareerAddModal && <NHISCareerAddModal onClickClose={() => setIsOpenNHISCareerAddModal(false)} afterLogic={() => console.log('success')} /> }
          { isCheckSavedResume && <JDDeleteModal title={`저장된 지원서 내용을\n 모두 확인 하셨나요?`} dimmed handleClickLeft={() => setIsCheckSavedResume(false)} onClickClose={() => setIsCheckSavedResume(false)} handleClickRight={handleCheckComplete} btnTextLeft='취소' btnTextRight='확인' isOpen={isCheckSavedResume} isDimClickClose />}
        </Frame>
      </>
    )
  );
};

export default inject(injectStore.context, injectStore.login, injectStore.profileModel, injectStore.termsModel, injectStore.profileBasicInfoModel)(observer(Profile));

interface IMainBanner {
  key:number;
  title: string;
  subTitle: string;
  imageUrl: string;
  backgroundColor: string;
}

const MainBannerList:IMainBanner[] = [
  {
    key: 0,
    title: '클릭 한 번으로 내 프로필 완성!',
    subTitle: '매칭 프로필 불러오기',
    imageUrl: MainBanner1,
    backgroundColor: `${V2BackgroundColors.accent_skyblue_default}`,
  },
  {
    key: 1,
    title: '내 프로필, 간편하게 채우기',
    subTitle: '제출한 지원서 불러오기',
    imageUrl: MainBanner2,
    backgroundColor: `${V2BackgroundColors.accent_green_default}`,
  },
  {
    key: 2,
    title: '가고 싶었던 기업에서 제안받기',
    subTitle: '잡다매칭 자세히 알아보기',
    imageUrl: MainBanner3,
    backgroundColor: `${V2BackgroundColors.accent_gray_strong}`,
  },
];
