/* eslint-disable array-callback-return */
import { yupResolver } from '@hookform/resolvers/yup';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileTopTab from 'components/_v2/profile/ProfileTopTab';
import ProfileExperienceActivities from 'components/_v2/profile/experience/ProfileExperienceActivities';
import ProfileExperienceExperiences from 'components/_v2/profile/experience/ProfileExperienceExperiences';
import ProfileExperienceForeignExperiences from 'components/_v2/profile/experience/ProfileExperienceForeignExperiences';
import useToast from 'hooks/useToast';
import { ExperienceForeignActivitiesType, IActivityDto, IExperienceDto, IForeignExperienceDto, IProfileExperienceRq } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const Frame = styled.div`
    position:relative;
    .prize-absolute{
      position: absolute;
      top:32px;
    }

`;
const defaultValues:Partial<IProfileExperienceRq> = { activities: [], experiences: [], foreignExperiences: [] };

export const experienceFormSchema = yup.object({
  activities: yup.array(
    yup.lazy((activity) => {
      if (!activity.type && !activity.place && !activity.startDate && !activity.endDate && !activity.description) return yup.object();
      if (!activity.type && !activity.place && !activity.startDate && !activity.endDate && activity.description) {
        return yup.object({
          type: yup.string().nullable().required('내용을 입력해 주세요.'),
          place: yup.string().required('기관/장소를 입력해 주세요.'),
          startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().required('일자를 입력해 주세요.'))),
          endDate: yup.lazy((endDate) => (endDate
            ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
            : yup.string().required('일자를 입력해 주세요.'))),
          description: yup.string(),
        });
      }

      return yup.object({
        type: yup.string().nullable().required('내용을 입력해 주세요.'),
        place: yup.string().required('기관/장소를 입력해 주세요.'),
        startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().required('일자를 입력해 주세요.'))),
        endDate: yup.lazy((endDate) => (endDate
          ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
          : yup.string().required('일자를 입력해 주세요.'))),
        description: yup.string(),
      });
    }),
  ),
  foreignExperiences: yup.array(
    yup.lazy((foreign) => {
      if (!foreign.countryName && !foreign.startDate && !foreign.endDate && !foreign.description) return yup.object();
      if (!foreign.countryName && !foreign.startDate && !foreign.endDate && foreign.description) {
        return yup.object({
          countryName: yup.string().required('국가를 입력해 주세요.'),
          startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().required('일자를 입력해 주세요.'))),
          endDate: yup.lazy((endDate) => (endDate
            ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
            : yup.string().required('일자를 입력해 주세요.'))),
          description: yup.string(),
        });
      }

      return yup.object({
        countryName: yup.string().required('국가를 입력해 주세요.'),
        startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().required('일자를 입력해 주세요.'))),
        endDate: yup.lazy((endDate) => (endDate
          ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
          : yup.string().required('일자를 입력해 주세요.'))),
        description: yup.string(),
      });
    }),

  ),
  ETC: yup.array(
    yup.object({ description: yup.string() }),
  ),
});

const ProfileExperience:FC<IProfileProps> = ({ profileModel = new ProfileModel(), isMatching = false }) => {
  const useFormed = useForm<IProfileExperienceRq>({
    mode: 'onSubmit',
    resolver: yupResolver(experienceFormSchema),
    defaultValues,
  });
  const { reset, formState, getValues, handleSubmit } = useFormed;
  const { setToastObject } = useToast();

  useEffect(() => {
    const init = async () => {
      try {
        const realActivity:any[] = [];
        const realForeign:any[] = [];
        const realETC:any[] = [];

        if (profileModel.experience) {
          profileModel.initExperience();

          profileModel.experience.activitiesAndForeignExperiences.map((exp) => {
            const tmpActivity = { type: '', place: '', startDate: '', endDate: '', description: '' };
            const tmpForeign = { countryCode: 0, countryName: '', startDate: '', endDate: '', description: '' };
            if (exp.tag === ExperienceForeignActivitiesType.OVERSEAS) {
              tmpForeign.countryCode = exp.code;
              tmpForeign.countryName = exp.title;
              tmpForeign.startDate = exp.startDate;
              tmpForeign.endDate = exp.endDate;
              tmpForeign.description = exp.description;
              realForeign.push(tmpForeign);
            } else {
              tmpActivity.type = exp.tag;
              tmpActivity.place = exp.title;
              tmpActivity.startDate = exp.startDate;
              tmpActivity.endDate = exp.endDate;
              tmpActivity.description = exp.description;
              realActivity.push(tmpActivity);
            }
          });
          if (profileModel.experience?.etcExperiences) {
            profileModel.experience.etcExperiences.map((etc) => {
              const tmpETC = { description: etc.description };
              // tmpETC.description = etc.description;
              realETC.push(tmpETC);
            });
          }
          if (profileModel.experienceEdit !== null) {
            profileModel.experienceEdit.activities = realActivity;
            profileModel.experienceEdit.foreignExperiences = realForeign;
            if (realETC.length > 0) {
              profileModel.experienceEdit.experiences = realETC;
            }
          }
          if (profileModel.experienceEdit?.activities.length === 0) {
            profileModel.experienceEdit.activities = [{ type: '', place: '', startDate: '', endDate: '', description: '' }];
          }
          if (profileModel.experienceEdit?.foreignExperiences.length === 0) {
            profileModel.experienceEdit.foreignExperiences = [{ countryCode: 0, countryName: '', startDate: '', endDate: '', description: '' }];
          }
          if (profileModel.experienceEdit?.experiences.length === 0) {
            profileModel.experienceEdit.experiences = [{ description: '' }];
          }
          reset(profileModel.experienceEdit!);
        }
      } catch (e) {
        console.error(e);
        reset(defaultValues);
      }
    };
    init();
  }, []);

  useEffect(() => {
    profileModel.isDirty = formState.isDirty;
  }, [formState.isDirty]);

  const onSubmit = useCallback(async (_data?: any) => {
    try {
      const tmpArray:number[] = [];
      profileModel.setIsWrongExperience(tmpArray);
      const inputData = getValues();
      const transformed:IProfileExperienceRq = {
        ...inputData,
        activities: inputData.activities?.map<IActivityDto>(({ type, startDate, endDate, place, description }) => ({ type, startDate, endDate, place, description })),
        foreignExperiences: inputData.foreignExperiences?.map<IForeignExperienceDto>(({ countryCode, startDate, endDate, description }) => ({ countryCode, startDate, endDate, description })),
        experiences: inputData.experiences?.map<IExperienceDto>(({ description }) => ({ description })),
      };
      if (transformed.activities[0].startDate === '' && transformed.activities[0].endDate === '') transformed.activities = [];
      if (transformed.foreignExperiences[0].startDate === '' && transformed.foreignExperiences[0].endDate === '') transformed.foreignExperiences = [];
      if (transformed.experiences[0].description === '') transformed.experiences = [];
      transformed.sourcePage = profileModel.sourcePage;
      await profileModel.saveExperience(transformed, isMatching);
      profileModel.isDirty = false;
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onError = (e:any) => {
    const tmpArray:number[] = [];
    if (e.activities) {
      tmpArray.push(0);
    }
    if (e.foreignExperiences) {
      tmpArray.push(1);
    }
    profileModel.setIsWrongExperience(tmpArray);
    setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 필요한 정보를 작성해 주세요.', subMessage: undefined });
  };

  const topTabList = [
    {
      code: 0,
      name: '대내외 활동',
    },
    {
      code: 1,
      name: '해외 경험',
    },
    {
      code: 2,
      name: '기타 경험',
    },
  ];

  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <>
      <ProfileEditTitleComponent
        title='나만의 멋진 경험을 입력해 주세요.'
        subtitle='경험을 통해 얻은 결과와 배운 점을 구체적으로 작성해 주세요.'
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <ProfileTopTab topTabList={topTabList} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <ProfileExperienceActivities hidden={!(topTabList[0].code === currentTab)} />
            <ProfileExperienceForeignExperiences hidden={!(topTabList[1].code === currentTab)} />
            <ProfileExperienceExperiences hidden={!(topTabList[2].code === currentTab)} />
          </Frame>

          <JDSaveButton fixed={false} />
        </FormProvider>
      </form>
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfileExperience));
