import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import React from 'react';
import styled from 'styled-components';
import ImgExample from './imgProfile.png';

const Frame = styled.div`
    padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
`;

const MatchApplySelfBrandingExample = () => {
  return (
    <Frame>
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_1_b}
        color={V2TextOption.color.default}
      >
        나를 소개할 수 있는 글과 사진을 등록해 주세요.
      </V2Text>
      <SpacingBlock size={SpaceValue.S} vertical />
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_2_m}
        color={V2TextOption.color.subtle}
      >
        인사담당자가 프로필을 볼 때 가장 먼저 확인하는 정보입니다.
      </V2Text>
      <SpacingBlock size={SpaceValue.L} vertical />
      <img src={ImgExample} alt='예시' />
    </Frame>
  );
};

export default MatchApplySelfBrandingExample;
