/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
import colors from '__designkit__/common/colors';
import AccaIntroducePage from '__pc__/page/accaIntroduce';
import EventChallenge from '__pc__/page/eventChallenge';
import EventChuseok from '__pc__/page/eventChuseok';
import EventDChamps from '__pc__/page/eventDChamps';
import EventMailProfile from '__pc__/page/eventMailProfile';
import EventProfile from '__pc__/page/eventProfile';
import MatchIntroduce from '__pc__/page/matchIntroduce';
import PlanMatching from '__pc__/page/timeattack_marketer';
import CardImg from '__pc__/page/timeattack_marketer/listItem/marketerCard/cardImg';
import MainHeader, { IMainHeaderProps } from 'components/_v2/_common/mainHeader/MainHeader';
import SignUp from 'components/_v2/join/SignUp';
import MyPageApplying from 'components/_v2/mypage/MyPageApplying';
import MyPageBookmark from 'components/_v2/mypage/MyPageBookmark';
import PageFrame, { IPageFrameProps, NavType } from 'components/common/PageFrame';
import RoutePaths from 'consts/RoutePaths';
import AiGate from 'pages/AiGate';
import AiIntroduce from 'pages/AiIntroduce';
import AiTutorial from 'pages/AiTutorial';
import Error404 from 'pages/Error404';
import FindAccount from 'pages/FindAccount';
import InfoDetailPage from 'pages/InfoDetailPage';
import InfoListPage from 'pages/InfoListPage';
import JobdaDeclaration from 'pages/JobdaDeclaration';
import JobdaFaq from 'pages/JobdaFaq';
import MarketingPolicy from 'pages/MarketingPolicy';
import MyPage from 'pages/MyPage';
import Privacy from 'pages/Privacy';
import PrivacyNotice from 'pages/PrivacyNotices';
import ResumeThirdpartyAgreement from 'pages/ResumeThirdpartyAgreement';
import UpdateAccountPage from 'pages/UpdateAccountPage';
import AccaAgree from 'pages/_v2/AccaAgree';
import BuilderLogin from 'pages/_v2/BuilderLogin';
import Calendar from 'pages/_v2/Calendar';
import DeleteAccountPage from 'pages/_v2/DeleteAccountPage';
import GlobalSearchResult from 'pages/_v2/GlobalSearchResult';
import Login from 'pages/_v2/Login';
import Notification from 'pages/_v2/Notification';
import Preparation from 'pages/_v2/Preparation';
import ServiceUnavailable from 'pages/_v2/ServiceUnavailable';
import UnavailableMobileOnly from 'pages/_v2/UnavailableMobileOnly';
import UpdateBirthdatePage from 'pages/_v2/UpdateBirthdatePage';
import AccaGate from 'pages/_v2/acca/AccaGate';
import AccaResultSample from 'pages/_v2/acca/AccaResultSample';
import PaccGate from 'pages/_v2/acca/PaccGate';
import Challenge from 'pages/_v2/challenge/Challenge';
import ChallengeDetail from 'pages/_v2/challenge/ChallengeDetail';
import ChattingPage from 'pages/_v2/chat/ChattingPage';
import CompanyDetailTab from 'pages/_v2/company/CompanyDetailTab';
import EventPage from 'pages/_v2/event/EventPage';
import Jobs from 'pages/_v2/jobs/Jobs';
import Main from 'pages/_v2/main/Main';
import MatchApply from 'pages/_v2/matchApply/MatchApply';
import MypageHome from 'pages/_v2/myPage/MyPageHome';
import MyPageNotice from 'pages/_v2/myPage/MyPageNotice';
import MyProfile from 'pages/_v2/myprofile/MyProfile';
import MyProfileAccaResults from 'pages/_v2/myprofile/MyProfileAccaResults';
import MyProfilePhsPstResults from 'pages/_v2/myprofile/MyProfilePhsPstResults';
import PassboardMain from 'pages/_v2/passboard/Passboard';
import PassboardDetail from 'pages/_v2/passboard/PassboardDetail';
import PassboardNew from 'pages/_v2/passboard/PassboardNew';
import Position from 'pages/_v2/position/Position';
import PositionJd from 'pages/_v2/position/PositionJd';
import PositionJdPreview from 'pages/_v2/position/PositionJdPreview';
import Profile from 'pages/_v2/profile/Profile';
import HiddenPage from 'pages/hiddenPage/HiddenPage';
import MyPageAccount from 'pages/myPage/MyPageAccount';
import MyPagePartnership from 'pages/myPage/MyPagePartnership';
import InterviewPracticeGate from '__pc__/page/PassInterviewGate';
import { ReactNode } from 'react';
import { Redirect, RouteProps } from 'react-router';
import Kakao from 'pages/_v2/kakao';
import PassInterviewHistory from 'components/_v2/passInterview/PassInterviewHistory';
import Roulette from 'pages/_v2/Roulette';
import Preferred from 'pages/_v2/preferred/Preferred';
import JobflexLogin from 'pages/_v2/jobflex/JobflexLogin';
import Welcome from 'components/_v2/join/Welcome';
import JobsPreview from 'pages/_v2/jobs/JobsPreview';
import UpdateMobilePage from 'pages/_v2/UpdateMobilePage';
import MatchingGuide from 'pages/_v2/matchinGuide/MatchingGuide';
import Upload from 'pages/_v2/upload/Upload';
import MatchingAd from '__pc__/page/matchingAd';
import PreferredSelfBranding from 'components/_v2/preferred/PreferredSelfBranding';
import MatchLogin from 'pages/_v2/MatchLogin';
import HxIntroduce from 'pages/_v2/hxIntroduce/HxIntroduce';

export enum GNBMenu {
  // v2
  Home = 'home',
  Profile = 'profile',
  Jobs = 'jobs',
  Employment='employment',
  AccaTest = 'acca/test',
  Match = 'match',
  // v1
  Mypage = 'mypage',
  Login = 'login',
  About = 'about',
  Company = 'company',
  Simule = 'simule',
  AccTutorial = 'tutorial',
  AccIntroduce = 'introduce',
  PassEvent = 'passevent',
  PassInterview ='passInterview',
  Pass = 'pass',
  Info = 'info',
  Getjob = 'getjob',
  JobMatchDashboard = 'match/dashboard',
  AccaIntroduce = 'acca/introduce',
}

export const RoutePrefixes: { [key: string]: string[] } = {
  // v2
  home: [RoutePaths.home, RoutePaths.root],
  employment: [RoutePaths.calendar, RoutePaths.position, RoutePaths.challenge],
  match: [RoutePaths.match, RoutePaths.match_status, RoutePaths.match_settings, RoutePaths.match_profile, RoutePaths.match_test],
  mypage: [RoutePaths.mypage, RoutePaths.mypage_apply, RoutePaths.mypage_bookmark, RoutePaths.profile, RoutePaths.myprofile, RoutePaths.acca_results(), RoutePaths.phs_pacc_results()],
  acca_test: [RoutePaths.acca_test, RoutePaths.phs_test],
  ai_tutorial: [RoutePaths.acc_tutorial],
  ai_introduce: [RoutePaths.acc_introduce],
  info: [RoutePaths.info, RoutePaths.passboard, RoutePaths.passboard_detail(), RoutePaths.passboard_edit()],
  jobMatchIntroduce: [RoutePaths.match_introduce],
  AccaIntroduce: [RoutePaths.acca_introcue],
  matchingAd: [RoutePaths.matching_ad],
};

export const path2Menu = (path: string): GNBMenu | '' => {
  switch (true) {
    // v2
    case !!(RoutePrefixes.home.includes(path)):
      return GNBMenu.Home;
    case !!(RoutePrefixes.employment.includes(path)):
      return GNBMenu.Employment;
    case !!(RoutePrefixes.acca_test.includes(path)):
      return GNBMenu.AccaTest;
    case !!(RoutePrefixes.mypage.includes(path)):
      return GNBMenu.Mypage;
    case !!(RoutePrefixes.ai_tutorial.includes(path)):
      return GNBMenu.AccTutorial;
    case !!(RoutePrefixes.ai_introduce.includes(path)):
      return GNBMenu.AccIntroduce;
    case !!(RoutePrefixes.info.includes(path)):
      return GNBMenu.Info;
    case !!(RoutePrefixes.AccaIntroduce.includes(path)):
      return GNBMenu.AccaIntroduce;
    case !!(RoutePrefixes.match.includes(path)):
      return GNBMenu.Match;
    default:
      return '';
  }
};

enum HeaderType {
  MAIN,
  INNER,
  BACKABLE,
  ONLY_BACKABLE,
  COMPANY,
  MATCH,
  ACCA,
  JD,
  V2,
  LOGO,
  Employment,
  Center,
  DETAIL,
}

interface renderRouteParams {
  pageFrameProps?: IPageFrameProps;
  headerType?: HeaderType;
  headerProps?: any;
  component?: ReactNode;
}

const renderPageFrame = ({ pageFrameProps = {}, headerType, headerProps = {}, component }: renderRouteParams): ReactNode => {
  switch (headerType) {
    case HeaderType.ONLY_BACKABLE:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} backable gnb={false} alarm={false} search={false} />}>
          {component}
        </PageFrame>
      );
    case HeaderType.BACKABLE:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} backable alarm={false} list={false} chat />}>
          {component}
        </PageFrame>
      );
    case HeaderType.LOGO:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} gnb={false} backable={false} search={false} alarm={false} list={false} />}>
          {component}
        </PageFrame>
      );
    case HeaderType.MAIN:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} chat />}>
          {component}
        </PageFrame>
      );
    case HeaderType.Employment:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} employment chat />}>
          {component}
        </PageFrame>
      );
    case HeaderType.Center:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} accCenter chat />}>
          {component}
        </PageFrame>
      );
    case HeaderType.ACCA:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} search={false} />}>
          {component}
        </PageFrame>
      );
    case HeaderType.JD:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} title='' backable alarm={false} gnb={false} search={false} share />}>
          {component}
        </PageFrame>
      );
    case HeaderType.DETAIL:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} title='' backable />}>
          {component}
        </PageFrame>
      );
    case HeaderType.V2:
      return (
        <PageFrame {...pageFrameProps} header={<MainHeader {...headerProps as IMainHeaderProps} alarm gnb search chat />}>
          {component}
        </PageFrame>
      );
    default:
      return (
        <PageFrame {...pageFrameProps}>
          {component}
        </PageFrame>
      );
  }
};

const { MAIN, INNER, LOGO, BACKABLE, ONLY_BACKABLE, ACCA, JD, V2, Employment, Center, DETAIL } = HeaderType;

/**
 * TODO: 헤더 쉐도우 정리해도 좋을 것 같음.
 * 회원가입/로그인 페이지와 GNB 하단에 탭이나 바로 아래 또다른 bar
 */

export const RootRoutePaths: RouteProps[] = [
  { path: RoutePaths.root, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <Main />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.home, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <Main />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.event, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <EventPage />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.notice_update, exact: true, render: () => renderPageFrame({ headerType: LOGO, component: <ServiceUnavailable />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.notice_update_test, exact: true, render: () => renderPageFrame({ headerType: LOGO, component: <ServiceUnavailable />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.calendar, exact: true, render: () => renderPageFrame({ headerType: Employment, component: <Calendar />, pageFrameProps: { scrollRestore: true, scrollInitialize: false, scrollRestoreDelay: 300, footer: false } }) },
  { path: RoutePaths.position, exact: true, render: () => renderPageFrame({ headerType: Employment, component: <Position />, pageFrameProps: { scrollRestore: true, scrollInitialize: false, scrollRestoreDelay: 300, footer: false } }) },
  { path: RoutePaths.position_recommend, exact: true, render: () => renderPageFrame({ headerType: Employment, component: <Position />, pageFrameProps: { scrollRestore: true, scrollInitialize: false, scrollRestoreDelay: 300, footer: false } }) },
  { path: RoutePaths.search_result(), exact: true, render: () => renderPageFrame({ component: <GlobalSearchResult />, pageFrameProps: { scrollRestore: true } }) },
  { path: RoutePaths.mypage, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MyPage />, pageFrameProps: {} }) },
  { path: RoutePaths.login, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <Login />, pageFrameProps: { background: colors.WHITE_100, navType: NavType.NONE }, headerProps: { underShadow: false, list: false } }) },
  { path: RoutePaths.match_event_login, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MatchLogin />, pageFrameProps: { background: colors.WHITE_100, navType: NavType.NONE }, headerProps: { underShadow: false, list: false } }) },
  { path: RoutePaths.builder_login(), exact: true, render: () => renderPageFrame({ component: <BuilderLogin />, pageFrameProps: { background: colors.WHITE_100, navType: NavType.NONE }, headerProps: { underShadow: false, list: false } }) },
  { path: RoutePaths.matchLogin, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <Login />, pageFrameProps: { background: colors.WHITE_100, navType: NavType.NONE }, headerProps: { underShadow: false, list: false } }) },
  { path: RoutePaths.findAccount(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <FindAccount />, pageFrameProps: { navType: NavType.NONE }, headerProps: { underShadow: false } }) },
  { path: RoutePaths.join, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <SignUp />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.welcome, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <Welcome />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.acc_gate, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <AiGate />, pageFrameProps: { footer: true } }) },
  { path: RoutePaths.acc_tutorial, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <AiTutorial />, pageFrameProps: { footer: true } }) },
  { path: RoutePaths.acc_introduce, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <AiIntroduce />, pageFrameProps: { footer: true } }) },
  { path: RoutePaths.acca_agree, exact: true, render: () => <AccaAgree /> },
  { path: RoutePaths.preparation, exact: true, render: () => renderPageFrame({ headerType: V2, component: <Preparation />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.company_details(), exact: true, render: () => renderPageFrame({ component: <CompanyDetailTab />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.pass_interview, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <InterviewPracticeGate />, pageFrameProps: { footer: false, navType: NavType.NONE } }) },
  { path: RoutePaths.info, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <InfoListPage />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.info_detail(), exact: true, render: () => renderPageFrame({ headerType: BACKABLE, component: <InfoDetailPage />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.jobda_declaration, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <JobdaDeclaration />, pageFrameProps: { footer: true } }) },
  { path: RoutePaths.jobda_faq, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <JobdaFaq />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.hiddenPageMain, exact: true, render: () => <HiddenPage /> },
  { path: RoutePaths.hiddenPage(), exact: true, render: () => <HiddenPage /> },
  { path: RoutePaths.privacy, exact: true, render: () => <Privacy /> },
  { path: RoutePaths.marketingPolicy, exact: true, render: () => <MarketingPolicy /> },
  { path: RoutePaths.privacyNotice(), exact: true, render: () => <PrivacyNotice /> },
  { path: RoutePaths.thirdpartyAgreement, exact: true, render: () => <ResumeThirdpartyAgreement /> },
  { path: RoutePaths.acc_result, exact: true, render: () => <Redirect to={`${RoutePaths.acc_gate}#result`} /> },
  { path: RoutePaths.acc_result_test, exact: true, render: () => <Redirect to={`${RoutePaths.acc_gate}#result`} /> },
  { path: RoutePaths.acc_result_competency, exact: true, render: () => <Redirect to={`${RoutePaths.acc_gate}#result`} /> },
  { path: RoutePaths.match_introduce, exact: true, render: () => renderPageFrame({ headerType: V2, component: <MatchIntroduce />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.matching_ad, exact: true, render: () => renderPageFrame({ headerType: V2, component: <MatchingAd />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.notification, exact: true, render: () => renderPageFrame({ component: <Notification />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.acca_result_save, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <UnavailableMobileOnly />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.phs_test, exact: true, render: () => renderPageFrame({ headerType: Center, component: <PaccGate />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.acca_test, exact: true, render: () => renderPageFrame({ headerType: Center, component: <AccaGate />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.acca_introcue, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <AccaIntroducePage />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.version, exact: true, render: () => process.env.REACT_APP_BUILD_BRANCH },
  { path: RoutePaths.position_jd(), exact: true, render: () => renderPageFrame({ headerType: JD, component: <PositionJd />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.position_ats_preview, exact: true, render: () => <PositionJdPreview /> },
  { path: RoutePaths.jobs_ats_preview, exact: true, render: () => <JobsPreview /> },
  { path: RoutePaths.challenge, exact: true, render: () => renderPageFrame({ headerType: Employment, component: <Challenge />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.challenge_detail(), exact: true, render: () => renderPageFrame({ headerType: JD, component: <ChallengeDetail />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.unavailableMobileOnly, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <UnavailableMobileOnly />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobflex_login, exact: true, render: () => renderPageFrame({ headerType: LOGO, component: <JobflexLogin />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobs_posting(), exact: true, render: () => renderPageFrame({ component: <Jobs />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.error, exact: true, render: () => renderPageFrame({ headerType: BACKABLE, component: <Error404 />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.jobda_challenge_event, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <EventChallenge />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobda_dchamps_event, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <EventDChamps />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobda_chuseok_event, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <EventChuseok />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobda_profile_event, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <EventProfile />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.jobda_mail_profile_event, exact: true, render: () => renderPageFrame({ headerType: ACCA, component: <EventMailProfile />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.chat, exact: false, render: () => renderPageFrame({ headerType: INNER, component: <ChattingPage />, pageFrameProps: { navType: NavType.NONE } }) },
  { path: RoutePaths.deleteAccount, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <DeleteAccountPage />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.update_birthdate, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <UpdateBirthdatePage />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.update_account, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <UpdateAccountPage />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.update_mobile, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <UpdateMobilePage />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.acca_sample_result, exact: true, render: () => renderPageFrame({ headerType: INNER, component: <AccaResultSample />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.passboard, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <PassboardMain />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.passboard_detail(), exact: true, render: () => renderPageFrame({ headerType: JD, component: <PassboardDetail />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.timeattack_marketer, exact: true, render: () => renderPageFrame({ component: <PlanMatching />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.timeattack_marketer_detail(), exact: true, render: () => renderPageFrame({ component: <CardImg />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.oauth2_callback_kakao, exact: true, render: () => renderPageFrame({ component: <Kakao /> }) },
  { path: RoutePaths.roulette, exact: true, render: () => renderPageFrame({ component: <Roulette />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.hx_introduce, exact: true, render: () => renderPageFrame({ component: <HxIntroduce />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.matchingGuideUpload(), exact: true, render: () => renderPageFrame({ component: <Upload />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { render: () => renderPageFrame({ headerType: BACKABLE, component: <Error404 />, pageFrameProps: { navType: NavType.NONE } }) },
];

export const RoutePathsNeedLogin: RouteProps[] = [
  { path: RoutePaths.mypage, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MyProfile />, pageFrameProps: {} }) },
  { path: RoutePaths.mypage_apply, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MypageHome />, headerProps: { title: '지원 현황', gnb: false, search: false, alarm: false } }) },
  { path: RoutePaths.mypage_applying, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPageApplying />, headerProps: { title: '작성중인 지원서', gnb: false, search: false, alarm: false } }) },
  { path: RoutePaths.mypage_account, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPageAccount />, headerProps: { title: '계정정보 관리', gnb: false, search: false, alarm: false } }) },
  { path: RoutePaths.mypage_partnership, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPagePartnership />, headerProps: { title: '제휴서비스 신청 관리', gnb: false, search: false, alarm: false } }) },
  { path: RoutePaths.mypage_notice, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPageNotice />, headerProps: { title: '알림', gnb: false, search: false } }) },
  { path: RoutePaths.mypage_partnership_list(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPagePartnership />, headerProps: { title: '제휴서비스 신청 관리', gnb: false, search: false, alarm: false } }) },

  { path: RoutePaths.mypage_bookmark, exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyPageBookmark />, headerProps: { title: '북마크', gnb: false, search: false } }) },
  { path: RoutePaths.acca_results(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyProfileAccaResults /> }) },
  { path: RoutePaths.phs_pacc_results(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyProfilePhsPstResults /> }) },
  { path: RoutePaths.phs_pst_results(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyProfilePhsPstResults /> }) },
  { path: RoutePaths.phs_dchamps_results(), exact: true, render: () => renderPageFrame({ headerType: ONLY_BACKABLE, component: <MyProfilePhsPstResults /> }) },
  { path: RoutePaths.match, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MatchApply />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.match_settings, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MatchApply />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.match_status, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MatchApply />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.match_profile, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MatchApply />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.match_test, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <MatchApply />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.profile, exact: true, render: () => renderPageFrame({ headerType: V2, component: <Profile />, pageFrameProps: { navType: NavType.JOBDA, footer: false } }) },
  { path: RoutePaths.preferred, exact: true, render: () => renderPageFrame({ component: <Preferred />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.selfBranding, exact: true, render: () => renderPageFrame({ component: <PreferredSelfBranding />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
  { path: RoutePaths.passboard_new, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <PassboardNew />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.passboard_edit(), exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <PassboardNew />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.pass_interview_history, exact: true, render: () => renderPageFrame({ headerType: MAIN, component: <PassInterviewHistory />, pageFrameProps: { footer: false } }) },
  { path: RoutePaths.matchingGuide(), exact: true, render: () => renderPageFrame({ component: <MatchingGuide />, pageFrameProps: { navType: NavType.NONE, footer: false } }) },
];

// export const RouteAllowedForNotAgreedUser:string[] = []; //TODO: 개인정보 재동의 시 필요

// * * history를 사용할 수 없는 곳에서는 이걸 사용하도록 함 */
export const historyReplace = (path: string) => {
  window.location.href = path;
};

export default ({});
