import { ReactComponent as IconClose } from 'assets/icon/icon_close_16.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption, V2IconSizeType } from '__pc__/constant/v2Design/V2IconType';

interface IJDClosableChipProps {
  onClose: () => void;
}

const Frame = styled.div`
  display:inline-flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  padding: 8px 12px 8px 16px;
  border-radius: 500px;
  border: 1px solid ${V2BorderColors.default};
  background:rgba(51, 52, 54, 0.00);
  white-space : nowrap;
  gap:8px;
`;

const JDClosableChip:FC<IJDClosableChipProps> = ({ onClose, ...props }) => (
  <Frame>
    <V2Text fontStyle={V2TextOption.fontStyle.body_3_sb} color={V2TextOption.color.subtle}>{props.children}</V2Text>
    <button type='button' onClick={onClose}>
      <V2Icon name='close' fill={V2IconOption.fill.subtle} size={V2IconSizeType.XS} />
    </button>
  </Frame>
);

export default JDClosableChip;
