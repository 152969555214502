import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import styled from 'styled-components';
import ImgBackground from 'pages/_v2/hxIntroduce/img/imgFooterBackground.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';

const PaddingFrame = styled.div`
  padding: 0 16px;
`;
const Frame = styled.div`
  display: flex;
  padding: 64px 0 144px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${RadiusValue.L}px;
  background-image: url(${ImgBackground});
  background-image: url(${ImgBackground});
  background-size: cover;
  background-repeat: no-repeat;
  
`;
const HxBottomBanner = () => {
  return (
    <PaddingFrame>
      <Frame>
        <V2Text styles={{ textAlign: 'center', whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.inverse}>{`H.X에서 완전히 새로운
      채용을 경험해보세요.`}
        </V2Text>
      </Frame>
    </PaddingFrame>
  );
};

export default HxBottomBanner;
