import PositionCard from '__designkit__/card/PositionCard';
import Colors from '__designkit__/common/colors';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { StyledMainCurationChip, StyledMainCurationTab } from 'components/_v2/main/MainContents';
import { Divider12B } from 'components/divider/Divider';
import Fonts from '__designkit__/common/fonts';
import { IProfileSkillDto } from 'interfaces/rqrs/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PaccGateModel from 'models/_v2/PaccGateModel';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import PaccGateDirtyModal from './PaccGateDirtyModal';
import PaccGateSkillKeywordRecommend from './PaccGateSkillKeywordRecommend';
import PaccGateSkillModal from './PaccGateSkillModal';
import PaccNoPosition from './PaccNoPositions';
import { IPaccGateProps } from './PaccGateService';

const PaccFrame = styled.div``;
const Frame = styled.div`
  padding: 32px 16px;
  > h1 {
    font: ${Fonts.H5_Bold};
    color: ${Colors.JOBDA_WHITE};
    margin-bottom: 8px;
  }
  > h2 {
    font: ${Fonts.B3_Medium_P};
    color: ${Colors.CG_60};
    margin-bottom: 4px;
  }
  .skill-input-text{
    display: flex;
    justify-content: end;
    font: ${Fonts.B3_Medium};
    color:${Colors.JOBDA_WHITE};
    text-decoration: underline;
    margin-bottom: 16px;
    
  }
  .custom-position-card {
    width: 158px;
    height: 245px;
    .card {
      width: 158px;
      height: 118px;
    }
  }
`;

const CustomStyledMainCurationTab = styled(StyledMainCurationTab)` 
.curation-frame{
    padding: 0px;
}
`;
const DarkModeStyledMainCurationChip = styled(StyledMainCurationChip)`
    background-color: ${(props) => (props.selected ? `${Colors.JOBDA_WHITE}` : `${Colors.CG_90}`)} ;
    border: none;
    .curation-text{
        color:${(props) => (props.selected ? `${Colors.CG_80}` : `${Colors.CG_60}`)} ;
    }
`;
interface IPaccGateSkillPosition extends IPaccGateProps {
    profileSkillModel?:ProfileSkillModel
}
const PaccGateSkillPosition: FC<IPaccGateSkillPosition> = ({ login = new Login(), paccGateModel = new PaccGateModel(), profileSkillModel = new ProfileSkillModel() }) => {
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const [isSkillModal, setIsSkillModal] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      await paccGateModel.loadPhsCenterSkill();
      if (paccGateModel.centerSkill) {
        paccGateModel.centerSkillChip = paccGateModel.centerSkill.skills.filter((item) => item.positionCount > 0);
        if (paccGateModel.centerSkillChip.length > 0) {
          paccGateModel.selectedKeyword = paccGateModel.centerSkillChip[0].code;
          paccGateModel.selectedKeywordName = paccGateModel.centerSkillChip[0].keyword;
          await paccGateModel.loadPhsCenterSkillPosition(paccGateModel.selectedKeyword);
        } else {
          paccGateModel.selectedKeyword = -1;
          paccGateModel.selectedKeywordName = '';
          paccGateModel.phsSkillPositions = null;
        }
      }
    };
    init();
  }, [isSkillModal]);

  const handleTabSelect = async (item:IProfileSkillDto) => {
    paccGateModel.selectedKeywordName = item.keyword;
    paccGateModel.selectedKeyword = item.code;
    await paccGateModel.loadPhsCenterSkillPosition(paccGateModel.selectedKeyword);
  };

  const handleModal = () => {
    setIsSkillModal(true);
  };

  const closeDirtyModal = () => {
    paccGateModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };

  return (
    <PaccFrame>
      <Frame>
        <h1>나의 기술 키워드를 활용할 수 있는 포지션</h1>
        {login.userInfo && paccGateModel.centerSkill && paccGateModel.centerSkill.skills.length > 0 && (
        <>
          {
          paccGateModel && paccGateModel.selectedKeywordName
          && <h2>{`${paccGateModel.selectedKeywordName} 기술을 요구하는 포지션이에요.`}</h2>
        }
          <div onClick={handleModal} role='button' className='skill-input-text'>내 기술 키워드 입력</div>

            {paccGateModel.centerSkill && paccGateModel.centerSkillChip.length > 0 && (
              <CustomStyledMainCurationTab>
                <div className='curation-frame'>
                  {paccGateModel.centerSkillChip.map((item) => (item.positionCount > 0 && (
                  <DarkModeStyledMainCurationChip key={item.code} selected={paccGateModel.selectedKeyword === item.code} onClick={() => handleTabSelect(item)}>
                    <div className='curation-text'>
                      {item.keyword}
                    </div>
                  </DarkModeStyledMainCurationChip>
                  )))}

                </div>
              </CustomStyledMainCurationTab>
            )}

        </>
        )}

        {
            (login.userInfo && paccGateModel.centerSkill && paccGateModel.centerSkill.skills.length > 0)
              ? (
                paccGateModel.centerSkillChip && paccGateModel.phsSkillPositions && paccGateModel.phsSkillPositions.positions.length > 0 ? (
                  <JDSwiper className='custom-position-swiper' slidesPerView='auto' spaceBetween={12}>
                    {paccGateModel.phsSkillPositions.positions.map((item, index) => (
                      <JDSwiperSlide key={`card-item-${item.sn}`} className='custom-position-card'>
                        <PositionCard
                          darkMode
                          item={{
                            positionSn: item.sn,
                            positionName: item.title,
                            recruitmentType: item.recruitmentType,
                            closingDateTime: item.closingDateTime,
                            locationCode: item.locationCode,
                            companyName: item.companyName,
                            logoImageUrl: item.logoImageUrl,
                            jobTitleNames: item.jobTitleNames,
                            bookmarkYn: item.bookmarkYn,
                            minSalary: item.minSalary,
                            maxSalary: item.maxSalary,
                            jobPosting: item.jobPosting,
                            teamImageUrl: item.thumbnailImageUrl,
                          }}
                        />
                      </JDSwiperSlide>
                    ))}
                  </JDSwiper>
                ) : (
                  <PaccNoPosition />
                )
              )
              : <PaccGateSkillKeywordRecommend isSkillModal={isSkillModal} setIsSkillModal={setIsSkillModal} />
       }
      </Frame>

      <Divider12B />
      <PaccGateSkillModal
        isOpen={isSkillModal}
        onClickClose={async () => {
          if (paccGateModel.isDirty) setIsDirtyModalOpen(true);
          else {
            profileSkillModel.recommendSkills = [];
            setIsSkillModal(false);
          }
        }}
      />
      <PaccGateDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={() => {
          paccGateModel.isDirty = false;
          setIsSkillModal(false);
        }}
        onClickClose={closeDirtyModal}
      />
    </PaccFrame>
  );
};

export default inject(injectStore.paccGateModel, injectStore.login, injectStore.profileSkillModel)(observer(PaccGateSkillPosition));
