/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import classnames from 'classnames/bind';
import { V2TextElementType } from '__pc__/constant/v2Design/V2TextType';
import { V2FontStyle, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface TextProps {
  element?: V2TextElementType;
  className?: string;
  fontStyle?: V2FontStyleType;
  color?: V2TextColorsType;
  ellipsis?: number;
  styles?: React.CSSProperties;
  children?: React.ReactNode;
}

/**
 * @param {V2TextElementType} [element] - 사용할 태그 지정.
 * @param {string} [className] - 추가 클래스를 설정.
 * @param {V2FontStyleType} [fontStyle] - 폰트 스타일 타입.
 * @param {V2TextColorsType} [color] - 폰트 색상 타입.
 * @param {number} [ellipsis] - 말 줄임 효과 적용.
 * @param {React.CSSProperties} [styles] - 추가 인라인 스타일.
 */

const V2Text = ({
  element = V2TextElementType.div,
  children,
  className,
  fontStyle = V2FontStyleType.body_1_b,
  color = V2TextColorsType.default,
  styles,
  ellipsis,
}: TextProps) => {
  const Element = element;
  const style = {
    ...V2FontStyle[fontStyle],
    color: V2TextColors[color],
    ...styles,
  };

  return (
    <Element className={cx(className, { [`ellipsis-${ellipsis}`]: ellipsis })} style={style as any}>
      {children}
    </Element>
  );
};

export default V2Text;
