/* eslint-disable camelcase */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import ImgEmptyJoy from 'assets/_v2/positionList/img_character_joy_empty.png';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import MatchApplyBlurFrame from 'components/_v2/matchApply/matchApplyStatus/MatchApplyBlurFrame';
import { Divider1G40 } from 'components/divider/Divider';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import { MatchApplyStatusType } from 'consts/_v2/match/MatchType';
import useQueryParams from 'hooks/useQueryParams';
import IMatchApplyStatusProps from 'interfaces/_v2/matchApply/IMatchApplyStatusProps';
import { IMatchingStatusDto } from 'interfaces/_v2/matchApply/IMatchingStatusListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import MatchApplyStatusCard from './MatchApplyStatusCard';

const HEAD_FRAME_HEIGHT = 329;

const Frame = styled.div``;

const MatchApplyStatusTab = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .tab-box {   
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        padding: 8px;
        border-radius: 4px;
        background-color: ${colors.WHITE_100};
        font: ${Fonts.B4_Medium};
        color: ${colors.CG_60};

        .count {
          position: relative;
          font: ${Fonts.H5_Bold};
          color: ${colors.CG_60};
        }

        .count.red-dot:after {
            content: '';
            width: 6px;
            height: 6px;
            background: ${colors.ERROR};
            position: absolute;
            top: 0; 
            right: -8px; 
            border-radius: 50%;
        }


        &[aria-current=true] { 
            background-color: ${colors.CG_30};
            color: ${colors.JOBDA_BLACK};

            .count {
                font: ${Fonts.H5_Bold};
                color: ${colors.JOBDA_BLACK};
            }
        }
    }
`;

const EmptyFrame = styled.div`
  padding: 64px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .img-joy{
    width: 80px;
    height: 80px;
    
  }
`;

const MatchApplyStatusTipTextFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SpaceValue.L}px;
    border-bottom: 1px solid #DFE0E2;
    .title-frame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rate-chip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: ${Fonts.B3_Bold};
        padding: 8px 16px;
        border: 1px solid ${colors.CG_50};
        border-radius: 48px;

        .rate {
            color: ${colors.G_200};
            margin: 0 4px;
        }

        .popover-frame{
          font: ${Fonts.B3_Medium};
          color: ${colors.CG_30};
        }
      }
    }
    .title {
        display: flex;
        align-items: center;
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        align-items: center;
        margin-bottom: 4px;

        >span {
          margin-left: 4px;
        }
        
        
    }

    .sub {
        font: ${Fonts.B3_Medium_P};
        color: ${colors.CG_70};
        white-space: pre-line;
    }
`;

export enum MatchApplyStatusTabType {
  RECOMMEND = 'RECOMMEND',
  MATCH_OFFER = 'MATCH_OFFER',
  MATCH_INTERVIEW_OFFER = 'MATCH_INTERVIEW_OFFER',
}

interface MatchApplyStatusTipTextProps {
  title: string;
  responseRate?: number | null;
}

const MatchApplyStatusTipText: React.FC<MatchApplyStatusTipTextProps> = ({ title, responseRate }) => (
  <MatchApplyStatusTipTextFrame>
    <div className='title-frame'>
      <V2Text fontStyle={V2TextOption.fontStyle.title_3_sb} color={V2TextOption.color.default}>{title}</V2Text>
      {responseRate !== null
      && (
      <div className='rate-chip'>
        응답률<span className='rate'>{responseRate}%</span>
        <JDPopover
          useOutsideClick
          position='non_pony_under_right'
          popoverWidth={180}
          anchorIcon={<Icon name='information' size={20} color={colors.CG_70} />}
        >
          <div className='popover-frame'>
            응답률이 50% 이상인 지원자는 더 많은 제안을 받고 있어요.
          </div>
        </JDPopover>

      </div>
      )}
    </div>
  </MatchApplyStatusTipTextFrame>
);

const MatchApplyStatusBoard: FC<IMatchApplyStatusProps> = ({ matchApplyStatusModel = new MatchApplyStatusModel(), matchMessageStore = new MatchMessageStore() }) => {
  const [currentTab, setCurrentTab] = useState<MatchApplyStatusTabType>(MatchApplyStatusTabType.MATCH_OFFER);
  const history = useHistory();
  const { tab, sn, accessPath } = useQueryParams();

  // 탭 제거에 따른 임시 주석처리들
  // const queryTab = Object.values(MatchApplyStatusTabType).includes(tab as MatchApplyStatusTabType)
  //   ? tab as MatchApplyStatusTabType
  //   : MatchApplyStatusTabType.MATCH_OFFER;

  useEffect(() => {
    (async () => {
      await matchApplyStatusModel.loadMatchStatus();
    })();
  }, []);

  useEffect(() => {
    if (sn) {
      openMessageModal(Number(sn));
    }
  }, [sn, accessPath]);

  // useEffect(() => {
  //   if (queryTab) setCurrentTab(queryTab);
  // }, [queryTab]);

  useEffect(() => {
    matchApplyStatusModel.matching = null;
    updateList();
  }, [currentTab]);

  const openMessageModal = async (matchingSn: number) => {
    matchMessageStore.open(matchingSn);
    await matchApplyStatusModel.loadMatchStatus();
  };
  const updateList = async () => {
    await matchApplyStatusModel.loadMatchList(currentTab);
    const hasUnreadItems = matchApplyStatusModel.matching?.matchingList.some((item) => !item.readYn);
    if (hasUnreadItems) readMatchTab(currentTab);
  };

  const readMatchTab = async (readTab: MatchApplyStatusTabType) => {
    switch (readTab) {
      case MatchApplyStatusTabType.RECOMMEND:
        if (!matchApplyStatusModel.matchStatus?.newRecommendYn) return;
        break;
      case MatchApplyStatusTabType.MATCH_OFFER:
        if (!matchApplyStatusModel.matchStatus?.newOfferYn) return;
        break;
      case MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER:
        if (!matchApplyStatusModel.matchStatus?.newInterviewOfferOpenYn) return;
        break;
      default:
        break;
    }
    await matchApplyStatusModel.readMatchCards(readTab);
    await matchApplyStatusModel.loadMatchStatus();
    await matchApplyStatusModel.loadMatchList(currentTab);
  };

  // const handleTabClick = async (tabItem: any) => {
  //   history.replace(RoutePaths.match_status_tab(tabItem.type));
  //   setCurrentTab(tabItem.type);
  // };

  let isFirstDesiredStatusFound = false;

  const hasAnyUnlikedItem = matchApplyStatusModel.matching?.matchingList.some((matchItem) => !matchItem.likeYn);
  const renderedList = matchApplyStatusModel.matching?.matchingList.map((matchItem: IMatchingStatusDto) => {
    const isDesiredStatus = (matchItem.statusType === MatchApplyStatusType.PROFILE_OPEN || matchItem.statusType === MatchApplyStatusType.RECOMMEND);
    const shouldShowTooltip: boolean = !!(isDesiredStatus && !isFirstDesiredStatusFound && hasAnyUnlikedItem && !matchItem.likeYn);
    if (shouldShowTooltip) {
      isFirstDesiredStatusFound = true;
    }

    return (
      <>
        <MatchApplyStatusCard key={matchItem.matchingSn} matchItem={matchItem} firstToolTip={shouldShowTooltip} />
        <Divider1G40 />
      </>
    );
  });

  const loadInfiniteScroll = async () => {
    await matchApplyStatusModel.lazeLoadMatchList(currentTab);
  };
  const renderMatchApplyStatusTipText = () => {
    return <MatchApplyStatusTipText title={'제안받은 포지션'} responseRate={matchApplyStatusModel.responseRate || null} />;
  };

  return (
    <>
      { renderMatchApplyStatusTipText() }

      <Frame>
        { matchApplyStatusModel.matching?.matchingList.length === 0 ? (
          <EmptyFrame>
            <img className='img-joy' src={ImgEmptyJoy} alt='joy' />
            <MatchApplyBlurFrame />
          </EmptyFrame>
        ) : (
          <>
            <CustomInfiniteScroll onInfinite={loadInfiniteScroll} threshold={150}>
              { renderedList }
            </CustomInfiniteScroll>
          </>
        )}
      </Frame>
      <MatchDashBoardMessageModal />
    </>
  );
};

export default inject(injectStore.matchApplyStatusModel, injectStore.matchMessageStore)(observer(MatchApplyStatusBoard));
