import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import { LazyImageLogo } from 'components/common/LazyImage';
import { IconBookmark24 } from 'consts/assets/icons/iconPages';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import { useBookmarkPositions, useBookmarkStatus } from 'query/bookmark/useBookmarkQuery';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    padding:24px 16px;
    display: flex;
    justify-content: space-between;
    .company-frame{
        display:flex;
        .company-logo{
            width: 48px;
            height: 48px;
            background-color: ${Colors.CG_40};
            margin-right: 12px;
        }
        .detail-frame{
            display:flex;
            gap:8px;
            flex-direction: column;
            align-self: center;
            .title{
                font:${Fonts.B1_Bold};
                color:${Colors.JOBDA_BLACK};
            }
            .subtitle{
                font:${Fonts.B2_Medium};
                color:${Colors.CG_70};
            
            }
        }
    }
    .bookmark-frame{
      display: flex;
      align-items: center;
    }
`;

interface ICompanyBookmarkCard{
    logo?:string;
    title?:string;
    bookmark?:boolean;
    numberOfPositions:number;
    companyModel?:CompanyModel
    companySn:number;
}
const CompanyBookmarkCard:FC<ICompanyBookmarkCard> = ({ companySn, logo, title, bookmark, numberOfPositions, companyModel = new CompanyModel() }) => {
  const { setToastObject } = useToast();
  const [like, setLike] = useState<boolean>(false);
  const { data: bookmarkStatus, refetch } = useBookmarkStatus();
  const history = useHistory();
  const handleLikeCompany = useCallback(async (e) => {
    e.stopPropagation();

    try {
      if (like) {
        await companyModel.unlikeCompany(companySn);
        setToastObject({ isOpen: true, type: 'INFO', message: '관심 기업에서 삭제되었습니다.' });
      } else {
        await companyModel.likeCompany(companySn);
        setToastObject({ isOpen: true, type: 'INFO', message: '관심 기업으로 등록되었습니다.' });
      }
      refetch();
      setLike(!like);
    } catch (error) {
      console.error(error);
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '문제가 발생했습니다. 다시 시도해 주세요.' });
    }
  }, [like, setToastObject]);

  useEffect(() => {
    if (bookmark) {
      setLike(true);
    } else {
      setLike(false);
    }
  }, [bookmark]);
  const handleMoveCompany = () => {
    history.push(RoutePaths.company_details(companySn));
  };
  return (
    <Frame onClick={handleMoveCompany}>
      <div className='company-frame'>
        {
            logo
              ? <LazyImageLogo src={logo} className='company-logo' />
              : <div className='company-logo' />
          }

        <div className='detail-frame'>
          <div className='title'>{title}</div>
          {numberOfPositions > 0 && <div className='subtitle'>{numberOfPositions}개 포지션 채용 중</div>}

        </div>
      </div>
      <div className='bookmark-frame'>
        <div
          role='presentation'
          className='bookmark'
          onClick={(e) => {
            handleLikeCompany(e);
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {like ? <Icon name='bookmark-filled' size={32} color={Colors.G_150} /> : <Icon name='bookmark' color={Colors.CG_40} size={32} />}
        </div>

        {/* {like
          ? <Icon name='heart-filled' size={32} color={Colors.ERROR} onClick={handleLikeCompany} />
          : <Icon name='heart' size={32} color={Colors.CG_40} onClick={handleLikeCompany} />} */}
      </div>
    </Frame>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyBookmarkCard));
