import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { RemoteMatchTestRepo } from 'repository/match/test/MatchTestRepo';
import { IMatchApplicantsAccApplyCodeRq, IMatchApplicantsPaccApplyCodeRq, IMatchApplicantsPstApplyCodeRq } from 'interfaces/_v2/IMatchTotalRqRs';
import { PhsAccType } from 'consts/MatchingMessageType';

const matchTestRepo = new RemoteMatchTestRepo();

// 일성검, 개성검, 개구검 다 useMutation으로 바꿔야함.

const fetchMatchApplicantsAccApplyCode = async (rq:IMatchApplicantsAccApplyCodeRq) => {
  const data = await matchTestRepo.loadMatchApplicantsAccApplyCode(rq);
  return data;
};

export function useMatchApplicantsAccApplyCode(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(fetchMatchApplicantsAccApplyCode, {
    onSuccess,
    onError,
  });
}

const fetchMatchApplicantsPaccApplyCode = async (rq:IMatchApplicantsPaccApplyCodeRq) => {
  const data = await matchTestRepo.loadMatchApplicantsPaccApplyCode(rq);
  return data;
};

export function useMatchApplicantsPaccApplyCode(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(fetchMatchApplicantsPaccApplyCode, {
    onSuccess,
    onError,
  });
}

const fetchMatchApplicantsPstApplyCode = async (rq:IMatchApplicantsPstApplyCodeRq) => {
  const data = await matchTestRepo.loadMatchApplicantsPstApplyCode(rq);
  return data;
};

export function useMatchApplicantsPstApplyCode(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(fetchMatchApplicantsPstApplyCode, {
    onSuccess,
    onError,
  });
}

const fetchMatchApplicantsMatchingInformationApply = async (rq:PhsAccType) => {
  const data = await matchTestRepo.loadMatchApplicantsMatchingInformationApply(rq);
  return data;
};
// useQuery
export function useMatchApplicantsMatchingInformationApply(rq:PhsAccType) {
  return useQuery(['matchApplicantsMatchingInformationApply', rq], () => fetchMatchApplicantsMatchingInformationApply(rq), {
    onError: (error) => {
      console.error(error);
    },
  });
}
