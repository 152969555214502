import React from 'react';
import classnames from 'classnames/bind';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import FlowList from '__pc__/page/accaIntroduce/flowStory/flowList';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import styles from './interview.module.scss';

const cx = classnames.bind(styles);

interface AnimationProps {
  fadeIn: boolean;
}

const Interview = ({ fadeIn }: AnimationProps) => {
  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  return (
    <div className={cx('commonBox', { on: fadeIn })}>
      <div className={cx('titleArea')}>
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.heading_3_b}
          color={V2TextOption.color.subtlest}
        >
          이미{' '}
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.heading_3_b}
            color={V2TextOption.color.default}
          >
            1,763,929명{' '}
          </V2Text>
          취뽀생들은
        </V2Text>
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.heading_3_b}
          color={V2TextOption.color.subtlest}
        >
          JOBDA 매칭으로<br />취업을 준비했어요.
        </V2Text>
        <button id='eventBtn-6' className={cx('btnApply')} onClick={moveToMatching}>
          <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
            바로 매칭 제안받으러 가기
          </V2Text>
        </button>
      </div>
      <FlowList />
    </div>
  );
};

export default Interview;
