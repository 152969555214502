import ImgTrashBin from 'assets/_v2/profile/icon_trashbin.svg';
import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ExperienceActivitiesType, ExperienceActivitiesTypeText } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';

export enum CareersActivitiesTypePlaceholdText {
  INTERNAL = '활동명 및 어떤 경험을 했는지 간단히 입력해 주세요.',
  EXTERNAL = '활동명 및 어떤 경험을 했는지 간단히 입력해 주세요.',
  EDUCATION = '이수하신 교육명 및 과정에 대해 간단히 입력해 주세요.',
  VOLUNTEER = '봉사명 및 어떤 활동을 했는지 간단히 입력해 주세요.',
  CLUB = '동아리명 및 어떤 활동을 했는지 간단히 입력해 주세요.'
}

const ActivitiesFrame = styled.div`
    .delete-frame{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 22px;
        .delete-field{
            display:flex;
            .delete-text{
                margin-left: 4px;
                font: ${Fonts.B2_Medium_P};
                line-height: 18px;
                color:${colors.c44474B_80}
        }
        }
        
    }
    .activity-selector{
        margin:20px 0 24px 0;
        .selector{
            margin-top: 12px;
        }
        .center-place{
            margin-top: 12px;
    }

    }
    
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin: 28px 0;
`;
const ProfileExperienceActivitiesCard:FC = () => {
  const useFormed = useFormContext();
  const { control, watch } = useFormed;
  const { fields: activitiesField, append: activitiesAppend, remove: activitiesRemove } = useFieldArray({
    control,
    name: 'activities',
  });
  useEffect(() => {
    if (activitiesField.length === 0) {
      activitiesAppend({
        type: '',
        place: '',
        startDate: '',
        endDate: '',
        description: '',
      }, false);
    }
  }, [activitiesField.length]);

  return (
    <>
      {
        (activitiesField).map((field, activityIndex) => (
          <ActivitiesFrame>
            <div key={field.id}>
              <div className='delete-frame'>
                <div onClick={() => activitiesRemove(activityIndex)} className='delete-field' role='button'>
                  <img src={ImgTrashBin} alt='trash' />
                  <div className='delete-text'>삭제</div>
                </div>
              </div>

              <div className='activity-selector'>
                <FormProfileValueTitle>활동 구분</FormProfileValueTitle>
                <div className='selector'>
                  <JDSelector
                    name={`activities[${activityIndex}].type`}
                    selectTitle='선택'
                    type={JDSelectorType.NORMAL}
                    value={watch(`activities[${activityIndex}].type`) || ''}
                  >
                    <select>
                      <option value='' hidden>선택</option>
                      {
                      Object.entries(ExperienceActivitiesTypeText).map(([key, text]) => <option key={`option-school-level-${activityIndex}-${key}`} value={key} selected={watch(`activities[${activityIndex}].type`) === key}>{text}</option>)
                    }
                    </select>
                  </JDSelector>
                </div>
                <div className='center-place'>
                  <JDBaseInput
                    name={`activities[${activityIndex}].place`}
                    placeholder='기관/장소를 입력해 주세요.'
                    maxLength={35}
                    defaultValue={field.place}
                  />
                </div>
              </div>
              <FormProfileValueTitle>기간</FormProfileValueTitle>
              <DatePickerFormValueFrame>
                <JDDatePickerInput
                  name={`activities[${activityIndex}].startDate`}
                  placeholder='연도. 월.'
                  month
                  fullSize
                  defaultValue={field.startDate}
                />
                <span className='date-picker-divider'>~</span>
                <JDDatePickerInput
                  name={`activities[${activityIndex}].endDate`}
                  placeholder='연도. 월.'
                  month
                  fullSize
                  defaultValue={field.endDate}
                />
              </DatePickerFormValueFrame>

              <FormProfileValueTitle>내용</FormProfileValueTitle>
              <JDDescriptionInput
                name={`activities[${activityIndex}].description`}
                placeholder={CareersActivitiesTypePlaceholdText[watch(`activities[${activityIndex}].type`) as ExperienceActivitiesType] || '내용을 간단히 입력해 주세요.'}
                maxLength={1000}
                height={120}
                autoFocus={false}
                defaultValue={field.description}
              />
              <Divider />

            </div>
          </ActivitiesFrame>

        ))
      }
      <JDPlusTextButton
        text='추가'
        onClick={() => activitiesAppend({
          type: '',
          place: '',
          startDate: '',
          endDate: '',
          description: '',
        })}
      />
    </>
  );
};

export default ProfileExperienceActivitiesCard;
