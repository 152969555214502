import { default as Colors, default as colors } from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import AccResultsModel from 'models/_v2/AccResultsModel';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import EventChuseokModel from 'models/_v2/event/EventChuseokModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import styles from './stateStyle.module.scss';

const ProfileGraphFrame = styled.div<{ percent: number, completed:boolean}>`
  width: 100%;
  height: fit-content;
  padding: 24px;
  border-radius: 8px;
  background-color:${(props) => (props.completed ? `${colors.G_30}` : `${colors.CG_40}`)} ;

  & > span {
    display:flex;
    margin-bottom: 8px;
    font: ${Fonts.B2_Bold};
    color:${(props) => (props.completed ? `${colors.G_200}` : `${colors.CG_70}`)}  ;
  }

  & > div {
    position: relative;
    width: 100%;
    height: 1px;
    background:${colors.CG_40};
    margin: 16px 0;
    height: 6px;
    border-radius: 3px;
    background-color: ${colors.WHITE_100};
    overflow: hidden;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ percent }) => percent}%;
      height: 6px;
      border-radius: 4px;
      background-color:${(props) => (props.completed ? `${colors.G_150}` : `${colors.CG_60}`)}  ;
    }
  }
`;

const cx = classnames.bind(styles);

interface IStateProps{
  login?:Login;
  eventChuseokModel?:EventChuseokModel;
  matchApplyModel?:MatchApplyModel;
  accResultsModel?:AccResultsModel;
}

const State:FC<IStateProps> = ({ login = new Login(), eventChuseokModel = new EventChuseokModel(), matchApplyModel = new MatchApplyModel(), accResultsModel = new AccResultsModel() }) => {
  const HolidayEventType = 'TALENT_POOL_HOLIDAY_2023_10';

  useEffect(() => {
    const init = async () => {
      await matchApplyModel.loadMatchingProfile();
      await matchApplyModel.loadPaccProgressList();
      await eventChuseokModel.loadEventsParticipationStatus(HolidayEventType);
      await accResultsModel.loadAccProgressList();
      await login.getMatchConfigStatus();
      if (matchApplyModel.profile) {
        if (matchApplyModel.profile.completionRate >= 30 && matchApplyModel.profile.profileRequiredCompleteYn) {
          eventChuseokModel.isProfileCompleted = true;
        }
      }
      if (login.matchConfigStatus?.developerYn && !login.matchConfigStatus?.generalYn) {
        eventChuseokModel.profileType = ProfileType.DEVELOPER;
        if (matchApplyModel.paccProgressList) {
          if (matchApplyModel.paccProgressList.length > 0) {
            eventChuseokModel.isPaccCompleted = true;
          }
        }
      } else if (accResultsModel.accProgressList) {
        eventChuseokModel.profileType = ProfileType.GENERAL;
        if (accResultsModel.accProgressList.length > 0) {
          eventChuseokModel.isAccCompleted = true;
        }
      }
    };
    init();
  }, []);
  return (
    <div className={cx('contentInner', 'bgDiff')}>
      {/* 섹션 제목 */}
      <div className={cx('basicTitle')}>
        <p className={cx('subTitle')}>지금 JOBDA에서<br />프로필 입력, 역량검사 완료하고</p>
        <p className={cx('mainTitle')}>
          취업 비공개 자료<br /><span className={cx('import')}>+</span> 네이버페이<br />받아가세요!
        </p>
      </div>
      <span className={cx('desc')}>
        <span className={cx('descImport')}>이벤트 기간</span>내 프로필 완성도 30% 이상(필수정보 포함),<br />역량검사 응시 완료 시 혜택 증정
      </span>

      {!login.userInfo
        ? (
          <div className={cx('loginBox')}>
            <span className={cx('loginText')}>JOBDA 로그인 후<br />현재 상태를 확인해보세요.</span>
            {/* 로그인 버튼 */}
            <JDALink to={RoutePaths.login} className={cx('btnClick', 'btnBlack')}>
              로그인 하기
              <div className={cx('btnIcon')}>
                <Icon name='arrow-right' size={24} color={Colors.WHITE_100} />
              </div>
            </JDALink>
          </div>
        )
        : (
          <>
            <div className={cx('nameState', 'balloon')}>
              <span className={cx('import')}>[{login.userInfo.name}]님</span>
              <span className={cx('StateText')}>의 현재 상태입니다.</span>
            </div>

            {/* 프로필, 역량검사 응시 상태 */}
            <div className={cx('stateInner')}>
              {/* 미션 완료시 missionOk, 미완료시 missionNo 추가 - 테투리랑 배지 바뀜 */}

              {/* 프로필 박스 */}
              <div className={cx('stateBox', eventChuseokModel.isProfileCompleted ? 'missionOk' : 'missionNo')}>
                {/* 상단 제목 */}
                <div className={cx('stateTitle')}>
                  <div className={cx('balloon', 'balloonSmall')}>미션 하나.</div>
                  <span className={cx('titleText')}>
                    프로필 완성도<span className={cx('titleSub')}>(필수정보 포함 30% 이상)</span>
                  </span>
                </div>

                {/* 상태 프로그래스 바 */}
                <ProfileGraphFrame completed={eventChuseokModel.isProfileCompleted} percent={matchApplyModel.profile?.completionRate || 0}>
                  <span>{ matchApplyModel.profile?.completionRate || 0 }% 완성</span>
                  <div>
                    <div />
                  </div>
                </ProfileGraphFrame>

                {/* 버튼 - 비활성화시 button tag에 disabled 추가 */}
                {eventChuseokModel.isProfileCompleted
                  ? (
                    <button className={cx('btnBasic')} disabled>
                      프로필 입력하러 가기
                      <div className={cx('btnIcon')}>
                        <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                      </div>
                    </button>
                  )
                  : (
                    <JDALink to={RoutePaths.profile} className={cx('btnBasic')}>
                      프로필 입력하러 가기
                      <div className={cx('btnIcon')}>
                        <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                      </div>
                    </JDALink>
                  )}
              </div>

              {/* 역량검사 응시 박스 */}
              {
                eventChuseokModel.profileType === ProfileType.DEVELOPER
                  ? (
                    <div className={cx('stateBox', eventChuseokModel.isPaccCompleted ? 'missionOk' : 'missionNo')}>
                      {/* 상단 제목 */}
                      <div className={cx('stateTitle')}>
                        <div className={cx('balloon', 'balloonSmall')}>미션 둘.</div>
                        <span className={cx('titleText')}>개발자역량검사 응시</span>
                      </div>

                      {/* 응시 완료 유무 */}
                      <div className={cx('testBox')}>
                        {/* 응시완료 */}
                        <div className={cx(eventChuseokModel.isPaccCompleted ? 'testYes' : 'testNo')} />
                      </div>

                      {/* 버튼 - 비활성화시 button tag에 disabled 추가 */}
                      {eventChuseokModel.isPaccCompleted
                        ? (
                          <button className={cx('btnBasic')} disabled>
                            개발자역량검사 응시하러 가기
                            <div className={cx('btnIcon')}>
                              <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                            </div>
                          </button>
                        )
                        : (
                          <JDALink to={RoutePaths.phs_test} className={cx('btnBasic')}>
                            개발자역량검사 응시하러 가기
                            <div className={cx('btnIcon')}>
                              <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                            </div>
                          </JDALink>
                        )}

                    </div>
                  )
                  : (
                    <div className={cx('stateBox', eventChuseokModel.isAccCompleted ? 'missionOk' : 'missionNo')}>
                      {/* 상단 제목 */}
                      <div className={cx('stateTitle')}>
                        <div className={cx('balloon', 'balloonSmall')}>미션 둘.</div>
                        <span className={cx('titleText')}>역량검사 응시</span>
                      </div>

                      {/* 응시 완료 유무 */}
                      <div className={cx('testBox')}>
                        {/* 응시완료 */}
                        <div className={cx(eventChuseokModel.isAccCompleted ? 'testYes' : 'testNo')} />
                      </div>

                      {/* 버튼 - 비활성화시 button tag에 disabled 추가 */}
                      {eventChuseokModel.isAccCompleted
                        ? (
                          <button className={cx('btnBasic')} disabled>
                            역량검사 응시하러 가기
                            <div className={cx('btnIcon')}>
                              <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                            </div>
                          </button>
                        )
                        : (
                          <JDALink to={RoutePaths.acca_test} className={cx('btnBasic')}>
                            역량검사 응시하러 가기
                            <div className={cx('btnIcon')}>
                              <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
                            </div>
                          </JDALink>
                        )}

                    </div>
                  )
              }

            </div>

            {/* 마지막 문구 */}
            <span className={cx('stateText')}>
              * 프로필 완성도: 프로필 입력창 우측 상단에서 완성도를 확인할 수 있어요.
              <br />
              *역량검사 응시이력: 응시 후 '분석 완료 알림톡'을 받으신 후 이벤트 응모가 가능합니다.
              <br />
              * 이벤트 시작일(9/26) 전에 이미 프로필 30% 이상 입력, 역량검사 응시를 완료한 회원은 이벤트 대상에서 제외됩니다.
            </span>
          </>
        )}

    </div>
  );
};

export default inject(injectStore.eventChuseokModel, injectStore.login, injectStore.matchApplyModel)(observer(State));
