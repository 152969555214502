import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDALink from 'components/JDALink';
import AccaTab from 'components/_v2/acca/gate/AccaTab';
import PaccGateDevPosition from 'components/_v2/phs/gate/PaccGateDevPosition';
import PaccGateService from 'components/_v2/phs/gate/PaccGateService';
import PaccGateSkillPosition from 'components/_v2/phs/gate/PaccGateSkillPosition';
import styled from 'styled-components';

const Frame = styled.div`
  height: 100%;
  padding-bottom: 32px;
  background-color: ${Colors.JOBDA_BLACK};
`;
const CountFrame = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info-frame {
    display: flex;
    align-items: center;
  }
  .info-link {
    color: ${Colors.JOBDA_WHITE};
    font: ${Fonts.B3_Medium};
    border-bottom: 1px solid ${Colors.JOBDA_WHITE};
  }
  h3 {
    font: ${Fonts.B4_Medium};
    color: ${Colors.CG_70};
    text-align: right;
    margin-bottom: 2px;
  }
`;

const PaccGate = () => {
  return (
    <Frame>
      <AccaTab />
      <CountFrame>
        <div className='info-frame'>
          <JDALink className='info-link' to='https://www.jobda.im/info/361'>
            개발자검사 응시 전, 꼭 확인하세요
          </JDALink>
          <Icon name='arrow-right' color={Colors.JOBDA_WHITE} size={24} />
        </div>
      </CountFrame>
      <PaccGateService />
      <PaccGateSkillPosition />
      <PaccGateDevPosition />
      {/* 다음 주차에 부활할 예정 */}
      {/* <PaccGateNudgeModal isOpen={isNudgeOpen} onClose={() => setIsNudgeOpen(false)} /> */}
    </Frame>
  );
};

export default PaccGate;
