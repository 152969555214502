import styled from 'styled-components';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { useV2MatchingHome } from 'query/match/useMatchQuery';
import { LocationCode } from 'consts/CompanyLocationType';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'consts/RoutePaths';

const Frame = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${SpaceValue.L}px ${SpaceValue.XL}px;
    border: 1px solid ${V2BorderColors.default};
    border-radius: ${RadiusValue.M}px;

    .icon-frame{
        position: absolute;
        right: 16px;
        bottom: 50%;
        transform: translateY(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: ${RadiusValue.S}px;
        padding: 4px;
        border: 1px solid ${V2BorderColors.default};
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: ${SpaceValue.M}px;
`;

const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    .mb-1{
      margin-bottom: 4px;
    }
`;

const MatchApplyHomeCondition = () => {
  const { data: matchingHomeData } = useV2MatchingHome(true);
  const history = useHistory();
  return (
    <Frame>
      <GridContainer>
        <GridItem>
          <V2Text
            fontStyle={V2FontStyleType.caption_1_m}
            color={V2TextColorsType.subtle}
            className='mb-1'
          >
            직군 · 직무
          </V2Text>
          {
            matchingHomeData?.condition.jobTitleNames && matchingHomeData?.condition.jobTitleNames.length > 0 && (
              <V2Text
                fontStyle={V2FontStyleType.body_3_m}
                color={V2TextColorsType.default}
              >
                {matchingHomeData.condition.jobTitleNames[0]}
                {matchingHomeData.condition.jobTitleNames.length > 1
                  && ` 외 ${matchingHomeData.condition.jobTitleNames.length - 1}개`}
              </V2Text>
            )
          }
        </GridItem>
        <GridItem>
          <V2Text
            fontStyle={V2FontStyleType.caption_1_m}
            color={V2TextColorsType.subtle}
            className='mb-1'
          >
            근무 지역
          </V2Text>
          <V2Text
            fontStyle={V2FontStyleType.body_3_m}
            color={V2TextColorsType.default}
          >
            {matchingHomeData?.condition.locations && matchingHomeData?.condition.locations.length > 0 ? `${LocationCode(Number(matchingHomeData?.condition.locations[0]))} ${matchingHomeData?.condition.locations.length > 1 ? `외 ${matchingHomeData?.condition.locations.length - 1}개` : ''} ` : ''}
          </V2Text>
        </GridItem>
        <GridItem>
          <V2Text
            fontStyle={V2FontStyleType.caption_1_m}
            color={V2TextColorsType.subtle}
            className='mb-1'
          >
            연봉
          </V2Text>
          <V2Text
            fontStyle={V2FontStyleType.body_3_m}
            color={V2TextColorsType.default}
          >
            {matchingHomeData?.condition.salary ? `${matchingHomeData?.condition.salary.toLocaleString()}만원 이상` : ''}
          </V2Text>
        </GridItem>
        <GridItem>
          <V2Text
            fontStyle={V2FontStyleType.caption_1_m}
            color={V2TextColorsType.subtle}
            className='mb-1'
          >
            기타 희망사항
          </V2Text>
          <V2Text
            fontStyle={V2FontStyleType.body_3_m}
            color={V2TextColorsType.default}
            ellipsis={1}
            styles={{ width: 141 }}
          >
            {matchingHomeData?.condition.workingPreference ? matchingHomeData?.condition.workingPreference : ''}
          </V2Text>
        </GridItem>
      </GridContainer>
      <div
        className='icon-frame'
        role='button'
        onClick={() => {
          history.push(`${RoutePaths.match_settings}`);
        }}
      >
        <V2Icon name={V2IconOption.name.guide} fill={V2IconOption.fill.default} size={V2IconOption.size.S} />
      </div>
    </Frame>
  );
};

export default MatchApplyHomeCondition;
