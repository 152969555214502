import { ReactComponent as IconAccaSvg } from '__designkit__/assets/icon/icon_acca.svg';
import { ReactComponent as IconAccaHomeSvg } from '__designkit__/assets/icon/icon_acca_home.svg';
import { ReactComponent as IconAddLightSvg } from '__designkit__/assets/icon/icon_add_light.svg';
import { ReactComponent as IconAssignmentSvg } from '__designkit__/assets/icon/icon_assignment.svg';
import { ReactComponent as IconAlertLightSvg } from '__designkit__/assets/icon/icon_alert_light.svg';
import { ReactComponent as IconAlertLightFilledSvg } from '__designkit__/assets/icon/icon_alert_light_filled.svg';
import { ReactComponent as IconArrowBottomLightSvg } from '__designkit__/assets/icon/icon_arrow_bottom_light.svg';
import { ReactComponent as IconArrowDownwardLightSvg } from '__designkit__/assets/icon/icon_arrow_downward_light.svg';
import { ReactComponent as IconArrowLeftLightSvg } from '__designkit__/assets/icon/icon_arrow_left_light.svg';
import { ReactComponent as IconArrowRightSvg } from '__designkit__/assets/icon/icon_arrow_right.svg';
import { ReactComponent as IconArrowTopLightSvg } from '__designkit__/assets/icon/icon_arrow_top_light.svg';
import { ReactComponent as IconArrowUpwardLightSvg } from '__designkit__/assets/icon/icon_arrow_upward_light.svg';
import { ReactComponent as IconAssignmentIndLightSvg } from '__designkit__/assets/icon/icon_assignment_ind_light.svg';
import { ReactComponent as IconWorkEightSvg } from '__designkit__/assets/icon/icon_bag_clock.svg';
import { ReactComponent as IconBellLightSvg } from '__designkit__/assets/icon/icon_bell_light.svg';
import { ReactComponent as IconBellOffLightSvg } from '__designkit__/assets/icon/icon_bell_off_light.svg';
import { ReactComponent as IconBookGreenSvg } from '__designkit__/assets/icon/icon_book_green.svg';
import { ReactComponent as IconBookmarkLightSvg } from '__designkit__/assets/icon/icon_bookmark_light.svg';
import { ReactComponent as IconBookmarkLightFilledSvg } from '__designkit__/assets/icon/icon_bookmark_light_filled.svg';
import { ReactComponent as IconBookmarkShadowLightSvg } from '__designkit__/assets/icon/icon_bookmark_shadow_light.svg';
import { ReactComponent as IconBookmarkShadowLightFilledSvg } from '__designkit__/assets/icon/icon_bookmark_shadow_light_filled.svg';
import { ReactComponent as IconBusinessFilledSvg } from '__designkit__/assets/icon/icon_business_filled_light.svg';
import { ReactComponent as IconBusinessSvg } from '__designkit__/assets/icon/icon_business_light.svg';
import { ReactComponent as IconCafeLightSvg } from '__designkit__/assets/icon/icon_cafe_light.svg';
import { ReactComponent as IconWorkThreeSvg } from '__designkit__/assets/icon/icon_calendar_check.svg';
import { ReactComponent as IconCalenderLightSvg } from '__designkit__/assets/icon/icon_calender_light.svg';
import { ReactComponent as IconCalenderFillSvg } from '__designkit__/assets/icon/icon_calender_fill.svg';
import { ReactComponent as IconCameraLightSvg } from '__designkit__/assets/icon/icon_camera_light.svg';
import { ReactComponent as IconCampingLightSvg } from '__designkit__/assets/icon/icon_camping_light.svg';
import { ReactComponent as IconCarLightSvg } from '__designkit__/assets/icon/icon_car_light.svg';
import { ReactComponent as IconChartLightSvg } from '__designkit__/assets/icon/icon_chart_light.svg';
import { ReactComponent as IconWorkTenSvg } from '__designkit__/assets/icon/icon_chat_blue.svg';
import { ReactComponent as IconChatYellowSvg } from '__designkit__/assets/icon/icon_chat_yellow.svg';
import { ReactComponent as IconCheckCircleSvg } from '__designkit__/assets/icon/icon_check_circle.svg';
import { ReactComponent as IconCheckCircleFilledSvg } from '__designkit__/assets/icon/icon_check_circle_light_filled.svg';
import { ReactComponent as IconCheckLightSvg } from '__designkit__/assets/icon/icon_check_light.svg';
import { ReactComponent as IconChildLightSvg } from '__designkit__/assets/icon/icon_child_light.svg';
import { ReactComponent as IconWorkSevenSvg } from '__designkit__/assets/icon/icon_clock.svg';
import { ReactComponent as IconCloseLightSvg } from '__designkit__/assets/icon/icon_close_light.svg';
import { ReactComponent as IconCodingSvg } from '__designkit__/assets/icon/icon_coding.svg';
import { ReactComponent as IconCashTwoSvg } from '__designkit__/assets/icon/icon_comfortable_life.svg';
import { ReactComponent as IconContentsLightSvg } from '__designkit__/assets/icon/icon_contents_light.svg';
import { ReactComponent as IconCorporateFareLightSvg } from '__designkit__/assets/icon/icon_corporate_fare_light.svg';
import { ReactComponent as IconDeleteLightSvg } from '__designkit__/assets/icon/icon_delete_light.svg';
import { ReactComponent as IconDesignLightSvg } from '__designkit__/assets/icon/icon_design_light.svg';
import { ReactComponent as IconDesktopAccaGreenSvg } from '__designkit__/assets/icon/icon_desktop_acca_green.svg';
import { ReactComponent as IconDesktopLightSvg } from '__designkit__/assets/icon/icon_desktop_light.svg';
import { ReactComponent as IconDeveloperLightSvg } from '__designkit__/assets/icon/icon_developer_light.svg';
import { ReactComponent as IconDiamondLightSvg } from '__designkit__/assets/icon/icon_diamond_light.svg';
import { ReactComponent as IconDiversitySvg } from '__designkit__/assets/icon/icon_diversity_2.svg';
import { ReactComponent as IconWorkSixSvg } from '__designkit__/assets/icon/icon_do_not_copy.svg';
import { ReactComponent as IconDownSFilledSvg } from '__designkit__/assets/icon/icon_down_s_filled.svg';
import { ReactComponent as IconDownloadSvg } from '__designkit__/assets/icon/icon_download.svg';
import { ReactComponent as IconCoworkerFourSvg } from '__designkit__/assets/icon/icon_easy_communication.svg';
import { ReactComponent as IconenterLightSvg } from '__designkit__/assets/icon/icon_enter_light.svg';
import { ReactComponent as IconErrorLightSvg } from '__designkit__/assets/icon/icon_error_light.svg';
import { ReactComponent as IconErrorLightFilledSvg } from '__designkit__/assets/icon/icon_error_light_filled.svg';
import { ReactComponent as IconEyeCloseLightSvg } from '__designkit__/assets/icon/icon_eye_close_light.svg';
import { ReactComponent as IconEyeOpenLightSvg } from '__designkit__/assets/icon/icon_eye_open_light.svg';
import { ReactComponent as IconCashThreeSvg } from '__designkit__/assets/icon/icon_fair_balance.svg';
import { ReactComponent as IconFileAddLightSvg } from '__designkit__/assets/icon/icon_file_add_light.svg';
import { ReactComponent as IconWorkFiveSvg } from '__designkit__/assets/icon/icon_file_done_green.svg';
import { ReactComponent as IconFilterLightSvg } from '__designkit__/assets/icon/icon_filter_light.svg';
import { ReactComponent as IconFinancialSupportLightSvg } from '__designkit__/assets/icon/icon_financial_support_light.svg';
import { ReactComponent as IconFolderLightSvg } from '__designkit__/assets/icon/icon_folder_light.svg';
import { ReactComponent as IconFolderOpenSvg } from '__designkit__/assets/icon/icon_folder_open.svg';
import { ReactComponent as IconGiftLightSvg } from '__designkit__/assets/icon/icon_gift_light.svg';
import { ReactComponent as IconCoworkerTwoSvg } from '__designkit__/assets/icon/icon_good_workers.svg';
import { ReactComponent as IconGuideAccaGreenSvg } from '__designkit__/assets/icon/icon_guide_acca_green.svg';
import { ReactComponent as IconGuideLightSvg } from '__designkit__/assets/icon/icon_guide_light.svg';
import { ReactComponent as IconHeartLightSvg } from '__designkit__/assets/icon/icon_heart_light.svg';
import { ReactComponent as IconHeartLightFilledSvg } from '__designkit__/assets/icon/icon_heart_light_filled.svg';
import { ReactComponent as IconHomeLightSvg } from '__designkit__/assets/icon/icon_home_light.svg';
import { ReactComponent as IconIdCardLightSvg } from '__designkit__/assets/icon/icon_id_card_light.svg';
import { ReactComponent as IconInfoUserSvg } from '__designkit__/assets/icon/icon_info_user.svg';
import { ReactComponent as IconInformationLightSvg } from '__designkit__/assets/icon/icon_information_light.svg';
import { ReactComponent as IconCircleInformationSvg } from '__designkit__/assets/icon/icon_circle_info.svg';
import { ReactComponent as IconInvitationUserSvg } from '__designkit__/assets/icon/icon_invitation_user.svg';
import { ReactComponent as IconIpinLightSvg } from '__designkit__/assets/icon/icon_ipin_light.svg';
import { ReactComponent as IconLaptopAccaBlueSvg } from '__designkit__/assets/icon/icon_laptop_acca_blue.svg';
import { ReactComponent as IconLargeArrowBottomLightSvg } from '__designkit__/assets/icon/icon_large_arrow_bottom_light.svg';
import { ReactComponent as IconLargeArrowLeftLightSvg } from '__designkit__/assets/icon/icon_large_arrow_left_light.svg';
import { ReactComponent as IconLargeArrowRightLightSvg } from '__designkit__/assets/icon/icon_large_arrow_right_light.svg';
import { ReactComponent as IconLargeArrowTopLightSvg } from '__designkit__/assets/icon/icon_large_arrow_top_light.svg';
import { ReactComponent as IconWorkElevenSvg } from '__designkit__/assets/icon/icon_laurel_wreath_star.svg';
import { ReactComponent as IconLinkLineLightSvg } from '__designkit__/assets/icon/icon_link_line_light.svg';
import { ReactComponent as IconListViewLightSvg } from '__designkit__/assets/icon/icon_list_view_light.svg';
import { ReactComponent as IconLocationAwayLightSvg } from '__designkit__/assets/icon/icon_location_away_light.svg';
import { ReactComponent as IconLuggageLightSvg } from '__designkit__/assets/icon/icon_luggage_light.svg';
import { ReactComponent as IconMallLightSvg } from '__designkit__/assets/icon/icon_mall_light.svg';
import { ReactComponent as IconMatchingSvg } from '__designkit__/assets/icon/icon_matching.svg';
import { ReactComponent as IconMatchLight } from '__designkit__/assets/icon/icon_match_light.svg';
import { ReactComponent as IconMatchingPersonGreenSvg } from '__designkit__/assets/icon/icon_matching_person_green.svg';
import { ReactComponent as IconMatchingYellow } from '__designkit__/assets/icon/icon_matching_yellow.svg';
import { ReactComponent as IconMealLightSvg } from '__designkit__/assets/icon/icon_meal_light.svg';
import { ReactComponent as IconMedicalLightSvg } from '__designkit__/assets/icon/icon_medical_light.svg';
import { ReactComponent as IconMessageLightSvg } from '__designkit__/assets/icon/icon_message_light.svg';
import { ReactComponent as IconMilitaryLightSvg } from '__designkit__/assets/icon/icon_military_light.svg';
import { ReactComponent as IconMilitaryTechSvg } from '__designkit__/assets/icon/icon_military_tech.svg';
import { ReactComponent as IconCashOneSvg } from '__designkit__/assets/icon/icon_money.svg';
import { ReactComponent as IconMoreHorizonLightSvg } from '__designkit__/assets/icon/icon_more_horizon_light.svg';
import { ReactComponent as IconMoreVertLightSvg } from '__designkit__/assets/icon/icon_more_vert_light.svg';
import { ReactComponent as IconMouseLightSvg } from '__designkit__/assets/icon/icon_mouse_light.svg';
import { ReactComponent as IconMoveLightSvg } from '__designkit__/assets/icon/icon_move_light.svg';
import { ReactComponent as IconOpenInNewLightSvg } from '__designkit__/assets/icon/icon_open_in_new_light.svg';
import { ReactComponent as IconOpenNew } from '__designkit__/assets/icon/icon_open_new.svg';
import { ReactComponent as IconOutputLightSvg } from '__designkit__/assets/icon/icon_output_light.svg';
import { ReactComponent as IconPageUserChoiceSvg } from '__designkit__/assets/icon/icon_page_user_choice.svg';
import { ReactComponent as IconPaletteLightSvg } from '__designkit__/assets/icon/icon_palette_light.svg';
import { ReactComponent as IconCoworkerOneSvg } from '__designkit__/assets/icon/icon_people_blue.svg';
import { ReactComponent as IconPeopleLightSvg } from '__designkit__/assets/icon/icon_people_light.svg';
import { ReactComponent as IconCoworkerThreeSvg } from '__designkit__/assets/icon/icon_person_love.svg';
import { ReactComponent as IconPinLightSvg } from '__designkit__/assets/icon/icon_pin_light.svg';
import { ReactComponent as IconWorkNineSvg } from '__designkit__/assets/icon/icon_polyshape.svg';
import { ReactComponent as IconProjectLightSvg } from '__designkit__/assets/icon/icon_project_light.svg';
import { ReactComponent as IconReadBookLightSvg } from '__designkit__/assets/icon/icon_read_book_light.svg';
import { ReactComponent as IconRealEstateLightSvg } from '__designkit__/assets/icon/icon_real_estate_light.svg';
import { ReactComponent as IconWorkTwoSvg } from '__designkit__/assets/icon/icon_red_heart.svg';
import { ReactComponent as IconRemoveLightSvg } from '__designkit__/assets/icon/icon_remove_light.svg';
import { ReactComponent as IconRemoveLightFilledSvg } from '__designkit__/assets/icon/icon_remove_light_filled.svg';
import { ReactComponent as IconResetLightSvg } from '__designkit__/assets/icon/icon_reset_light.svg';
import { ReactComponent as IconResumeSvg } from '__designkit__/assets/icon/icon_resume.svg';
import { ReactComponent as IconResumeDoneGreenSvg } from '__designkit__/assets/icon/icon_resume_done_green.svg';
import { ReactComponent as IconResumeSeparationBlue } from '__designkit__/assets/icon/icon_resume_separation_blue.svg';
import { ReactComponent as IconResumeYellowSvg } from '__designkit__/assets/icon/icon_resume_yellow.svg';
import { ReactComponent as IconRobotLightSvg } from '__designkit__/assets/icon/icon_robot_light.svg';
import { ReactComponent as IconRocketLaunchLightSvg } from '__designkit__/assets/icon/icon_rocket_launch_light.svg';
import { ReactComponent as IconSavingMoneyLightSvg } from '__designkit__/assets/icon/icon_saving_money_light.svg';
import { ReactComponent as IconScoreLightSvg } from '__designkit__/assets/icon/icon_score_light.svg';
import { ReactComponent as IconSearchAccaBlueSvg } from '__designkit__/assets/icon/icon_search_acca_blue.svg';
import { ReactComponent as IconSearchLightSvg } from '__designkit__/assets/icon/icon_search_light.svg';
import { ReactComponent as IconSendLightSvg } from '__designkit__/assets/icon/icon_send_light.svg';
import { ReactComponent as IconSendLightFilledSvg } from '__designkit__/assets/icon/icon_send_light_filled.svg';
import { ReactComponent as IconSettingLightSvg } from '__designkit__/assets/icon/icon_settings.svg';
import { ReactComponent as IconShareLightSvg } from '__designkit__/assets/icon/icon_share_light.svg';
import { ReactComponent as IconSmartphoneLightSvg } from '__designkit__/assets/icon/icon_smartphone_light.svg';
import { ReactComponent as IconStarFilledSvg } from '__designkit__/assets/icon/icon_star_filled.svg';
import { ReactComponent as IconStarLightSvg } from '__designkit__/assets/icon/icon_star_light.svg';
import { ReactComponent as IconStdioBlueSvg } from '__designkit__/assets/icon/icon_stdio_blue.svg';
import { ReactComponent as IconSwapVertLightSvg } from '__designkit__/assets/icon/icon_swap_vert_light.svg';
import { ReactComponent as IconTestAccaSvg } from '__designkit__/assets/icon/icon_test_acca.svg';
import { ReactComponent as IconWorkFourSvg } from '__designkit__/assets/icon/icon_test_blue.svg';
import { ReactComponent as IconTestCodingBlueSvg } from '__designkit__/assets/icon/icon_test_coding_blue.svg';
import { ReactComponent as IconTextWriteLightSvg } from '__designkit__/assets/icon/icon_text_write_light.svg';
import { ReactComponent as IconWorkOneSvg } from '__designkit__/assets/icon/icon_three_star.svg';
import { ReactComponent as IconThumbsUpLightSvg } from '__designkit__/assets/icon/icon_thumbs_up_light.svg';
import { ReactComponent as IconTimeLightSvg } from '__designkit__/assets/icon/icon_time_light.svg';
import { ReactComponent as IconTop20LightSvg } from '__designkit__/assets/icon/icon_top_20_light.svg';
import { ReactComponent as IconUniversityLightSvg } from '__designkit__/assets/icon/icon_university_light.svg';
import { ReactComponent as IconUpSFilledSvg } from '__designkit__/assets/icon/icon_up_s_filled.svg';
import { ReactComponent as IconUploadLightSvg } from '__designkit__/assets/icon/icon_upload_light.svg';
import { ReactComponent as IconUserFolderLightSvg } from '__designkit__/assets/icon/icon_user_folder_light.svg';
import { ReactComponent as IconUserLightSvg } from '__designkit__/assets/icon/icon_user_light.svg';
import { ReactComponent as IconVerifiedFilledSvg } from '__designkit__/assets/icon/icon_verified_filled.svg';
import { ReactComponent as IconVerifiedLightSvg } from '__designkit__/assets/icon/icon_verified_light.svg';
import { ReactComponent as IconVisibilityOffLightSvg } from '__designkit__/assets/icon/icon_visibility_off_light.svg';
import { ReactComponent as IconWorkLightSvg } from '__designkit__/assets/icon/icon_work_light.svg';
import { ReactComponent as IconLockYellow } from '__designkit__/assets/icon/icon_lock_yellow.svg';
import { ReactComponent as IconPageBlueSvg } from '__designkit__/assets/icon/icon_page_checklist_blue.svg';
import { ReactComponent as IconHandShakeSvg } from '__designkit__/assets/icon/icon_handshake.svg';
import { ReactComponent as IconGiftSvg } from '__designkit__/assets/icon/icon_gift_red.svg';
import { ReactComponent as IconCheckbox } from '__designkit__/assets/icon/icon_checkbox.svg';
import { ReactComponent as IconCheckboxEmpty } from '__designkit__/assets/icon/icon_checkbox_empty.svg';
import { ReactComponent as IconIdea } from '__designkit__/assets/icon/icon_idea_2.svg';

import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';

import Colors from '__designkit__/common/colors';
import type { IconName } from 'interfaces/_v2/_common/iconType';
import { V2IconSizeType } from '__pc__/constant/v2Design/V2IconType';

export interface V2IconProps extends IComponentProps {
  name: IconName;
  width?: number;
  height: number;
  fill?:string
}

// const V2Icon: FC<IIconProps> = (props) => (
//   <IconContainer width={size} height={size} color={color} {...props}>
//     <IconSvg name={name} />
//   </IconContainer>
// );
const V2IconList: FC<V2IconProps> = ({ name = 'Error', width = 48, height = 48, fill, ...props }) => (
  <IconContainer width={width} height={height} color={fill} {...props}>
    <IconSvg name={name} />
  </IconContainer>
);

const IconSvg = ({ name } : {name:string}) => {
  switch (name) {
    case 'acca':
      return <IconAccaSvg viewBox='0 0 56 56' />;
    case 'acca-home':
      return <IconAccaHomeSvg viewBox='0 0 48 48' />;
    case 'add':
      return <IconAddLightSvg viewBox='0 0 48 48' />;
    case 'assignment':
      return <IconAssignmentSvg viewBox='0 0 40 40' />;
    case 'alert':
      return <IconAlertLightSvg viewBox='0 0 48 48' />;
    case 'alert-filed':
      return <IconAlertLightFilledSvg viewBox='0 0 20 20' />;
    case 'arrow-bottom':
      return <IconArrowBottomLightSvg viewBox='0 0 48 48' />;
    case 'arrow-left':
      return <IconArrowLeftLightSvg viewBox='0 0 48 48' />;
    case 'arrow-right':
      return <IconArrowRightSvg viewBox='0 0 48 48' />;
    case 'arrow-top':
      return <IconArrowTopLightSvg viewBox='0 0 48 48' />;
    case 'arrow-upward':
      return <IconArrowUpwardLightSvg viewBox='0 0 48 48' />;
    case 'arrow-downward':
      return <IconArrowDownwardLightSvg viewBox='0 0 16 16' />;
    case 'assignment-ind':
      return <IconAssignmentIndLightSvg viewBox='0 0 48 48' />;
    case 'bell':
      return <IconBellLightSvg viewBox='0 0 32 32' />;
    case 'bell-off':
      return <IconBellOffLightSvg viewBox='0 0 32 32' />;
    case 'book-green':
      return <IconBookGreenSvg viewBox='0 0 56 56' />;
    case 'bookmark-filled':
      return <IconBookmarkLightFilledSvg viewBox='0 0 48 48' />;
    case 'bookmark':
      return <IconBookmarkLightSvg viewBox='0 0 48 48' />;
    case 'bookmark-shadow-filled':
      return <IconBookmarkShadowLightFilledSvg viewBox='0 0 48 48' />;
    case 'bookmark-shadow':
      return <IconBookmarkShadowLightSvg viewBox='0 0 48 48' />;
    case 'cafe':
      return <IconCafeLightSvg viewBox='0 0 48 48' />;
    case 'calender':
      return <IconCalenderLightSvg viewBox='0 0 48 48' />;
    case 'calender-fill':
      return <IconCalenderFillSvg viewBox='0 0 24 24' />;
    case 'camera':
      return <IconCameraLightSvg viewBox='0 0 48 48' />;
    case 'camping':
      return <IconCampingLightSvg viewBox='0 0 24 24' />;
    case 'car':
      return <IconCarLightSvg viewBox='0 0 48 48' />;
    case 'chart':
      return <IconChartLightSvg viewBox='0 0 48 48' />;
    case 'chat-yellow':
      return <IconChatYellowSvg viewBox='0 0 56 56' />;
    case 'check-circle':
      return <IconCheckCircleSvg viewBox='0 0 48 48' />;
    case 'check-circle-filled':
      return <IconCheckCircleFilledSvg viewBox='0 0 24 24' />;
    case 'check':
      return <IconCheckLightSvg viewBox='0 0 48 48' />;
    case 'checkbox':
      return <IconCheckbox viewBox='0 0 24 24' />;
    case 'checkbox-empty':
      return <IconCheckboxEmpty viewBox='0 0 24 24' />;
    case 'child':
      return <IconChildLightSvg viewBox='0 0 48 48' />;
    case 'close':
      return <IconCloseLightSvg viewBox='0 0 48 48' />;
    case 'coding':
      return <IconCodingSvg viewBox='0 0 56 56' />;
    case 'contents':
      return <IconContentsLightSvg viewBox='0 0 48 48' />;
    case 'corporate-fare':
      return <IconCorporateFareLightSvg viewBox='0 0 48 48' />;
    case 'delete':
      return <IconDeleteLightSvg viewBox='0 0 48 48' />;
    case 'design':
      return <IconDesignLightSvg viewBox='0 0 48 48' />;
    case 'desktop-acca-green':
      return <IconDesktopAccaGreenSvg viewBox='0 0 72 72' />;
    case 'desktop':
      return <IconDesktopLightSvg viewBox='0 0 48 48' />;
    case 'diamond':
      return <IconDiamondLightSvg viewBox='0 0 48 48' />;
    case 'diversity':
      return <IconDiversitySvg viewBox='0 0 56 56' />;
    case 'down-s-filled':
      return <IconDownSFilledSvg viewBox='0 0 48 48' />;
    case 'enter':
      return <IconenterLightSvg viewBox='0 0 48 48' />;
    case 'error':
      return <IconErrorLightSvg viewBox='0 0 48 48' />;
    case 'error-filled':
      return <IconErrorLightFilledSvg viewBox='0 0 24 24' />;
    case 'eye-close':
      return <IconEyeCloseLightSvg viewBox='0 0 48 48' />;
    case 'eye-open':
      return <IconEyeOpenLightSvg viewBox='0 0 48 48' />;
    case 'file-add':
      return <IconFileAddLightSvg viewBox='0 0 48 48' />;
    case 'filter':
      return <IconFilterLightSvg viewBox='0 0 48 48' />;
    case 'financial-support':
      return <IconFinancialSupportLightSvg viewBox='0 0 48 48' />;
    case 'folder':
      return <IconFolderLightSvg viewBox='0 0 48 48' />;
    case 'gift':
      return <IconGiftLightSvg viewBox='0 0 48 48' />;
    case 'guide-acca-green':
      return <IconGuideAccaGreenSvg viewBox='0 0 56 56' />;
    case 'guide':
      return <IconGuideLightSvg viewBox='0 0 48 48' />;
    case 'heart':
      return <IconHeartLightSvg viewBox='0 0 48 48' />;
    case 'heart-filled':
      return <IconHeartLightFilledSvg viewBox='0 0 32 32' />;
    case 'home':
      return <IconHomeLightSvg viewBox='0 0 48 48' />;
    case 'id-card':
      return <IconIdCardLightSvg viewBox='0 0 48 48' />;
    case 'idea':
      return <IconIdea viewBox='0 0 64 64' />;
    case 'info-user':
      return <IconInfoUserSvg viewBox='0 0 56 56' />;
    case 'information':
      return <IconInformationLightSvg viewBox='0 0 48 48' />;
    case 'circle-info':
      return <IconCircleInformationSvg viewBox='0 0 36 36' />;
    case 'ipin':
      return <IconIpinLightSvg viewBox='0 0 48 48' />;
    case 'laptop-acca-blue':
      return <IconLaptopAccaBlueSvg viewBox='0 0 56 56' />;
    case 'large-arrow-bottom':
      return <IconLargeArrowBottomLightSvg viewBox='0 0 48 48' />;
    case 'large-arrow-left':
      return <IconLargeArrowLeftLightSvg viewBox='0 0 48 48' />;
    case 'large-arrow-right':
      return <IconLargeArrowRightLightSvg viewBox='0 0 48 48' />;
    case 'large-arrow-top':
      return <IconLargeArrowTopLightSvg viewBox='0 0 48 48' />;
    case 'link-line':
      return <IconLinkLineLightSvg viewBox='0 0 48 48' />;
    case 'list-view':
      return <IconListViewLightSvg viewBox='0 0 48 48' />;
    case 'location-away':
      return <IconLocationAwayLightSvg viewBox='0 0 48 48' />;
    case 'luggage':
      return <IconLuggageLightSvg viewBox='0 0 48 48' />;
    case 'mall':
      return <IconMallLightSvg viewBox='0 0 48 48' />;
    case 'meal':
      return <IconMealLightSvg viewBox='0 0 48 48' />;
    case 'medical':
      return <IconMedicalLightSvg viewBox='0 0 48 48' />;
    case 'message':
      return <IconMessageLightSvg viewBox='0 0 32 32' />;
    case 'military':
      return <IconMilitaryLightSvg viewBox='0 0 48 48' />;
    case 'more-horizon':
      return <IconMoreHorizonLightSvg viewBox='0 0 24 24' />;
    case 'more-vert':
      return <IconMoreVertLightSvg viewBox='0 0 48 48' />;
    case 'mouse':
      return <IconMouseLightSvg viewBox='0 0 32 32' />;
    case 'move':
      return <IconMoveLightSvg viewBox='0 0 48 48' />;
    case 'open-in-new':
      return <IconOpenInNewLightSvg viewBox='0 0 48 48' />;
    case 'output':
      return <IconOutputLightSvg viewBox='0 0 32 32' />;
    case 'page-user-choice':
      return <IconPageUserChoiceSvg viewBox='0 0 66 66' />;
    case 'palette':
      return <IconPaletteLightSvg viewBox='0 0 48 48' />;
    case 'people':
      return <IconPeopleLightSvg viewBox='0 0 48 48' />;
    case 'pin':
      return <IconPinLightSvg viewBox='0 0 48 48' />;
    case 'read-book':
      return <IconReadBookLightSvg viewBox='0 0 48 48' />;
    case 'real-estate':
      return <IconRealEstateLightSvg viewBox='0 0 48 48' />;
    case 'remove-filled':
      return <IconRemoveLightFilledSvg viewBox='0 0 48 48' />;
    case 'remove':
      return <IconRemoveLightSvg viewBox='0 0 48 48' />;
    case 'reset':
      return <IconResetLightSvg viewBox='0 0 48 48' />;
    case 'resume':
      return <IconResumeSvg viewBox='0 0 48 48' />;
    case 'resume-done-green':
      return <IconResumeDoneGreenSvg viewBox='0 0 56 56' />;
    case 'resume-yellow':
      return <IconResumeYellowSvg viewBox='0 0 72 72' />;
    case 'robot':
      return <IconRobotLightSvg viewBox='0 0 48 48' />;
    case 'rocket-launch':
      return <IconRocketLaunchLightSvg viewBox='0 0 48 48' />;
    case 'saving-money':
      return <IconSavingMoneyLightSvg viewBox='0 0 48 48' />;
    case 'score':
      return <IconScoreLightSvg viewBox='0 0 48 48' />;
    case 'search-acca-blue':
      return <IconSearchAccaBlueSvg viewBox='0 0 56 56' />;
    case 'search':
      return <IconSearchLightSvg viewBox='0 0 32 32' />;
    case 'send-light':
      return <IconSendLightSvg viewBox='0 0 24 24' />;
    case 'send-light-filled':
      return <IconSendLightFilledSvg viewBox='0 0 24 24' />;
    case 'settings':
      return <IconSettingLightSvg viewBox='0 0 20 20' />;
    case 'share':
      return <IconShareLightSvg viewBox='0 0 48 48' />;
    case 'smartphone':
      return <IconSmartphoneLightSvg viewBox='0 0 48 48' />;
    case 'star-filled':
      return <IconStarFilledSvg viewBox='0 0 48 48' />;
    case 'star':
      return <IconStarLightSvg viewBox='0 0 48 48' />;
    case 'stdio-blue':
      return <IconStdioBlueSvg viewBox='0 0 56 56' />;
    case 'swap-vert':
      return <IconSwapVertLightSvg viewBox='0 0 48 48' />;
    case 'test-acca':
      return <IconTestAccaSvg viewBox='0 0 56 56' />;
    case 'test-coding-blue':
      return <IconTestCodingBlueSvg viewBox='0 0 56 56' />;
    case 'text-write':
      return <IconTextWriteLightSvg viewBox='0 0 48 48' />;
    case 'thumbs-up':
      return <IconThumbsUpLightSvg viewBox='0 0 48 48' />;
    case 'time':
      return <IconTimeLightSvg viewBox='0 0 24 24' />;
    case 'top-20':
      return <IconTop20LightSvg viewBox='0 0 48 48' />;
    case 'university':
      return <IconUniversityLightSvg viewBox='0 0 48 48' />;
    case 'up-s-filled':
      return <IconUpSFilledSvg viewBox='0 0 48 48' />;
    case 'upload':
      return <IconUploadLightSvg viewBox='0 0 48 48' />;
    case 'user-folder':
      return <IconUserFolderLightSvg viewBox='0 0 48 48' />;
    case 'user':
      return <IconUserLightSvg viewBox='0 0 48 48' />;
    case 'verified':
      return <IconVerifiedLightSvg viewBox='0 0 48 48' />;
    case 'verified-filled':
      return <IconVerifiedFilledSvg viewBox='0 0 32 32' />;
    case 'visibility-off':
      return <IconVisibilityOffLightSvg viewBox='0 0 48 48' />;
    case 'work':
      return <IconWorkLightSvg viewBox='0 0 48 48' />;
    case 'business':
      return <IconBusinessSvg viewBox='0 0 48 48' />;
    case 'business-filled':
      return <IconBusinessFilledSvg viewBox='0 0 16 16' />;
    case 'invitation-user':
      return <IconInvitationUserSvg viewBox='0 0 40 40' />;
    case 'matching_person_green':
      return <IconMatchingPersonGreenSvg viewBox='0 0 40 40' />;
    case 'open_new':
      return <IconOpenNew viewBox='0 0 24 24' />;
    case 'resume_separation_blue':
      return <IconResumeSeparationBlue viewBox='0 0 72 72' />;
    case 'military-tech':
      return <IconMilitaryTechSvg viewBox='0 0 24 25' />;
    case 'folder-open':
      return <IconFolderOpenSvg viewBox='0 0 24 25' />;
    case 'project-light':
      return <IconProjectLightSvg viewBox='0 0 40 40' />;
    case 'developer':
      return <IconDeveloperLightSvg viewBox='0 0 32 32' />;
    case 'download':
      return <IconDownloadSvg viewBox='0 0 18 18' />;
    case 'three-star':
      return <IconWorkOneSvg viewBox='0 0 24 24' />;
    case 'red-heart':
      return <IconWorkTwoSvg viewBox='0 0 24 24' />;
    case 'calendar-check':
      return <IconWorkThreeSvg viewBox='0 0 24 24' />;
    case 'test-blue':
      return <IconWorkFourSvg viewBox='0 0 24 24' />;
    case 'file-done-green':
      return <IconWorkFiveSvg viewBox='0 0 24 24' />;
    case 'do-not-copy':
      return <IconWorkSixSvg viewBox='0 0 24 24' />;
    case 'clock':
      return <IconWorkSevenSvg viewBox='0 0 24 24' />;
    case 'bag-clock':
      return <IconWorkEightSvg viewBox='0 0 24 24' />;
    case 'polyshape':
      return <IconWorkNineSvg viewBox='0 0 24 24' />;
    case 'chat-blue':
      return <IconWorkTenSvg viewBox='0 0 24 24' />;
    case 'laurel-wreath-star':
      return <IconWorkElevenSvg viewBox='0 0 24 24' />;
    case 'people-blue':
      return <IconCoworkerOneSvg viewBox='0 0 24 24' />;
    case 'good-workers':
      return <IconCoworkerTwoSvg viewBox='0 0 24 24' />;
    case 'person-love':
      return <IconCoworkerThreeSvg viewBox='0 0 24 24' />;
    case 'easy-communication':
      return <IconCoworkerFourSvg viewBox='0 0 24 24' />;
    case 'money':
      return <IconCashOneSvg viewBox='0 0 24 24' />;
    case 'comfortable-life':
      return <IconCashTwoSvg viewBox='0 0 24 24' />;
    case 'fair-balance':
      return <IconCashThreeSvg viewBox='0 0 24 24' />;
    case 'matching-yellow':
      return <IconMatchingYellow viewBox='0 0 56 56' />;
    case 'matching':
      return <IconMatchingSvg viewBox='0 0 48 48' />;
    case 'match-light':
      return <IconMatchLight viewBox='0 0 32 32' />;
    case 'lock-yellow':
      return <IconLockYellow viewBox='0 0 192 192' />;
    case 'page-blue':
      return <IconPageBlueSvg viewBox='0 0 24 24' />;
    case 'handshake':
      return <IconHandShakeSvg viewBox='0 0 24 24' />;
    case 'gift-red':
      return <IconGiftSvg viewBox='0 0 24 24' />;
    default:
      return <IconErrorLightSvg viewBox='0 0 48 48' />;
  }
};

const IconContainer = styled.div<{
  width?: number;
  height?: number;
  color?: string;
  stroke?:string;
}>`
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: ${({ height }) => (height ? `${height}px` : '')};
  & svg {
    width: ${({ width }) => (width ? `${width}px` : '')};
    height: ${({ height }) => (height ? `${height}px` : '')};
  }
  & path {
    fill: ${({ color }) => color || ''};
    stroke: ${({ stroke }) => stroke || ''};
  }
`;

export default V2IconList;
