import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import styled from 'styled-components';
import MatchApplyHomeCondition from './MatchApplyHomeCondition';
import MatchApplyHomeIntroduce from './MatchApplyHomeIntroduce';
import MatchApplyHomeRolling from './MatchApplyHomeRolling';
import MatchApplyHomeUserInfo from './MatchApplyHomeUserInfo';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XXL}px ${SpaceValue.L}px;
    
    
`;

const MatchApplyHomeOverview = () => {
  return (
    <Frame>
      <MatchApplyHomeRolling />
      <SpacingBlock size={SpaceValue.L} vertical />
      <MatchApplyHomeUserInfo />
      <SpacingBlock size={SpaceValue.L} vertical />
      <MatchApplyHomeIntroduce />
      <SpacingBlock size={SpaceValue.M} vertical />
      <MatchApplyHomeCondition />
    </Frame>
  );
};

export default MatchApplyHomeOverview;
