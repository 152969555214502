import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import useActiveTabFlag from 'hooks/useActiveTabFlag';
import { useMatchHomeMatchingPositions } from 'query/match/useMatchQuery';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  padding: 0 16px;
`;

const ExampleFrame = styled.div<{rotateX?:number, ANGLE?:number}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: ${V2BackgroundColors.accent_cobaltblue_subtle};

    .rolling-space{
      display: flex;
      align-items: center;
      gap: 8px;

      .rotator-area{
        overflow: hidden;
        
        .rotator{
          position: relative;
          display: flex;
          align-items: center;
          width: 75vw;
          height: 18px;
          transform-style: preserve-3d;
          transition: all 1s ease-in-out;
          transform: ${(props) => `rotateX(${props.rotateX}deg)`};

          .item {
            position: absolute;
            backface-visibility: hidden;

            .rolling-text {
              ${lineClampBox(1)}
            }
          }
        }
      }
    }
`;

const MainRolling = () => {
  const [rotateX, setRotateX] = useState(0);
  const [angle, setAngle] = useState(0);
  const { data: mainRollingData } = useMatchHomeMatchingPositions();
  const initFlag = useActiveTabFlag();

  const [rollingList, setRollingList] = useState<string[]>([]);

  useEffect(() => {
    if (mainRollingData) {
      setAngle(360 / mainRollingData.matchingPositionList.length);
      const tmpLollgingLst:string[] = [];
      mainRollingData.matchingPositionList.forEach((data) => {
        const text = `매칭제안 : ${data.userName}님, ${data.companyName}`;
        tmpLollgingLst.push(text);
      });
      setRollingList(tmpLollgingLst);
    }
  }, [mainRollingData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - angle);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [angle]);

  if (!initFlag) return null;

  return (
    <Frame>
      <ExampleFrame rotateX={rotateX} ANGLE={angle}>
        <div className='rolling-space'>
          <Icon name='marketing' size={32} />
          <div className='rotator-area'>
            <div className='rotator'>
              {rollingList.map((text, index) => (
                <div
                  className='item'
                  key={`example-${index}`}
                  style={{ transform: `rotateX(${index * angle}deg) translateZ(50px)` }}
                >
                  <V2Text
                    className='rolling-text'
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_2_sb}
                    color={V2TextOption.color.information}
                    ellipsis={1}
                  >
                    {text}
                  </V2Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ExampleFrame>
    </Frame>
  );
};

export default MainRolling;
