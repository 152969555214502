import FindAccountModel from 'models/FindAccountModel';
import Info from 'models/Info';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import MyPagePartnershipModel from 'models/MyPagePartnershipModel';
import NudgeModel from 'models/NudgeModel';
import TermsModel from 'models/TermsModel';
import AccResultsModel from 'models/_v2/AccResultsModel';
import CalendarModel from 'models/_v2/CalendarModel';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import ChallengeModel from 'models/_v2/ChallengeModel';
import ChattingModel from 'models/_v2/ChattingModel';
import CompanyModel from 'models/_v2/CompanyModel';
import Contents from 'models/_v2/Contents';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import JobsModel from 'models/_v2/JobsModel';
import JoinModel from 'models/_v2/JoinModel';
import Main from 'models/_v2/Main';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import MyProfileModel from 'models/_v2/MyProfileModel';
import NotificationModel from 'models/_v2/NotificationModel';
import PaccGateModel from 'models/_v2/PaccGateModel';
import PositionCurationModel from 'models/_v2/PositionCurationModel';
import PositionJdModel from 'models/_v2/PositionJdModel';
import PositionListModel from 'models/_v2/PositionListModel';
import PstResultsModel from 'models/_v2/PstResultsModel';
import ResumeModel from 'models/_v2/ResumeModel';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import EventChallengeModel from 'models/_v2/event/EventChallengeModel';
import EventChuseokModel from 'models/_v2/event/EventChuseokModel';
import EventMatchModel from 'models/_v2/event/EventMatchModel';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import ProfileCareersModel from 'models/_v2/profile/ProfileCareersModel';
import ProfileEducationModel from 'models/_v2/profile/ProfileEducationModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import ProfileProjectModel from 'models/_v2/profile/ProfileProjectModel';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import storeBase from 'models/storeBase';
import EventProfileModel from './_v2/event/EventProfileModel';

const store = {
  ...storeBase,
  login: new Login(),
  findAccountModel: new FindAccountModel(),
  resumeModel: new ResumeModel(),
  nudgeModel: new NudgeModel(),
  info: new Info(),
  matchMessageStore: new MatchMessageStore(),
  myPagePartnershipModel: new MyPagePartnershipModel(),
  termsModel: new TermsModel(),
  main: new Main(),
  // v2
  positionListModel: new PositionListModel(),
  positionJdModel: new PositionJdModel(),
  positionCurationModel: new PositionCurationModel(),
  globalSearchModel: new GlobalSearchModel(),
  matchHomeDashboardModel: new MatchHomeDashboardModel(),
  challengeModel: new ChallengeModel(),
  challengeDetailModel: new ChallengeDetailModel(),
  notificationModel: new NotificationModel(),
  accResultsModel: new AccResultsModel(),
  pstResultsModel: new PstResultsModel(),
  companyModel: new CompanyModel(),
  matchApplyModel: new MatchApplyModel(),
  joinModel: new JoinModel(),
  paccGateModel: new PaccGateModel(),
  contents: new Contents(),
  matchReportModel: new MatchReportModel(),
  // profile
  profileModel: new ProfileModel(),
  profileBasicInfoModel: new ProfileBasicInfoModel(),
  profileEducationModel: new ProfileEducationModel(),
  profileCareersModel: new ProfileCareersModel(),
  profileProjectModel: new ProfileProjectModel(),
  profileSkillModel: new ProfileSkillModel(),
  // MyProfile
  myProfileModel: new MyProfileModel(),
  // Acca
  accaGateModel: new AccaGateModel(),
  // Jobs
  jobsModel: new JobsModel(),
  // Calendar
  calendarModel: new CalendarModel(),
  matchApplyStatusModel: new MatchApplyStatusModel(),
  // Event
  eventChallengeModel: new EventChallengeModel(),
  eventChuseokModel: new EventChuseokModel(),
  eventMatchModel: new EventMatchModel(),
  eventProfileModel: new EventProfileModel(),
  // Chatting
  chattingModel: new ChattingModel(),
};

const injectStoreConstruct = (): { [key in keyof typeof store]: string } => {
  const result: { [key in keyof typeof store]: string | any } = { ...store };
  (Object.keys(store) as Array<keyof typeof store>).forEach((key) => {
    result[key] = key;
  });
  return result;
};

export const injectStore: { [key in keyof typeof store]: string } = injectStoreConstruct();

export default store;
