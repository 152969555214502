/* eslint-disable class-methods-use-this */
import { JobSearchStatusType } from 'consts/MatchType';
import URIs from 'consts/URIs';
import { IMatchHomeDashboardRs } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import { IMatchingHomeAccJobPostingRs, IMatchingHomeDashboardRs, IMatchingHomeJobPostingsBookmarkRs, IMatchingHomeMatchingCompanyRs, IMatchingHomeMatchingPositionRs, IMatchingHomeMatchingQuestStatusRs, IMatchingHomeMatchingSettingUpdateRq, IMatchingHomeNowJobPostingRs, IMatchingHomeRecommendPositionsRs, IMatchingHomeRs, IUserDisplayNotificationRs, IUserDisplayNotificationRq } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { request } from 'utils/request';

export default interface MatchRepo{

    // 홈 > 대시보드
    loadMatchHomeDashboard(): Promise<IMatchingHomeDashboardRs>;

    // 홈 > 대시보드 >구직 상태 업데이트
    updateMatchHomeDashboardMatchingSetting(rq:IMatchingHomeMatchingSettingUpdateRq): Promise<boolean>;

    // 홈 > 역검 활용 공고 조회
    loadMatchHomeJobPostingsAcc(pazeSize:number):Promise<IMatchingHomeAccJobPostingRs>

    // 홈 > 지금 뜨는 공고 조회
    loadMatchHomeJobPostingsNow(pazeSize:number):Promise<IMatchingHomeNowJobPostingRs>

    // 홈 > 매칭 참여 기업
    loadMatchHomeMatchingCompany():Promise<IMatchingHomeMatchingCompanyRs>

    // 홈 > 매칭제안 리스트
    loadMatchHomeMatchingPositions():Promise<IMatchingHomeMatchingPositionRs>

    // 홈 > 매칭 퀘스트 현황 조회
    loadMatchHomeMatchingQuestStatus():Promise<IMatchingHomeMatchingQuestStatusRs>

    // 홈 > 매칭 퀘스트 > 매칭 튜토리얼 완료 여부 저장
    saveMatchHomeMatchingTutorialComplete():Promise<boolean>

    // 홈 > 매칭 튜토리얼 > 매칭 참여 구직자 수
    loadMatchHomeMatchingUserCount():Promise<number>

    // 홈 > 직무별 추천 포지션
    loadMatchHomePositionsRecommend(jobGroupCode:number, pageSize:number):Promise<IMatchingHomeRecommendPositionsRs>

    sendMatchHomeMatchingTutorialAlarm():Promise<boolean>

    // 공고 북마크 조회
    loadV1JobPostingsBookmark(jobPostingSns:number[]):Promise<IMatchingHomeJobPostingsBookmarkRs>

    // 포지션 북마크 조회
    loadV1PositionsBookmark(positionSns:number[]):Promise<IMatchingHomeJobPostingsBookmarkRs>

    // 구직 상태 변경
    updateMatchJobSearchStatus(jobSearchStatus:JobSearchStatusType):Promise<boolean>

    // 매칭 홈 조회
    loadV2MatchingHome():Promise<IMatchingHomeRs>

    // 알림 설정 조회
    loadUserDisplayNotification():Promise<IUserDisplayNotificationRs>

    // 매칭프로필 -> 프로필
    postProfilesFromMatchingProfile():Promise<boolean>

    // 프로필 -> 매칭프로필
    postMatchProfileFromProfile():Promise<boolean>

    // 매칭프로필 -> 프로필
    postProfilesFromMatchingProfile(rq:IUserDisplayNotificationRq):Promise<boolean>
};

export class RemoteMatchRepo implements MatchRepo {
  loadMatchHomeDashboard(): Promise<IMatchingHomeDashboardRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_dashboard,
    });
  }

  updateMatchHomeDashboardMatchingSetting(rq: IMatchingHomeMatchingSettingUpdateRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_home_dashboard_maching_setting,
      data: rq,
    });
  }

  loadMatchHomeJobPostingsAcc(pageSize:number): Promise<IMatchingHomeAccJobPostingRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_job_postings_acc,
      params: { pageSize },
    });
  }

  loadMatchHomeJobPostingsNow(pageSize:number): Promise<IMatchingHomeNowJobPostingRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_job_postings_now,
      params: { pageSize },
    });
  }

  loadMatchHomeMatchingCompany(): Promise<IMatchingHomeMatchingCompanyRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_matching_company,
    });
  }

  loadMatchHomeMatchingPositions(): Promise<IMatchingHomeMatchingPositionRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_matching_positions,
    });
  }

  loadMatchHomeMatchingQuestStatus(): Promise<IMatchingHomeMatchingQuestStatusRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_matching_quest_status,
    });
  }

  saveMatchHomeMatchingTutorialComplete(): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_match_home_matching_tutorial_complete,
    });
  }

  loadMatchHomeMatchingUserCount(): Promise<number> {
    return request({
      method: 'get',
      url: URIs.get_match_home_matching_user_count,
    });
  }

  loadMatchHomePositionsRecommend(jobGroupCode:number, pageSize:number): Promise<IMatchingHomeRecommendPositionsRs> {
    return request({
      method: 'get',
      url: URIs.get_match_home_positions_recommend,
      params: { jobGroupCode, pageSize },
    });
  }

  sendMatchHomeMatchingTutorialAlarm(): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_match_home_matching_tutorial_alarm,
    });
  }

  loadV1JobPostingsBookmark(jobPostingSns:number[]): Promise<IMatchingHomeJobPostingsBookmarkRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_job_postings_bookmark,
      params: { jobPostingSns },
    });
  }

  loadV1PositionsBookmark(positionSns:number[]): Promise<IMatchingHomeJobPostingsBookmarkRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_position_bookmark,
      params: { positionSns },
    });
  }

  updateMatchJobSearchStatus(jobSearchStatus:JobSearchStatusType): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_v1_match_job_search_status,
      data: { jobSearchStatus },
    });
  }

  loadV2MatchingHome(): Promise<IMatchingHomeRs> {
    return request({
      method: 'get',
      url: URIs.get_v2_matching_home,
    });
  }

  loadUserDisplayNotification(): Promise<IUserDisplayNotificationRs> {
    return request({
      method: 'get',
      url: URIs.get_users_display_notification,
    });
  }

  postProfilesFromMatchingProfile(): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_profiles_from_matching_profile,
    });
  }

  postMatchProfileFromProfile(): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_match_profile_from_profile,
    });
  }

  putUsersDisplayNotification(rq:IUserDisplayNotificationRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_users_display_notification,
      data: rq,
    });
  }
}
