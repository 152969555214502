// 잡다매치 홈

import MatchingStroy from '../../main/MainMatchingStroy';
import MatchApplyHomeBanner from './MatchApplyHomeBanner';
import MatchApplyHomeOverview from './MatchApplyHomeOverview';
import MatchHomeRequiredInfo from './MatchHomeRequiredInfo';

const MatchApplyHome = () => {
  return (
    <>
      <MatchApplyHomeOverview />
      <MatchHomeRequiredInfo />
      <MatchApplyHomeBanner />
      <MatchingStroy />
    </>
  );
};

export default MatchApplyHome;
