import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import RoutePaths from 'consts/RoutePaths';
import { MatchingHomeJobPostingDto } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { useMatchHomeJobPostingsNow, useV1JobPostingsBookmark } from 'query/match/useMatchQuery';
import React, { useEffect, useState, useContext, FC } from 'react';
import styled from 'styled-components';
import Login from 'models/Login';
import { observer, inject } from 'mobx-react';

import { injectStore } from 'models/store';
import MainNoPosition from './MainNoPosition';
import MainAccaJobsCard from './MainAccaJobsCard';

const Frame = styled.div`
  width:100%;
  height: fit-content;
  margin-top: ${SpaceValue.XXL}px;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px 16px;
`;

const CardFrame = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${SpaceValue.XS}px;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-area {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: max-content;
    gap: ${SpaceValue.XXL}px;
    padding: 0 ${SpaceValue.XS}px;
  }
`;

const InnerFrame = styled.div`
`;

const MainRecruitFrame = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface ExtendedJobPostingDto extends MatchingHomeJobPostingDto {
  bookmarked: boolean;
}

interface IMainRecruit {
  login?: Login;
}

const MainRecruit:FC<IMainRecruit> = ({ login = new Login() }) => {
  const { data: jobPostingNowData, isLoading: isLoadingNow } = useMatchHomeJobPostingsNow(15);
  const [processedJobPostings, setProcessedJobPostings] = useState<ExtendedJobPostingDto[]>([]);

  const { data: bookmarkData, isLoading: isLoadingBookmark } = useV1JobPostingsBookmark(
    jobPostingNowData?.nowJobPostings.map((posting) => posting.sn) || [],
    !!login.userInfo,
  );

  useEffect(() => {
    if (jobPostingNowData) {
      const extendedPostings: ExtendedJobPostingDto[] = jobPostingNowData.nowJobPostings.map((posting) => ({
        ...posting,
        bookmarked: login.userInfo
          ? bookmarkData?.bookmarkList?.some(
            (bookmark) => bookmark.positionSn === posting.sn && bookmark.isBookmark,
          ) || false
          : false,
      }));

      setProcessedJobPostings(extendedPostings);
    }
  }, [jobPostingNowData, bookmarkData, login.userInfo]);

  const isLoading = isLoadingNow || (login.userInfo && isLoadingBookmark);

  return (
    <Frame>
      <TitleFrame>
        <V2Text
          fontStyle={V2FontStyleType.title_3_b}
          color={V2TextColorsType.strong}
        >
          지금 뜨는 공고
        </V2Text>
        <V2Button
          className='btnViewAll'
          size={V2ButtonOption.size.S}
          fontStyle={V2ButtonOption.fontStyle.body_2_sb}
          color={V2ButtonOption.color.subtle}
          aProps={{ href: RoutePaths.jobs }}
        >
          전체 보기
        </V2Button>
      </TitleFrame>
      <CardFrame>
        <>
          { processedJobPostings.length > 0 ? (
            <MainRecruitFrame>
              <div className='scroll-area'>
                { processedJobPostings.map((item) => (
                  <InnerFrame key={item.sn}>
                    <MainAccaJobsCard
                      item={item}
                      positionList={processedJobPostings}
                    />
                  </InnerFrame>
                ))}
              </div>
            </MainRecruitFrame>
          ) : !isLoading ? <MainNoPosition /> : <></>}
        </>
      </CardFrame>
      <SpacingBlock vertical size={SpaceValue.XXL} />
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MainRecruit));
