import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import RadioGroup, { RadioOption } from '__pc__/components/common/v2Design/radio';
import V2Text from '__pc__/components/common/v2Design/text';
import V2ColorPalette from '__pc__/constant/v2Design/Colors/V2ColorPalette';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import activeSearchIcon from 'assets/_v2/match/icon_invitation_star.png';
import openToOffersIcon from 'assets/_v2/match/icon_send_blue.png';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import MatchSingleMenuItem from 'components/_v2/matchApply/matchApplySettings/MatchSingleMenuItem';
import ConditionEditModal from 'components/_v2/profile/condition/ConditionEditModal';
import { JobSearchStatus, JobSearchStatusType } from 'consts/MatchType';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useMatchJobSearchStatus } from 'query/match/useMatchQuery';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding:${SpaceValue.XL}px;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
  width: 343px;
  height: fit-content;
`;
const OptionFormFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  .green-frame{
    display: flex;
    flex: none;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: ${V2ColorPalette.C_GREEN_25};
    align-items: center;
    justify-content: center;
  }
  .sky-frame{
    display: flex;
    flex: none;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: ${V2ColorPalette.C_SKYBLUE_25};
    align-items: center;
    justify-content: center;
  }
  .icon{
    width: 40px;
    height: 40px;
  }
`;
interface IMatchServiceConditionInductionModalProps {
    isOpen: boolean;
    onClickClose: () => void;
    login?: Login;
    isAlreadyMatched?: boolean;
}

const MatchServiceConditionInductionModal:FC<IMatchServiceConditionInductionModalProps> = ({ login = new Login(), isOpen, onClickClose, isAlreadyMatched = false }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [jobStatus, setJobStatus] = useState<JobSearchStatusType | null>(null);
  const { setToastObject } = useToast();
  const saveSuccess = async () => {
    await login.getMatchConfigStatus();
    if (isAlreadyMatched) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '구직 상태가 변경되었습니다.', subMessage: undefined });
    } else {
      setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '잡다매칭을 신청했습니다.', subMessage: undefined });
    }
    onClickClose();
  };
  const { mutate: saveJobSearchStatus } = useMatchJobSearchStatus(jobStatus ?? JobSearchStatus.ACTIVE_SEARCH, saveSuccess);

  const handleStatusClick = (status: JobSearchStatusType) => {
    setJobStatus(status);
  };
  const handleStatusChange = () => {
    saveJobSearchStatus(jobStatus ?? JobSearchStatus.ACTIVE_SEARCH);
  };
  useEffect(() => {
    (async () => {
      if (isOpen) {
        await login.getMatchConfigStatus();
        if (login.matchConfigStatus) setJobStatus(login.matchConfigStatus.jobSearchStatus);
      }
    })();
  }, [isOpen]);

  return (
    <>
      <JDBaseModal
        dimmed
        isOpen={isOpen}
        onClickClose={onClickClose}
      >
        <Frame>
          <V2Text
            fontStyle={V2TextOption.fontStyle.title_3_b}
            color={V2TextOption.color.default}
          >
            {isAlreadyMatched ? '제안 받기' : '잡다매칭 신청 완료'}
          </V2Text>
          <SpacingBlock vertical size={8} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtle}
          >
            {`${login.userInfo?.name}님이 원하는 방식으로 제안을 보내드릴게요.`}
          </V2Text>
          <OptionFormFrame>
            <MatchSingleMenuItem
              leftIcon={(
                <div className='green-frame'>
                  <img src={activeSearchIcon} alt='적극적으로 제안 받기' className='icon' />
                </div>
            )}
              title='적극적으로 제안 받기'
              subTitle='선호조건이 완벽히 일치하지 않아도 여러 제안을 받고 싶어요.'
              rightIcon={(
                <RadioGroup.Radio
                  size={RadioOption.size.L}
                  selected={jobStatus === JobSearchStatus.ACTIVE_SEARCH}
                  disabled={!login.matchConfigStatus?.matchingConfigYn}
                  value={JobSearchStatus.ACTIVE_SEARCH}
                  onClick={() => handleStatusClick(JobSearchStatus.ACTIVE_SEARCH)}
                />
            )}
              onClick={() => handleStatusClick(JobSearchStatus.ACTIVE_SEARCH)}
              selected={jobStatus === JobSearchStatus.ACTIVE_SEARCH}
              disabled={!login.matchConfigStatus?.matchingConfigYn}
            />
            <MatchSingleMenuItem
              leftIcon={(
                <div className='sky-frame'>
                  <img src={openToOffersIcon} alt='매칭 제안만 받기' className='icon' />
                </div>
            )}
              title='맞춤 제안만 받기'
              subTitle='내가 원하는 조건과 정확히 맞는 제안만 받고 싶어요.'
              rightIcon={(
                <RadioGroup.Radio
                  size={RadioOption.size.L}
                  selected={jobStatus === JobSearchStatus.OPEN_TO_OFFERS}
                  disabled={!login.matchConfigStatus?.matchingConfigYn}
                  value={JobSearchStatus.OPEN_TO_OFFERS}
                  onClick={() => handleStatusClick(JobSearchStatus.OPEN_TO_OFFERS)}
                />
            )}
              onClick={() => handleStatusClick(JobSearchStatus.OPEN_TO_OFFERS)}
              selected={jobStatus === JobSearchStatus.OPEN_TO_OFFERS}
              disabled={!login.matchConfigStatus?.matchingConfigYn}
            />
          </OptionFormFrame>
          <SpacingBlock vertical size={24} />
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_2_sb}
            color={V2ButtonOption.color.inverse}
            fill={V2ButtonOption.fillType.brand_strong_default}
            styles={{ width: '100%' }}
            buttonProps={{ onClick: () => handleStatusChange(), disabled: !jobStatus || jobStatus === JobSearchStatus.NOT_SELECTED }}
          >
            확인
          </V2Button>
        </Frame>
      </JDBaseModal>
      <ConditionEditModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />
    </>
  );
};

export default inject(injectStore.login)(observer(MatchServiceConditionInductionModal));
