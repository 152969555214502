import { ReactComponent as IconSearch } from 'assets/icon/icon_search_24.svg';
import IconInfo1 from 'assets/icon/mypage/premium/icon_info1.png';
import IconInfo2 from 'assets/icon/mypage/premium/icon_info2.png';
import IconInfo3 from 'assets/icon/mypage/premium/icon_info3.png';
import { Divider1G40 } from 'components/divider/Divider';
import JDAValidInputCation from 'components/inputs/JDAValidInputCaption';
import JDBaseInput from 'components/inputs/JDBaseInput';
import JDInnerButtonInput from 'components/inputs/JDInnerButtonInput';
import JDSearchInput from 'components/inputs/JDSearchInput';
import JDTimerConfirmInput from 'components/inputs/JDTimerConfirmInput';
import useToast from 'hooks/useToast';
import MyPagePartershipListModal from 'components/myPage/MyPagePartershipListModal';
import MyPagePartershipSuccessModal from 'components/myPage/MyPagePartershipSuccessModal';
import MyPagePartershipToast, { MY_PAGE_PARTERSHIP_TOAST_TYPE } from 'components/myPage/MyPagePartershipToast';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { emailValidator, emailValidatorPartnerShip, numberEnglishIdValidator } from 'consts/ValidationRule';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import { IUniversityInfoRq, RegisteredCouponDto } from 'interfaces/myPage/partership/IMyPagePartnership';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import MyPagePartnershipModel from 'models/MyPagePartnershipModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import CommonUtils, { debounce } from 'utils/CommonUtils';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import { PARTNERSHIP_AUTH_TYPE } from 'consts/_v2/partnership/partnership';
import type { partnershipAuthType } from 'consts/_v2/partnership/partnership';
import PartnershipTermsModal from 'components/_v2/_common/terms/PartnershipTermsModal';
import { Button, ButtonMode } from '__designkit__/button/Button';
import SimpleConfirmModal from 'components/_v2/_common/modals/SimpleConfirmModal';
import { useLocation } from 'react-router';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';

const Frame = styled.div`
  padding:12px 20px 32px 20px;

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 36px;
    padding: 0 24px;
    height: 88px;
    background-color: ${colors.CG_30};
    border-radius: 8px;
    margin-bottom: 24px;

    .sub-title {
      font: ${Fonts.B2_Medium};
      color: ${colors.JOBDA_BLACK};
    }

    .main-title {
      margin-top: 4px;
      font: ${Fonts.B1_Bold};
      color: ${colors.JOBDA_BLACK};
    }
  }

  .sub-title{
    position: relative;
    display: inline-block;
    font: ${Fonts.B1_Bold};
    color: ${colors.CG_80};

    &[aria-required=true]{
      ::after{
        position: absolute;
        content: '';
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: ${colors.ERROR};
        top: 9px;
        right: -12px;
      }
    };
  }
  .total-count{
    font: ${Fonts.B4_Medium};
    color: ${colors.CG_90};
    margin-top: 5px;
  }

  .required{
    position: relative;
    font: ${Fonts.B3_Medium};
    color: ${colors.ERROR};

    ::after{
      position: absolute;
      content: '';
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: ${colors.ERROR};
      top: 6px;
      left: -8px;
    }
  }

  .sub-info{
    margin-top: 4px;
    margin-left: 16px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_70};
  }

  .partnership-add-link{
    display: block;
    text-align: right;
    font: ${Fonts.B3_Medium};
    color: ${colors.B_100};
    text-decoration: underline;
    margin-bottom: 12px;
  }
  .btn-frame{
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }
  .btn-submit {
    margin-top: 56px;
    ${JDMainButton()};
    height: 46px;

    :disabled{
      opacity: 0.5;
    }
  }

  .none{
    display: none;
  }
`;

const ValueFrame = styled.div<{ marginTop?: number, marginBottom?: number }>`
  margin-top: 10px;
  ${(props) => props.marginTop && `
  margin-top: ${props.marginTop}px;
  `}
  ${(props) => props.marginBottom && `
  margin-bottom: ${props.marginBottom}px;
  `}
`;

const PremiumInfoCardFrame = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;

  h3{
    margin-bottom: 16px;
    font: ${Fonts.B3_Medium};
    color: ${colors.ERROR};
  }

  div{
    padding: 12px 16px;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${colors.CG_40};
    margin-bottom: 8px;

    > img{
      width: 32px;
    }

    > span{
      margin-left: 12px;
      font: ${Fonts.B2_Medium};
      color: ${colors.CG_80};
    }
  }
`;

const PremiumListFrame = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const AuthTypeTab = styled.div`
  margin-top: 32px;
  display: flex;

  > div {
    position: relative;
    display: flex;
    align-items: center;
  }

  button {
    position: relative;
    background: transparent;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_60};

    &[aria-current=true]{
      font: ${Fonts.B3_Bold};
      color: ${colors.CG_80};  
    }
  }

  .verified {
    margin-left: 4px;
    padding: 2px 6px;
    font: ${Fonts.B4_Medium};
    color: ${colors.G_200};
    background: ${colors.cE0F9E4};
    border-radius: 50px;
    content: '완료';
  }

  >:not(:first-child) {
    margin-left: 16px;

    ::before{
      content: '';
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background: ${colors.CG_40};
    }
  }
`;

const VerifiedFrame = styled.div`
  display: flex;
  flex-direction: column;

  h1{
    font: ${Fonts.B1_Medium};
    color: ${colors.CG_70};
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;

const CodeItemsFrame = styled.div`
  margin-top: 12px;
  width: 100%;
`;

const CouponCodeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;

  .coupon-name {
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  .date{
    font: ${Fonts.B3_Medium};
    color: ${colors.JOBDA_BLACK};
  }

  .expired-date{
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_60};
  }
`;

const CollegeFrame = styled.div<{ hidden: boolean }>`
  ${(props) => props.hidden && ` display: none;`}
  margin-top: 24px;
`;

const CodeFrame = styled.div<{ hidden: boolean }>`
  ${(props) => props.hidden && ` display: none;`}
  margin-top: 24px;
`;

const EventFrame = styled.div<{ hidden: boolean }>`
  ${(props) => props.hidden && ` display: none;`}
  margin-top: 24px;
`;

interface IMyPagePartnershipProps extends IComponentProps {
  myPagePartnershipModel?: MyPagePartnershipModel;
}

const MyPagePartnership: FC<IMyPagePartnershipProps> = ({ myPagePartnershipModel = new MyPagePartnershipModel() }) => {
  const useFormed = useForm({ mode: 'all' });
  const { formState, watch, setError, reset, getValues, setValue } = useFormed;
  const { errors } = formState;
  const { search } = useLocation();
  const { list } = CommonUtils.parseQuery(search);
  const [openListView, setOpenListView] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<{ isOpen: boolean, type: MY_PAGE_PARTERSHIP_TOAST_TYPE }>({ isOpen: false, type: MY_PAGE_PARTERSHIP_TOAST_TYPE.SEND_MAIL });
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [authType, setAuthType] = useState<partnershipAuthType>(PARTNERSHIP_AUTH_TYPE.UNIVERSITY);
  const [viewAgreeModal, setViewAgreeModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  useEffect(() => {
    if (list) setOpenListView(true);
  }, [list]);
  const { setToastObject } = useToast();

  const checkValidation = async (type: partnershipAuthType) => {
    if (type === PARTNERSHIP_AUTH_TYPE.UNIVERSITY) {
      if (watch('schoolCode') === '') {
        setError('schoolName', { type: 'string', message: '', shouldFocus: true });
        return false;
      }
      if (watch('majorCode') === '') {
        setError('majorName', { type: 'string', message: '', shouldFocus: true });
        return false;
      }
      if (watch('studentId') === '') {
        setError('studentId', { type: 'string', message: '', shouldFocus: true });
        return false;
      }
      if (!numberEnglishIdValidator(watch('studentId'))) {
        setError('studentId', { type: 'string', message: '학번은 영문자, 숫자, -, _만 사용할 수 있습니다.', shouldFocus: true });
        return false;
      }
    } else if (type === PARTNERSHIP_AUTH_TYPE.CODE) {
      if (watch('code') === '') {
        setError('code', { type: 'string', message: '', shouldFocus: true });
        return false;
      }
    } else if (type === PARTNERSHIP_AUTH_TYPE.EVENT) {
      if (!myPagePartnershipModel.eventCodeChecked) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async () => {
    try {
      if (authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY) {
        const { schoolCode, majorName, majorCode, studentId, email } = getValues();
        const rq: IUniversityInfoRq = { schoolCode, majorName, majorCode, studentId, email };
        await myPagePartnershipModel.registerUniversities(rq);
        setIsRegister(true);
      } else if (authType === PARTNERSHIP_AUTH_TYPE.EVENT) {
        await myPagePartnershipModel.registerCoupons(watch('event'));
        setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증이 완료되었습니다.' });
        setValue('event', '');
        myPagePartnershipModel.eventCodeChecked = false;
        await myPagePartnershipModel.loadCouponsRegistered();
      } else {
        const { code } = getValues();
        await myPagePartnershipModel.registerOrganization(code);
        setIsRegister(true);
      }
      await init();
    } catch (e) {
      setToastObject({ isOpen: true, type: 'ERROR', message: '인증실패했습니다.', position: 'middle' });
    }
  };

  const onClickRemoveVerificateBtn = async () => {
    await myPagePartnershipModel.deleteVerification(authType);
    await init();
    setIsOpenDeleteModal(false);
  };

  const hasExpiredCoupons = (registeredCoupons: RegisteredCouponDto[]): boolean => registeredCoupons.some((coupon) => !coupon.isExpired);

  useEffect(() => {
    const init = async () => {
      await myPagePartnershipModel.loadCouponsRegistered();
      if (myPagePartnershipModel.eventCodeList) {
        myPagePartnershipModel.eventVerified = hasExpiredCoupons(myPagePartnershipModel.eventCodeList?.registeredCoupons);
      }
    };
    init();
  }, []);

  const init = async () => {
    await myPagePartnershipModel.loadPartnershipUserInfo();
    if (myPagePartnershipModel.userInfo?.universityVerified) {
      reset({
        majorName: myPagePartnershipModel.userInfo.universityInfo.majorName,
        majorCode: myPagePartnershipModel.userInfo.universityInfo.majorCode,
        studentId: myPagePartnershipModel.userInfo.universityInfo.studentId,
      });
      setAuthType(PARTNERSHIP_AUTH_TYPE.UNIVERSITY);
    } else if (myPagePartnershipModel.userInfo?.organizationVerified) {
      setAuthType(PARTNERSHIP_AUTH_TYPE.CODE);
    } else if (myPagePartnershipModel.eventCodeList && myPagePartnershipModel.eventCodeList.count > 0) {
      setAuthType(PARTNERSHIP_AUTH_TYPE.EVENT);
    }

    AuthorizeUtil.updateAccessToken(true);
  };

  useEffect(() => {
    if (!viewAgreeModal) init();
  }, [viewAgreeModal]);

  return (
    <Frame>
      <FormProvider {...useFormed}>
        <section>
          <a href='https://forms.office.com/pages/responsepage.aspx?id=JTLE58C7VEGk4KU2E7crB4LpRDnJY8dOlIrkAwV2owhURVE4WDRYSElMVUxKS1M5Q1Y0QldKUzcwWC4u' target='_blank' className='banner' rel='noreferrer'>
            <div className='text'>
              <span className='sub-title'>나의 학교, 기관이 리스트에 없나요?</span>
              <p className='main-title'>제휴 서비스 제안하기</p>
            </div>
            <Icon size={64} name='handshake' />
          </a>
          <div className='sub-title'>제휴서비스 혜택 안내</div>
          <PremiumInfoCardFrame>
            <h3>*제휴서비스 혜택은 PC에서만 이용 가능합니다.</h3>
            <div><img src={IconInfo1} alt='혜택1' /><span>역량검사 응시 횟수 추가 제공</span></div>
            <div><img src={IconInfo2} alt='혜택2' /><span>심층 분석한 역량검사 결과지 오픈</span></div>
            <div><img src={IconInfo3} alt='혜택3' /><span>자신있는 면접을 위한 기출 면접 연습 기능</span></div>
          </PremiumInfoCardFrame>
          <PremiumListFrame>
            <V2Button
              type={V2ButtonOption.type.Fill}
              fill={V2ButtonOption.fillType.brand_strong_default}
              color={V2ButtonOption.color.inverse}
              fontStyle={V2ButtonOption.fontStyle.body_1_sb}
              buttonProps={{ onClick: () => setOpenListView(true) }}
              size={V2ButtonOption.size.L}
              styles={{ width: '100%' }}
            >
              제휴 리스트 확인하기
            </V2Button>
          </PremiumListFrame>
        </section>
        <Divider1G40 />
        <section>
          <AuthTypeTab>
            <div>
              <button aria-current={authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY} onClick={() => setAuthType(PARTNERSHIP_AUTH_TYPE.UNIVERSITY)}>제휴 대학 인증</button>
              { myPagePartnershipModel.userInfo?.universityVerified && <div className='verified'>완료</div> }
            </div>
            <div>
              <button aria-current={authType === PARTNERSHIP_AUTH_TYPE.CODE} onClick={() => setAuthType(PARTNERSHIP_AUTH_TYPE.CODE)}>제휴 기관 인증</button>
              { myPagePartnershipModel.userInfo?.organizationVerified && <div className='verified'>완료</div> }
            </div>
            <div>
              <button aria-current={authType === PARTNERSHIP_AUTH_TYPE.EVENT} onClick={() => setAuthType(PARTNERSHIP_AUTH_TYPE.EVENT)}>이벤트 인증</button>
              { myPagePartnershipModel.eventVerified && <div className='verified'>완료</div> }
            </div>
          </AuthTypeTab>
          <CollegeFrame hidden={authType !== PARTNERSHIP_AUTH_TYPE.UNIVERSITY}>
            { myPagePartnershipModel.userInfo?.universityVerified && (
              <VerifiedFrame>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className='sub-title'>인증 방법</div>
                  <div className='required'>필수 입력</div>
                </div>
                <h1>{`제휴대학 이메일 인증(${myPagePartnershipModel.userInfo?.universityInfo.verifiedDate})`}</h1>
                <div className='sub-title'>제휴 대학</div>
                <h1>{`${myPagePartnershipModel.userInfo?.universityInfo.schoolName}`}</h1>
              </VerifiedFrame>
            )}
            <div className={myPagePartnershipModel.userInfo?.universityVerified ? 'none' : ''}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='sub-title' aria-required>제휴 대학</div>
                <div className='required'>필수 입력</div>
              </div>
              <ValueFrame marginBottom={16}>
                <JDSearchInput
                  title='제휴대학'
                  textName='schoolName'
                  codeName='schoolCode'
                  placeholder='학교를 검색해 주세요.'
                  onSearch={(searchText: string) => {
                    if (searchText === '') myPagePartnershipModel.universities = [];
                    else {
                      debounce(async () => {
                        await myPagePartnershipModel.searchSchools(searchText);
                      }, 800);
                    }
                  }}
                  searchList={myPagePartnershipModel.universities}
                />
                <h4 className='sub-info'>제휴된 대학만 인증 가능합니다.</h4>
              </ValueFrame>
            </div>
            <div className='sub-title' aria-required>학과</div>
            <ValueFrame marginBottom={16}>
              <JDSearchInput
                title='학과'
                textName='majorName'
                codeName='majorCode'
                placeholder='학과를 검색해 주세요.'
                onSearch={(searchText: string) => {
                  if (searchText === '') myPagePartnershipModel.majorAliases = [];
                  else {
                    debounce(async () => {
                      await myPagePartnershipModel.searchMajorAliases(searchText);
                    }, 800);
                  }
                }}
                searchList={myPagePartnershipModel.majorAliases}
              />
            </ValueFrame>
            <div className='sub-title' aria-required>학번</div>
            <ValueFrame marginBottom={16}>
              <JDBaseInput
                name='studentId'
                placeholder='학번을 입력해 주세요.'
                customValidator={{
                  validate: (value: string) => {
                    const res = numberEnglishIdValidator(value);
                    if (!res) return '학번은 영문자, 숫자, -, _만 사용할 수 있습니다.';
                    return true;
                  },
                }}
                defaultValue={myPagePartnershipModel.userInfo?.universityInfo?.studentId}
              />
            </ValueFrame>
            <div className={myPagePartnershipModel.userInfo?.universityVerified ? 'none' : ''}>
              <div className='sub-title' aria-required>제휴 대학 이메일 인증</div>
              <ValueFrame>
                <JDInnerButtonInput
                  className={myPagePartnershipModel.isUniversityAuthSuccess ? 'none' : ''}
                  name='email'
                  customValidator={{ validate: (value: string) => emailValidatorPartnerShip(value) }}
                  placeholder='대학 이메일을 입력해 주세요.'
                  btnLabel={myPagePartnershipModel.isUniversityAuthSuccess ? '인증완료' : myPagePartnershipModel.isAuthCall > 0 ? '재전송' : '인증요청'}
                  onClickInnerBtn={() => {
                    if (watch('schoolCode') === '') {
                      setError('schoolName', { type: 'string', message: '', shouldFocus: true });
                      return;
                    }
                    setTimeout(async () => {
                      try {
                        await myPagePartnershipModel.sendEmail(watch('email'), watch('schoolCode'));
                        setToastVisible({ isOpen: true, type: MY_PAGE_PARTERSHIP_TOAST_TYPE.SEND_MAIL });
                      } catch (e) {
                        const err = e as { response: any };
                        setError('email', { type: 'string', message: err.response.data.message });
                      }
                    }, 200);
                  }}
                  done={myPagePartnershipModel.isUniversityAuthSuccess}
                  caption={(!errors.email && myPagePartnershipModel.isAuthCall && !myPagePartnershipModel.isUniversityAuthSuccess) ? <JDAValidInputCation>발송된 메일을 통해 인증코드를 확인해 주세요.</JDAValidInputCation> : <></>}
                />
                <JDInnerButtonInput
                  className={myPagePartnershipModel.isUniversityAuthSuccess ? '' : 'none'}
                  name='done'
                  value='인증이 완료되었습니다.'
                  btnLabel='인증완료'
                  disabled
                  done
                />
                <JDTimerConfirmInput
                  key={`timer-input-${myPagePartnershipModel.isAuthCall}`}
                  className={`${myPagePartnershipModel.isAuthCall > 0 && !myPagePartnershipModel.isUniversityAuthSuccess ? '' : 'none'}`}
                  placeholder='인증코드를 입력해 주세요.'
                  name='token'
                  count={600}
                  disabled={myPagePartnershipModel.isAuthCall === 0}
                  isRunning={!myPagePartnershipModel.isUniversityAuthSuccess && myPagePartnershipModel.isAuthCall > 0}
                  onTimerEnd={() => {
                    myPagePartnershipModel.isAuthCall = 0;
                    myPagePartnershipModel.isUniversityAuthSuccess = false;
                  }}
                  onClickInnerBtn={() => {
                    setTimeout(async () => {
                      try {
                        await myPagePartnershipModel.checkEmail(watch('email'), watch('token'));
                        setToastVisible({ isOpen: true, type: MY_PAGE_PARTERSHIP_TOAST_TYPE.SUCCESS });
                      } catch (e) {
                        const err = e as { response: any };
                        setError('token', { type: 'string', message: err.response.data.message });
                      }
                    }, 200);
                  }}
                />
              </ValueFrame>
            </div>
            <div className='btn-frame'>
              {
                myPagePartnershipModel.userInfo?.universityVerified && (
                  <V2Button
                    type={V2ButtonOption.type.OutLine}
                    color={V2ButtonOption.color.subtle}
                    fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                    buttonProps={{ onClick: () => setIsOpenDeleteModal(true) }}
                    size={V2ButtonOption.size.L}
                    styles={{ width: '100%' }}
                  >
                    인증정보 삭제
                  </V2Button>
                )
              }
              <V2Button
                type={V2ButtonOption.type.Fill}
                color={V2ButtonOption.color.inverse}
                fill={V2ButtonOption.fillType.brand_strong_default}
                fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                buttonProps={{
                  onClick: async () => {
                    try {
                      if (watch('studentId') === '') setError('studentId', { type: 'string', message: '', shouldFocus: true });
                      if (!numberEnglishIdValidator(watch('studentId'))) setError('studentId', { type: 'string', message: '학번은 영문자, 숫자, -, _만 사용할 수 있습니다.', shouldFocus: true });
                      if (watch('majorCode') === '') setError('majorName', { type: 'string', message: '', shouldFocus: true });
                      if (watch('majorCode') !== '' && watch('studentId') !== '' && numberEnglishIdValidator(watch('studentId'))) {
                        await myPagePartnershipModel.updateUniversities(watch('majorName'), watch('majorCode'), watch('studentId'));
                        setToastObject({ isOpen: true, type: 'SUCCESS', message: '변경사항이 저장되었습니다.', position: 'middle' });
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  },
                }}
                className={myPagePartnershipModel.userInfo?.universityVerified ? '' : 'none'}
                size={V2ButtonOption.size.L}
                styles={{ width: '100%' }}
              >
                변경사항 저장
              </V2Button>
            </div>
          </CollegeFrame>
          <CodeFrame hidden={authType !== PARTNERSHIP_AUTH_TYPE.CODE}>
            { !myPagePartnershipModel.userInfo?.organizationVerified && <div className='sub-title' aria-required>인증코드 입력</div> }
            { myPagePartnershipModel.userInfo?.organizationVerified && (
              <VerifiedFrame>
                <div className='sub-title'>인증 방법</div>
                <h1>{`인증코드 인증(${myPagePartnershipModel.userInfo?.organizationInfo.verifiedDate})`}</h1>
                <div className='sub-title'>인증 기관</div>
                <h1>{`${myPagePartnershipModel.userInfo?.organizationInfo.organizationName}`}</h1>
              </VerifiedFrame>
            )}
            <JDInnerButtonInput
              className={(myPagePartnershipModel.userInfo?.organizationVerified || myPagePartnershipModel.isCodeAuthSuccess) && 'none'}
              name='code'
              placeholder='인증코드를 입력해 주세요.'
              btnLabel={myPagePartnershipModel.isCodeAuthSuccess ? '인증완료' : '확인'}
              onClickInnerBtn={() => {
                setTimeout(async () => {
                  try {
                    await myPagePartnershipModel.checkCode(watch('code'));
                  } catch (e) {
                    const err = e as { response: any };
                    setError('code', { type: 'string', message: err.response.data.message });
                  }
                }, 200);
              }}
              done={myPagePartnershipModel.isCodeAuthSuccess}
              caption={(!errors.email && myPagePartnershipModel.isAuthCall) ? <JDAValidInputCation>발송된 메일을 통해 인증코드를 확인해 주세요.</JDAValidInputCation> : <></>}
            />
            <JDInnerButtonInput
              className={myPagePartnershipModel.isCodeAuthSuccess ? '' : 'none'}
              name='done'
              value='인증이 완료되었습니다.'
              btnLabel='인증완료'
              disabled
              done
            />
            { myPagePartnershipModel.userInfo?.organizationVerified && (
              <>
                <SpacingBlock size={16} vertical />
                <Button label='인증정보 삭제' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setIsOpenDeleteModal(true)} />
              </>
            )}
          </CodeFrame>
          <EventFrame hidden={authType !== PARTNERSHIP_AUTH_TYPE.EVENT}>
            <div className='sub-title' aria-required>인증코드 입력</div>
            <JDInnerButtonInput
              name='event'
              placeholder='인증코드를 입력해 주세요.'
              btnLabel={myPagePartnershipModel.eventCodeChecked ? '인증완료' : '확인'}
              onClickInnerBtn={() => {
                setTimeout(async () => {
                  try {
                    await myPagePartnershipModel.loadCouponsCheck(watch('event'));
                  } catch (e) {
                    const err = e as { response: any };
                    setError('event', { type: 'string', message: err.response.data.message });
                  }
                }, 200);
              }}
              done={myPagePartnershipModel.eventCodeChecked}
            />
            { myPagePartnershipModel.eventCodeList && myPagePartnershipModel.eventCodeList.count > 0 && (
              <>
                <SpacingBlock size={16} vertical />
                <div className='sub-title'>등록 인증코드</div>
                <div className='total-count'>총 {myPagePartnershipModel.eventCodeList.count}개</div>
                <CodeItemsFrame>
                  { myPagePartnershipModel.eventCodeList.registeredCoupons.map((code) => (
                    <CouponCodeItem key={`event-code-${code.code}`}>
                      <div className='coupon-name'>{code.code}</div>
                      { code.isExpired
                        ? <div className='expired-date'>기간 만료</div>
                        : <div className='date'>{DateUtil.format(new Date(code.expiresDateTime), 'yyyy.MM.dd (week) HH:mm 만료')}</div>}
                    </CouponCodeItem>
                  ))}
                </CodeItemsFrame>
              </>
            ) }
          </EventFrame>
        </section>
        <SpacingBlock size={56} vertical />
        <V2Button
          type={V2ButtonOption.type.Fill}
          color={V2ButtonOption.color.inverse}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          buttonProps={{
            disabled: (!myPagePartnershipModel.isUniversityAuthSuccess && authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY)
                      || (!myPagePartnershipModel.isCodeAuthSuccess && authType === PARTNERSHIP_AUTH_TYPE.CODE)
                      || (!myPagePartnershipModel.eventCodeChecked && authType === PARTNERSHIP_AUTH_TYPE.EVENT),
            onClick: async () => {
              const check = await checkValidation(authType);
              if (check) {
                if (authType === PARTNERSHIP_AUTH_TYPE.EVENT) {
                  onSubmit();
                } else {
                  setViewAgreeModal(true);
                }
              }
            },
          }}
          className={(myPagePartnershipModel.userInfo?.organizationVerified && authType === PARTNERSHIP_AUTH_TYPE.CODE)
                    || (myPagePartnershipModel.userInfo?.universityVerified && authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY)
            ? 'none'
            : ''}
          size={V2ButtonOption.size.L}
          styles={{ width: '100%' }}
        >
          제휴서비스 신청
        </V2Button>
      </FormProvider>

      { viewAgreeModal && (
        <PartnershipTermsModal
          onClickComplete={() => {
            onSubmit();
            setViewAgreeModal(false);
          }}
          onClickClose={() => setViewAgreeModal(false)}
          authType={authType}
        />
      )}
      { toastVisible.isOpen && (
        <MyPagePartershipToast
          type={toastVisible.type}
          isOpen
          onDidDismiss={() => {
            setToastVisible({ isOpen: false, type: MY_PAGE_PARTERSHIP_TOAST_TYPE.SEND_MAIL });
          }}
          duration={2000}
        />
      )}
      <MyPagePartershipListModal isOpen={openListView} onClose={() => setOpenListView(false)} />
      <MyPagePartershipSuccessModal isOpen={isRegister} onClickClose={() => setIsRegister(false)} />
      <SimpleConfirmModal isOpen={isOpenDeleteModal} title='인증정보를 삭제하시겠어요?' subTitle='삭제된 인증코드는 다시 사용할 수 없어요.' confirmBtnText='확인' cancelBtnText='취소' onClickConfirm={onClickRemoveVerificateBtn} onClickCancel={() => setIsOpenDeleteModal(false)} />
    </Frame>
  );
};

export default inject(injectStore.myPagePartnershipModel)(observer(MyPagePartnership));
