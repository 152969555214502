/* eslint-disable array-callback-return */
/* eslint-disable react/no-this-in-sfc */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import FormValueFrame, { DatePickerFormValueFrame, EducationTypeFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfileEducationMajor from 'components/_v2/profile/education/ProfileEducationMajor';
import ProfileEducationResearches from 'components/_v2/profile/education/ProfileEducationResearches';
import { EducationLevel, EducationSchoolText, EducationType, EducationTypeText } from 'consts/_v2/profile/EducationLevel';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IProfileEducation, IProfileEducationRq, MajorType, ScoreType } from 'interfaces/_v2/profile/IProfileEducation';
import IComponentProps from 'interfaces/props/IComponentProps';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import { inject, observer } from 'mobx-react';
import ProfileEducationModel from 'models/_v2/profile/ProfileEducationModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import * as yup from 'yup';

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 8px;
`;

const FormFrame = styled.form`
  width: 100%;
  height: calc(100% - 56px);
`;

const EducationsFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 16px 16px;
    overflow-y: auto;

    .title {
      display: flex;
      justify-content: flex-end;
      
      .delete-field {
        display: flex;
        align-items: center;
        height: 24px;
        padding: 2px;
      
        & > span {
          display: flex;
          align-items: center;
          font: ${Fonts.B3_Medium};
          color:${Colors.JOBDA_BLACK};
        }

        :disabled {
          opacity: 0.5;
        }
      }
    }

    .register-item-frame {
      > * {
        margin-bottom: 16px;
      }
    }
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.none {
    display: none;
  }
`;

const EducationRegisterFrame = styled.div`
  .content{
    padding: 16px;
  }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
`;

interface IProfileEducationModal extends IComponentProps {
  profileEducationModel?: ProfileEducationModel;
  isOpen?: boolean;
  onClose?: () => void;
  isMatching?: boolean;
}
export const EduFormValidationSchema = yup.object().shape({
  educations: yup.array().of(
    yup.object().shape(
      {
        educationType: yup.string().nullable(),
        schoolLevel: yup.string().required('학교 구분을 입력해 주세요.'),
        schoolName: yup.string().required('학교를 입력해 주세요.').max(30, '30자 이내로 입력해 주세요.').nullable(),
        startDate: yup.string().required('입학 일자를 입력해 주세요.'),
        endDate: yup.string().when('educationType', {
          is: (val: EducationType) => val === EducationType.GRADUATION || val === EducationType.GRADUATION_CANDIDATE,
          then: yup
            .string()
            .when('startDate', (startDate: any, schema: any) => schema.test({
              test: (endDate: Date) => endDate >= startDate,
              message: '졸업 일자가 입학 일자보다 빠릅니다.',
            }))
            .nullable().required('졸업 일자를 입력해 주세요.'),
          otherwise: yup.string().when('educationType', {
            is: (val: EducationType) => val === EducationType.DROP,
            then: yup
              .string()
              .when('startDate', (startDate: any, schema: any) => schema.test({
                test: (endDate: Date) => endDate >= startDate,
                message: '중퇴 일자가 입학 일자보다 빠릅니다.',
              }))
              .nullable().required('중퇴 일자를 입력해 주세요.'),
            otherwise: yup.string().nullable().notRequired(),
          }),
        }).nullable(),
        profileMajors: yup.array().when('schoolLevel', {
          is: (val: EducationLevel) => val === EducationLevel.HIGHSCHOOL,
          then: yup.array().notRequired(),
          otherwise: yup.array().of(
            yup.object().shape({
              majorCode: yup.string().required(),
              majorName: yup.string().required('학과 · 전공을 입력해주세요'),
              majorType: yup.string().required('학과 · 전공을 검색해주세요 '),
              score: yup.string().test({
                test(value, ctx) {
                  if (ctx.parent.scoreType === 'SCORE' && ctx.parent.perfectScore !== '' && value === '') {
                    return this.createError({ message: '평점을 입력해 주세요.' });
                  }
                  return true;
                },
              }).nullable(),
              perfectScore: yup.string().when('scoreType', {
                is: (val: ScoreType) => val === 'SCORE',
                then: yup
                  .string()
                  .test({
                    test(value, ctx) {
                      if (!value && ctx.parent.score) return ctx.createError({ message: '만점을 입력해 주세요.' });
                      return true;
                    },
                  }),
              }),
            }),
          ).min(1),
        }),
      },
    ),
  ).nullable(),
}, []);
const ProfileEducation: FC<IProfileEducationModal> = ({ profileEducationModel = new ProfileEducationModel(), isOpen, onClose, isMatching = false }) => {
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const [isError, setIsError] = useState<boolean>(false);
  const [registerItemMode, setRegisterItemMode] = useState<boolean>(false);
  const [openRegisterFrame, setOpenRegisterFrame] = useState<{ open: boolean, selectIndex: number, type: 'NEW' | 'EDIT' }>({ open: false, selectIndex: -1, type: 'NEW' });
  const [searchSchool, setSearchSchools] = useState<INameCodeRs[]>([]);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const registerFrameRef = useRef<HTMLDivElement | null>(null);

  const EduDefaultValue = {
    educationType: undefined,
    endDate: undefined,
    profileMajors: [
      {
        majorCode: undefined,
        majorType: MajorType.MAJOR,
        perfectScore: undefined,
        score: undefined,
        scoreType: ScoreType.SCORE,
      },
    ],
    profileResearches: [
      { attachFiles: [] },
    ],
    schoolCode: 0,
    schoolLevel: undefined,
    schoolName: undefined,
    startDate: undefined,
    transferYn: undefined,
  };
  const useFormed = useForm<Partial<IProfileEducationRq>>({
    mode: 'onSubmit',
    resolver: yupResolver(EduFormValidationSchema),
  });

  const { watch, control, setValue, reset, formState, getValues, handleSubmit, trigger } = useFormed;
  const { fields: educationsFields, append: educationsAppend, remove: educationsRemove } = useFieldArray({
    control,
    name: 'educations',
  });

  const makePeriodText = (startDate: string, endDate: string, educationType: EducationType) => {
    switch (educationType) {
      case EducationType.ATTENDING:
      case EducationType.COMPLETE:
      case EducationType.LEAVE:
        return `${startDate} ~ ${EducationTypeText[educationType]}`;
      case EducationType.GRADUATION_CANDIDATE:
      case EducationType.DROP:
        return `${startDate} ~ ${endDate} (${EducationTypeText[educationType]})`;
      case EducationType.GRADUATION:
        return `${startDate} ~ ${endDate}`;
      default:
        return '';
    }
  };

  const onClickCloseBtn = async () => {
    if (registerItemMode) setRegisterItemMode(false);
    if (profileEducationModel.isDirty) {
      setIsDirtyModalOpen(true);
      return;
    }
    if (openRegisterFrame.open && openRegisterFrame.type === 'NEW') {
      educationsRemove(educationsFields.length - 1);
    } else if (openRegisterFrame.open) {
      setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
    } else {
      await profileEducationModel.loadEducations(isMatching);
      if (onClose) onClose();
    }
  };

  const onClickEducationItemDeleteBtn = (itemIndex: number) => {
    if (educationsFields.length > 1) educationsRemove(itemIndex);
    else {
      setToastObject({ isOpen: true, message: '학력 정보는 최소 1개 이상 등록해 주세요.', type: 'ERROR', duration: 2000 });
      setIsError(true);
    }
  };

  const educationsInit = async () => {
    reset({ educations: profileEducationModel.educations || undefined });
  };

  const onSubmit = async (data?: any) => {
    try {
      const { educations } = getValues() as IProfileEducationRq;
      if (educations !== undefined && educations.length > 0) {
        educations.forEach((edu: IProfileEducation) => {
          if (edu.schoolLevel === EducationLevel.HIGHSCHOOL) {
            delete edu.profileMajors;
            delete edu.profileResearches;
          } else {
            edu.profileMajors?.map((major) => {
              if (major.scoreType === ScoreType.PERCENTAGE) {
                major.perfectScore = 100;
              }
            });
          }

          if (edu.profileResearches) {
            edu.profileResearches.forEach((research, researchIndex) => {
              if (research.attachFiles !== undefined && research.description === '') delete research.description;
              else if (research.attachFiles === undefined && research.description === '') edu.profileResearches?.splice(researchIndex, 1);
            });
          }
        });
        await profileEducationModel.saveEducation({ educations: [...educations], sourcePage }, isMatching);
      } else {
        await profileEducationModel.saveEducation({ educations: [], sourcePage }, isMatching);
      }
      setToastObject({ isOpen: true, message: '성공적으로 저장되었습니다.', type: 'SUCCESS' });
      setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
      setRegisterItemMode(false);
      await profileEducationModel.loadEducations(isMatching);
      educationsInit();
    } catch (e: any) {
      console.error(e);
      setToastObject({ isOpen: true, message: e.response.data.message || '저장에 실패하였습니다.', type: 'ERROR' });
    }
  };

  const onError = useCallback((data) => {
    console.error(data);
    setToastObject({ isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.', type: 'ERROR' });
  }, []);

  useEffect(() => {
    if (isOpen) {
      educationsInit();
      // profileResearches에서 ui 상으로 Append 한번 되야해서 reset 하자마자 isDirty가 true가 되는데 이를 방지하기 위한 코드
      setTimeout(() => {
        reset(getValues());
      }, 10);
    }
  }, [isOpen]);

  useEffect(() => {
    profileEducationModel.isDirty = formState.isDirty;
  }, [formState.isDirty]);

  useEffect(() => {
    if (registerItemMode) {
      profileEducationModel.isDirty = true;
    }
  }, [registerItemMode]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  }, [isError]);

  return !isOpen ? <></> : (
    <JDFullModal title='학력'>
      <Header>
        <Icon name='large-arrow-left' size={32} color={Colors.JOBDA_BLACK} onClick={onClickCloseBtn} />
      </Header>
      <FormFrame onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <EducationsFrame hidden={openRegisterFrame.open}>
            <ProfileEditTitleComponent
              title='등록된 학력 정보를 확인해 주세요.'
              subtitle='최근 활동 순으로 자동 정렬돼요.'
            />
            <div className='content'>
              { educationsFields.length > 0
                ? (
                  <>
                    <div className='title'>
                      <button
                        type='button'
                        className='delete-field'
                        disabled={educationsFields.length === 0}
                        onClick={() => {
                          if (registerItemMode) educationsInit();
                          setRegisterItemMode(!registerItemMode);
                        }}
                      >
                        <span>
                          { registerItemMode ? <></> : <Icon name='guide' size={24} color={Colors.JOBDA_BLACK} /> }
                          { registerItemMode ? '취소' : '편집' }
                        </span>
                      </button>
                    </div>
                    <SpacingBlock size={16} vertical />
                    <div className='register-item-frame'>
                      { educationsFields.map((educationsField, educationIndex) => (
                        <SingleMenuItem
                          key={educationsField.id}
                          title={educationsField.schoolName}
                          subTitle={makePeriodText(educationsField.startDate, educationsField.endDate, educationsField.educationType)}
                          onClick={registerItemMode
                            ? () => { onClickEducationItemDeleteBtn(educationIndex); }
                            : () => { setOpenRegisterFrame({ open: true, selectIndex: educationIndex, type: 'EDIT' }); }}
                          rightIcon={registerItemMode ? <Icon name='remove-filled' size={32} color={Colors.JOBDA_BLACK} /> : undefined}
                          error={isError}
                        />
                      ))}
                    </div>
                  </>
                )
                : (
                  <SingleMenuItem
                    title='직접 추가하기'
                    onClick={() => {
                      educationsAppend({ ...EduDefaultValue }, false);
                      setOpenRegisterFrame({ open: true, selectIndex: educationsFields.length, type: 'NEW' });
                      debounce(() => {
                        if (registerFrameRef.current) registerFrameRef.current.focus();
                      }, 500);
                    }}
                    leftIcon={<Icon name='add' size={32} color={Colors.JOBDA_BLACK} />}
                  />
                )}
            </div>
            { registerItemMode
              ? <JDSaveButton type='submit' fixed={false} />
              : educationsFields.length > 0 && (
                <BtnFrame>
                  <Button
                    label='학력 추가하기'
                    size='large'
                    buttonMode={ButtonMode.PRIMARY}
                    onClick={() => {
                      educationsAppend({ ...EduDefaultValue }, false);
                      setOpenRegisterFrame({ open: true, selectIndex: educationsFields.length, type: 'NEW' });
                      debounce(() => {
                        if (registerFrameRef.current) registerFrameRef.current.focus();
                      }, 500);
                    }}
                  />
                </BtnFrame>
              ) }
          </EducationsFrame>
          <EducationRegisterFrame hidden={!openRegisterFrame.open} ref={registerFrameRef}>
            <SpacingBlock size={16} vertical />
            <ProfileEditTitleComponent
              title='학교 정보를 등록해 주세요.'
              subtitle='학력 정보는 기업에 따라 선택적으로 활용돼요.'
              divider={false}
            />
            {educationsFields.map((educationsField, educationIndex) => (
              <div className='content' key={educationsField.id} hidden={openRegisterFrame.selectIndex !== educationIndex}>
                <FormProfileValueTitle required>학교 구분</FormProfileValueTitle>
                <FormValueFrame>
                  <JDSelector
                    type={JDSelectorType.NORMAL}
                    name={`educations[${educationIndex}].schoolLevel`}
                    selectTitle='학교 구분을 선택해 주세요.'
                    value={watch(`educations[${educationIndex}].schoolLevel`)}
                    onChange={() => {
                      setValue(`educations[${educationIndex}].schoolName`, undefined);
                      setValue(`educations[${educationIndex}].schoolCode`, undefined);
                    }}
                  >
                    <select>
                      <option value='' hidden>학교 구분을 선택해 주세요.</option>
                      {
                        Object.entries(EducationSchoolText).map(([key, text]) => <option key={`option-school-level-${educationIndex}-${key}`} value={key} selected={watch(`educations[${educationIndex}].schoolLevel`) === key}>{text}</option>)
                      }
                    </select>
                  </JDSelector>
                </FormValueFrame>
                <FormProfileValueTitle required>학교명</FormProfileValueTitle>
                <FormValueFrame>
                  <JDSearchInput
                    key={educationsField.id}
                    title='학교명'
                    isDirectItem
                    defaultTextName={educationsField.schoolName}
                    defaultCodeName={educationsField.schoolCode}
                    textName={`educations[${educationIndex}].schoolName`}
                    codeName={`educations[${educationIndex}].schoolCode`}
                    placeholder='학교를 검색해 주세요.'
                    disabled={!watch(`educations[${educationIndex}].schoolLevel`)}
                    onSearch={(searchText: string) => {
                      if (searchText === '') setSearchSchools([]);
                      else {
                        debounce(async () => {
                          await profileEducationModel.searchSchools(watch(`educations[${educationIndex}].schoolLevel`) as EducationLevel, searchText);
                          await setSearchSchools(profileEducationModel.schools);
                        }, 400);
                      }
                    }}
                    searchList={searchSchool}
                  />
                </FormValueFrame>
                <FormProfileValueTitle required>상태</FormProfileValueTitle>
                <EducationTypeFormValueFrame>
                  <JDSelector
                    type={JDSelectorType.NORMAL}
                    name={`educations[${educationIndex}].educationType`}
                    selectTitle='재학 상태'
                    value={watch(`educations[${educationIndex}].educationType`)}
                    onChange={(value?: string) => {
                      if (value !== EducationType.GRADUATION && value !== EducationType.DROP && value !== EducationType.GRADUATION_CANDIDATE) {
                        setValue(`educations[${educationIndex}].endDate`, undefined);
                        trigger(`educations[${educationIndex}].endDate`);
                      }
                    }}
                  >
                    <select>
                      <option value='' hidden>재학 상태</option>
                      {
                        Object.entries(EducationTypeText).map(([key, text]) => <option key={`option-school-level-${educationIndex}-${key}`} value={key} selected={watch(`educations[${educationIndex}].educationType`) === key}>{text}</option>)
                      }
                    </select>
                  </JDSelector>
                  <JDCheckInput
                    type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                    name={`educations[${educationIndex}].transferYn`}
                    value
                    label='편입'
                    defaultChecked={watch(`educations[${educationIndex}].transferYn`)}
                  />
                </EducationTypeFormValueFrame>
                <FormProfileValueTitle required>재학기간</FormProfileValueTitle>
                <DatePickerFormValueFrame>
                  <JDDatePickerInput
                    name={`educations[${educationIndex}].startDate`}
                    placeholder='연도. 월.'
                    defaultValue={educationsField.startDate}
                    month
                  />
                  <span className='date-picker-divider'>~</span>
                  <JDDatePickerInput
                    name={`educations[${educationIndex}].endDate`}
                    placeholder='연도. 월.'
                    defaultValue={educationsField.endDate}
                    month
                    disabled={watch(`educations[${educationIndex}].educationType`) !== EducationType.GRADUATION && watch(`educations[${educationIndex}].educationType`) !== EducationType.DROP && watch(`educations[${educationIndex}].educationType`) !== EducationType.GRADUATION_CANDIDATE}
                  />
                </DatePickerFormValueFrame>

                <ProfileEducationMajor
                  prefixName={`educations[${educationIndex}]`}
                  hidden={watch(`educations[${educationIndex}].schoolLevel`) === EducationLevel.HIGHSCHOOL}
                />

                <ProfileEducationResearches
                  prefixName={`educations[${educationIndex}]`}
                  hidden={watch(`educations[${educationIndex}].schoolLevel`) === EducationLevel.HIGHSCHOOL}
                />

              </div>
            ))}
            <JDSaveButton
              type='submit'
              fixed={false}
              register
            />
          </EducationRegisterFrame>
        </FormProvider>
      </FormFrame>
      <ProfileMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={async () => {
          if (openRegisterFrame.open) {
            if (openRegisterFrame.type === 'NEW') educationsRemove(educationsFields.length - 1);
            setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
            setIsDirtyModalOpen(false);
          } else {
            await profileEducationModel.loadEducations(isMatching);
            if (profileEducationModel.educations) {
              reset({ educations: profileEducationModel.educations || undefined });
            }
            setRegisterItemMode(false);
            if (onClose) {
              profileEducationModel.isDirty = false;
              setIsDirtyModalOpen(false);
              onClose();
            }
          }
        }}
        onClickClose={() => setIsDirtyModalOpen(false)}
      />

    </JDFullModal>
  );
};

export default inject(injectStore.profileEducationModel)(observer(ProfileEducation));
