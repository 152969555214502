import useToast from 'hooks/useToast';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import { IconPlus24 } from 'consts/assets/icons/iconPages';
import { debounce } from 'utils/CommonUtils';

import { SkillListItem } from 'components/_v2/profile/skill/ProfileSkill';
import { INameSnRs } from 'interfaces/rqrs/ICommonRqRs';
import { toJS } from 'mobx';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { IProfileBlindCompanyRs } from 'interfaces/rqrs/IProfileBlindCompanyRs';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import SpacingBlock from '__designkit__/components/SpacingBlock';

const BlindCompany = styled.div`
    padding:${SpaceValue.XL}px ${SpaceValue.L}px;
    .title {
    margin-bottom: 4x;
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};
    }
    .description {
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_60};
    margin-bottom: 24px;
    li {
        margin: 8px 0 0 2px;
    }
    }

    .frame-chips {
    display: flex;
    flex-wrap: wrap;

    > div {
        margin-right: 8px;
        margin-bottom: 8px;
    }
    }
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;
`;
interface IMatchApplyBlindCompany {
    matchApplyModel?: MatchApplyModel;
}
const MatchApplyBlindCompany :FC<IMatchApplyBlindCompany> = ({ matchApplyModel = new MatchApplyModel() }) => {
  const { setToastObject } = useToast();
  const [searchCompany, setSearchCompany] = useState<INameSnRs[]>([]);
  const [isActionBlindCompanies, setIsActionBlindCompanies] = useState<boolean>(false);
  const useFormed = useForm<IProfileBlindCompanyRs>({
    mode: 'all',
    defaultValues: {
      blindCompanies: [
        {
          name: undefined,
          sn: undefined,
        },
      ],
    },
  });
  const {
    register,
    control,
    watch,
    getValues,
    reset,
  } = useFormed;
  const matchCareerFields = useFieldArray({ control, name: 'blindCompanies' });

  useEffect(() => {
    const appendCompany = async () => {
      try {
        const inputData = getValues();
        if (inputData.blindCompanies) {
          const blindCompanySnList: number[] = inputData.blindCompanies.map(({ sn }) => Number(sn));
          matchApplyModel.blindCompany.blindCompanySnSet = blindCompanySnList;
        } else {
          matchApplyModel.blindCompany.blindCompanySnSet = [];
        }
        if ((matchApplyModel.blindCompany.blindCompanySnSet.length > 0 && inputData.blindCompanies[0].sn > 0) || matchApplyModel.blindCompany.blindCompanySnSet.length === 0) {
          await matchApplyModel.saveMyBlinds(matchApplyModel.blindCompany);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (isActionBlindCompanies) {
      appendCompany();
      setIsActionBlindCompanies(false);
    }
  }, [isActionBlindCompanies]);

  useEffect(() => {
    const init = async () => {
      try {
        matchApplyModel.blindCompany.blindCompanySnSet = [];
        await matchApplyModel.loadMyBlinds();
        if (matchApplyModel.myBlinds !== null) reset(toJS(matchApplyModel.myBlinds));
      } catch (e) {
        console.error(e);
      }
    };
    init();
    return (() => {
      matchApplyModel.terminate();
    });
  }, []);

  return (

    <>
      <FormProvider {...useFormed}>
        <BlindCompany>
          <div className='title'>프로필 열람 제한 기업</div>
          <SpacingBlock size={16} vertical />
          <Frame>
            <JDSearchInput
              className='input-search'
              textName='companyName'
              name='idx'
              codeName='companySn'
              placeholder='기업명을 검색해 주세요'
              listItemIcon={<IconPlus24 />}
              removeInputValue
              onSearch={async (searchText: string) => {
                debounce(async () => {
                  await matchApplyModel.searchCompanies(searchText);
                  await setSearchCompany(toJS(matchApplyModel.companySearchList));
                }, 400);
              }}
              searchList={[...searchCompany]}
              onSelectItem={async () => {
                const selectName = watch('companyName');
                const selectSn = watch('companySn');
                const found = matchCareerFields.fields.some(
                  ({ sn }) => Number(sn) === Number(selectSn),
                );
                if (found) {
                  setToastObject({ isOpen: true, type: 'ERROR', message: '이미 등록된 기업입니다.' });
                  return;
                }
                setIsActionBlindCompanies(true);
                matchCareerFields.append({
                  name: selectName,
                  sn: selectSn,
                });
              }}
            >
              <SkillListItem>
                {matchApplyModel.companySearchList.map((company, idx) => {
                  if (
                    searchCompany.some(
                      (field) => field.sn === company.sn,
                    )
                  )
                    return <></>;
                  return (
                    <li role='presentation' key={company.sn} value={idx}>
                      {company.name}
                    </li>
                  );
                })}
              </SkillListItem>
            </JDSearchInput>

            <ul className='description'>
              <li>
                열람 제한으로 등록한 기업에서는 내 프로필을 확인할 수 없어요.
              </li>
            </ul>
            <section className='frame-chips'>
              {matchCareerFields.fields.map((field, idx) => (
                <React.Fragment key={field.sn}>
                  <input
                    aria-hidden
                    ref={register()}
                    type='text'
                    name={`blindCompanies[${idx}].name`}
                    defaultValue={field.name || ''}
                  />
                  <input
                    aria-hidden
                    ref={register()}
                    type='text'
                    name={`blindCompanies[${idx}].sn`}
                    defaultValue={field.sn ?? -1}
                  />
                  <JDClosableChip
                    onClose={() => {
                      matchCareerFields.remove(idx);
                      setIsActionBlindCompanies(true);
                    }}
                  >
                    {field.name}
                  </JDClosableChip>
                </React.Fragment>
              ))}
            </section>
          </Frame>
        </BlindCompany>

      </FormProvider>
    </>
  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplyBlindCompany));
