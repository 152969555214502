import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import MatchProfile from 'assets/_v2/drawNavigation/icon_match_profile.svg';
import MatchTest from 'assets/_v2/drawNavigation/icon_match_test.svg';
const MatchingIconFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px 12px 16px;
    height: 90px;

    .icon-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 80px;
    }
`;

const MainMatching = () => {
  const history = useHistory();
  return (
    <MatchingIconFrame>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.match_profile)}>
        <Icon name='match-profile' size={42} />
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.default}
          ellipsis={1}
        >
          매칭 프로필
        </V2Text>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.match_test)}>
        <Icon name='match-test' size={42} />
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.default}
          ellipsis={1}
        >
          매칭 검사
        </V2Text>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.mypage_apply)}>
        <Icon name='resume' size={48} />
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.default}
          ellipsis={1}
        >
          지원현황
        </V2Text>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.match)}>
        <Icon name='matching' size={48} />
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.default}
          ellipsis={1}
        >
          매칭현황
        </V2Text>
      </button>
    </MatchingIconFrame>
  );
};

export default MainMatching;
