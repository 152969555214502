/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/media-has-caption */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import Loading from 'components/Loading';
import NoticeDialog from 'components/modals/NoticeDialog';
import URIs from 'consts/URIs';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import Slider from 'rc-slider'; // Slider와 Range 모두 확인 가능
import 'rc-slider/assets/index.css';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import request from 'utils/request';
import { inherits } from 'util';
import { ReactComponent as IconSpeaker } from './icon_speaker_light.svg';
import leftHandleIcon from './icon_trim_left.png';
import rightHandleIcon from './icon_trim_right.png';
import { ReactComponent as IconMute } from './icon_volume_mute_light.svg';
import IconPaused from './iconPaused.png';
import IconPlay from './iconPlay.png';
import { DialogType } from './VideoRecordGuideHorizontalModal';
import { VideoAndImagesType } from './UploadIntro';
import { VideoRecordNotice4 } from './VideoRecordNotice';

const MediaReviewWrapper = styled.div`
  position: relative;
  padding-top: 64px;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .videoContainer {
    position: relative;
    width: 100%;
    max-width: 800px; /* 가로 비율 고정 */
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9; /* 가로 세로 비율 */
    height: 220px;
    flex-direction: column;
      overflow: hidden;
    .video {
      width: 100%; /* 초기 width */
  height: 95%; /* 초기 height */
  transform-origin: center; /* 회전 중심점 설정 */
  transform: scaleX(-1) rotate(270deg);
  aspect-ratio: 9 / 16;
  scale:2;
    }
    
    }
  
    .progressContainer {
      padding: 0 12px;
      width: 100%;
      border-top: 1px solid #00b840;
      border-bottom: 1px solid #00b840;

      .rc-slider {
        height: 30px;
        background-color: #000;
        padding: 0;
        border-radius: 0;
      }
      .rc-slider-handle{
        border-radius: 0;
        border: 2px solid red;
      }

      .rc-slider-rail {
        height: 0;
      }
      .rc-slider-track {
        border-radius: 0;
      }
    }

    .controllBar {
      /* position: absolute;
      bottom: -40px; */
      display: flex;
      align-items: center;
      padding: 0 10px;
      gap: 16px;
      width: 100%;
      height: 50px;
      justify-content: space-between;
      z-index:1;
      
      .cover-area{
        display: flex;
        gap:16px;
        .playSetting {
        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .volumeArea {
        display: flex;
        align-items: center;
        .icon {
          width: 25px;
          height: 25px;
          color: #121619;
        }
        .volume {
          -webkit-appearance: none;

          width: 50px;
          opacity: 1;
          height: 3px;
          background: #121619;
          outline: none;
          cursor: pointer;
          color: #121619;
          &::-webkit-slider-thumb {
            -webkit-appearance: none;

            color: #121619;
            appearance: none;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background: #121619;
          }
        }
      }
      }
      

      .playTime {
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.0866667px;
        color: #121619;
        pointer-events: none;
      }
    }
  .desc-frame {
    display: flex;
    padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
    flex-direction: column;
  }

  .check-frame {
    width: 100%;
    padding: 0 ${SpaceValue.L}px ${SpaceValue.XXL}px ${SpaceValue.L}px;
  }
  .action-buttons {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XL}px
      ${SpaceValue.L}px;
    background-color: white;
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }
`;
interface IVideoRecordCheck {
  mediaBlobUrl: string | null;
  setMediaBlobUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  videoBlob: Blob | null;
  setVideoBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
  setIsVideoMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  videoType: VideoAndImagesType | null
}
const VideoRecordCheck: React.FC<IVideoRecordCheck> = ({
  setCurrentStep,
  videoBlob,
  setVideoBlob,
  setMediaBlobUrl,
  setIsVideoMoveOutModal,
  videoType,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const frameId = useRef<number>(0);
  const prevVolumeValue = useRef<number>(0.5);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // 동영상 전체 시간
  const [videoWidth, setVideoWidth] = useState(0); // 비디오 요소의 너비
  const [volume, setVolume] = useState(0.5);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [isSubmitModal, setIsSubmitModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistComplete, setIsChecklistComplete] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // 🔥 초기화
      videoRef.current.src = URL.createObjectURL(videoBlob as Blob);
    }
  }, [videoBlob]);

  const trimVideo = async (onTrimmed: (trimmedBlob: Blob) => void) => {
    if (!videoBlob || !videoRef.current) return;

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(videoBlob);

    await new Promise((resolve) => {
      videoElement.onloadedmetadata = resolve;
    });

    const { duration } = videoElement;
    const startTime = (startPoint / 10) * duration; // startPoint → 초 단위
    const endTime = (endPoint / 10) * duration; // endPoint → 초 단위

    if (endTime <= startTime) {
      alert('Invalid trim range.');
      return;
    }
    const stream = (videoElement as any).captureStream();
    if (stream.getVideoTracks().length === 0) {
      return;
    }
    const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/mp4; codecs=vp9' });
    const chunks: Blob[] = [];

    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    mediaRecorder.onstop = () => {
      const trimmedBlob = new Blob(chunks, { type: 'video/mp4' });
      setVideoBlob(trimmedBlob); // 상태 업데이트
      onTrimmed(trimmedBlob); // 잘라낸 동영상 전달
    };

    mediaRecorder.start();
    console.log('🎥 MediaRecorder started. Current state:', mediaRecorder.state);

    videoElement.currentTime = startTime;
    videoElement.play().then(() => {
      console.log('✅ 비디오 재생 시작됨');
    }).catch((err) => {
      console.error('❌ 비디오 재생 실패:', err);
    });

    setTimeout(() => {
      mediaRecorder.stop();
    }, (endTime - startTime) * 1000); // 자를 구간에 따라 시간 설정
  };
  const uploadVideo = async () => {
    setIsLoading(true);
    if (!videoBlob) {
      alert('No video to upload!');
      return;
    }
    console.log('🎥 Trim 완료:', videoBlob.size, 'bytes');

    // 🔥 Step 2: 밝기 필터 적용 (MP4 유지)
    // const brightBlob = await applyBrightnessFilter(videoBlob, 1.2);
    // console.log('🎨 밝기 적용 완료:', brightBlob.size, 'bytes');

    // 🔥 Step 3: File 변환 후 업로드 준비
    const videoFile = new File([videoBlob], `processed-video.mp4`, { type: 'video/mp4' });

    const formData = new FormData();
    formData.append('multipartFile', videoFile);
    formData.append('startTime', String(startPoint));
    formData.append('endTime', String(endPoint));
    if (videoType && videoType === VideoAndImagesType.MATCHING_SELF_INTRODUCTION_VIDEO) formData.append('type', VideoAndImagesType.MATCHING_SELF_INTRODUCTION_VIDEO);
    else formData.append('type', VideoAndImagesType.MATCHING_MOTIVATION_VIDEO);

    try {
      const response = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_v1_match_applicants_video,
        data: formData,
      });

      if (response.fileUid) {
        console.log('✅ Video uploaded:', response.fileUid);
        setMediaBlobUrl(null);
        setVideoBlob(null);
        setIsLoading(false);
        setCurrentStep(0); // 업로드 완료 후 다음 단계로 이동
      } else {
        console.error('❌ Upload failed:', response);
        alert('An error occurred while uploading the video.');
      }
    } catch (error) {
      console.error('❌ Upload error:', error);
      alert('An error occurred while uploading the video.');
    }
    // trimVideo(async (trimmedBlob) => {

    // });
  };

  // 재촬영 함수
  const retryRecording = () => {
    setIsVideoMoveOutModal(true);
  };

  // 재생 버튼 클릭 함수
  const handleClickPlayPaused = async () => {
    if (!videoRef.current) return;
    if (!playing) {
      setPlaying(true);
      videoRef.current.play();
    } else {
      setPlaying(false);
      videoRef.current.pause();
    }
  };

  // 시간 변환 함수
  const secondsToMinSec = (sec: number): string => {
    const zeroFiled = (value: number) => (value < 10 ? `0${value}` : value);
    const tempSeconds = sec % 60;
    const minute = Math.floor(sec / 60);

    // 소수 첫째 자리로 초 계산
    const formattedSeconds = (tempSeconds % 60).toFixed(0);

    return `${zeroFiled(minute)}:${zeroFiled(Number(formattedSeconds))}`;
  };

  // Volume Change
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newVolume = Number(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) videoRef.current.volume = newVolume;
    prevVolumeValue.current = newVolume;
  };

  // Volume Icon Click
  const handleVolumeOnOff = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!videoRef.current) return;

    if (!volume) {
      const volume = prevVolumeValue.current ? prevVolumeValue.current : 0.5;
      videoRef.current.volume = volume;
      setVolume(prevVolumeValue.current ? prevVolumeValue.current : 0.5);
    } else {
      // 이전 volume값 저장
      prevVolumeValue.current = volume;
      videoRef.current.volume = 0;
      setVolume(0);
    }
  };

  // 동영상 전체시간 구하는 함수
  const addTimeUpdateHandler = async () => {
    const videoElement = document.createElement('video');
    if (typeof videoBlob === 'string') {
      videoElement.src = videoBlob;
    } else if (videoBlob !== null)
      videoElement.src = URL.createObjectURL(videoBlob as MediaSource | Blob);
    if (videoElement) {
      const handleLoaded = () => {
        if (videoElement.duration === Infinity) {
          videoElement.currentTime = Number.MAX_SAFE_INTEGER; // video 재생 헤드를 최대로 이동시킨다. 더 많은 비디오를 로드하여 지속 시간을 계산할 수 있도록 한다.
          videoElement.ontimeupdate = () => {
            videoElement.ontimeupdate = null; // 반복 실행을 방지하기 위해 이벤트 핸들러를 제거한다.
            setDuration(videoElement.duration);
            setEndPoint(videoElement.duration);
            // video 메타데이터 로드 시
            videoElement.removeEventListener('loadedmetadata', handleLoaded);
          };
        } else {
          videoElement.removeEventListener('loadedmetadata', handleLoaded);
          setDuration(videoElement.duration);
          setEndPoint(videoElement.duration);
        }
      };
      videoElement.addEventListener('loadedmetadata', handleLoaded);
    }
  };

  // video 현재 재생시간 업데이트 함수
  const timeUpdate = () => {
    if (frameId.current) cancelAnimationFrame(frameId.current);
    setCurrentTime(videoRef.current?.currentTime ?? 0);

    if (playing) frameId.current = requestAnimationFrame(timeUpdate);
    else frameId.current && cancelAnimationFrame(frameId.current);
  };

  // currentTime 업데이트
  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.addEventListener('timeupdate', timeUpdate);
    setVideoWidth(videoRef.current.clientWidth);
    const handleResize = () => {
      if (videoRef.current) {
        setVideoWidth(videoRef.current.clientWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      if (!videoRef.current) return;
      window.removeEventListener('resize', handleResize);
      videoRef.current.removeEventListener('timeupdate', timeUpdate);
    };
  }, [currentTime, videoRef.current, playing]);

  // videoRef 할당
  useEffect(() => {
    addTimeUpdateHandler();
    if (!videoRef.current || !videoBlob) return;

    if (typeof videoBlob === 'string') videoRef.current.src = videoBlob;
    else
      videoRef.current.src = URL.createObjectURL(
        videoBlob as MediaSource | Blob,
      );
  }, [videoBlob]);

  const handleChange = (value: any) => {
    setStartPoint(value[0]);
    setEndPoint(value[1]);
  };

  // startPoint 변경시 video currentTime 변경
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = startPoint;
      setCurrentTime(startPoint);
    }
  }, [startPoint, videoRef.current]);

  // endPoint 변경시 video 종료시점 변경
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onplay = () => {
        if (videoRef.current && videoRef.current.currentTime >= endPoint) {
          videoRef.current.pause();
          videoRef.current.currentTime = startPoint;
          videoRef.current.play();
        }
      };

      videoRef.current.ontimeupdate = () => {
        if (videoRef.current && videoRef.current.currentTime >= endPoint) {
          videoRef.current.pause(); // endPoint에 도달하면 비디오를 멈춥니다.

          setPlaying(false); // 상태를 정지로 업데이트
        }
      };
    }
  }, [endPoint, videoRef.current, startPoint]);

  if (isLoading) return <Loading />;
  if (videoBlob) {
    return (
      <MediaReviewWrapper>
        <div className='videoContainer' role='button'>
          <video
            ref={videoRef}
            className='video'
            playsInline
            controls={false}
            preload='auto'
            loop
            onEnded={() => setPlaying(false)}
          >
            <source src={`${URL.createObjectURL(videoBlob as Blob)}#t=0.001`} type='video/mp4' />
          </video>
        </div>

        <div className='controllBar'>
          <div className='cover-area'>
            <button className='playSetting' onClick={handleClickPlayPaused}>
              {!playing ? (
                <img
                  className='icon'
                  src={IconPlay}
                  alt='play'
                  width={40}
                  height={40}
                />
              ) : (
                <img
                  className='icon'
                  src={IconPaused}
                  alt='paused'
                  width={40}
                  height={40}
                />
              )}
            </button>
            {/* <div className='volumeArea'>
              <button onClick={handleVolumeOnOff}>
                {volume ? (
                  <IconSpeaker className='icon' fill='black' />
                ) : (
                  <IconMute className='icon' fill='black' />
                )}
              </button>
              <input
                className='volume'
                type='range'
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
              />
            </div> */}
          </div>
          <div className='playTime'>
            {`${secondsToMinSec(currentTime)} / 
          ${secondsToMinSec(endPoint || duration)}`}
          </div>
        </div>
        <div className='progressContainer'>
          {/* 슬라이더를 제어하는 부분. 현재 handleStyle의 backgroundImage 첫번쨰와 세번째가 트림 컨트롤러인데 이것의 위치를 밑으로 내려놓은 상태 */}
          {duration > 0 ? ( // duration 값이 0보다 클 때만 Slider 렌더링
            <Slider
              range
              step={0.1}
              min={0}
              pushable={0.001}
              max={duration}
              value={[startPoint, currentTime, endPoint]}
              trackStyle={[
                { backgroundColor: 'gray', height: 30 }, // ✅ 재생된 부분 (startPoint ~ currentTime, 진한 녹색)
                { backgroundColor: 'lightgray', height: 30 }, // ✅ 트림된 구간 전체 (연한 녹색)
              ]}
              handleStyle={[
                { backgroundImage: `url(${leftHandleIcon})`, backgroundSize: 'cover', width: 24, height: 24, marginTop: 30 }, // 좌측 트림 핸들
                { backgroundColor: '#ff0000', width: 2, height: '100%', marginTop: 0, borderRadius: 0, border: 0 }, // 현재 재생 위치 핸들 (진한 녹색)
                { backgroundImage: `url(${rightHandleIcon})`, backgroundSize: 'cover', width: 24, height: 24, marginTop: 30 }, // 우측 트림 핸들
              ]}
              onChange={(value) => {
                const [newStart, newCurrent, newEnd] = value as [number, number, number];

                // 왼쪽 트림 핸들
                if (newStart > currentTime) {
                  setStartPoint(newStart);
                  setCurrentTime(newStart);
                  if (videoRef.current) videoRef.current.currentTime = newStart;
                } else {
                  setStartPoint(newStart);
                }

                // 오른쪽 트림 핸들
                if (newEnd < currentTime) {
                  setEndPoint(newEnd);
                  setCurrentTime(newEnd);
                  if (videoRef.current) videoRef.current.currentTime = newEnd;
                } else {
                  setEndPoint(newEnd);
                }

                // 현재 재생 핸들
                if (newCurrent >= newStart && newCurrent <= newEnd) {
                  setCurrentTime(newCurrent);
                  if (videoRef.current) videoRef.current.currentTime = newCurrent;
                }
              }}
            />

          ) : (
            <div>Loading...</div> // duration 값이 아직 설정되지 않았을 때 표시
          )}
        </div>
        {/* <div className='brightnessControl'>
            <label htmlFor='brightness'>밝기 조정</label>
            <input
              id='brightness'
              type='range'
              min='0.5'
              max='2.0'
              step='0.1'
              value={brightness}
              onChange={(e) => setBrightness(parseFloat(e.target.value))}
            />
          </div> */}

        <SpacingBlock vertical size={12} />

        <div className='desc-frame' style={{ width: '100%' }}>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_3_sb}
            color={V2TextOption.color.brand}
          >
            영상 가이드
          </V2Text>
          <SpacingBlock vertical size={4} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.title_1_b}
            color={V2TextOption.color.default}
          >
            영상 확인
          </V2Text>
          <SpacingBlock vertical size={12} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.danger}
          >
            영상은 최소 50초 이상의 답변내용이 포함되어있어야 하며, 시간이 부족할 경우 불합격기준에 해당됩니다.
          </V2Text>
          <SpacingBlock vertical size={12} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.subtle}
          >
            답변내용에 불필요한 영상장면을 편집해주세요.
          </V2Text>
          <SpacingBlock vertical size={8} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.subtle}
          >
            편집 기능을 활용하여, 영상을 재생해보며
            <br />
            영상 시작과 종료 부분을 확인 후 편집해주시면됩니다.
            <br />
            (Ex. 시작 후 5초 , 종료 전 5초)
          </V2Text>
          <SpacingBlock vertical size={12} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_m}
            color={V2TextOption.color.subtle}
          >
            최종 제출 후 수정은 불가능합니다.
          </V2Text>
        </div>
        <div className='check-frame'>
          <VideoRecordNotice4 onChecklistChange={setIsChecklistComplete} />
        </div>
        <div className='action-buttons'>
          <V2Button
            type={V2ButtonOption.type.OutLine}
            size={V2ButtonOption.size.L}
            buttonProps={{ onClick: retryRecording }}
            fill={V2ButtonOption.fillType.neutral_subtle_default}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.subtle}
            styles={{ width: '100%' }}
          >
            재촬영
          </V2Button>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            buttonProps={{ onClick: setIsSubmitModal.bind(null, true), disabled: !isChecklistComplete }}
            fill={V2ButtonOption.fillType.brand_strong_default}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            styles={{ width: '100%' }}
          >
            다음
          </V2Button>
        </div>
        <NoticeDialog
          dialogType={DialogType.NOTICE}
          title='최종제출 하시겠습니까?'
          content='최종제출 후 수정은 불가능합니다.'
          isOpen={isSubmitModal}
          onOk={() => {
            uploadVideo();
          }}
          onCancel={() => setIsSubmitModal(false)}
          firstButtonText='제출하기'
          secondButtonText='나가기'
        />
      </MediaReviewWrapper>
    );
  }
  return <></>;
};

export default VideoRecordCheck;
