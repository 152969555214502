import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import TabsWithComponent from '__designkit__/tab/TabsWithComponent';
import IconInfo from 'assets/_v2/_common/icon_information_circle_15_Regular_grey.svg';
import IconResults from 'assets/_v2/acc/icon_acc_results.png';
import ImgBlock from 'assets/_v2/acc/img_acca_block_dummy.png';
import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import JDALink from 'components/JDALink';
import Loading from 'components/Loading';
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import AccApplyVideoCheck from 'components/_v2/acc//AccApplyVideoCheck';
import AccApplyTotalReport from 'components/_v2/acc/AccApplyTotalReport';
import AccPstGrade from 'components/_v2/acc/AccPstGrade';
import ErrorCode from 'consts/ErrorCodes';
import { PhsAccType } from 'consts/MatchingMessageType';
import RoutePaths from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import shadows from 'consts/style/shadows';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MyPagePartnershipModel from 'models/MyPagePartnershipModel';
import AccResultsModel from 'models/_v2/AccResultsModel';
import EventChuseokModel from 'models/_v2/event/EventChuseokModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

const TitleFrame = styled.div`
  width: 100%;
  height:56px;
  display: flex;
  padding-top:17px;
  justify-content:center;
  box-shadow: inset 0px -0.5px 0.5px rgba(207, 209, 213, 0.5);

  .header-title{
    font: ${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
  }
`;

const ContentTab = styled.div`
  position: sticky;
  z-index: 10002;
  transition: top .25s, background .25s;
  top: ${(props) => (!(props as any)['data-hide'] ? '57' : '0')}px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:stretch;
  width:100%;
  height:40px;
  overflow-x:auto;
  white-space:pre;
  margin-top: 8px;
  padding: 0 16px;
  background: ${colors.WHITE_100};
  ${shadows.headers};

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  position:relative;
  font: ${Fonts.B2_Bold};
  line-height: 17px;
  padding-top: 8px;
  padding-bottom: 14px;
  color: ${colors.CG_60};
  margin-right: 32px;
  display: flex;
  align-items: center;

  &[aria-current=true] {
    color: ${colors.JOBDA_BLACK};
  }

  &[aria-current=true]:before {
    display:block;
    position:absolute;
    content:'';
    bottom:0;
    left:0;
    width:100%;
    height:2px;
    background:${colors.JOBDA_BLACK};
  }
`;

const ContentFrame = styled.div`
  padding:16px;
`;

const ResultsBox = styled.div`
  width:100%;
  height:84px;
  display: flex;
  padding: 18px 16px;
  justify-content: space-between;
  border-radius: 4px;
  background:${colors.JOBDA_BLACK};
  align-items: center;
`;

const NameContent = styled.div` 
  display:flex;

  .name-text{
    align-self: center;
    margin-left: 18px;
    font: ${Fonts.B1_Bold};
    line-height: 21px;
    color:${colors.WHITE_100};
  }
  
  .icon-results{
    width:48px;
    height:48px;
  }
`;

const CopyRightFrame = styled.div`
  display: flex;
  padding:0 16px 24px 16px;
  color:${colors.CG_60};
  position: absolute;
  padding-bottom: calc(40px + env(safe-area-inset-bottom));
  margin-top: 16px;
  .icon-info{
    width:16px;
    height:16px;
    margin-right: 6px;
  }

  .text-info{
    font: ${Fonts.B2_Medium};
    line-height: 20px;
  }
`;

const ErrorFrame = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width:100%;
  height:85vh;

  .joy-img{
    width:100px;
    height:100px;
  }

  .joy-text{
    margin-top: 32px;
    font: ${Fonts.H5_Bold};
    line-height: 23px;
    text-align:center;
  }

  .link-text{
    margin-top: 16px;
    font: ${Fonts.B2_Medium};
    line-height: 20px;
    color:${colors.B_90};
    text-decoration:underline;
    text-align:center;
  }
`;

const BlockFrame = styled.div<{isDummy?:boolean}>`
  width: 100%;
  height:100%;
  ${(props) => (props.isDummy ? css`
    background-image: url(${ImgBlock});
  ` : css`
    background-color: ${colors.WHITE_100};
  `)}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const V3Frame = styled.div`
  width: 100%;
  height:100%;

  .info{
    padding:16px;

    > h1{
      font: ${Fonts.B3_Bold_P};
      color:${colors.JOBDA_BLACK};
    }
  }
`;

const StyledIFrame = styled.iframe`
  position: relative; height: 100%; width: 100%;
  touch-action:pinch-zoom;
`;

const IFrame: FC<{ src: string }> = ({ src }) => (
  <StyledIFrame
    className='pinch-frame'
    src={src}
    allow='autoplay; fullscreen; picture-in-picture'
  />
);

export interface IAccResultModal {
  sn: number;
  type: PhsAccType;
  isOpen?: boolean;
  onClose?: () => void;
  item?: IAccProgressCompleteDto; // v3 결과표 확인할때 필요
  login?: Login;
  accResultsModel?: AccResultsModel;
  myPagePartnershipModel?: MyPagePartnershipModel;
  eventChuseokModal?:EventChuseokModel;
}

const AccResultModal: FC<IAccResultModal> = ({ type, item, sn, isOpen, onClose, myPagePartnershipModel = new MyPagePartnershipModel(), login = new Login(), accResultsModel = new AccResultsModel(), eventChuseokModal = new EventChuseokModel() }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [errorState, setErrorState] = useState<number>(0);
  const [isRecommendServiceBlock, setIsRecommendServiceBlock] = useState<boolean>(false);
  const [isPartnerServiceBlock, setIsPartnerServiceBlock] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean | null>(null);
  const HolidayEventType = 'TALENT_POOL_HOLIDAY_2023_10';

  const history = useHistory();
  const TabTitle = [
    {
      code: 0,
      name: '종합 리포트',
    },
    {
      code: 1,
      name: '영상 확인',
    },
  ];

  const switchTab = (idx: number) => {
    setCurrentTab(idx);
  };

  const checkRecommendService = async () => {
    if (!(login.matchConfigStatus?.matchingConfigYn && login.matchConfigStatus?.matchingConfigCondition.profileRequiredCompleteYn))
      setIsRecommendServiceBlock(true);
  };
  const loadAccData = async () => {
    try {
      await accResultsModel.loadApplyResult(sn, type);
      await accResultsModel.loadGame(sn, type);
      await accResultsModel.loadSelfReport(sn, type);
      await accResultsModel.loadVideo(sn, type);
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B002:
          setErrorState(2);
          break;
        case ErrorCode.B901:
          setErrorState(901);
          break;
        case ErrorCode.B902:
          setErrorState(902);
          break;
        case ErrorCode.B903:
          setErrorState(903);
          break;
        default:
          break;
      }
    }
  };

  const onClickClose = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      if (sn === 0 || type === PhsAccType.PST) return;
      await login.getMatchConfigStatus();
      if (type === PhsAccType.PACC) {
        await checkRecommendService();
        await loadAccData();
      } else if (type === PhsAccType.ACC) {
        if (item === undefined) {
          await checkRecommendService();
          await loadAccData();
        } else if (item.version === 'V1' || item.version === 'V2') {
          await checkRecommendService();
          await loadAccData();
        } else if (item.version === 'V3') {
          await checkRecommendService();
          await accResultsModel.loadApplyResultToken(item.code);
          await myPagePartnershipModel.loadPartnershipUserInfo();
          setIsPartnerServiceBlock(!((myPagePartnershipModel.userInfo?.organizationVerified || myPagePartnershipModel.userInfo?.universityVerified)));

          // 모든 조건 체크 (회원이면 필수조건 입력 안해도 결과표 볼수있다.)
          if ((login.matchConfigStatus?.matchingConfigYn && (login.matchConfigStatus.generalMatchingConfigAvailableYn || login.matchConfigStatus.developerMatchingConfigAvailableYn))
          || myPagePartnershipModel.userInfo?.organizationVerified || myPagePartnershipModel.userInfo?.universityVerified) {
            setIsRecommendServiceBlock(false);
          }
        }
      }
      await eventChuseokModal.loadEventsParticipationStatus(HolidayEventType);
      if (eventChuseokModal.eventParticipationStatus?.isParticipation && !eventChuseokModal.eventParticipationStatus.isEnded) {
        setIsPartnerServiceBlock(false);
      }
      setLoading(false);
    };
    if (isOpen) init();
  }, [sn, isOpen, item]);

  const renderV1V2 = () => (
    <div>
      { accResultsModel.game && accResultsModel.video && accResultsModel.applyResult && accResultsModel.selfReport && errorState === 0 ? (
        <div>
          <ContentFrame>
            <ResultsBox>
              <NameContent>
                <img src={IconResults} className='icon-results' alt='' />
                <div className='name-text'>{accResultsModel.applyResult.userName}님의<br /> 종합 응시결과</div>
              </NameContent>
              <AccPstGrade accGrade={accResultsModel.applyResult.resultType} type={PhsAccType.ACC} />
            </ResultsBox>
          </ContentFrame>
          <ContentTab data-hide>
            { TabTitle.map(({ name }, idx) => <Tab onClick={() => switchTab(idx)} aria-current={currentTab === idx}>{name}</Tab>)}
          </ContentTab>
          { isRecommendServiceBlock ? <BlockFrame isDummy /> : (
            <div>
              <AccApplyTotalReport hidden={!(TabTitle[0].code === currentTab)} />
              <AccApplyVideoCheck hidden={!(TabTitle[1].code === currentTab)} />
            </div>
          )}
          <CopyRightFrame>
            <img src={IconInfo} className='icon-info' alt='' />
            <div className='text-info'>역량검사 리포트(역량검사결과표)는 잡다의 소유이며, 무단 재배포 시 법적 불이익을 받을 수 있습니다.</div>
          </CopyRightFrame>
        </div>
      ) : (
        <div>
          <ErrorFrame>
            <img src={ImgJoyEmpty} alt='' className='joy-img' />
            { errorState === 2 ? <div className='joy-text'>응시 결과가 삭제되어 <br /> 확인이 불가능해요.</div> : (
              errorState === 901 ? <div className='joy-text'> 응시 결과가 삭제되어 <br /> 확인이 불가능해요.</div> : (
                errorState === 902 ? <div className='joy-text'> 응시 결과를 분석중이에요! <br /> 결과 분석은 최대 1일이 <br /> 소요될 수 있어요.</div> : (
                  <div className='joy-text'>   응시 결과 분석에 실패했어요.<br /> 오류가 지속될 경우 <br /> 헬프데스크로 문의 해 주세요.</div>)))}
            { errorState === 903 && <a className='link-text' href={`mailto:${SupportMail}`}>{SupportMail}</a> }
          </ErrorFrame>
        </div>
      )}
      <JDSimpleInfoModal
        title={`프로필을 입력하면\n응시 분석결과를 볼 수 있어요.`}
        descriptionsTag={(
          <h1 className='descriptionsTag'><JDALink to={RoutePaths.profile}>[내 프로필]에서</JDALink> 기본, 선호, 학력만 입력하면 끝!<br />
            <JDALink to={RoutePaths.profile}>잡다매칭 신청</JDALink> 상태가 ON이어야 합니다.
          </h1>
        )}
        btnText='1분만에 프로필 입력하기'
        isOpen={isRecommendServiceBlock}
        isDimClickClose
        handleClick={() => history.push(RoutePaths.profile)}
        onClickClose={() => {
          setIsRecommendServiceBlock(false);
          if (onClose) onClose();
        }}
      />
    </div>
  );

  const renderV3 = () => (
    <V3Frame>
      <TabsWithComponent
        tabs={['응시 점검결과', '기본 분석 결과', '심층 분석 결과']}
        components={
          [
            <>
              <div className='info'>
                <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
              </div>
              { isRecommendServiceBlock ? <BlockFrame /> : <IFrame src={`${accResultsModel.applyResultToken?.url}/acca/examinee-result-for-examinee?Authorization=Bearer${accResultsModel.applyResultToken?.token}&requestPage=check`} /> }
              <JDSimpleInfoModal
                title={`매칭 제안받기 상태가 ON이어야\n응시 결과를 확인할 수 있어요.`}
                btnText='매칭 제안 받기'
                isOpen={isRecommendServiceBlock}
                isDimClickClose
                // isBackContentClickable // 제휴서비스를 제외하고는 굳이 못누르게 한다.
                handleClick={() => history.push(RoutePaths.match)}
                onClickClose={() => {
                  setIsRecommendServiceBlock(false);
                  if (onClose) onClose();
                }}
              />
            </>,
            <>
              <div className='info'>
                <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
              </div>
              { isRecommendServiceBlock ? <BlockFrame /> : <IFrame src={`${accResultsModel.applyResultToken?.url}/acca/examinee-result-for-examinee?Authorization=Bearer${accResultsModel.applyResultToken?.token}&requestPage=basic`} /> }
              <JDSimpleInfoModal
                title={`프로필을 입력하면\n응시 분석결과를 볼 수 있어요.`}
                btnText='1분만에 프로필 입력하기'
                isOpen={isRecommendServiceBlock}
                isDimClickClose
                // isBackContentClickable
                handleClick={() => history.push(RoutePaths.profile)}
                onClickClose={() => {
                  setIsRecommendServiceBlock(false);
                  if (onClose) onClose();
                }}
              />
            </>,
            <>
              <div className='info'>
                <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
              </div>
              { isPartnerServiceBlock ? <BlockFrame /> : <IFrame src={`${accResultsModel.applyResultToken?.url}/acca/examinee-result-for-examinee?Authorization=Bearer${accResultsModel.applyResultToken?.token}&requestPage=deep`} /> }
              <JDSimpleInfoModal
                title={`제휴서비스를 신청하면\n역량 분석결과를 볼 수 있어요.`}
                btnText='제휴서비스 신청하기'
                isOpen={isPartnerServiceBlock}
                isBackContentClickable
                isDimCloseBtn
                isDimClickClose={false}
                handleClick={() => history.push(RoutePaths.mypage_partnership)}
                onClickClose={() => setIsPartnerServiceBlock(false)}
              />
            </>,
          ]
        }
      />
    </V3Frame>
  );

  return (
    <JDFullScreenBaseModal customZIndex isOpen={isOpen} onClose={onClickClose}>
      <TitleFrame>
        <div className='header-title'>결과표 확인</div>
      </TitleFrame>
      { loading ? <Loading />
        : (type === PhsAccType.PACC || item === undefined || item.version === 'V1' || item.version === 'V2') ? (renderV1V2()) : renderV3() }
    </JDFullScreenBaseModal>
  );
};

export default inject(injectStore.accResultsModel, injectStore.eventChuseokModel, injectStore.login, injectStore.myPagePartnershipModel)(observer(AccResultModal));
