const SOCIAL_TYPE = {
  KAKAO: 'KAKAO',
  GOOGLE: 'GOOGLE',
} as const;

export type SocialType = typeof SOCIAL_TYPE[keyof typeof SOCIAL_TYPE];

export interface IRequestVerificationCodeRq {
  name: string;
  birthDate: string;
  countryCode: string;
  mobile: string;
}

export interface ICheckVerificationCodeRq {
  countryCode: string;
  mobile: string;
  token: string;
}

export interface ICheckDuplicateIdRq {
  id: string;
}

interface IJoinBasic {
  name: string;
  birthDate: string;
  genderFlag: string;
  mobile: string;
  countryCode: string;
}

interface IJoinAccount {
  email: string;
  id: string;
  password: string;
}

interface IJoinTerms {
  agreeTermsOfServiceYn: boolean;
  agreePrivacyCollectionYn: boolean;
  marketingConsentYn: boolean;
  marketingSmsConsentYn: boolean;
  marketingEmailConsentYn: boolean;
}

interface IJoinSocial {
  openId: string | null;
  openIdProvider: SocialType | null;
}

export enum InflowType {
  JOBDA = 'JOBDA',
  JF3 = 'JF3',
}
export interface IJoinRq {
  basic: IJoinBasic;
  account: IJoinAccount;
  terms: IJoinTerms;
  social: IJoinSocial,
  mobileToken: string,
  utm: string,
  inflow: InflowType,
}

export interface IGetCheckMobileDuplicateRq {
  mobile: string;
  countryCode: string;
}

export interface ISuccessOrNotRs {
  success: boolean;
}

export interface IDuplicateOrNotRs {
  isDuplicate: boolean;
}

export interface IResultOrNotRs {
  result: boolean;
}

export interface IGetCheckMobileDuplicateRs {
  maskedUserId:string | null;
  createdDateTime:string | null;
}
