import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import styled from 'styled-components';
import { useState } from 'react';
import HxDetailModal from './HxDetailModal';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding:64px ${SpaceValue.L}px;
  .modal-container{
    display: flex;
    padding:5px 0 5px 6px;
    gap:2px;
    margin-bottom: 12px;
  }
  .divider{
    width: 100%;
    height: 1px;
    background-color: ${V2BorderColors.default};
    margin: ${SpaceValue.M}px 0;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    margin-top: 20px;
  }
`;

interface IHxKeyFeatureProps {
  title: string;
  content: string;
  contentList?: string[];
  image?: string;
  isModal?: boolean;
}
const HxKeyFeature = ({ title, content, contentList, image, isModal = false }: IHxKeyFeatureProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Frame>
      <V2Text styles={{ textAlign: 'center', whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.strong}>{title}</V2Text>
      <SpacingBlock size={16} vertical />
      <V2Text styles={{ textAlign: 'center', whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_1_m} color={V2TextOption.color.default}>
        {content}
      </V2Text>
      <SpacingBlock size={SpaceValue.XL} vertical />
      {
        isModal && (
        <div className='modal-container' onClick={() => setIsOpen(true)} role='button'>
          <V2Text fontStyle={V2TextOption.fontStyle.body_2_sb} color={V2TextOption.color.subtle}>
            H.X 추천 정확도 확인하기
          </V2Text>
          <V2Icon name={V2IconOption.name.arrowRight} size={V2IconOption.size.XS} fill={V2IconOption.fill.subtle} />
        </div>
        )
      }
      {
        contentList?.map((item) => (
          <>
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>
              {item}
            </V2Text>
            <div className='divider' />
          </>
        ))
      }
      {
        image && (
          <img src={image} alt='key' />
        )
      }
      <HxDetailModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />

    </Frame>
  );
};

export default HxKeyFeature;
