import IconClose from 'assets/icon/icon_close_24.svg';
import { IPositionCurationList } from 'components/_v2/position/curation/PositionCurationList';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { ErrorCodes } from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import PositionCurationModel from 'models/_v2/PositionCurationModel';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ImgInfo from 'assets/_v2/positionList/img_recommend_error.png';

const Frame = styled.div`
  width:312px;
  height: 396px;
  display: flex;
  flex-direction: column;
  background: ${colors.WHITE_100};
  border-radius: 12px;
  padding:24px;
  .icon-close{
    align-self: flex-end;
    margin-bottom: 8px;
    height:fit-content;
  }
  h1{
    font: ${Fonts.H5_Bold};
    ${colors.JOBDA_BLACK};
    text-align: center;
  }

  h2{
    margin-top:8px;
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};
    margin-bottom:24px;
    text-align: center;
  }

  .img-info{
    margin-bottom:24px;
  }

  .match-home-btn{
    padding: 14px 22px 14px 22px;
    width: 100%;
    border-radius: 4px;
    font: ${Fonts.B2_Bold};
    text-align: center;
    color:${colors.WHITE_100};
    background: ${colors.JOBDA_BLACK};
  }
`;

const PostionRecommendErrorModal: FC<IPositionCurationList> = ({ login = new Login(), positionCurationModel = new PositionCurationModel() }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (positionCurationModel.recommendPositionError === ErrorCodes.get_curations_positions.B902
      || positionCurationModel.recommendPositionError === ErrorCodes.get_curations_positions.B903
      || positionCurationModel.recommendPositionError === ErrorCodes.get_curations_positions.B904) setIsOpen(true);
    else setIsOpen(false);
  }, [positionCurationModel.recommendPositionError]);

  const onClose = () => {
    setIsOpen(false);
    positionCurationModel.recommendPositionError = '';
  };

  return (
    <JDBaseModal dimmed={false} isOpen={isOpen} onClickClose={onClose}>
      <Frame>
        <button className='icon-close' onClick={onClose}><img src={IconClose} alt='icon-close' /></button>
        <h1>잡다매칭을 신청하면<br />
          확인 할 수 있어요.
        </h1>
        <h2>취준생을 위한 다양한 무료혜택을 받아보세요!</h2>
        <img className='img-info' src={ImgInfo} alt='잡다매칭' />
        <Link to={RoutePaths.match}>
          <button className='match-home-btn'>신청하러 가기</button>
        </Link>
      </Frame>
    </JDBaseModal>
  );
};

export default inject(injectStore.login, injectStore.positionCurationModel)(observer(PostionRecommendErrorModal));
