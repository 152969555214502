import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import ProfileSkill from 'components/_v2/profile/skill/ProfileSkill';
import IModalProps from 'interfaces/props/IModalProps';
import React, { FC } from 'react';

const PreferSkillEditModal: FC<IModalProps> = ({ isOpen, onClose, isMatching = false }) => (
  !isOpen ? <></> : (
    <JDFullModal isOpen={isOpen} handleClose={onClose} title='지식 • 기술'>
      <ProfileSkill isMatching={isMatching} />
    </JDFullModal>
  ));

export default PreferSkillEditModal;
