import { Haptics, ImpactStyle } from '@capacitor/haptics';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import RoutePaths from 'consts/RoutePaths';
import shadows from 'consts/style/shadows';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import React, { FC } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import { GNBMenu, path2Menu } from 'utils/RouteUtils';
import ScrollUtil from 'utils/ScrollUtil';

export const Frame = styled.ul`
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  border-top: 1px solid rgb(231, 232, 234);
  padding: 8px 0 calc(env(safe-area-inset-bottom) + 8px);
  text-align: center;
  background: ${Colors.WHITE_100};
  z-index: 1000;
  ${shadows.base}

  > * {
    flex: 1;
  }

  li {
    position: relative;
    display: inline-block;
    &[data-disabled='true'] {
      pointer-events: none;
    }

    .btn-tab-button {
      position: relative;
      overflow: hidden;
      background: transparent;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      &:disabled {
        opacity: 0.5;
      }
    }

    .menu-text {
      font: ${Fonts.B4_Medium};
      color: ${Colors.CG_60};
      margin-top: 2px;

      &.active {
        color: ${Colors.JOBDA_BLACK};
      }
    }
  }
`;

interface INavigationIconFrame {
  menu: '' | GNBMenu;
  type: GNBMenu;
  text: string;
  url: string;
  icon: React.ReactNode;
  login?:Login
}

const NavigationIconFrame: FC<INavigationIconFrame> = ({ menu, type, text, url, icon, login = new Login() }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: profileConditionsData } = useProfileConditions(login.userInfo !== null);

  // IOS에서 짧은 시간안에 페이지 이동이 많으면 에러가 뜨는 현상때문에 debounce 작업 추가
  const handleDebounce = async () => {
    if (login.userInfo === null) {
      await login.loadCurrentUser();
    }
    try {
      await Haptics.impact({ style: ImpactStyle.Medium });
    } catch (e) {
      console.log(e);
    }
    const scrollTop = await ScrollUtil.getScrollTop();
    if (pathname === url && scrollTop && scrollTop !== 0) {
      ScrollUtil.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      debounce(async () => {
        if (type === GNBMenu.Match) {
          if (login.userInfo === null || profileConditionsData?.jobGroups.length === 0) {
            history.push(RoutePaths.match_introduce);
          } else {
            history.push(url);
          }
        } else {
          history.push(url);
        }
      }, 100);
    // }
    }
  };
  return (
    <div role='button' onClick={handleDebounce}>
      <button className={`btn-tab-button ${menu === type ? 'selected' : ''}`}>
        {icon}
        <div className={`menu-text ${menu === type ? 'active' : ''}`}>{text}</div>
      </button>
    </div>
  );
};

interface IBottomNavigation {
  login?:Login
}

const BottomNavigation: FC<IBottomNavigation> = ({ login = new Login() }) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const menu = path2Menu(path) || path2Menu(pathname);
  return (
    <>
      <Frame className='frame-tab-bar'>
        <li>
          <NavigationIconFrame menu={menu} type={GNBMenu.Home} text='홈' url={RoutePaths.home} icon={menu === GNBMenu.Home ? <Icon name='home' size={32} /> : <Icon name='home' size={32} color={Colors.CG_60} />} />
        </li>
        <li>
          <NavigationIconFrame menu={menu} type={GNBMenu.Employment} text='채용정보' url={RoutePaths.calendar} icon={menu === GNBMenu.Employment ? <Icon name='work' size={32} /> : <Icon name='work' size={32} color={Colors.CG_60} />} />
        </li>
        <li>
          <NavigationIconFrame menu={menu} type={GNBMenu.Match} text='잡다매칭' url={RoutePaths.match} icon={menu === GNBMenu.Match ? <Icon name='match-light' size={32} /> : <Icon name='match-light' size={32} color={Colors.CG_60} />} />
        </li>
        <li>
          <NavigationIconFrame menu={menu} type={GNBMenu.Info} text='취업콘텐츠' url={RoutePaths.info} icon={menu === GNBMenu.Info ? <Icon name='read-book' size={32} /> : <Icon name='read-book' size={32} color={Colors.CG_60} />} />
        </li>
        <li>
          <NavigationIconFrame menu={menu} type={GNBMenu.Mypage} text='MY' url={RoutePaths.mypage} icon={menu === GNBMenu.Mypage ? <Icon name='user' size={32} /> : <Icon name='user' size={32} color={Colors.CG_60} />} />
        </li>
      </Frame>
    </>
  );
};

export default inject(injectStore.login)(observer(BottomNavigation));
