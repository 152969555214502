import React from 'react';
import classnames from 'classnames/bind';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface AnimationProps {
  fadeIn: boolean;
}

const LogoList = ({ fadeIn }: AnimationProps) => {
  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  return (
    <div className={cx('commonBox', 'logoList', { on: fadeIn })}>
      <V2Text
        element={V2TextOption.element.span}
        fontStyle={V2TextOption.fontStyle.heading_3_b}
        color={V2TextOption.color.default}
      >
        20분 투자
        <V2Text
          element={V2TextOption.element.span}
          fontStyle={V2TextOption.fontStyle.heading_3_b}
          color={V2TextOption.color.subtlest}
        >
          로
        </V2Text>
        <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.subtlest}>
          간단하게 취업해 볼까요?
        </V2Text>
      </V2Text>
      <button id='eventBtn-7' className={cx('btnApply')} onClick={moveToMatching}>
        <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
          JOBDA에서 제안받기
        </V2Text>
      </button>
      <div className={cx('logoImg')} />
    </div>
  );
};

export default LogoList;
