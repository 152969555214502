/* eslint-disable prefer-destructuring */
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import IconChange from 'assets/_v2/match/icon_change.svg';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import ImageEasyCropModal from 'components/_v2/_common/crop/ImageEasyCropModal';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { useMatchApplicantFiles } from 'query/match/applicants/useMatchApplicantsQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';

import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import MatchApplySelfBrandingProfileExampleModal from './MatchApplySelfBrandingProfileExampleModal';

const HeaderFrame = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    align-items: center;
    .header-title{
      display: flex;
      gap : 8px;
      align-items: center;
    }
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;

  .file-wrap {
    position: relative;
    /* border: 1px dashed ${V2BorderColors.default}; */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCC' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    padding: ${SpaceValue.XXXL}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid ${V2BorderColors.default};

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    .icon-camera{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: ${V2BackgroundColors.accent_green_default};
      border-radius: 50%;
    }
  }

  .file-change {
    position: absolute;
    right: 0;
    bottom: 0;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

interface IAttachFile {
  fileName: string;
  fileUid: string;
  fileUrl: string;
  filePath: string;
  fileDescription: string;
}
export interface IGetMatchingFilesRs{
  files: IAttachFile[];
}

interface Props {
  onImageChange: (file: File | null) => void;
}

const MatchApplySelfBrandingProfile: FC<Props> = ({ onImageChange }) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { data: filesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO);

  useEffect(() => {
    const init = async () => {
      try {
        if (filesData?.files[0].fileUrl) {
          const fileUrl = filesData.files[0].fileUrl;
          const fileName = filesData.files[0].fileName;

          setProfileImageUrl(fileUrl);
          setPreviewUrls([fileUrl]);
          const fileExt = fileName.split('.').pop()?.toLowerCase();
          const mimeType = fileExt === 'png' ? 'image/png'
            : fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg'
              : 'image/jpeg';

          const existingFile = await FileUtil.urlToFile(fileUrl, fileName, mimeType);
          onImageChange(existingFile);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [filesData]);

  const setFile = async (file: File, cropOn?: boolean) => {
    onImageChange(file);
    setPreviewUrls([URL.createObjectURL(file)]);
    setProfileImageUrl(URL.createObjectURL(file));
    setImgName(file.name);
    if (cropOn) setImageCropModalOpen(true);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.item(0)!) {
      setFile(e.target.files?.item(0)!, true);
      e.target.value = ''; // 동일한 파일 올릴 수 있도록 초기화
    }
  };

  const changeImage = (canvas: HTMLCanvasElement) => {
    const file = FileUtil.convertCanvasToFile(canvas.toDataURL(), imgName);
    setFile(file);
  };

  return (
    <Frame>
      <HeaderFrame>
        <div className='header-title'>
          <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextColorsType.default}>
            매칭 프로필 사진
          </V2Text>
          <V2Text fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextColorsType.danger}>
            필수
          </V2Text>
        </div>
        <V2Button
          type={V2ButtonOption.type.Text}
          size={V2ButtonOption.size.M}
          fontStyle={V2ButtonOption.fontStyle.body_3_sb}
          color={V2ButtonOption.color.subtle}
          buttonProps={{ onClick: () => setIsOpen(true) }}
        >
          가이드 보기
        </V2Button>
      </HeaderFrame>
      <div className='file-wrap'>
        <div className='file-upload'>
          {previewUrls.length > 0 ? (
            <img src={previewUrls[0]} alt='Uploaded Preview' />
          ) : (
            <>
              <img src={IconDumy} alt='Uploaded Preview' />
              <div className='icon-camera'>
                <V2Icon name={V2IconOption.name.camera} fill={V2IconOption.fill.inverse} size={V2IconOption.size.M} />
              </div>
            </>
          )}
          <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload} />
          {previewUrls.length > 0 && (
          <div className='file-change'>
            <img src={IconChange} alt='사진 수정' />
            <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload} />
          </div>
          )}
        </div>

      </div>
      <ImageEasyCropModal
        imgSrc={profileImageUrl || IconDumy}
        isOpen={imageCropModalOpen}
        onClickClose={() => setImageCropModalOpen(false)}
        onChange={changeImage}
      />
      <MatchApplySelfBrandingProfileExampleModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Frame>
  );
};

export default MatchApplySelfBrandingProfile;
