import { CapacitorCookies, CapacitorHttp } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import config from 'config';
import URIs from 'consts/URIs';
import { IAccessTokenRs, ILoginRs } from 'interfaces/rqrs/ILoginRqRs';
import ILoginUserInfoRs from 'interfaces/rqrs/ILoginUserInfoRs';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';
import CommonUtils from 'utils/CommonUtils';
import PushUtils, { FCM_TOKEN } from 'utils/PushUtils';
import { request } from 'utils/request';

export enum AUTHORIZE_TOKEN_KEY {
  ACCESS_TOKEN = 'accessToken',
  ACCESS_TOKEN_EXPIRES_TIME = 'accessToken_time',
  REFRESH_TOKEN = 'refreshToken'
}

const AUTO_LOGIN_KEY = 'JOBDA_AUTO_LOGIN';

// 백엔드 accessToken 만료 2시간
const AccessTokenExpireTime = 120 * 60 * 1000;
// 백엔드 refreshToken 만료 30일
const RefreshTokenExpireTime = 30 * 24 * 60 * 60 * 1000;

class AuthorizeUtil {
  static expiredCallback: Function = () => { };
  static bearerAccessToken: string = '';
  static isUpdatingAccessToken: boolean = false; // 플래그 추가

  static isUsingService: boolean = true;
  static checkAccessTokenExpireId = -1;
  static init = async () => {
    await AuthorizeUtil.getRefreshToken();
    const accessToken = await AuthorizeUtil.getAccessToken();
    if (accessToken) {
      AuthorizeUtil.bearerAccessToken = `Bearer ${accessToken}`;
    }
  };

  static updateBackendWithFCMToken = async () => {
    if (PushUtils.FCM_TOKEN) {
      try {
        await request({
          method: 'post',
          url: URIs.post_users_device_fcm_token,
          data: { token: PushUtils.FCM_TOKEN },
        });
      } catch (error) {
        console.error('Failed to post FCM token:', error);
        throw error;
      }

      try {
        await request({
          method: 'put',
          url: URIs.put_users_device_fcm_app_runtime,
          data: { token: PushUtils.FCM_TOKEN },
        });
      } catch (error) {
        console.error('Failed to update FCM runtime:', error);
        throw error;
      }
    }
  };

  static updateFCMRunTimeToken = async () => {
    if (PushUtils.FCM_TOKEN) {
      await request({
        method: 'put',
        url: URIs.put_users_device_fcm_app_runtime,
        data: { token: PushUtils.FCM_TOKEN },
      });
    }
  };

  static removeFCMTokenFromBackend = async () => {
    if (FCM_TOKEN) {
      await request({
        method: 'delete',
        url: URIs.delete_users_device_fcm_token,
        data: { token: FCM_TOKEN },
      });
    }
  };

  static updateAccessToken = async (isRefresh?: boolean) => {
    if (AuthorizeUtil.isUpdatingAccessToken) {
      return; // 갱신 중이면 중복 실행 방지
    }

    AuthorizeUtil.isUpdatingAccessToken = true;
    AuthorizeUtil.removeAccessToken();
    try {
      const url = URIs.get_reissue_token;
      let updateRs;
      const refreshToken = await AuthorizeUtil.getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token available for access token update.');
      }

      if (!MobileStore.isMobile) {
        // 웹 환경에서 토큰 갱신
        updateRs = await request<ILoginRs & IAccessTokenRs>({
          method: 'get',
          url,
        });
      } else {
        // 모바일 환경 (iOS, Android)에서의 토큰 갱신
        // Preferences에서 refreshToken을 가져와 쿠키를 설정한 후 갱신 요청
        updateRs = (await CapacitorHttp.get({ url: config.host + url })).data;
      }
      if (updateRs && updateRs.accessToken) {
        // 갱신된 accessToken 저장
        await AuthorizeUtil.setAccessToken(updateRs.accessToken);
        if (isRefresh && updateRs.refreshToken) {
          await AuthorizeUtil.setRefreshToken(updateRs.refreshToken);
        }
      } else {
        AuthorizeUtil.removeAccessToken(); // 갱신 실패 시 초기화
      }
    } catch (e) {
      console.error('Failed to refresh access token:', e);
      AuthorizeUtil.removeAccessToken(); // 갱신 실패 시 초기화
      AuthorizeUtil.removeRefreshToken();
      AuthorizeUtil.expiredCallback();
      throw e;
    } finally {
      AuthorizeUtil.isUpdatingAccessToken = false;
    }
  };

  // expiredCallback 추가 함수
  static addEventExpired = (callback: Function) => {
    AuthorizeUtil.expiredCallback = callback;
  };

  // access 토큰 만료 여부 확인 및 리셋
  static checkAccessTokenExpired = async () => {
    try {
      const loginInfo = await request<ILoginUserInfoRs>({
        method: 'get',
        url: URIs.get_login_user_info,
      });
      if (loginInfo) return true;

      AuthorizeUtil.removeAccessToken();
      return false;
    } catch (e) {
      console.error(e);
    }
  }

  static getIsAutoLogin = async () => {
    if (!MobileStore.isMobile) {
      return jsCookie.get(AUTO_LOGIN_KEY) === 'true';
    }
    return (await Preferences.get({ key: AUTO_LOGIN_KEY })).value === 'true';
  }

  static setIsAutoLogin = async (isAutoLogin: boolean) => {
    if (!MobileStore.isMobile) {
      jsCookie.set(AUTO_LOGIN_KEY, `${isAutoLogin}`);
    } else {
      await Preferences.set({ key: AUTO_LOGIN_KEY, value: `${isAutoLogin}` });
    }
  }

  static removeIsAutoLogin = async () => {
    if (!MobileStore.isMobile) {
      jsCookie.remove(AUTO_LOGIN_KEY);
    } else {
      await Preferences.remove({ key: AUTO_LOGIN_KEY });
    }
  }

  static getAutoLoginInfo = async () => ({ type: (await Preferences.get({ key: 'type' })).value })

  static setAutoLoginInfo = async (type: OpenIdProvider) => {
    await Preferences.set({ key: 'type', value: `${type}` });
  }

  static removeAutoLoginInfo = async () => {
    await Preferences.remove({ key: 'type' });
  }

  static setAccessToken = async (accessToken:string) => {
    if (!MobileStore.isMobile) {
      const currentCookieDomain = CommonUtils.getCookieDomain();
      jsCookie.set(
        AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN,
        accessToken,
        { expires: new Date(Date.now() + AccessTokenExpireTime), domain: currentCookieDomain },
      );
    } else {
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN, value: accessToken });
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME, value: new Date(Date.now() + AccessTokenExpireTime).toUTCString() });
    }
    AuthorizeUtil.bearerAccessToken = `Bearer ${accessToken}`;

    if (MobileStore.isMobile) {
      try {
        await AuthorizeUtil.updateBackendWithFCMToken();
      } catch (e) {
        console.error('Failed to update backend with FCM token:', e);
      }
    }
  };

  static getAccessToken = async () => {
    if (!MobileStore.isMobile) {
      const accessToken = jsCookie.get(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN);
      if (!accessToken) {
        AuthorizeUtil.expiredCallback();
        return null;
      }
      return accessToken;
    }
    const accessToken = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN })).value;
    const accessTokenExpiresTime = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME })).value;
    if (accessToken && accessTokenExpiresTime && new Date() < new Date(accessTokenExpiresTime)) {
      return accessToken;
    }
    if (!accessToken) {
      AuthorizeUtil.expiredCallback();
      return null;
    }
  };

  static removeAccessToken = async () => {
    if (!MobileStore.isMobile) {
      const currentCookieDomain = CommonUtils.getCookieDomain();
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN);
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN, { domain: currentCookieDomain });
    } else {
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN });
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME });
    }
    AuthorizeUtil.bearerAccessToken = '';
  };

  static setRefreshToken = async (refreshToken:string) => {
    if (!MobileStore.isMobile) {
      jsCookie.set(
        AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN,
        refreshToken, { expires: new Date(Date.now() + RefreshTokenExpireTime) },
      );
    } else {
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN, value: refreshToken });
      await CapacitorCookies.setCookie({ url: config.host, key: 'refreshToken', value: refreshToken });
    }
  }

  static getRefreshToken = async () => {
    if (!MobileStore.isMobile) {
      const refreshToken = jsCookie.get(AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN);
      return refreshToken;
    }
    const refreshToken = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN })).value;
    if (refreshToken) await CapacitorCookies.setCookie({ url: config.host, key: 'refreshToken', value: refreshToken });
    return refreshToken;
  }

  static removeRefreshToken = async () => {
    if (!MobileStore.isMobile) {
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN);
    } else {
      try {
        await AuthorizeUtil.removeFCMTokenFromBackend();
      } catch (e) {
        console.error('Failed to delete backend with FCM token:', e);
      }
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN });
      await CapacitorCookies.clearCookies({ url: config.host });
    }
  }
}

export default AuthorizeUtil;
