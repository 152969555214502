import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ConditionFrame from 'components/_v2/profile/condition/ConditionFrame';
import JobTab from 'components/_v2/profile/condition/JobTab';
import LocationTab from 'components/_v2/profile/condition/LocationTab';
import SalaryTab from 'components/_v2/profile/condition/SalaryTab';
import WelfareTab from 'components/_v2/profile/condition/WelfareTab';
import WorkingPreferenceTab from 'components/_v2/profile/condition/WorkingPreferenceTab';
import { LocationCode, LocationCodeList, CompanyLocation } from 'consts/CompanyLocationType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import { observer, inject } from 'mobx-react';

import Login from 'models/Login';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import MatchApplyConditionFrame from './MatchApplyConditionFrame';

const Frame = styled.div`
    padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
`;
const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  padding: 0 16px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${V2BorderColors.subtle};
`;
interface IMatchApplyPreferredProps{
    login?: Login;
}
const MatchApplyPreferred: FC<IMatchApplyPreferredProps> = ({ login = new Login() }) => {
  const [jobGuideText, setJobGuideText] = useState<string[]>(['희망하는 직군 · 직무를 선택해 주세요.']);
  const [locationGuideText, setLocationGuideText] = useState<string[]>(['희망하는 근무 지역을 선택해 주세요.']);
  const [salaryGuideText, setSalaryGuideText] = useState<string[]>(['선호하는 연봉 수준을 입력해 주세요.']);
  const [welfareGuideText, setWelfareGuideText] = useState<string[]>(['선호하는 복지 키워드를 선택해 주세요.']);
  const [etcGuideText, setEtcGuideText] = useState<string[]>(['어떤 일하고 싶은지 자유롭게 작성해 주세요.']);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const [conditionRegisterType, setConditionRegisterType] = useState<ConditionRegisterType>(ConditionRegisterType.LIST);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);

  useEffect(() => {
    setConditionRegisterType(ConditionRegisterType.LIST);
    return () => {
      setConditionRegisterType(ConditionRegisterType.LIST);
    };
  }, []);
  useEffect(() => {
    if (!profileConditionsData) return;

    const { jobGroups, locations, salary, welfare, workingPreference } = profileConditionsData;

    // 직군/직무 업데이트
    if (jobGroups.length > 0) {
      const jobDescriptions = jobGroups.flatMap((group) => group.jobTitleNames);
      setJobGuideText([...new Set(jobDescriptions)]);
    } else {
      setJobGuideText(['희망하는 직군 · 직무를 선택해 주세요.']);
    }

    // 지역 업데이트
    if (locations.length > 0) {
      const includesAllLocations = LocationCodeList.filter((code) => code !== 10000)
        .every((location) => locations.includes(location));

      if (includesAllLocations) {
        setLocationGuideText(['전체 지역']);
      } else {
        setLocationGuideText(locations.map((locationCode) => {
          const locationKey = Object.keys(CompanyLocation).find(
            (key) => CompanyLocation[key as keyof typeof CompanyLocation].code === locationCode.toString(),
          );
          return locationKey ? CompanyLocation[locationKey as keyof typeof CompanyLocation].name : '';
        }).filter(Boolean));
      }
    } else {
      setLocationGuideText(['희망하는 근무 지역을 선택해 주세요.']);
    }

    // 연봉 업데이트
    if (salary > 0) {
      setSalaryGuideText([`${salary.toLocaleString()}만원 이상`]);
    } else {
      setSalaryGuideText(['선호하는 연봉 수준을 입력해 주세요.']);
    }

    // 복지 업데이트
    if (welfare.length > 0) {
      setWelfareGuideText(welfare.map((w) => w.name));
    } else {
      setWelfareGuideText(['선호하는 복지 키워드를 선택해 주세요.']);
    }

    // 기타 희망사항 업데이트
    if (workingPreference) {
      setEtcGuideText([workingPreference]);
    } else {
      setEtcGuideText(['어떤 일하고 싶은지 자유롭게 작성해 주세요.']);
    }
  }, [profileConditionsData]);
  return (
    <Frame>
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_1_sb}
        color={V2TextOption.color.default}
      >
        선호하는 근무 조건
      </V2Text>
      <MatchApplyConditionFrame
        onClick={() => setConditionRegisterType(ConditionRegisterType.JOBGROUP)}
        title='직군 · 직무'
        description={jobGuideText}
        type={ConditionRegisterType.JOBGROUP}
        isPlaceholder={jobGuideText[0] === '희망하는 직군 · 직무를 선택해 주세요.'}
      />
      <Divider />
      <MatchApplyConditionFrame
        onClick={() => setConditionRegisterType(ConditionRegisterType.LOCATION)}
        title='근무 지역'
        description={locationGuideText}
        type={ConditionRegisterType.LOCATION}
        isPlaceholder={locationGuideText[0] === '희망하는 근무 지역을 선택해 주세요.'}
      />
      <Divider />
      <MatchApplyConditionFrame
        onClick={() => setConditionRegisterType(ConditionRegisterType.SALARY)}
        title='연봉'
        description={salaryGuideText}
        type={ConditionRegisterType.SALARY}
      />
      <Divider />
      {/* <MatchApplyConditionFrame
        onClick={() => setConditionRegisterType(ConditionRegisterType.WELFARE)}
        title='복지 혜택'
        description={welfareGuideText}
        type={ConditionRegisterType.WELFARE}
        isPlaceholder={welfareGuideText[0] === '선호하는 복지 키워드를 선택해 주세요.'}
      /> */}
      <Divider />
      <MatchApplyConditionFrame
        onClick={() => setConditionRegisterType(ConditionRegisterType.WORKING_PREFERENCE)}
        title='기타 희망사항'
        description={etcGuideText}
        type={ConditionRegisterType.WORKING_PREFERENCE}
      />
      {
        conditionRegisterType === ConditionRegisterType.JOBGROUP && (
          <JDFullModal>
            <HeaderFrame>
              <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
            </HeaderFrame>
            <JobTab setConditionRegisterType={setConditionRegisterType} jobGroupData={profileConditionsData?.jobGroups} hidden={conditionRegisterType !== ConditionRegisterType.JOBGROUP} />
          </JDFullModal>
        )
      }
      {
        conditionRegisterType === ConditionRegisterType.SALARY && (
          <JDFullModal>
            <HeaderFrame>
              <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
            </HeaderFrame>
            <SalaryTab setConditionRegisterType={setConditionRegisterType} salaryData={profileConditionsData?.salary} hidden={conditionRegisterType !== ConditionRegisterType.SALARY} />
          </JDFullModal>
        )
      }
      {/* {
        conditionRegisterType === ConditionRegisterType.WELFARE && (
          <JDFullModal>
            <HeaderFrame>
              <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
            </HeaderFrame>
            <WelfareTab setConditionRegisterType={setConditionRegisterType} welfareData={profileConditionsData?.welfare} hidden={conditionRegisterType !== ConditionRegisterType.WELFARE} />
          </JDFullModal>
        )
      } */}
      {
        conditionRegisterType === ConditionRegisterType.LOCATION && (
          <JDFullModal>
            <HeaderFrame>
              <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
            </HeaderFrame>
            <LocationTab setConditionRegisterType={setConditionRegisterType} locationData={profileConditionsData?.locations} hidden={conditionRegisterType !== ConditionRegisterType.LOCATION} />
          </JDFullModal>
        )
      }
      {
        conditionRegisterType === ConditionRegisterType.WORKING_PREFERENCE && (
          <JDFullModal>
            <HeaderFrame>
              <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
            </HeaderFrame>
            <WorkingPreferenceTab setConditionRegisterType={setConditionRegisterType} workingPreferenceData={profileConditionsData?.workingPreference} hidden={conditionRegisterType !== ConditionRegisterType.WORKING_PREFERENCE} />
          </JDFullModal>
        )
      }
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsDirtyModalOpen(false)} onClickClose={() => setIsDirtyModalOpen(false)} />

    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApplyPreferred));
