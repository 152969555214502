import V2Button from '__pc__/components/common/v2Design/button';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { IconArrowBack, IconLogo } from 'consts/assets/icons/iconPages';
import { RoutePaths } from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ImgGuideEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';

const Frame = styled.div`
  position: relative;
  .btn-back {
    position: absolute;
    display: block;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 40px;
    z-index: 9;

    &:disabled {
      display: none;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    .img-empty {
      width: 100px;
      height: 100px;
    }
  }

  .action-buttons {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XL}px
      ${SpaceValue.L}px;
    background-color: white;
  }
`;

const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${V2BackgroundColors.surface_default};
  z-index: 1;
  top: 0;
`;
const MatchingGuideEmpty = () => {
  const history = useHistory();
  const handlePreviousStep = () => {
    history.push(RoutePaths.home);
  };
  return (
    <Frame>
      <Header>
        <IconLogo className='logo' />
        <button className='btn-back'>
          <IconArrowBack onClick={handlePreviousStep} />
        </button>
      </Header>
      <div className='content'>
        <img className='img-empty' src={ImgGuideEmpty} alt='matching-guide-empty' />
        <SpacingBlock size={24} vertical />
        <V2Text
          fontStyle={V2TextOption.fontStyle.title_1_sb}
          color={V2TextOption.color.default}
        >
          지원한 포션이 아니에요.
        </V2Text>
        <SpacingBlock size={12} vertical />
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_sb}
          color={V2TextOption.color.subtle}
        >
          지금 바로 지원하고 면접 기회를 잡아 보세요.
        </V2Text>
      </div>
      <div className='action-buttons'>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: () => history.push(RoutePaths.position) }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
        >
          전체 포지션 보러가기
        </V2Button>
      </div>
    </Frame>
  );
};

export default MatchingGuideEmpty;
