import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{error?:boolean}>`
  padding: ${SpaceValue.XL}px 0;
  border-radius: 8px;
  display:flex;
  flex-direction:column;
  .inner-container{
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    align-items: center;
    .header-frame{
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
    }
    .icon-container{
      width: 32px;
      height: 32px;
    }
  }
  .description-container{
    display:flex;
    gap:8px;
    width:100%;
    overflow-x:scroll;
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .description-badge{
    display:flex;
    align-items:center;
    width:fit-content;
    padding:${SpaceValue.XS}px ${SpaceValue.L}px;
    border-radius:500px;
    border:1px solid ${V2BorderColors.default};
    flex:none;
  }
`;
const IconFrame = styled.div`
  width: 32px;
  height: 32px;
  border-radius: ${RadiusValue.S}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${V2BorderColors.default};
`;
interface IMatchApplyConditionFrame {
  title: string;
  description: string[];
  type: ConditionRegisterType;
  onClick?: () => void;
  error?: boolean;
  isPlaceholder?: boolean;
}

const MatchApplyConditionFrame: FC<IMatchApplyConditionFrame> = ({
  title,
  description,
  onClick,
  type,
  error,
  isPlaceholder = false,
}) => {
  return (
    <Frame onClick={onClick} error={error}>
      <div className='inner-container'>
        <div className='header-frame'>
          <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextOption.color.default}>{title}</V2Text>
          <IconFrame>
            <V2Icon
              name={V2IconOption.name.guide}
              fill={V2IconOption.fill.default}
              size={V2IconOption.size.S}
            />
          </IconFrame>
        </div>
      </div>
      <SpacingBlock vertical size={16} />
      {
        (type === ConditionRegisterType.SALARY || type === ConditionRegisterType.WORKING_PREFERENCE || isPlaceholder)
          ? <V2Text ellipsis={2} fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>{description[0]}</V2Text>
          : (
            <div className='description-container'>
              {description.map((item) => (
                <V2Text fontStyle={V2TextOption.fontStyle.body_3_sb} color={V2TextOption.color.subtle} key={item} className='description-badge'>{item}</V2Text>
              ))}
            </div>
          )
      }
    </Frame>
  );
};

export default MatchApplyConditionFrame;
