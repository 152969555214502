import classnames from 'classnames/bind';
import V2Icon from '__pc__/components/common/v2Design/icon';

import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import imgLogo from '../img/logo.png';
import imgLogo1 from '../img/logo1.png';
import imgLogo2 from '../img/logo2.png';
import imgLogo3 from '../img/logo3.png';
import imgLogo4 from '../img/logo4.png';
import imgLogo5 from '../img/logo5.png';
import imgLogo6 from '../img/logo6.png';
import imgLogo7 from '../img/logo7.png';
import imgLogo8 from '../img/logo8.png';
import imgLogo9 from '../img/logo9.png';
import imgLogo10 from '../img/logo10.png';
import imgLogo11 from '../img/logo11.png';
import imgLogo12 from '../img/logo12.png';
import imgLogo13 from '../img/logo13.png';
import imgLogo14 from '../img/logo14.png';
import imgLogo15 from '../img/logo15.png';
import imgLogo16 from '../img/logo16.png';
import imgLogo17 from '../img/logo17.png';
import imgLogo18 from '../img/logo18.png';
import imgLogo19 from '../img/logo19.png';
import imgLogo20 from '../img/logo20.png';
import imgLogo21 from '../img/logo21.png';
import imgLogo22 from '../img/logo22.png';
import imgLogo23 from '../img/logo23.png';
import imgLogo24 from '../img/logo24.png';
import imgLogo25 from '../img/logo25.png';
import imgLogo26 from '../img/logo26.png';
import imgLogo27 from '../img/logo27.png';
import imgLogo28 from '../img/logo28.png';
import imgLogo29 from '../img/logo29.png';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface AnimationProps {
  fadeIn: boolean;
}
const LogoFlow = ({ fadeIn }: AnimationProps) => {
  const logoList = [
    imgLogo,
    imgLogo1,
    imgLogo2,
    imgLogo3,
    imgLogo4,
    imgLogo5,
    imgLogo6,
    imgLogo7,
    imgLogo8,
    imgLogo9,
    imgLogo10,
    imgLogo11,
    imgLogo12,
    imgLogo13,
    imgLogo14,
    imgLogo15,
    imgLogo16,
    imgLogo17,
    imgLogo18,
    imgLogo19,
    imgLogo20,
    imgLogo21,
    imgLogo22,
    imgLogo23,
    imgLogo24,
    imgLogo25,
    imgLogo26,
    imgLogo27,
    imgLogo28,
    imgLogo29,
  ];

  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  return (
    <div className={cx('commonBox', 'wrapper', { on: fadeIn })}>
      <div className={cx('badge')}>
        <V2Icon name='corporate-fare' size={V2IconOption.size.XS} fill={V2IconOption.fill.inverse} />
        <V2Text fontStyle={V2TextOption.fontStyle.body_3_sb} color={V2TextOption.color.subtle}>
          매칭 참여기업 750사!
        </V2Text>
      </div>
      <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.subtlest}>
        지금 JOBDA에서 당신에게
      </V2Text>
      <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.default}>
        제안을 준비하는 기업이<br />확 늘었어요!
      </V2Text>
      <button id='eventBtn-2' className={cx('btnApply')} onClick={moveToMatching}>
        <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
          JOBDA에서 제안받기
        </V2Text>
      </button>
      <div className={cx('logoFlow')}>
        <div className={cx('logoItem')}>
          {[...logoList.slice(0, 14), ...logoList.slice(0, 14)].map((logo, index) => (
            <div key={`left-${index}`} className={cx('logoWrapper')}>
              <img src={String(logo)} alt={`logo${index + 1}`} className={cx('logoImage')} />
            </div>
          ))}
        </div>
        <div className={cx('logoItem')}>
          {[...logoList.slice(14, 29), ...logoList.slice(14, 29)].map((logo, index) => (
            <div key={`right-${index}`} className={cx('logoWrapper')}>
              <img src={String(logo)} alt={`logo${index + 15}`} className={cx('logoImage')} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoFlow;
