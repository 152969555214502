import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import ProfileExperienceEditModal from './PorfileExperienceEditModal';

const Frame = styled.div`
  margin-bottom:16px;
`;

const ETCExperienceTitle = styled.div`
  font: ${Fonts.B2_Bold};
  color:${colors.CG_90};
`;

const ETCExperienceField = styled.div`
  width:100%;
  padding:18px 16px;
  margin-top: 16px;
  background:${colors.CG_30};

  span {
    width: 100%;
    display: inline-block;
    white-space: pre-line;
    font: ${Fonts.B2_Medium_P};
    color:${colors.c44474B_80};
  }
`;
export interface IProfileExperienceProps{
  profileModel?:ProfileModel
  isMatching?: boolean;
}

const ProfileExperienceView:FC<IProfileExperienceProps> = ({ profileModel = new ProfileModel(), isMatching = false }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  const { experience } = profileModel;

  const closeDirtyModal = () => {
    profileModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };

  const handleEditClick = () => {
    setIsEditOpen(true);
  };
  const checkAudit = (caseType:ProfileAuditItemType, index:number) => {
    if (experience === null || experience === undefined) return false;
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_ACTIVITY_START_DATE:
        return experience.activitiesAndForeignExperiences[index].auditItems?.includes(ProfileAuditItemType.PROFILE_ACTIVITY_START_DATE);
      case ProfileAuditItemType.PROFILE_ACTIVITY_END_DATE:
        return experience.activitiesAndForeignExperiences[index].auditItems?.includes(ProfileAuditItemType.PROFILE_ACTIVITY_END_DATE);
      case ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_START_DATE:
        return experience.activitiesAndForeignExperiences[index].auditItems?.includes(ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_START_DATE);
      case ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_END_DATE:
        return experience.activitiesAndForeignExperiences[index].auditItems?.includes(ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_END_DATE);
      default:
        return false;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='read-book' text='경험' subText='대내외 활동, 해외 경험, 봉사 경험 등을 적어주세요.' onClickEditBtn={handleEditClick} />
      { experience && experience.activitiesAndForeignExperiences !== null && experience.activitiesAndForeignExperiences.map((card, index) => (
        <ProfileListCard editDate={checkAudit(ProfileAuditItemType.PROFILE_ACTIVITY_START_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_ACTIVITY_END_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_START_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_FOREIGN_EXPERIENCE_END_DATE, index)} key={`card-list${card.title}`} tag={card.tag} institution={card.title} date={`${card.startDate} ~ ${card.endDate}`} description={card.description} index={index} length={experience.activitiesAndForeignExperiences ? experience.activitiesAndForeignExperiences.length : 0} />
      ))}
      { experience && experience.etcExperiences !== null && experience.etcExperiences.length > 0 && (
      <ETCExperienceTitle>기타 경험</ETCExperienceTitle>
      )}
      { experience?.etcExperiences && experience.etcExperiences.map((etc) => (
        etc.description && (
        <ETCExperienceField key={`card-etc${etc.description}`}>
          <span>
            {etc.description}
          </span>
        </ETCExperienceField>
        )
      ))}

      <ProfileExperienceEditModal
        isOpen={isEditOpen}
        isMatching={isMatching}
        onClose={async () => {
          if (profileModel.isDirty)setIsDirtyModalOpen(true);
          else {
            setIsEditOpen(false);
            const tmpArray:number[] = [];
            profileModel.setIsWrongExperience(tmpArray);
            refetchAutoSave();
            await profileModel.loadExperience(isMatching);
          }
        }}
      />
      <ProfileMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={() => {
          const tmpArray:number[] = [];
          profileModel.setIsWrongExperience(tmpArray);
          setIsEditOpen(false);
        }}
        onClickClose={closeDirtyModal}
      />
    </Frame>
  );
};

export default inject(injectStore.profileModel)(observer(ProfileExperienceView));
