import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import V2Button from '__pc__/components/common/v2Design/button';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { useMatchApplicantFiles, useSaveMatchApplicantIntroduction, useUploadMatchApplicantProfilePhoto, useUploadMatchApplicantPhotosSelfExpression, useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchApplySelfBrandingExample from '../matchApply/matchApplySelfBranding/MatchApplySelfBrandingExample';
import MatchApplySelfBrandingProfile from '../matchApply/matchApplySelfBranding/MatchApplySelfBrandingProfile';
import MatchApplySelfBrandingIntroduce from '../matchApply/matchApplySelfBranding/MatchApplySelfBrandingIntroduce';
import MatchApplySelfBrandingDailyPhotos from '../matchApply/matchApplySelfBranding/MatchApplySelfBrandingDailyPhotos';

const Frame = styled.div`
  width: 100%;

  .profile {
    padding: 0 ${SpaceValue.L}px;
  }
`;

const ButtonFrame = styled.div`
  width: 100%;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  background-color: ${V2BackgroundColors.surface_default};
`;

interface IPreferredSelfbrandingList {
  login?: Login;
}
const PreferredSelfbrandingList: FC<IPreferredSelfbrandingList> = ({ login = new Login() }) => {
  // Profile 관련 state
  const [profileImage, setProfileImage] = useState<File | null>(null);

  // Introduce 관련 state
  const [introduceTitle, setIntroduceTitle] = useState('');
  const [introduceContent, setIntroduceContent] = useState('');

  // DailyPhotos 관련 state
  const [dailyPhotos, setDailyPhotos] = useState<(File | null)[]>([null, null, null]);
  const [photoDescriptions, setPhotoDescriptions] = useState<string[]>(['', '', '']);
  const [uploadFileUid, setUploadFileUid] = useState<(string | null)[]>([null, null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const { data: profileFilesData, refetch: refetchProfileFiles } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO, !!login.userInfo);
  const { data: dailyFilesData, refetch: refetchDailyFiles } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO, !!login.userInfo);
  const { data: introductionData, refetch: refetchIntroduction } = useMatchApplicantIntroduction(!!login.userInfo);
  const { data: profileConditionsData, refetch: refetchProfileConditions } = useProfileConditions(!!login.userInfo);
  // API mutations
  const { mutate: saveIntroduction } = useSaveMatchApplicantIntroduction(
    () => {
    },
    (error) => {
      console.error('Failed to save:', error);
    },
  );
  const { mutate: uploadProfilePhoto } = useUploadMatchApplicantProfilePhoto(
    () => {
    },
    (error) => {
      console.error('Failed to upload profile photo:', error);
    },
  );
  const { mutate: uploadDailyPhotos } = useUploadMatchApplicantPhotosSelfExpression(
    async () => {
      await refetchIntroduction();
      await refetchProfileFiles();
      await refetchDailyFiles();
      await refetchProfileConditions();
      history.push(RoutePaths.match);
    },
    (error) => {
      console.error('Failed to upload daily photos:', error);
    },
  );
  const history = useHistory();
  const isAllInputsCompleted = () => {
    // 프로필 사진 체크
    const isProfileCompleted = !!profileImage;

    // 소개 체크
    const isIntroduceCompleted = !!introduceTitle && !!introduceContent;

    // 일상 사진 체크
    const isPhotosCompleted = uploadFileUid.every((photo) => photo !== null)
      && photoDescriptions.every((desc) => desc.trim() !== '');

    return isProfileCompleted && isIntroduceCompleted && isPhotosCompleted;
  };

  const handleSaveAll = async () => {
    setIsLoading(true);
    try {
      // 프로필 사진 저장
      if (profileImage) {
        const profileFormData = new FormData();
        profileFormData.append('multipartFile', profileImage);
        await uploadProfilePhoto(profileFormData);
      }

      // 소개 저장
      await saveIntroduction({
        title: introduceTitle,
        content: introduceContent,
      });
      if (uploadFileUid.every((uid) => uid !== null)
          && photoDescriptions.length > 0
          && photoDescriptions.every((desc) => desc.trim() !== '')) {
        // 일상 사진 저장
        await uploadDailyPhotos({
          photos: photoDescriptions.map((desc, index) => ({
            fileUid: uploadFileUid[index] as string,
            description: desc,
          })),
        });
      }
    } catch (error) {
      console.error('Failed to save:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Frame>
      <div className='profile'>
        <MatchApplySelfBrandingProfile
          onImageChange={setProfileImage}
        />
      </div>
      <MatchApplySelfBrandingIntroduce
        title={introduceTitle}
        content={introduceContent}
        onTitleChange={setIntroduceTitle}
        onContentChange={setIntroduceContent}
      />
      <MatchApplySelfBrandingDailyPhotos
        photos={dailyPhotos}
        descriptions={photoDescriptions}
        onPhotosChange={setDailyPhotos}
        onDescriptionsChange={setPhotoDescriptions}
        uploadFileUid={uploadFileUid}
        setUploadFileUid={setUploadFileUid}
      />
      <ButtonFrame>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          isLoading={isLoading}
          buttonProps={{
            onClick: handleSaveAll,
            disabled: !isAllInputsCompleted(),
          }}
        >
          잡다 이용하기
        </V2Button>
      </ButtonFrame>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(PreferredSelfbrandingList));
