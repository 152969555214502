import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon, { IIconProps } from '__designkit__/icon/Icon';
import { V2IconProps } from '__pc__/components/common/v2Design/icon/V2Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import SpacingBlock from '__designkit__/components/SpacingBlock';

const Frame = styled.div<{ isSubBadge: boolean, isError: boolean, isDisabled: boolean, isSelected: boolean, isInverted: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: fit-content;
  padding: ${({ isSubBadge }) => (isSubBadge ? `${SpaceValue.XL}px ${SpaceValue.XS}px ${SpaceValue.XL}px ${SpaceValue.L}px` : `${SpaceValue.L}px`)};
  border: 1px solid ${(props) => (props.isDisabled ? Colors.CG_50 : props.isError ? Colors.ERROR : props.isSelected ? Colors.G_150 : props.isInverted ? Colors.JOBDA_BLACK : Colors.CG_50)};
  border-radius: 8px;
  background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isSelected ? Colors.WHITE_100 : props.isInverted ? Colors.JOBDA_BLACK : Colors.WHITE_100)};
  user-select: none;

  &:active {
    background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isInverted ? Colors.CG_70 : Colors.CG_30)};
  }

  & * {
    user-select: none;
  }
`;

const InnerFrame = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const ContentsFrame = styled.div<{ isSubTitle: boolean, isInverted: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  max-width: 100%;

  > .title {
    display: flex;
    align-items: center;
    gap: 2px;
    font: ${(props) => (props.isSubTitle ? Fonts.B1_Bold : Fonts.B1_Medium)};
    color: ${(props) => (props.isInverted ? Colors.WHITE_100 : Colors.JOBDA_BLACK)};
    word-break: break-all;
  }

  > .sub-title {
    font: ${Fonts.B2_Medium};
    line-height: 22px;
    color: ${Colors.CG_70};
    word-break: break-all;
  }
`;

const MainFrame = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

interface IMatchSingleMenuItemProps extends IComponentProps {
  title: string; // 필수값
  subTitle?: string | ReactElement; // 선택값, 지정하면 title 아래에 나타납니다
  badge?: ReactElement; // 선택값, 지정하면 title 오른쪽에 나타납니다
  subBadge?: ReactElement; // 선택값, 지정하면 title 아래쪽 나타납니다
  leftIcon?: ReactElement<V2IconProps>; // 선택값, 지정하면 [title + subTitle] 프레임의 왼쪽에 나타납니다
  rightIcon?: ReactElement<IIconProps> | null; // 선택값, 기본 값은 arrow-right 입니다. 아예 없애고 싶다면 null을 넣어주세요
  error?: boolean; // 선택값, 지정하면 border가 빨간색, 나타납니다
  disabled?: boolean; // 선택값, 지정하면 클릭할 수 없습니다
  selected?: boolean; // 선택값, 지정하면 active 상태가 됩니다
  inverted?: boolean; // 선택값, 지정하면 배경색과 글자색이 반전됩니다. (기존: 배경 - 흰, 글자 - 검)
  onClick: () => void; // 필수값
}

const MatchSingleMenuItem: FC<IMatchSingleMenuItemProps> = ({ title, subTitle = undefined, badge = undefined, subBadge = undefined, leftIcon = undefined, rightIcon = <Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />, error = false, disabled = false, selected = false, inverted = false, onClick, ...props }) => {
  const onClickFrame = () => {
    if (!disabled) onClick();
  };

  return (
    <Frame isSubBadge={!!subBadge} onClick={onClickFrame} isError={error} isDisabled={disabled} isSelected={selected} isInverted={inverted} {...props} onContextMenu={(e) => e.preventDefault()}>
      <MainFrame>
        <InnerFrame isDisabled={!!disabled}>
          { leftIcon }
          <ContentsFrame isSubTitle={!!subTitle} isInverted={inverted}>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_sb}
              color={V2TextOption.color.default}
            >
              { title }
            </V2Text>
            { subTitle && (
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtle}
              styles={{
                minWidth: '154px',
                maxWidth: '164px',
                wordBreak: 'break-all',
              }}
            >
              { subTitle }
            </V2Text>
            ) }
            { subBadge && (
              <>
                <SpacingBlock vertical size={4} />
                { subBadge }
              </>
            ) }
          </ContentsFrame>
        </InnerFrame>
        <InnerFrame isDisabled={disabled}>
          { rightIcon }
        </InnerFrame>
      </MainFrame>
    </Frame>
  );
};

export default MatchSingleMenuItem;
