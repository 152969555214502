import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import IconRemove from './icon_remove.png';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: 120px 16px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${V2BorderColors.default};
`;
const AccordionFrame = styled.div`
  display: flex;
  padding: ${SpaceValue.XL}px 0;
  flex-direction: column;
  width: 100%;
  .title-frame {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }
 
  .content-container {
    overflow: hidden;
    height: 0;
    transition: all 0.2s ease-in-out;
  }
 
  .content-container.open {
    padding-top: ${SpaceValue.L}px;
  }
`;
interface IAccordionSectionProps {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}
const AccordionSection: FC<IAccordionSectionProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setTimeout(() => {
        if (contentRef.current) {
          setContentHeight(contentRef.current.scrollHeight + 15);
        }
      }, 0);
    }
  }, [content, contentRef]);

  return (
    <>
      <AccordionFrame>
        <div className='title-frame' onClick={onToggle} role='button'>
          <V2Text
            styles={{
              alignSelf: 'center',
              maxWidth: '90%',
              wordBreak: 'break-all',
            }}
            fontStyle={V2TextOption.fontStyle.body_1_sb}
            color={V2TextOption.color.default}
          >
            {title}
          </V2Text>

          {isOpen ? (
            <img
              src={IconRemove}
              alt='icon_remove'
              style={{ width: '24px', height: '24px' }}
            />
          ) : (
            <V2Icon name={V2IconOption.name.add} size={V2IconOption.size.S} />
          )}
        </div>

        <div
          className={`content-container ${isOpen ? 'open' : ''}`}
          style={{ height: isOpen ? `${contentHeight}px` : '0px' }}
        >
          <div ref={contentRef}>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_m}
              color={V2TextOption.color.subtle}
            >
              {content}
            </V2Text>
          </div>
        </div>
      </AccordionFrame>
      <Divider />
    </>
  );
};
const HxFaq = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Frame>
      <V2Text
        styles={{ textAlign: 'center' }}
        fontStyle={V2TextOption.fontStyle.title_1_sb}
        color={V2TextOption.color.strong}
      >
        자주하는 질문
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXL} vertical />
      {FaqItems.map((item, index) => (
        <AccordionSection
          key={item.title}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </Frame>
  );
};

export default HxFaq;
const FaqItems = [
  {
    title: 'H.X는 기존 채용 방식과 무엇이 다른가요?',
    content:
      'H.X는 공고나 홍보 없이도 원하는 인재를 즉시 확인하고, 빠르게 채용할 수 있는 AI 기반 솔루션입니다. 팀 리더는 GPT 기반으로 적합한 인재를 찾은 후 채용 담당자에게 전달하며, 채용 담당자가 검토 후 제안을 진행하는 방식입니다.',
  },
  {
    title: '우리 회사 기준에 맞는 인재만 조회되도록 설정할 수 있나요?',
    content:
      '네, 가능합니다. 기업이 원하는 조건을 미리 설정하면, 해당 조건에 맞지 않는 인재는 검색 결과에서 자동으로 제외됩니다. \n불필요한 조회를 줄이고, 원하는 인재를 빠르게 확인할 수 있습니다.',
  },
  {
    title: '인재 검색 및 제안 횟수에 제한이 있나요?',
    content:
      'H.X는 인재 검색과 제안 횟수에 제한 없이 자유롭게 사용하실 수 있습니다. 필요할 때마다 인재를 찾고, 우수한 인재에게 제안을 보낼 수 있어 더 많은 채용 기회를 확보하실 수 있습니다.',
  },
  {
    title: '우리 기업을 선호하는 지원자가 부족하지는 않을까요?',
    content:
      '걱정하지 않으셔도 됩니다. H.X는 팀 리더가 선택한 인재의 유사 인재를 추가로 확인하고, \n해당 인재들의 입사 의지까지 확인한 후 기업에 다시 제안드리기 때문에, 보다 높은 성공률을 기대하실 수 있습니다.',
  },
  {
    title: '채용담당자가 직접 활용할 수도 있나요?',
    content:
      ' 네, 가능합니다. H.X는 팀 리더 외에도 채용 담당자가 직접 인재를 탐색하고 제안할 수 있도록 설계되었습니다. \n조직 상황에 따라 유연하게 활용하실 수 있습니다.',
  },
  {
    title: 'H.X를 직접 사용해볼 수 있나요?',
    content:
      '지금 사전 예약한 기업이라면 H.X를 무료로 사용해볼 수 있어요.\nH.X로 혁신적인 채용 방식을 직접 경험해보세요.',
  },
];
