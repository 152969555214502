/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import V2Badge from '__pc__/components/common/v2Design/badge';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BadgeOption, V2BadgeType } from '__pc__/constant/v2Design/V2BadgeType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { MatchingHomeJobPostingDto } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { inject, observer } from 'mobx-react';
import CalendarModel from 'models/_v2/CalendarModel';
import JobsModel from 'models/_v2/JobsModel';
import Main from 'models/_v2/Main';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';

const MainAccaRecruitCardFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${SpaceValue.M}px;
  width: 320px;

  &::before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${V2BorderColors.default};
  }

  .card-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${SpaceValue.M}px;

    .recruit-img {
      flex: none;
      width: 48px;
      height: 48px;
      border: 1px solid ${V2BorderColors.default};
    }

    .title-frame {
      display: flex;
      flex-direction: column;
      gap: ${SpaceValue.XXS}px;
      width: 216px;
    }
  }

  .date-inner {
    display: flex;
    align-items: center;
    gap: ${SpaceValue.XS}px;
  }
`;

interface IMainAccaJobsCard{
    item:MatchingHomeJobPostingDto & { bookmarked: boolean }
    main?: Main
    jobsModel?: JobsModel;
  login?:Login;
  calendarModel?:CalendarModel
  positionList:MatchingHomeJobPostingDto[]
  }
const MainAccaJobsCard:FC<IMainAccaJobsCard> = ({ positionList, item, login = new Login(), calendarModel = new CalendarModel(), main = new Main(), jobsModel = new JobsModel() }) => {
  const [bookmark, setBookmark] = useState<boolean>(item.bookmarked);
  const history = useHistory();
  const { setToastObject } = useToast();

  useEffect(() => {
    if (item) {
      setBookmark(item.bookmarked);
    }
  }, [item]);
  const checkBookmark = async (e: any) => {
    e.stopPropagation();
    if (!login.userInfo) {
      history.push(RoutePaths.login);
    } else {
      if (!bookmark) await calendarModel.saveJobPostingsBookmark(item.sn);
      else await calendarModel.deleteJobPostingsBookmark(item.sn);
      setBookmark(!bookmark);
      if (!bookmark) {
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크에 저장되었습니다', subMessage: undefined });
      } else {
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크가 삭제되었습니다', subMessage: undefined });
      }
    }
  };

  return (
    <MainAccaRecruitCardFrame onClick={(e) => {
      const sns = positionList.map((v) => v.sn);
      jobsModel.jobPostingListSn = sns;
      history.push(RoutePaths.jobs_posting(item.sn));
      e.stopPropagation();
    }}
    >
      <div className='card-inner'>
        <img
          src={`${item.logoImageUrl}?w=48&h=48` || ImgDumy}
          className='recruit-img'
          alt='logo'
          onError={(e) => e.currentTarget.src = ImgDumy}
        />
        <div className='title-frame'>
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_1_b}
            color={V2TextOption.color.default}
            ellipsis={1}
          >
            {item.companyName}
          </V2Text>
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_3_m}
            color={V2TextOption.color.subtle}
            ellipsis={1}
          >
            {item.title}
          </V2Text>
        </div>
        {/* [북마크] */}
        <div role='button' className='bookmark' onClick={(e) => checkBookmark(e)}>{bookmark ? <V2Icon name={V2IconOption.name.bookmarkFilled} size={V2IconOption.size.M} fill={V2IconOption.fill.primary} /> : <V2Icon name={V2IconOption.name.bookmark} size={V2IconOption.size.M} fill={V2IconOption.fill.subtlest} />}</div>
      </div>
      <div className='date-inner'>
        {item.closingDateTime
          && (
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_3_m}
            color={V2TextOption.color.subtlest}
            ellipsis={1}
          >
            {DateFormatYYYYMMDDCCHHMMUnit(item.closingDateTime)}

          </V2Text>
          )}
        {item.closingDateTime
          ? (
            <V2Badge
              size={V2BadgeOption.size.L}
              type={V2BadgeType.OutLine}
              fontStyle={V2BadgeOption.fontStyle.caption_2_sb}
              color={V2BadgeOption.color.subtlest}
              borderColor={V2BadgeOption.borderColor.default}
            >
              {getDDayString(str2DateTime(item.closingDateTime))}
            </V2Badge>
          )
          : (
            <V2Badge
              size={V2BadgeOption.size.L}
              type={V2BadgeType.OutLine}
              fontStyle={V2BadgeOption.fontStyle.caption_2_sb}
              color={V2BadgeOption.color.subtlest}
              borderColor={V2BadgeOption.borderColor.default}
            >
              상시채용
            </V2Badge>
          )}
      </div>
    </MainAccaRecruitCardFrame>
  );
};

export default inject(injectStore.joinModel, injectStore.main, injectStore.login, injectStore.calendarModel)(observer(MainAccaJobsCard));
