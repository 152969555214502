import Colors from '__designkit__/common/colors';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import TermsModel from 'models/TermsModel';
import PaccGateModel from 'models/_v2/PaccGateModel';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import { injectStore } from 'models/store';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import PaccGateSkillModal from 'components/_v2/phs/gate/PaccGateSkillModal';
import { IPaccGateProps } from './PaccGateService';

const TotalFrame = styled.div`
  margin-top:8px;
`;
const Frame = styled.div`
    width:100%;
    height: 168px;
    background-color: ${Colors.CG_90};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:8px;
    .guide-text{
        font: ${Fonts.H5_Bold};
        color:${Colors.JOBDA_WHITE};
        margin-bottom: 16px;
        text-align: center;
    }
    .button-frame{
        width:200px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #4021ff;
        .button-text{
            color:${Colors.JOBDA_WHITE};
            font: ${Fonts.B2_Bold};
        }
    }
`;
interface IPaccGateSkillKeywordRecommend extends IPaccGateProps {
  profileSkillModel?:ProfileSkillModel
  setIsSkillModal:Dispatch<SetStateAction<boolean>>;
  isSkillModal:boolean;
}

const PaccGateSkillKeywordRecommend:FC<IPaccGateSkillKeywordRecommend> = ({ isSkillModal, setIsSkillModal, termsModel = new TermsModel(), context = new Context(), login = new Login(), paccGateModel = new PaccGateModel(), profileSkillModel = new ProfileSkillModel() }) => {
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleButton = async () => {
    if (login.userInfo === null) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    } else { setIsSkillModal(true); }
  };
  const closeDirtyModal = () => {
    paccGateModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };
  return (
    <TotalFrame>
      <Frame>
        <div className='guide-text'>기술 키워드를 입력하면 <br /> 맞춤 포지션을 추천해드려요.</div>
        <div className='button-frame'>
          <div role='button' onClick={handleButton} className='button-text'>기술 키워드 입력하기</div>
        </div>
      </Frame>
      <PaccGateSkillModal
        isOpen={isSkillModal}
        onClickClose={async () => {
          if (paccGateModel.isDirty) setIsDirtyModalOpen(true);
          else {
            profileSkillModel.recommendSkills = [];

            setIsSkillModal(false);
          }
        }}
      />
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsSkillModal(false)} onClickClose={closeDirtyModal} />
    </TotalFrame>
  );
};

export default inject(injectStore.login, injectStore.termsModel)(observer(PaccGateSkillKeywordRecommend));
