import styled from 'styled-components';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import IconLogo from './icon_hdot_full_logo.png';
import IconBlog from './icon_blog.png';
import IconInstagram from './icon_insta.png';
import IconYoutube from './icon_youtube.png';

const Frame = styled.div`
display: flex;
flex-direction: column;
padding: 46px 26px;
.policy-frame{
    display: flex;
    gap:16px;
    align-items: center;
    .divider{
        width: 1px;
        height: 12px;
        background-color: #E9EAEB;
    }

}
.social-frame{
    display: flex;
    align-items: center;
    gap:12px;
}
`;
const LightestText = styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
/* Type Styles/text-sm/[R] */
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 22.4px */
color: #ACAEB0;
`;
const LightText = styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
/* Type Styles/text-sm/[R] */
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 22.4px */
color: #838486;
`;
const BoldText = styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
/* Type Styles/text-sm/[B] */
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 600;
color: #838486;
`;
const HxFooter = () => {
  return (
    <Frame>
      <img src={IconLogo} alt='icon_logo' style={{ width: '89px' }} />
      <SpacingBlock size={32} vertical />
      <div className='policy-frame'>
        <LightText onClick={() => windowOpenUtils('https://h.place/terms/service', '_blank')}>이용약관</LightText>
        <BoldText onClick={() => windowOpenUtils('https://h.place/terms/privacy', '_blank')}>개인정보처리방침</BoldText>
      </div>
      <SpacingBlock size={8} vertical />
      <div className='policy-frame'>
        <LightText>(주)자인원</LightText>
        <div className='divider' />
        <LightText>대표자 : 신대석</LightText>
      </div>
      <LightText>사업자번호 : 170-88-01418</LightText>
      <LightText>경기도 성남시 분당구 판교로 228번길 17, B동
        마이다스아이티 (우:13487)
      </LightText>
      <LightText>문의 : 031-789-2000 </LightText>
      <SpacingBlock size={24} vertical />
      <div className='social-frame'>
        <button onClick={() => windowOpenUtils('https://contents.h.place/?__hstc=40670653.b980d11be4b393122f7f47296683e902.1722373247172.1743084620705.1743388367669.84&__hssc=40670653.3.1743388367669&__hsfp=2232757334', '_blank')}>
          <img src={IconBlog} alt='icon_blog' style={{ width: '40px', height: '40px' }} />
        </button>
        <button onClick={() => windowOpenUtils('https://www.instagram.com/accounts/login/?next=%2Fh_dot_official%2F&source=omni_redirect/', '_blank')}>
          <img src={IconInstagram} alt='icon_instagram' style={{ width: '40px', height: '40px' }} />
        </button>
        <button onClick={() => windowOpenUtils('https://www.youtube.com/@hdot_midas', '_blank')}>
          <img src={IconYoutube} alt='icon_youtube' style={{ width: '40px', height: '40px' }} />
        </button>
      </div>
      <SpacingBlock size={40} vertical />
      <LightestText>ⓒ 2025. JAINWON Inc. All rights reserved.</LightestText>
    </Frame>
  );
};

export default HxFooter;
