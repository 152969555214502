import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import imgArrow from 'assets/_v2/_common/icon_arrow_right.svg';
import IconClose from 'assets/icon/notification/icon_circle_close.svg';
import { MatchApplyStatusTabType } from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import LazyImage from 'components/common/LazyImage';
import RoutePaths from 'consts/RoutePaths';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import { INotification, NotificationType } from 'interfaces/_v2/notification/INotificationRqRs';
import { inject } from 'mobx-react';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import NotificationModel from 'models/_v2/NotificationModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getDCount, getDHour, getDMinutes } from 'utils/DateUtils';

const StyledNotificationCard = styled.div`
  display:flex;
  padding:20px;
  justify-content: space-between;

  .icon-frame{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .content-frame{
    flex:2;
    display: flex;
    flex-direction: column;
    >div{
      display: flex;
      margin-bottom:4px;
    }
    h1{
      font: ${Fonts.B2_Medium};
      white-space: pre-line;
      color:${colors.CG_80};
      margin-bottom:16px;
      strong{
        font: ${Fonts.B2_Bold};
      }
    }
    h3{
      height:20px;
      display:flex;
      align-items:flex-end;
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_60};

    }
    .move-button{
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.CG_70};
      flex-direction: row;
      .icon-arrow{
        margin-left: 5px;
      }
    }
    .new{
      margin-left:4px;
      margin-top:5px;
      width:4px;
      height:4px;
      border-radius: 50%;
      background: ${colors.ERROR};
    }
  }

  .close-frame{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    button{
      background: transparent;
    }
  }
`;

interface INotificationCard {
  item: INotification;
  notificationModel?: NotificationModel;
  matchMessageStore?: MatchMessageStore;
  login?:Login;
}

const NotificationCard: FC<INotificationCard> = ({ item, notificationModel = new NotificationModel(), matchMessageStore = new MatchMessageStore(), login = new Login() }) => {
  const history = useHistory();

  const getMsgType = (userNotificationType: NotificationType) => {
    switch (userNotificationType) {
      case (NotificationType.INTERVIEW_OFFER):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`} 면접 제안이 도착했습니다.</h1>;
      case (NotificationType.STATUS_CHANGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 최종 결과가 발표됐습니다.`}</h1>;
      case (NotificationType.FILE_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 `}추가 서류를 요청했습니다.</h1>;
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName}`} 포지션에서 요청한 추가 서류 제출 마감일입니다.</h1>;
      case NotificationType.FILE_REQUEST_SUBMITTED:
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 추가 서류 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.SIMPLE_MESSAGE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 메시지가 도착했습니다.`}</h1>;
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
        return <h1>{`역량검사 분석이 완료됐습니다. \n검사 결과를 확인해 보세요.`}</h1>;
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
        return <h1>{`개발자 역량검사 분석이 완료됐습니다. \n검사 결과를 확인해 보세요.`}</h1>;
      case (NotificationType.PST_ANALYSIS_COMPLETE):
        return <h1>{`개발 구현 능력 검사 분석이 완료됐습니다. \n검사 결과를 확인해 보세요.`}</h1>;
      case (NotificationType.MATCHING_CREATED):
        return <h1>{`잡다매칭에 참여할 준비가 됐습니다.\n등록하신 역량과 프로필 정보에 딱 맞는 특별한 제안을 보내드릴게요.`}</h1>;
      case (NotificationType.MATCHING_DELETED):
        return <h1>{`잡다매칭을 취소했습니다\n더 좋은 제안을 드리기위해 노력하고 있을게요.`}</h1>;
      case (NotificationType.MATCHING_TALK_LINK):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션이 연동되어 전형을 진행합니다. `}</h1>;
      case (NotificationType.MATCHING_OFFER):
        return <h1>{`${login.userInfo?.name}님이 \n${item.data.companyName}의 ${item.data.positionName} 포지션에 최우수 매칭인재로 선정됐습니다. \n제안된 포지션에 지원하고 합격에 한 걸음 다가가 보세요..`}</h1>;
      case (NotificationType.MATCHING_OFFER_DEADLINE_REMINDER_D_DAY):
        return <h1>{`${login.userInfo?.name}님을 기다리고 있는 ${item.data.companyName}의 ${item.data.positionName} 포지션이 있습니다. 제안에 응답하고 더 정확한 맞춤 제안 받아 보세요.`}</h1>;
      case (NotificationType.ACC_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`} 역량검사 검사 결과 제출을 요청했습니다.</h1>;
      case (NotificationType.PHS_REQUEST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`} 개발자 검사 결과 제출을 요청했습니다.</h1>;
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션 지원이 완료됐습니다.\n다른 지원자의 이야기가 궁금하시다면 기업채팅에 참여해 보세요.`}</h1>;
      case (NotificationType.JOB_APPLICATION_CANCELED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션 지원이 취소됐습니다. \n다른 포지션을 찾아볼까요?`}</h1>;
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션 면접이 확정됐습니다. \n 잡다에서 준비한 10,000개의 기출 질문으로 면접을 실전처럼 연습해 보세요.`}</h1>;
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 면접 제안 응답 마감까지 3일 남았습니다.`}</h1>;
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 면접 제안 응답 마감일입니다.`}</h1>;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션 입사 지원 마감일입니다.`}</h1>;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션 입사 지원 마감까지 3일 남았습니다.`}</h1>;
      case (NotificationType.DELETE_JOB_APPLICATION):
        return <h1>{`${item.data.companyName} 요청으로 ${item.data.positionName} 포지션 지원이 취소됐습니다.`}</h1>;
      case (NotificationType.COPY_JOB_APPLICATION):
        return <h1>{`${item.data.companyName} 요청으로 ${item.data.positionName} 포지션에서 심사 중입니다.`}</h1>;
      case (NotificationType.PROFILE_INPUT_REMINDER):
        return <h1>{`프로필을 작성하면 맞춤형 기업 추천, 빠른\n입사지원 혜택을 누릴 수 있어요.`}</h1>;
      case (NotificationType.ACC_COMPLETE):
        return <h1>{`역량검사 응시가 완료됐습니다.\n결과 분석은 최대 1일 정도 걸릴 수 있어요.`}</h1>;
      case (NotificationType.PACC_COMPLETE):
        return <h1>{`개발자 역량검사 응시가 완료됐습니다.\n결과 분석은 최대 1일 정도 걸릴 수 있어요.`}</h1>;
      case (NotificationType.PST_COMPLETE):
        return <h1>{`개발 구현 능력 검사 응시가 완료됐습니다.\n결과 분석은 최대 1일 정도 걸릴 수 있어요.`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 역량검사 결과 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발자 역량검사 결과 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발 구현 능력 검사 결과 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.ACC_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 역량검사 응시하고 나에게 딱 맞는 포지션을\n쉽게 찾아보세요.`}</h1>;
      case (NotificationType.PACC_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 개발자 역량검사 응시하고 나에게 딱 맞는 포지션을\n쉽게 찾아보세요.`}</h1>;
      case (NotificationType.PST_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 개발 구현 능력 검사 응시하고 개발 실무 능력을\n길러 보세요.`}</h1>;
      case (NotificationType.ACC_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 역량검사가 존재합니다. 응시 완료하고 기업 추천, 입사지원 제안 등의 무료 혜택을 받아보세요.`}</h1>;
      case (NotificationType.PACC_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 개발자 역량검사가 존재합니다.\n응시 완료하고 기업 추천, 입사지원 제안 등의 무료 혜택을 받아보세요.`}</h1>;
      case (NotificationType.PST_COMPLETE_REMINDER):
        return <h1>{`${login.userInfo?.name}님, 현재 응시 중인 개발 구현 능력 검사가 존재합니다. \n응시 완료하고 기업 추천, 입사지원 제안 등의 무료 혜택을 받아보세요.`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 역량검사 결과 제출 마감일입니다.`}</h1>;
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 역량검사 결과 제출 마감까지 3일 남았습니다.`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발자 역량검사 결과 제출 마감일입니다.`}</h1>;
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발자 역량검사 결과 제출 마감까지 3일 남았습니다.`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발 구현 능력 검사 결과 제출 마감일입니다.`}</h1>;
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 개발 구현 능력 검사 결과 제출 마감까지 3일 남았습니다.`}</h1>;
      case (NotificationType.REGISTER_USER):
        return <h1>{`${login.userInfo?.name}님 만나서 반가워요!\n잡다에서는 경력이 없어도 기업에게 먼저 제안을 받을 수 있는 방법이 있어요.`}</h1>;
      case (NotificationType.ACC_RECOMMEND):
        return <h1>750개 이상의 회사가 사용하는 역량검사 응시하고 나의 잠재역량과 잘 맞는 직무, 기업을 찾아보세요.</h1>;
      case (NotificationType.ACC_LITE_COMPLETE):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 역량검사 결과 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.ACC_LITE_RESULT_SUBMITTED):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 요청한 역량검사 제출이 완료됐습니다.`}</h1>;
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서 면접 제안이 도착했습니다. \n면접 일정표를 보고 가능한 시간을 선택해 주세요.`}</h1>;
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
        return <h1>{`${item.data.companyName}의 ${item.data.positionName} 포지션에서`}면접 일정을 안내드립니다.</h1>;
      default:
        return '';
    }
  };
  const getNavMsgType = (userNotificationType: NotificationType) => {
    switch (userNotificationType) {
      case (NotificationType.INTERVIEW_OFFER):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
        return '면접 제안 응답하기';
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
        return '면접 일정 선택하기';
      case (NotificationType.STATUS_CHANGE):
        return '최종 결과 확인하기';
      case (NotificationType.PHS_REQUEST):
        return '개발자 검사 결과 제출하기';
      case (NotificationType.ACC_REQUEST):
        return '역량검사 결과 제출하기';
      case (NotificationType.MATCHING_OFFER):
      case (NotificationType.MATCHING_OFFER_DEADLINE_REMINDER_D_DAY):
        return '제안 메시지 확인하기';
      case (NotificationType.FILE_REQUEST):
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
        return '추가서류 제출하기';
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
      case (NotificationType.PST_ANALYSIS_COMPLETE):
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
        return '분석결과 확인하기';
      case (NotificationType.MATCHING_CREATED):
        return '잡다매칭 알아보기';
      case (NotificationType.MATCHING_TALK_LINK):
        return '지원현황 확인하기';
      case (NotificationType.SIMPLE_MESSAGE):
        return '메시지 확인하기';
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
        return '채팅방 확인하기';
      case (NotificationType.COPY_JOB_APPLICATION):
        return '지원현황 확인하기';
      case (NotificationType.JOB_APPLICATION_CANCELED):
        return '포지션 보러가기';
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        return '면접 연습하러 가기';
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
        return '면접 일정 확인하기';

      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        return '지원서 작성하기';
      case (NotificationType.ACC_REMINDER):
      case (NotificationType.PACC_REMINDER):
        return '역량검사 응시하러 가기';
      case (NotificationType.PST_REMINDER):
        return '개발 구현 능력 검사 응시하러 가기';
      case (NotificationType.ACC_COMPLETE_REMINDER):
        return '응시 중인 역량검사 확인하기';
      case (NotificationType.PACC_COMPLETE_REMINDER):
        return '응시 중인 개발자 역량검사 확인하기';
      case (NotificationType.PST_COMPLETE_REMINDER):
        return '응시 중인 개발 구현 능력 검사 확인하기';
      case (NotificationType.PROFILE_INPUT_REMINDER):
        return '프로필 작성하러 가기';
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '역량검사 결과 제출하기';
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '개발자 역량검사 결과 제출하기';
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
        return '개발 구현 능력 검사 결과 제출하기';
      case (NotificationType.MATCHING_DELETED):
        return '잡다매칭 혜택 다시보기';
      case (NotificationType.REGISTER_USER):
        return '잡다매칭 알아보기';
      case (NotificationType.ACC_RECOMMEND):
        return '역량검사 알아보기';
      default:
        return '';
    }
  };
  const onClickNotification = (userNotificationType: NotificationType, positionSn?: number) => {
    switch (userNotificationType) {
      case (NotificationType.ACC_ANALYSIS_COMPLETE):
        history.push(RoutePaths.acca_results());
        break;
      case (NotificationType.FILE_REQUEST):
      case (NotificationType.FILE_REQUEST_DEADLINE_REMINDER_D_DAY):
      case (NotificationType.ACC_REQUEST):
      case (NotificationType.PHS_REQUEST):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_3):
      case (NotificationType.ACC_RESULT_SUBMITTED_REMINDER_D_DAY):
      case (NotificationType.PACC_RESULT_SUBMITTED_REMINDER_D_DAY):
      case (NotificationType.PST_RESULT_SUBMITTED_REMINDER_D_DAY):
      case (NotificationType.INTERVIEW_OFFER):
      case (NotificationType.INTERVIEW_SCHEDULE_ADJUST):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3):
      case (NotificationType.INTERVIEW_SCHEDULE_CONFIRM):
      case (NotificationType.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY):
      case (NotificationType.STATUS_CHANGE):
      case (NotificationType.MATCHING_TALK_LINK):
        history.push({ pathname: '/mypage/apply', search: `?tab=${MatchTabType.IN_PROGRESS}&sn=${positionSn}` });
        break;

      case (NotificationType.SIMPLE_MESSAGE):
        matchMessageStore.messageAccessPath = MessageAccessPath.JOBDA_NOTIFICATION;
        matchMessageStore.open(positionSn || -1);
        break;
      case (NotificationType.PACC_ANALYSIS_COMPLETE):
        history.push(RoutePaths.phs_pacc_results());
        break;
      case (NotificationType.PST_ANALYSIS_COMPLETE):
        history.push(RoutePaths.phs_pst_results());
        break;
      case (NotificationType.PROFILE_INPUT_REMINDER):
        history.push(RoutePaths.profile);
        break;
      case (NotificationType.REGISTER_USER):
      case (NotificationType.MATCHING_CREATED):
        history.push(RoutePaths.match_introduce);
        break;
      case (NotificationType.JOB_APPLICATION_CANCELED):
        history.push(RoutePaths.position);
        break;
      case (NotificationType.JOB_APPLICATION_SUBMITTED):
        history.push({ pathname: '/chat/channel', search: `?channelId=${item.data.channelId}` });
        break;
      case (NotificationType.COPY_JOB_APPLICATION):
        history.push(RoutePaths.mypage_apply);
        break;
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_3):
      case (NotificationType.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY):
        history.push(RoutePaths.mypage_applying);
        break;
      case (NotificationType.INTERVIEW_OFFER_RESPONDED):
        history.push(RoutePaths.pass_interview);
        break;
      case (NotificationType.ACC_REMINDER):
      case (NotificationType.ACC_COMPLETE_REMINDER):
        history.push(RoutePaths.acca_test);
        break;
      case (NotificationType.ACC_RECOMMEND):
        history.push(RoutePaths.acc_introduce);
        break;
      case (NotificationType.PACC_REMINDER):
      case (NotificationType.PACC_COMPLETE_REMINDER):
      case (NotificationType.PST_REMINDER):
      case (NotificationType.PST_COMPLETE_REMINDER):
        history.push(RoutePaths.phs_test);
        break;
      case (NotificationType.MATCHING_DELETED):
        history.push(RoutePaths.match_introduce);
        break;
      case (NotificationType.MATCHING_OFFER):
      case (NotificationType.MATCHING_OFFER_DEADLINE_REMINDER_D_DAY):
        history.push(RoutePaths.match_status_tab(MatchApplyStatusTabType.MATCH_OFFER, item.data.positionSn, MessageAccessPath.JOBDA_NOTIFICATION));
        break;
      default:
        break;
    }
  };
  const getTime = (dateTime: string) => {
    const dcount = getDCount(dateTime);
    if (dcount === 1) {
      const diffMin = getDMinutes(dateTime);
      if (diffMin < 1) return `방금전`;
      if (diffMin >= 1 && diffMin < 60) return `${diffMin}분 전`;
      if (diffMin > 60 && diffMin < 1380) return `${getDHour(dateTime)}시간 전`;
      return `${dcount}일 전`;
    }
    return `${dcount}일 전`;
  };
  const NotificationComponent = () => {
    if (item.type === NotificationType.ACC_COMPLETE
      || item.type === NotificationType.PACC_COMPLETE
      || item.type === NotificationType.PST_COMPLETE
      || item.type === NotificationType.ACC_LITE_COMPLETE
      || item.type === NotificationType.ACC_LITE_RESULT_SUBMITTED
      || item.type === NotificationType.DELETE_JOB_APPLICATION
      || item.type === NotificationType.ACC_RESULT_SUBMITTED
      || item.type === NotificationType.PACC_RESULT_SUBMITTED
      || item.type === NotificationType.PST_RESULT_SUBMITTED
      || item.type === NotificationType.FILE_REQUEST_SUBMITTED
    ) {
      return null;
    }
    return <LazyImage src={imgArrow} className='icon-arrow' />;
  };
  return (
    <StyledNotificationCard className={`notification-card-${item.notificationSn}`}>
      <div className='content-frame'>
        <div>
          <h3>
            {getTime(item.createdDateTime)}
          </h3>
          {!item.accessYn && <div className='new' />}
        </div>
        {getMsgType(item.type)}
        <div
          className='move-button'
          role='button'
          onClick={async () => {
            onClickNotification(item.type, item.data.positionSn);
            await notificationModel.readNotifications([item.notificationSn]);
          }}
        >
          {getNavMsgType(item.type)}
          {NotificationComponent()}
        </div>
      </div>
      <div className='close-frame'>
        <button onClick={async () => {
          await notificationModel.deleteNotifications([item.notificationSn]);
          notificationModel.notifications = [...notificationModel.notifications.filter((listItem) => listItem.notificationSn !== item.notificationSn)];
        }}
        >
          <img src={IconClose} alt='닫기' />
        </button>
      </div>
    </StyledNotificationCard>
  );
};

export default inject(injectStore.notificationModel, injectStore.matchMessageStore, injectStore.login)(NotificationCard);
