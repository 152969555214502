/* eslint-disable import/prefer-default-export */
export enum NotificationLogType {
    MATCHING_OFFER = 'MATCHING_OFFER'
}

export enum MediumType {
    PUSH = 'PUSH',
    KAKAOTALK = 'KAKAOTALK',
    SMS = 'SMS',
    EMAIL = 'EMAIL',
    APP = 'APP',
    PC_BROWSER = 'PC_BROWSER',
    MOBILE_BROWSER = 'MOBILE_BROWSER',
    NONE = 'NONE',
    UNKNOWN = 'UNKNOWN',

}
export enum MessageAccessPath{
    AUTO = 'AUTO',
    POSITION = 'POSITION',
    JOBDA_NOTIFICATION = 'JOBDA_NOTIFICATION',
    MATCHING_CARD = 'MATCHING_CARD',
    JOBDA_MESSAGE = 'JOBDA_MESSAGE',
    MATCHING_REJECT_MODAL = 'MATCHING_REJECT_MODAL',
    COMPANY_CHANNEL = 'COMPANY_CHANNEL',
    NONE = 'NONE',
}
export interface IPushNotificationSendLogSaveRq{
    matchingTalkMessageSn:number;
    notificationLogType:NotificationLogType
}

export interface INotificationViewLogSaveRq{
    positionSn:number;
    userId:string;
    mediumType:MediumType;
    messageAccessPath:MessageAccessPath
    notificationLogType:NotificationLogType
}

export interface IMatchingReportViewLogSaveRq{
    positionSn:number;
    userId:string;
    mediumType:MediumType;
    matchingReportAccessPath:MessageAccessPath
}
