import URIs from 'consts/URIs';
import { ReactSelectOption } from 'consts/_v2/_common/ReactSelectOptions';
import ISkillListRs, { ISkillDto } from 'interfaces/rqrs/ISkillListRs';
import ISkillRecommendListRs, { ISkillCategoryDto } from 'interfaces/rqrs/ISkillRecommendListRs';
import { IProfileProjectDto, IProfileProjectListRqRs, IProfileProjectSaveRq } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import store from 'models/store';

export default class ProfileProjectModel {
    @observable projects: IProfileProjectSaveRq[] | null = null;
    @observable searchedSkills:ISkillDto[] = [];
    @observable importedProjects:IProfileProjectDto[] | null = null;
    @observable recommendSkills: ISkillCategoryDto[] = [];
    @observable isDirty: boolean = false;

    @action
    loadProjects = async (isMatching?: boolean) => {
      try {
        const url = isMatching ? URIs.get_match_profile_projects : URIs.get_profiles_projects;
        const res = (await request<IProfileProjectListRqRs>({
          method: 'get',
          url,
        }));
        this.setProjects(res);
        this.setImportedProjects(res);
        if (res.projects.length > 0) store.profileModel.snbCheckAdditional.projectInfo.completeYn = true;
        else store.profileModel.snbCheckAdditional.projectInfo.completeYn = false;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    setProjects = (projects: IProfileProjectListRqRs) => {
      this.projects = projects.projects;
    }

    @action
    setImportedProjects = (projects: IProfileProjectListRqRs) => {
      const tmpArray = [];
      if (projects.projects && projects.projects.length > 0) {
        for (let index = 0; index < projects.projects.length; index++) {
          const newContribution:ReactSelectOption = { label: '', value: 0 };
          const item = projects.projects[index];
          if (item) {
            const newItem:IProfileProjectDto = {
              title: item.title,
              startDate: item.startDate,
              endDate: item.endDate,
              teamType: item.teamType,
              contribution: undefined,
              performance: item.performance,
              institution: item.institution,
              skills: item.skills,
            };
            if (item.contribution && item.contribution > 0) {
              newContribution.value = item.contribution;
              newContribution.label = `${item.contribution}%`;
              newItem.contribution = newContribution;
            }

            tmpArray.push(newItem);
          }
        }
      }
      this.importedProjects = tmpArray;
    }

    @action
    saveProjects = async (projects:Partial<IProfileProjectListRqRs>, isMatching?: boolean) => {
      try {
        const method = isMatching ? 'post' : 'put';
        const url = isMatching ? URIs.post_match_profile_projects : URIs.put_profiles_projects;
        await request({
          method,
          url,
          data: projects,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    addProject = async (projectRq:Partial<IProfileProjectSaveRq>) => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.post_profiles_projects,
          data: { projectRq },
        }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    editProject = async (projectSn:number, projectRq:Partial<IProfileProjectSaveRq>) => {
      try {
        const res = (await request({
          method: 'put',
          url: URIs.put_profiles_projects_projectSn,
          params: { projectSn },
          data: { projectRq },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    deleteProject = async (projectSn:number) => {
      try {
        const res = (await request({
          method: 'delete',
          url: URIs.delete_profiles_projects_projectSn,
          params: { projectSn },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    searchSkills = async (keyword:string) => {
      try {
        this.searchedSkills = (await request<ISkillListRs>({
          method: 'get',
          url: URIs.get_skills,
          params: { keyword },
        })).skills;
      } catch (e) {
        console.error(e);
        this.searchedSkills = [];
      }
    }

    @action
    loadRecommendSkills = async (skillCode: number) => {
      try {
        this.recommendSkills = (await request<ISkillRecommendListRs>({
          method: 'get',
          url: URIs.get_skills_recommend(skillCode),
        })).recommendSkills;
      } catch (e) {
        console.error(e);
        this.recommendSkills = [];
      }
    }
}
