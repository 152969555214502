import Colors from '__designkit__/common/colors';
import RadioGroup, { RadioOption } from '__pc__/components/common/v2Design/radio';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import V2ColorPalette from '__pc__/constant/v2Design/Colors/V2ColorPalette';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import activeSearchIcon from 'assets/_v2/match/icon_invitation_star.png';
import openToOffersIcon from 'assets/_v2/match/icon_send_blue.png';
import MatchServiceToggle from 'components/_v2/profile/match/MatchServiceToggle';
import { LoggingType } from 'consts/_v2/LoggingType';
import { JobSearchStatus, JobSearchStatusType } from 'consts/MatchType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useMatchJobSearchStatus } from 'query/match/useMatchQuery';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import MatchSingleMenuItem from './MatchSingleMenuItem';

const Frame = styled.div`
  background: ${Colors.WHITE_100};
`;

const SuggestionFrame = styled.div`
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  `;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .title-group {
    display: flex;
    gap: 8px;
    .green-text-frame{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${V2ColorPalette.C_GREEN_25};
      border-radius: 4px;
      padding: 4px 8px;
    }
    .violet-text-frame{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${V2ColorPalette.C_VIOLET_25};
      border-radius: 4px;
      padding: 4px 8px;
    }
    .gray-text-frame{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${V2ColorPalette.C_GRAY_50};
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
`;

const OptionFormFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  .green-frame{
    display: flex;
    flex: none;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: ${V2ColorPalette.C_GREEN_25};
    align-items: center;
    justify-content: center;
  }
  .sky-frame{
    display: flex;
    flex: none;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-color: ${V2ColorPalette.C_SKYBLUE_25};
    align-items: center;
    justify-content: center;
  }
  .icon{
    width: 40px;
    height: 40px;
  }
`;

enum MatchingRequiredItemType {
  GENERAL_PERSONALITY = 'accPersonalityCompleteYn', // 일반 직군 성향검사
  INTRODUCTION = 'selfBrandingCompleteYn', // 소개
  MATCHING_PROFILE = 'matchingProfileRequiredCompleteYn', // 매칭 프로필
  DEVELOPER_PERSONALITY = 'paccPersonalityCompleteYn', // 개발직군 성향검사
  DEVELOPER_SKILL = 'pstCompleteYn', // 개발 구현 능력 검사
  PREFERENCE = 'preferenceCompleteYn', // 선호 정보
}

interface IMatchingRequiredItem {
  key: MatchingRequiredItemType;
  title: string;
  type: '공통' | '일반 직군 매칭' | '개발 직군 매칭';
}

const MATCHING_REQUIRED_ITEMS: IMatchingRequiredItem[] = [
  {
    key: MatchingRequiredItemType.INTRODUCTION,
    title: '소개',
    type: '공통',
  },
  {
    key: MatchingRequiredItemType.MATCHING_PROFILE,
    title: '매칭 프로필',
    type: '공통',
  },
  {
    key: MatchingRequiredItemType.GENERAL_PERSONALITY,
    title: '일반 직군 성향검사',
    type: '일반 직군 매칭',
  },
  {
    key: MatchingRequiredItemType.DEVELOPER_PERSONALITY,
    title: '개발직군 성향검사',
    type: '개발 직군 매칭',
  },
  {
    key: MatchingRequiredItemType.DEVELOPER_SKILL,
    title: '개발 구현 능력 검사',
    type: '개발 직군 매칭',
  },
];

interface IMatchApplySettingsJobSearchStatusProps {
  login?: Login;
}

const MatchApplySettingsJobSearchStatus: FC<IMatchApplySettingsJobSearchStatusProps> = ({ login = new Login() }) => {
  const [jobStatus, setJobStatus] = useState<JobSearchStatusType | null>(login.matchConfigStatus?.jobSearchStatus || null);

  const saveSuccess = async () => {
    await login.getMatchConfigStatus();
  };
  const { mutate: saveJobSearchStatus } = useMatchJobSearchStatus(jobStatus || JobSearchStatus.ACTIVE_SEARCH, saveSuccess);

  useEffect(() => {
    const init = async () => {
      await login.getMatchConfigStatus();
    };

    init();
  }, []);
  useEffect(() => {
    if (login.matchConfigStatus?.jobSearchStatus) {
      setJobStatus(login.matchConfigStatus.jobSearchStatus);
    } else {
      setJobStatus(null);
    }
  }, [login.matchConfigStatus?.jobSearchStatus]);

  const handleStatusClick = (status: JobSearchStatusType) => {
    if (status === JobSearchStatus.NOT_LOOKING_FOR_JOB) {
      // 관심없음 클릭시 상태만 변경하고 API 호출은 하지 않음
      setJobStatus(status);
    } else {
      // 다른 상태 클릭시 바로 API 호출
      onSubmit(status);
    }
  };

  const onSubmit = async (status: JobSearchStatusType) => {
    setJobStatus(status);

    saveJobSearchStatus(status);
  };

  const handleRadioChange = (value?: string) => {
    if (value) {
      handleStatusClick(value as JobSearchStatusType);
    }
  };

  return (
    <Frame>

      <SuggestionFrame>
        <Header>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_1_sb}
            color={V2TextOption.color.default}
          >
            제안 설정
          </V2Text>

          <MatchServiceToggle sourcePage={LoggingType.MATCH} />
        </Header>

        <OptionFormFrame>
          <MatchSingleMenuItem
            leftIcon={(
              <div className='green-frame'>
                <img src={activeSearchIcon} alt='적극적으로 구직 중' className='icon' />
              </div>
            )}
            title='적극적으로 구직 중'
            subTitle='선호조건이 완벽히 일치하지 않아도, 여러 제안을 받고 싶어요.'
            rightIcon={(
              <RadioGroup.Radio
                size={RadioOption.size.L}
                selected={jobStatus === JobSearchStatus.ACTIVE_SEARCH}
                disabled={!login.matchConfigStatus?.matchingConfigYn}
                value={JobSearchStatus.ACTIVE_SEARCH}
                onClick={() => handleStatusClick(JobSearchStatus.ACTIVE_SEARCH)}
              />
            )}
            onClick={() => handleStatusClick(JobSearchStatus.ACTIVE_SEARCH)}
            selected={jobStatus === JobSearchStatus.ACTIVE_SEARCH}
            disabled={!login.matchConfigStatus?.matchingConfigYn}
          />
          <MatchSingleMenuItem
            leftIcon={(
              <div className='sky-frame'>
                <img src={openToOffersIcon} alt='매칭 제안 희망' className='icon' />
              </div>
            )}
            title='맞춤 제안만 받기'
            subTitle='내가 원하는 조건과 정확히 맞는 제안만 받고 싶어요.'
            rightIcon={(
              <RadioGroup.Radio
                size={RadioOption.size.L}
                selected={jobStatus === JobSearchStatus.OPEN_TO_OFFERS}
                disabled={!login.matchConfigStatus?.matchingConfigYn}
                value={JobSearchStatus.OPEN_TO_OFFERS}
                onClick={() => handleStatusClick(JobSearchStatus.OPEN_TO_OFFERS)}
              />
            )}
            onClick={() => handleStatusClick(JobSearchStatus.OPEN_TO_OFFERS)}
            selected={jobStatus === JobSearchStatus.OPEN_TO_OFFERS}
            disabled={!login.matchConfigStatus?.matchingConfigYn}
          />
        </OptionFormFrame>
      </SuggestionFrame>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApplySettingsJobSearchStatus));
