const protocolRegex = /(http:\/\/|https:\/\/)/;
const includeJobdaUrlRegex = /(www.jobda.im|jobda.im|www-jobda-im.kr-st-jainwon.com|www-jobda-im.kr-dv-jainwon.com |st2-m-jobda-im.kr-st-jainwon.com|^\/)/;
const exceptionUrlRegex = /^(https:\/\/acca\.jobda\.im($|\/.*)|https:\/\/adjustment\.match\.jobda\.im($|\/.*))/;

export default class TextUtil {
  static formatWithProtocol(url: string) {
    if (url.match(protocolRegex)) return url;
    return `//${url}`;
  }

  static formatWithProtocolHttp(url: string) {
    if (url.match(protocolRegex)) return url;
    return `https://${url}`;
  }

  static textLengthEllipsis(text: string, len :number) {
    if (text.length >= len) {
      return `${text.substring(0, len)}...`;
    }
    return text;
  }

  static checkIncludeJobdaUrl(url: string) {
    if (url.match(includeJobdaUrlRegex)) return true;
    return false;
  }

  static checkExceptionUrl(url: string) {
    if (url.match(exceptionUrlRegex)) return true;
    return false;
  }

  static numberToCommaNumber=(value:number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  static removeParenthesesContent(text: string) {
    return text.replace(/\([^)]*\)/g, '').trim();
  }
}
