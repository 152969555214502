/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export enum V2FontStyleType {
  display_1_b = 'display_1_b',
  display_1_sb = 'display_1_sb',
  display_2_b = 'display_2_b',
  display_2_sb = 'display_2_sb',
  display_3_b = 'display_3_b',
  display_3_sb = 'display_3_sb',
  heading_1_b = 'heading_1_b',
  heading_1_sb = 'heading_1_sb',
  heading_1_m = 'heading_1_m',
  heading_2_b = 'heading_2_b',
  heading_2_sb = 'heading_2_sb',
  heading_2_m = 'heading_2_m',
  heading_3_b = 'heading_3_b',
  heading_3_sb = 'heading_3_sb',
  heading_3_m = 'heading_3_m',
  title_1_b = 'title_1_b',
  title_1_sb = 'title_1_sb',
  title_1_m = 'title_1_m',
  title_2_b = 'title_2_b',
  title_2_sb = 'title_2_sb',
  title_2_m = 'title_2_m',
  title_3_b = 'title_3_b',
  title_3_sb = 'title_3_sb',
  title_3_m = 'title_3_m',
  body_1_b = 'body_1_b',
  body_1_sb = 'body_1_sb',
  body_1_m = 'body_1_m',
  body_2_b = 'body_2_b',
  body_2_sb = 'body_2_sb',
  body_2_m = 'body_2_m',
  body_3_b = 'body_3_b',
  body_3_sb = 'body_3_sb',
  body_3_m = 'body_3_m',
  caption_1_sb = 'caption_1_sb',
  caption_1_m = 'caption_1_m',
  caption_2_sb = 'caption_2_sb',
  caption_2_m = 'caption_2_m',
}

export const V2FontStyle = {
  display_1_b: {
    fontSize: '64px',
    fontWeight: '700',
    lineHeight: '82px',
    letterSpacing: '-0.64px',
  },
  display_1_sb: {
    fontSize: '64px',
    fontWeight: '600',
    lineHeight: '82px',
    letterSpacing: '-0.64px',
  },
  display_2_b: {
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '72px',
    letterSpacing: '-0.56px',
  },
  display_2_sb: {
    fontSize: '56px',
    fontWeight: '600',
    lineHeight: '72px',
    letterSpacing: '-0.56px',
  },
  display_3_b: {
    fontSize: '48px',
    fontWeight: '700',
    lineHeight: '60px',
    letterSpacing: '-0.48px',
  },
  display_3_sb: {
    fontSize: '48px',
    fontWeight: '600',
    lineHeight: '60px',
  },
  heading_1_b: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '52px',
  },
  heading_1_sb: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '52px',
  },
  heading_1_m: {
    fontSize: '40px',
    fontWeight: '500',
    lineHeight: '52px',
  },
  heading_2_b: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '40px',
  },
  heading_2_sb: {
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '40px',
  },
  heading_2_m: {
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: '40px',
  },
  heading_3_b: {
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '38px',
  },
  heading_3_sb: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '38px',
  },
  heading_3_m: {
    fontSize: '28px',
    fontWeight: '500',
    lineHeight: '38px',
  },
  title_1_b: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  title_1_sb: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
  },
  title_1_m: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '32px',
  },
  title_2_b: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
  },
  title_2_sb: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  title_2_m: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '28px',
  },
  title_3_b: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '26px',
  },
  title_3_sb: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '26px',
  },
  title_3_m: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '26px',
  },
  body_1_b: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  body_1_sb: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  body_1_m: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  body_2_b: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px',
  },
  body_2_sb: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '22px',
  },
  body_2_m: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  },
  body_3_b: {
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '18px',
  },
  body_3_sb: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '18px',
  },
  body_3_m: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '18px',
  },
  caption_1_sb: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
  },
  caption_1_m: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
  },

  caption_2_sb: {
    fontSize: '11px',
    fontWeight: '600',
    lineHeight: '14px',
  },
  caption_2_m: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '14px',
  },
};


export const V2FontStyleDirect = {
  display_1_b: '700 64px/82px Pretendard; letter-spacing: -0.64px',
  display_1_sb: '600 64px/82px Pretendard; letter-spacing: -0.64px',
  display_2_b: '700 56px/72px Pretendard; letter-spacing: -0.56px',
  display_2_sb: '600 56px/72px Pretendard; letter-spacing: -0.56px',
  display_3_b: '700 48px/60px Pretendard; letter-spacing: -0.48px',
  display_3_sb: '600 48px/60px Pretendard; letter-spacing: -0.48px',
  heading_1_b: '700 40px/52px Pretendard',
  heading_1_sb: '600 40px/52px Pretendard',
  heading_1_m: '500 40px/52px Pretendard',
  heading_2_b: '700 32px/40px Pretendard',
  heading_2_sb: '600 32px/40px Pretendard',
  heading_2_m: '500 32px/40px Pretendard',
  heading_3_b: '700 28px/38px Pretendard',
  heading_3_sb: '600 28px/38px Pretendard',
  heading_3_m: '500 28px/38px Pretendard',
  title_1_b: '700 24px/32px Pretendard',
  title_1_sb: '600 24px/32px Pretendard',
  title_1_m: '500 24px/32px Pretendard',
  title_2_b: '700 20px/30px Pretendard',
  title_2_sb: '600 20px/30px Pretendard',
  title_2_m: '500 20px/30px Pretendard',
  title_3_b: '700 18px/28px Pretendard',
  title_3_sb: '600 18px/28px Pretendard',
  title_3_m: '500 18px/28px Pretendard',
  body_1_b: '700 16px/24px Pretendard',
  body_1_sb: '600 16px/24px Pretendard',
  body_1_m: '500 16px/24px Pretendard',
  body_2_b: '700 14px/20px Pretendard',
  body_2_sb: '600 14px/20px Pretendard',
  body_2_m: '500 14px/20px Pretendard',
  body_3_b: '700 13px/18px Pretendard',
  body_3_sb: '600 13px/18px Pretendard',
  body_3_m: '500 13px/18px Pretendard',
  caption_1_sb: '600 12px/16px Pretendard',
  caption_1_m: '500 12px/16px Pretendard',
  caption_2_sb: '600 11px/14px Pretendard',
  caption_2_m: '500 11px/14px Pretendard',
};
