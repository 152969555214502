import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ConditionFrame from 'components/_v2/profile/condition/ConditionFrame';
import JobTab from 'components/_v2/profile/condition/JobTab';
import LocationTab from 'components/_v2/profile/condition/LocationTab';
import SalaryTab from 'components/_v2/profile/condition/SalaryTab';
import WelfareTab from 'components/_v2/profile/condition/WelfareTab';
import WorkingPreferenceTab from 'components/_v2/profile/condition/WorkingPreferenceTab';
import { LocationCode, LocationCodeList } from 'consts/CompanyLocationType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const ConditionListFrame = styled.div``;
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(56px);
  background: ${Colors.WHITE_100};
  padding: 0 8px;
  position: sticky;
  top:0;
  z-index:1;
`;

const BottomFrame = styled.div`
  padding: 24px;
`;

interface IConditionEditModalProps extends IComponentProps {
  isOpen: boolean;
  onClose: () => void;
  login?: Login;
  profileModel?: ProfileModel;}

const ConditionEditModal: FC<IConditionEditModalProps> = ({ isOpen, onClose, login = new Login(), profileModel = new ProfileModel() }) => {
  const [jobGuideText, setJobGuideText] = useState<string>('희망하는 직군 · 직무를 선택해 주세요.');
  const [locationGuideText, setLocationGuideText] = useState<string>('희망하는 근무 지역을 선택해 주세요.');
  const [salaryGuideText, setSalaryGuideText] = useState<string>('선호하는 연봉 수준을 입력해 주세요.');
  const [welfareGuideText, setWelfareGuideText] = useState<string>('선호하는 복지 키워드를 선택해 주세요.');
  const [etcGuideText, setEtcGuideText] = useState<string>('어떤 일하고 싶은지 자유롭게 작성해 주세요.');
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const [conditionRegisterType, setConditionRegisterType] = useState<ConditionRegisterType>(ConditionRegisterType.LIST);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const onClickCloseBtn = () => {
    if (conditionRegisterType === ConditionRegisterType.LIST) onClose();
    else setConditionRegisterType(ConditionRegisterType.LIST);
  };
  useEffect(() => {
    setConditionRegisterType(ConditionRegisterType.LIST);
    return () => {
      setConditionRegisterType(ConditionRegisterType.LIST);
    };
  }, [isOpen]);
  useEffect(() => {
    if (!profileConditionsData) return;

    const { jobGroups, locations, salary, welfare, workingPreference } = profileConditionsData;
    const updateJobGuideText = () => {
      if (jobGroups.length === 0) return;
      const allJobTitles = jobGroups.flatMap((group) => group.jobTitleNames);
      const uniqueJobTitles:any = [...new Set(allJobTitles)];
      if (uniqueJobTitles.length === 1) {
        setJobGuideText(uniqueJobTitles[0]);
      } else {
        setJobGuideText(`${uniqueJobTitles[0]} 외 ${uniqueJobTitles.length - 1}개`);
      }
    };

    const updateLocationGuideText = () => {
      if (locations.length === 0) return;
      const includesAllLocations = LocationCodeList.filter((code) => code !== 10000).every((location) => locations.includes(location));
      if (includesAllLocations) {
        setLocationGuideText('전체 지역');
      } else {
        setLocationGuideText(locations.length === 1 ? LocationCode(locations[0]) : `${LocationCode(locations[0])} 외 ${locations.length - 1}개`);
      }
    };

    const updateSalaryGuideText = () => {
      if (salary > 0) setSalaryGuideText(`${salary.toLocaleString()}만원 이상`);
    };

    // const updateWelfareGuideText = () => {
    //   if (welfare.length === 0) return;
    //   setWelfareGuideText(welfare.length === 1 ? welfare[0].name : `${welfare[0].name} 외 ${welfare.length - 1}개`);
    // };

    const updateEtcGuideText = () => {
      if (workingPreference) setEtcGuideText(workingPreference);
    };

    updateJobGuideText();
    updateLocationGuideText();
    updateSalaryGuideText();
    // updateWelfareGuideText();
    updateEtcGuideText();
  }, [profileConditionsData]);
  return !isOpen ? <></> : (
    <JDFullModal>
      <Header>
        <Icon name='large-arrow-left' size={32} color={Colors.JOBDA_BLACK} onClick={onClickCloseBtn} />
      </Header>
      <ConditionListFrame hidden={conditionRegisterType !== ConditionRegisterType.LIST}>
        <ProfileEditTitleComponent
          title='선호하는 근무 조건을 알려주세요.'
          subtitle='입력하신 정보에 딱 맞는 추천 공고와 포지션을 제안해 드릴게요.'
        />
        <BottomFrame>
          <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.JOBGROUP)} title='직군 · 직무' required description={jobGuideText} />
          <SpacingBlock vertical size={12} />
          <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.LOCATION)} title='근무 지역' required description={locationGuideText} />
          <SpacingBlock vertical size={12} />
          <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.SALARY)} title='연봉' required description={salaryGuideText} />
          <SpacingBlock vertical size={12} />
          {/* <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.WELFARE)} title='복지 혜택' required={false} description={welfareGuideText} />
          <SpacingBlock vertical size={12} /> */}
          <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.WORKING_PREFERENCE)} title='기타 희망사항' required={false} description={etcGuideText} />
        </BottomFrame>
      </ConditionListFrame>
      <JobTab setConditionRegisterType={setConditionRegisterType} jobGroupData={profileConditionsData?.jobGroups} hidden={conditionRegisterType !== ConditionRegisterType.JOBGROUP} />
      <SalaryTab setConditionRegisterType={setConditionRegisterType} salaryData={profileConditionsData?.salary} hidden={conditionRegisterType !== ConditionRegisterType.SALARY} />
      {/* <WelfareTab setConditionRegisterType={setConditionRegisterType} welfareData={profileConditionsData?.welfare} hidden={conditionRegisterType !== ConditionRegisterType.WELFARE} /> */}
      <LocationTab setConditionRegisterType={setConditionRegisterType} locationData={profileConditionsData?.locations} hidden={conditionRegisterType !== ConditionRegisterType.LOCATION} />
      <WorkingPreferenceTab setConditionRegisterType={setConditionRegisterType} workingPreferenceData={profileConditionsData?.workingPreference} hidden={conditionRegisterType !== ConditionRegisterType.WORKING_PREFERENCE} />
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsDirtyModalOpen(false)} onClickClose={() => setIsDirtyModalOpen(false)} />

    </JDFullModal>
  );
};

export default inject(injectStore.login, injectStore.profileModel)(observer(ConditionEditModal));
