import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';

export enum MatchingInformationFieldType{
  SELF_INTRODUCTION='SELF_INTRODUCTION',
  PHOTO='PHOTO',
  COMPLETED_PERSONALITY_APPLY='COMPLETED_PERSONALITY_APPLY',
  COMPLETED_DEVELOPER_PERSONALITY_APPLY='COMPLETED_DEVELOPER_PERSONALITY_APPLY',
  COMPLETED_PST_APPLY='COMPLETED_PST_APPLY',
}

export enum MatchingInformationFieldTypeText{
  SELF_INTRODUCTION='자기소개 문구',
  PHOTO='나를 표현하는 사진',
  COMPLETED_PERSONALITY_APPLY='성향검사',
  COMPLETED_DEVELOPER_PERSONALITY_APPLY='개발자 성향검사',
  COMPLETED_PST_APPLY='개발 구현 능력 검사',
}

export interface IAttachFile {
  fileName: string;
  fileUid: string;
  fileUrl: string;
  filePath: string;
}

export interface IGetMatchingFilesRs {
  files: IAttachFile[];
}

export interface IPostIntroductionRq {
  title: string;
  content: string;
}

export interface IUploadPhotosParams {
  formData: FormData;
  descriptions: string[];
}

export interface IGetIntroductionRs {
  title: string;
  content: string;
}

export interface IGetExistFilesRs {
  hasMatchingSelfIntroductionVideo: boolean;
  hasMatchingMotivationVideo: boolean;
  hasMatchingProfilePhoto: boolean;
  hasMatchingSelfExpressionPhoto: boolean;
}

export interface IGetMatchingFilesRq {
  type: VideoAndImagesType;
}

export interface ICheckExistFilesRq {
  type: VideoAndImagesType;
}

export interface IMatchingInformationFieldRs{
  field:MatchingInformationFieldType;
  isCompleted:boolean
}
export interface IGetMatchingInformationFieldExistsRs{
  isFinalSubmit:boolean
  submitDeadline:string
  requiredFields:IMatchingInformationFieldRs[];
}

export interface IMatchApplicantPhotoUploadRq {
  descriptions: string[];
}
