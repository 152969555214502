/* eslint-disable camelcase */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import Icon from '__designkit__/icon/Icon';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import MainBanner1 from 'assets/_v2/profile/img_load_match_profile.png';
import Loading from 'components/Loading';
import { ProfileMetaTag } from 'components/_v2/_common/meta/MetaTag';
import ProfileAttachedFileView from 'components/_v2/profile/attachedFile/ProfileAttachedFileView';
import ProfileBasicInfoView from 'components/_v2/profile/baseInfo/ProfileBasicInfoView';
import ProfileCareersView from 'components/_v2/profile/careers/ProfileCareersView';
import ProfileEducationView from 'components/_v2/profile/education/ProfileEducationView';
import ProfileExperienceView from 'components/_v2/profile/experience/ProfileExperienceView';
import ProfilePreferentialView from 'components/_v2/profile/preferential/ProfilePreferentialView';
import ProfilePrizeView from 'components/_v2/profile/prize/ProfilePrizeView';
import ProfileProjectView from 'components/_v2/profile/project/ProfileProjectView';
import ProfileSkillView from 'components/_v2/profile/skill/ProfileSkillView';
import { DialogType } from 'components/_v2/videoRecord/VideoRecordGuideHorizontalModal';
import NoticeDialog from 'components/modals/NoticeDialog';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import { usePostMatchProfileFromProfile, useUserDisplayNotification, usePutUsersDisplayNotification, useMatchHomeDashboard } from 'query/match/useMatchQuery';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { useMatchApplicantFiles } from 'query/match/applicants/useMatchApplicantsQuery';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';

import { DisplayNotificationType } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import MatchApplyProfileSnb from './MatchApplyProfileSnb';
import MatchApplyProfileSelfBranding from './MatchApplyProfileSelfBranding';
import MatchApplyProfileFloating from './MatchApplyProfileFloating';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 16px 64px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.CG_40};
`;

const PaddingFrame = styled.div`
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
`;

const StyledSNB = styled.div`
  position: fixed;
  bottom: calc(81px + env(safe-area-inset-bottom));
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${Colors.G_150};
  filter: ${Shadows.Shadow_300};
`;

const ResumeCheckFrame = styled.div`
  width:100%;
  padding:24px 16px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${Colors.Y_100};
  border-bottom: ${Colors.Y_100};
  background-color: ${Colors.Y_30};
  .text-frame{
    font:${Fonts.B2_Bold};
    color:${Colors.JOBDA_BLACK};
    span{
      background-color:${Colors.Y_100};
    }
  }
  .confirm-btn{
    display:flex;
    padding:9px 18px;
    border-radius: 17px;
    background-color:${Colors.JOBDA_BLACK};
    justify-content: center;
    align-items: center;
    font:${Fonts.B3_Medium};
    color:${Colors.WHITE_100};
  }
`;

const HeaderBannerFrame = styled.div`
  padding:${SpaceValue.XS}px ${SpaceValue.L}px;
`;

const HeaderUpdateFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding:${SpaceValue.L}px;
  gap:${SpaceValue.XS}px;
  .title-frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  border-bottom: 1px solid ${V2BorderColors.default};

`;
const BannerFrame = styled.div<{backgroundColor:string}>`
  width: 100%;
  height: 88px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding:21px ${SpaceValue.XL}px;
  position: relative;
  .subtitle-frame{
    display: flex;
    gap:2px;

  }
  .profile-banner-img{
    width: 140px;
    height: auto;
    position: absolute;
    right:5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface IMatchApplyProfile {
    login?:Login
    profileModel?:ProfileModel
    profileBasicInfoModel?:ProfileBasicInfoModel
}
const MatchApplyProfile:FC<IMatchApplyProfile> = ({ login = new Login(), profileModel = new ProfileModel(), profileBasicInfoModel = new ProfileBasicInfoModel() }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isOpenSNB, setIsOpenSNB] = useState<boolean>(false);
  const [isOpenBasicInfo, setIsOpenBasicInfo] = useState<boolean>(false);
  const [isOpenSelfIntroduction, setIsOpenSelfIntroduction] = useState<boolean>(false);
  const [isOpenLessFloating, setIsOpenLessFloating] = useState<boolean>(false);
  const [isOpenMoreFloating, setIsOpenMoreFloating] = useState<boolean>(false);
  const { search } = useLocation();
  const { moveToBasicInfo, moveToSelfIntroduction } = CommonUtils.parseQuery(search);
  const { refetch } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO);
  const { data: dashboardData } = useMatchHomeDashboard();

  const { mutate: postMatchProfileFromProfile } = usePostMatchProfileFromProfile(
    async () => {
      await profileModel.checkEssential(true);
      await profileModel.checkAdditional(true);
      await profileBasicInfoModel.loadBaseInfo();
      refetch();
      setIsUpdateModal(false);
      setIsOpenSNB(false);
    },
  );
  useEffect(() => {
    (async () => {
      if (!login.userDefault) await login.loadUserDefault();
      if (login.userInfo) {
        await login.getMatchConfigStatus();
        await profileModel.checkEssential(true);
        await profileModel.checkAdditional(true);
      }
      setIsLoading(false);
    })();
  }, [login, profileModel]);
  useEffect(() => {
    if (moveToBasicInfo) {
      setIsOpenBasicInfo(true);
    } else if (moveToSelfIntroduction) {
      setIsOpenSelfIntroduction(true);
    }
  }, [moveToBasicInfo, moveToSelfIntroduction]);
  useEffect(() => {
    const init = async () => {
      if (login.matchConfigStatus?.matchingConfigYn) {
        const check = await TodayRenderCheckUtil.getTodayRenderCheck('profile-floating');
        if (check) {
          if (dashboardData?.matchingProfilePercent && dashboardData.matchingProfilePercent < 60) {
            setIsOpenLessFloating(true);
          }
        } else if (dashboardData?.matchingProfilePercent && dashboardData.matchingProfilePercent < 60) {
          setIsOpenLessFloating(true);
        }
      }
    };
    init();
  }, [dashboardData, login.matchConfigStatus?.matchingConfigYn]);
  return (isLoading ? <Loading />
    : (
      <>
        <ProfileMetaTag />

        <HeaderBannerFrame>

          <BannerFrame backgroundColor={V2BackgroundColors.accent_skyblue_default} onClick={() => { setIsUpdateModal(true); }}>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_2_b}
              color={V2TextOption.color.inverse}
            >
              매칭 프로필, 한 번에 채우기!
            </V2Text>
            <div className='subtitle-frame'>
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.inverse}
              >
                내 프로필 불러오기
              </V2Text>
              <V2Icon name={V2IconOption.name.arrowRight} size={V2IconOption.size.XS} fill={V2IconOption.fill.inverse} />
            </div>
            <img src={MainBanner1} alt='profile-banner' className='profile-banner-img' />
          </BannerFrame>
        </HeaderBannerFrame>
        <HeaderUpdateFrame>
          <div className='title-frame'>
            <V2Text
              fontStyle={V2TextOption.fontStyle.title_3_sb}
              color={V2TextOption.color.default}
            >
              매칭 프로필
            </V2Text>
            <V2Text
              fontStyle={V2TextOption.fontStyle.caption_1_m}
              color={V2TextOption.color.subtlest}
            >
              {login.matchConfigStatus?.lastMatchingProfileUpdateDateTime && `${DateFormatYYYYMMDDWithDot(login.matchConfigStatus?.lastMatchingProfileUpdateDateTime)} 업데이트`}
            </V2Text>
          </div>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtle}
          >
            매칭에 활용되는 내 커리어 정보입니다.
          </V2Text>
        </HeaderUpdateFrame>
        <PaddingFrame>

          <ProfileBasicInfoView isSelfBranding suggestionModalCode={0} isOpenBasicInfo={isOpenBasicInfo} />
        </PaddingFrame>
        <Frame>
          <Divider />
          <MatchApplyProfileSelfBranding isOpenSelfIntroduction={isOpenSelfIntroduction} />
          <Divider />
          <ProfileEducationView suggestionModalCode={0} isMatching />
          <Divider />

          <ProfileCareersView isMatching />
          <Divider />

          <ProfileProjectView isMatching />
          <Divider />

          <ProfileSkillView isMatching />
          <Divider />

          <ProfileExperienceView isMatching />
          <Divider />

          <ProfilePrizeView isMatching />
          <Divider />

          <ProfilePreferentialView isMatching />
          <Divider />

          <ProfileAttachedFileView isMatching />
          <StyledSNB onClick={() => setIsOpenSNB((prevState) => !prevState)}>
            <Icon
              name={isOpenSNB ? 'close' : 'add'}
              size={32}
              color={Colors.WHITE_100}
              className='icon'
            />
          </StyledSNB>
          <NoticeDialog
            dialogType={DialogType.NOTICE}
            title='내 프로필 불러오기'
            content='현재 입력된 프로필 정보를 불러와 매칭 프로필을 업데이트하시겠습니까?'
            isOpen={isUpdateModal}
            onOk={() => {
              postMatchProfileFromProfile();
            }}
            onCancel={() => setIsUpdateModal(false)}
            firstButtonText='불러오기'
            secondButtonText='취소'
          />
          <MatchApplyProfileSnb
            onClickSuccess={() => {
              setIsUpdateModal(true);
            }}
            isOpen={isOpenSNB}
            onClickClose={() => setIsOpenSNB(false)}
          />
          <MatchApplyProfileFloating
            type='less'
            firstText={`현재 작성률 : ${dashboardData?.matchingProfilePercent}%`}
            secondText='프로필 작성률 60% 이상인 인재는 평균보다
더 많은 제안을 받았어요.'
            isOpen={isOpenLessFloating}
            onClickClose={() => {
              TodayRenderCheckUtil.setTodayRenderCheck('profile-floating');
              setIsOpenLessFloating(false);
            }}
          />
          <MatchApplyProfileFloating
            type='more'
            firstText={`최근 업데이트 : ${DateFormatYYYYMMDDWithDot(login.matchConfigStatus?.lastMatchingProfileUpdateDateTime || '')}`}
            secondText='인사담당자는 최근에 업데이트된
프로필 정보에 눈길이 가요.'
            isOpen={isOpenMoreFloating}
            onClickClose={() => {
              TodayRenderCheckUtil.setTodayRenderCheck('profile-floating');
              setIsOpenMoreFloating(false);
            }}
          />
        </Frame>
      </>
    )
  );
};

export default inject(injectStore.login, injectStore.profileModel, injectStore.profileBasicInfoModel)(observer(MatchApplyProfile));
