import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import { useMatchHomeDashboard } from 'query/match/useMatchQuery';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div.button-frame {
    display: flex;
  }
`;
const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const TitleText = styled.div`
  font: ${Fonts.B1_Bold_P};
  color: ${colors.JOBDA_BLACK};
  white-space: pre-wrap;
  text-align: center;
  margin-top: 16px;

`;
const Text = styled.div`
  
    margin-top: 8px;
    font:${Fonts.B2_Medium_P};
    color:${colors.CG_70};
`;

interface IMatchApplyBlurFrameProps extends IComponentProps {
  login?: Login;
  matchApplyModel?: MatchApplyModel;
}

const MatchApplyBlurFrame: FC<IMatchApplyBlurFrameProps> = ({ login = new Login(), matchApplyModel = new MatchApplyModel() }) => {
  const history = useHistory();
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const [textComponent, setTextComponents] = useState<JSX.Element>();
  const [btnText, setBtnText] = useState<string>();

  const onClickBtn = async () => {
    if (login.matchConfigStatus?.matchingConfigYn) {
      if (matchApplyModel.profile!.completionRate >= 60) {
        history.push(RoutePaths.position);
      } else {
        history.push(RoutePaths.match_profile);
      }
    } else if (!login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn) {
      history.push(RoutePaths.match_profile);
    } else if (login.matchConfigStatus?.generalYn) {
      if (!login.matchConfigStatus.matchingConfigCondition.accPersonalityCompleteYn) {
        history.push(RoutePaths.match_test);
      } else {
        await login.changeMatchConfigStatus(true);
        await login.getMatchConfigStatus();
        setIsOpenConditionInductionModal(true);
      }
    } else if (login.matchConfigStatus?.developerYn) {
      if (!login.matchConfigStatus.matchingConfigCondition.paccPersonalityCompleteYn) {
        history.push(RoutePaths.match_test);
      } else {
        await login.changeMatchConfigStatus(true);
        await login.getMatchConfigStatus();
        setIsOpenConditionInductionModal(true);
      }
    }
  };
  const { data: dashboardData, refetch } = useMatchHomeDashboard();

  useEffect(() => {
    (async () => {
      if (login.matchConfigStatus === null) await login.getMatchConfigStatus();
      if (matchApplyModel.profile === null) await matchApplyModel.loadMatchingProfile();
      if (login.matchConfigStatus?.matchingConfigYn) {
        const lastUpdate = new Date(login.matchConfigStatus?.lastMatchingProfileUpdateDateTime || '');
        const now = new Date();

        // 날짜 차이를 밀리초로 계산 후 일수로 변환
        const diffTime = Math.abs(now.getTime() - lastUpdate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.floor(diffDays / 30);
        if (dashboardData && dashboardData?.matchingProfilePercent >= 50 && diffMonths < 3) {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>제안을 기다리는 동안<br />잡다의 최신 채용 정보를 확인해 보세요.</Text></TextFrame>);
          setBtnText('채용 공고 확인하기');
        } else if (diffMonths >= 3) {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>마지막 프로필 업데이트가 {diffMonths}개월 전이에요.<br />최신 정보를 작성하면<br />입사 제안 확률이 20% 증가해요</Text></TextFrame>);
          setBtnText('프로필 업데이트하기');
        } else {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>매칭 프로필 완성도가 50% 이상이면<br />입사 제안 확률이 20% 이상 증가해요.</Text></TextFrame>);
          setBtnText('매칭 프로필 작성하기');
        }
      } else if (!login.matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn) {
        // 프로필 작성 안 한 사람.
        setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 매칭 프로필 작성하고<br />검증된 우수 기업에서 직접 제안 받아보세요.</Text></TextFrame>);
        setBtnText('매칭 프로필 작성하기');
      } else if (login.matchConfigStatus?.generalYn) {
        if (!login.matchConfigStatus.matchingConfigCondition.accPersonalityCompleteYn) {
          // 역량검사 안 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 매칭 검사 완료하고<br />750개 + 기업에서 먼저 제안 받아보세요.</Text></TextFrame>);
          setBtnText('매칭 검사 응시하기');
        } else {
          // 프로필 쓰고, 역량검사 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>검증된 우수 기업에서 제안 받을 준비 끝!<br />아래 버튼만 누르면 잡다매칭이 시작돼요.</Text></TextFrame>);
          setBtnText('우수 기업에서 제안받기');
        }
      } else if (login.matchConfigStatus?.developerYn) {
        if (!login.matchConfigStatus.matchingConfigCondition.paccPersonalityCompleteYn) {
          // 개역량검사 안 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 매칭 검사 응시하고<br />750개 + 기업에게 직접 입사 제안받아보세요.</Text></TextFrame>);
          setBtnText('매칭 검사 응시하기');
        } else {
          // 프로필 쓰고, 개역량검사 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>검증된 우수 기업에서 제안 받을 준비 끝!<br />아래 버튼만 누르면 잡다매칭이 시작돼요.</Text></TextFrame>);
          setBtnText('우수 기업에서 제안받기');
        }
      }
    })();
  }, [login.matchConfigStatus, login.matchConfigStatus, dashboardData]);

  return (
    <Frame>
      { textComponent }
      <SpacingBlock size={24} vertical />
      <V2Button buttonProps={{ onClick: onClickBtn }} fontStyle={V2ButtonOption.fontStyle.body_1_sb} color={V2ButtonOption.color.inverse} fill={V2ButtonOption.fillType.brand_strong_default}>
        {btnText}
      </V2Button>
      <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchApplyBlurFrame));
