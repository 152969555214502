import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import ConditionEditModal from 'components/_v2/profile/condition/ConditionEditModal';
import ConditionListPopUp from 'components/_v2/profile/match/ConditionListPopUp';
import { Divider1G40 } from 'components/divider/Divider';
import { RoutePaths } from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: fit-content;
  padding: 32px 20px 24px;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_300};

`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;

  &.semi {
    color: ${Colors.CG_70};
    font: ${Fonts.B2_Medium_P};

    > b {
        font: ${Fonts.B2_Bold_P};
        color: ${Colors.JOBDA_BLACK};
    }
  }

  .green {
    color: ${Colors.G_200};
  }

`;

const ButtonFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


    .confirm-button {
        margin-left: 8px;
    }
`;

interface IMatchServiceCancelConditionModalProps extends IJDBaseModal{
    isOpen: boolean;
    onClickClose: () => void;
    login?: Login;
}

const MatchServiceCancelConditionModal:FC<IMatchServiceCancelConditionModalProps> = ({ login = new Login(), isOpen, onSuccess, onClickClose }) => {
  const history = useHistory();

  return (
    <>
      <JDBaseModal
        dimmed
        isOpen={isOpen}
        onClickClose={onClickClose}
        isDimClickClose
      >
        <Frame>
          <Text>혹시 <span className='green'>선호하는 근무조건</span>이<br />바뀌셨나요?</Text>
          <SpacingBlock size={8} vertical />
          <Text className='semi'>정확히 작성해주시면 <b>딱 맞는 제안</b>을 드릴게요.</Text>
          <SpacingBlock size={24} vertical />
          <Divider1G40 />
          <SpacingBlock size={24} vertical />
          <ConditionListPopUp />
          <SpacingBlock size={20} vertical />
          <ButtonFrame>
            <Button
              label='선호 정보 수정'
              buttonMode={ButtonMode.DEFAULT}
              size='large'
              outLined
              onClick={() => {
                onClickClose();
                history.push(`${RoutePaths.match_settings}?moveToPreference=true`);
              }}
            />
            <Button
              label='잡다 매칭 취소'
              className='confirm-button'
              buttonMode={ButtonMode.PRIMARY}
              size='large'
              onClick={onSuccess}
            />
          </ButtonFrame>
        </Frame>
      </JDBaseModal>
    </>
  );
};

export default inject(injectStore.login)(observer(MatchServiceCancelConditionModal));
