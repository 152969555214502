import ProfilePrizeEditModal from 'components/_v2/profile/prize/ProfilePrizeEditModal';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';

const Frame = styled.div`
`;

export interface IProfilePrizeProps{
  profileModel?:ProfileModel
  isMatching?: boolean;
}
const ProfilePrizeView:FC<IProfilePrizeProps> = ({ profileModel = new ProfileModel(), isMatching = false }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  const { prize } = profileModel;

  const closeDirtyModal = () => {
    profileModel.isPrizeDirty = false;
    setIsDirtyModalOpen(false);
  };

  const handleEditClick = () => {
    setIsEditOpen(true);
  };
  const checkAudit = (caseType:ProfileAuditItemType, index:number) => {
    if (prize === null || prize === undefined) return false;
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_PRIZE_DATE:
        return prize.prizes[index].auditItems?.includes(ProfileAuditItemType.PROFILE_PRIZE_DATE);
      default:
        return false;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='diamond' text='수상내역' subText='수상명, 수상연도 등을 적어주세요.' onClickEditBtn={handleEditClick} />
      { prize && prize.prizes.map((card, index) => (
        card.institution && <ProfileListCard editDate={checkAudit(ProfileAuditItemType.PROFILE_PRIZE_DATE, index)} key={`card-list${card.institution}`} institution={card.institution} date={card.date} name={card.name} description={card.description} index={index} length={prize?.prizes.length} />
      ))}
      <ProfilePrizeEditModal
        isOpen={isEditOpen}
        isMatching={isMatching}
        onClose={async () => {
          if (profileModel.isPrizeDirty) {
            setIsDirtyModalOpen(true);
          } else {
            await profileModel.loadPrize(isMatching);
            refetchAutoSave();
            setIsEditOpen(false);
          }
        }}
      />
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsEditOpen(false)} onClickClose={closeDirtyModal} />
    </Frame>

  );
};

export default inject(injectStore.profileModel)(observer(ProfilePrizeView));
