import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import shadows from '__designkit__/common/shadows';
import MatchApplyStatus from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatus';
import RoutePaths from 'consts/RoutePaths';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MatchApplyPersonality from './matchApplyTest/MatchApplyTest';
import MatchApplyProfile from './matchApplyProfile/MatchApplyProfile';
import MatchApplySettings from './matchApplySettings/MatchApplySettings';
import MatchApplyHome from './matchApplyHome/MatchApplyHome';

const LinkNaviTab = styled.nav`
    display: flex;
    position: sticky;
    top:56px;
    align-items: center;
    gap: 24px;
    padding: 16px 16px 0 16px;
    ${shadows.headers};
    overflow-x: scroll;
    width: 100%;
    background-color: ${colors.WHITE_100};
    z-index: 10001;

    white-space: nowrap; // 텍스트 줄바꿈 방지
    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .a-link {
        position:relative;
        width:fit-content;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
        padding-bottom: 16px;
        
        &[aria-current=true] {
            font: ${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK};
        }

        &[aria-current=true]:before {
          display:block;
          position:absolute;
          content:'';
          bottom: 0;
          left:0;
          width:100%;
          height:2px;
          background:${colors.JOBDA_BLACK};
        }
    }


`;

const MatchApplyTab = () => {
  const { pathname } = useLocation();

  return (
    <>
      <LinkNaviTab>
        <Link className='a-link' aria-current={pathname === RoutePaths.match} to={RoutePaths.match}>매칭 홈</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_status} to={RoutePaths.match_status}>매칭 현황</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_profile} to={RoutePaths.match_profile}>매칭 프로필</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_test} to={RoutePaths.match_test}>매칭 검사</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_settings} to={RoutePaths.match_settings}>매칭 설정</Link>
      </LinkNaviTab>
      {
        pathname === RoutePaths.match && <MatchApplyHome />
      }
      {
        pathname === RoutePaths.match_status && <MatchApplyStatus />
      }
      {
        pathname === RoutePaths.match_settings && <MatchApplySettings />
      }
      {
        pathname === RoutePaths.match_profile && <MatchApplyProfile />
      }
      {
        pathname === RoutePaths.match_test && <MatchApplyPersonality />
      }
    </>
  );
};

export default MatchApplyTab;
