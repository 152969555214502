import V2Text from '__pc__/components/common/v2Design/text';
import styled from 'styled-components';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { useV2MatchingHome } from 'query/match/useMatchQuery';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router-dom';
import IconAbility from './icon_person_ability.png';

const Frame = styled.div`
    display: flex;
    padding: ${SpaceValue.XS}px ${SpaceValue.L}px;
    background-color: ${V2BackgroundColors.accent_skyblue_subtle};
    align-items: center;
    justify-content: space-between;
    .img-text-frame{
        display: flex;
        align-items: center;
    }
    .img-ability{
        width: 24px;
        height: 24px;
    }
    .icon-frame{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: ${RadiusValue.S}px;
        padding: 4px;
        border: 1px solid ${V2BorderColors.default};
    }
`;
const MatchApplyHomeIntroduce = () => {
  const { data: matchingHomeData } = useV2MatchingHome(true);
  const history = useHistory();
  return (
    <Frame>
      <div
        className='img-text-frame'

      >
        <img src={IconAbility} alt='소개글' className='img-ability' />
        <SpacingBlock size={SpaceValue.M} vertical />
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_m}
          color={V2TextOption.color.default}
          ellipsis={1}
        >
          {matchingHomeData?.introductionTitle}
        </V2Text>
      </div>
      <SpacingBlock size={SpaceValue.L} vertical />
      <div
        className='icon-frame'
        role='button'
        onClick={() => {
          history.push(`${RoutePaths.match_profile}?moveToSelfIntroduction=true`);
        }}
      >
        <V2Icon name={V2IconOption.name.guide} fill={V2IconOption.fill.default} size={V2IconOption.size.S} />
      </div>
    </Frame>
  );
};

export default MatchApplyHomeIntroduce;
