import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Portal from 'components/common/Portal';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { keyFrameFadeIn, keyFrameFadeOut } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import useToast from 'hooks/useToast';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';

const Wrapper = styled.div<{ position: 'middle' | 'bottom', bottom?: number }>`
  position: fixed;
  bottom: ${({ position, bottom }) => (bottom ? `${bottom}px` : position === 'bottom' ? '78px' : '50%')};
  transform: ${({ position }) => (position === 'bottom' ? 'none' : 'translateY(50%)')};
  width: 100%;
  padding: 0 32px 0 0;
  height: fit-content;
  z-index: 10004;
`;

const TosatFrame = styled.div<{toast: 'less' | 'more'}>`
display: flex;
padding: ${SpaceValue.L}px;
border-radius: ${RadiusValue.M}px;
background-color: ${(props) => (props.toast === 'less' ? V2BackgroundColors.accent_skyblue_default : V2BackgroundColors.accent_teal_default)};
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 2px 6px 0px rgba(0, 0, 0, 0.30);
gap:${SpaceValue.L}px;
justify-content: space-between;
.left-frame{
    display: flex;
    flex-direction: column;
    gap:4px;
    max-width: 270px;
}
.right-frame{
    display: flex;
    align-items: center;
}
`;
interface IMatchApplyProfileFloatingProps extends IComponentProps {
    type:'less' | 'more'
    firstText:string
    secondText:string
    isOpen:boolean
    onClickClose:()=>void
}
const MatchApplyProfileFloating:FC<IMatchApplyProfileFloatingProps> = ({ type, firstText, secondText, isOpen, onClickClose }) => {
  if (!isOpen) return null;
  return (
    <Wrapper position='bottom' bottom={undefined}>
      <TosatFrame toast={type}>
        <div className='left-frame'>
          <V2Text fontStyle={V2TextOption.fontStyle.body_2_sb} color={V2TextOption.color.inverse}>
            {firstText}
          </V2Text>
          <V2Text styles={{ whiteSpace: 'pre-line' }} fontStyle={V2TextOption.fontStyle.body_3_m} color={V2TextOption.color.inverse}>
            {secondText}
          </V2Text>
        </div>
        <div className='right-frame' onClick={onClickClose} role='button'>
          <V2Icon name='close' size={V2IconOption.size.S} fill={V2IconOption.fill.inverse} />
        </div>
      </TosatFrame>
    </Wrapper>
  );
};

export default MatchApplyProfileFloating;
