import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts, { fonts, fontStyle } from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import JDPasswordInput from 'components/_v2/_common/input/JDPasswordInput';
import { Divider16G } from 'components/divider/Divider';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import { passwordValidator } from 'consts/ValidationRule';
import useToast from 'hooks/useToast';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextElementType, V2TextOption } from '__pc__/constant/v2Design/V2TextType';

const Frame = styled.div`
  padding-top:0;

  .frame-form {
    padding: 24px 20px;

    .row-info {
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      flex-wrap:wrap;

      .title {
        ${fontStyle.BODY1_SEMIBOLD};
        min-width:86px;
      }

      .frame-value {
        flex:1;
        color:${colors.CG_90};
        font: ${Fonts.B1_Medium};

        .jda-input {
          margin:0;
        }
      }

      &.full-line {
        .title,.frame-value {
          flex:none;
          width:100%;
        }

        .frame-value {
          margin-top:16px;
        }

        .title + .frame-value {
          margin-top:10px;
        }
      }
    }

    .btn-submit {
      margin-top: 32px;
      ${JDMainButton()};
      height: 46px;
    }
  }

  .description {
    margin-top:16px;
    font: ${Fonts.B2_Medium};
    color:${colors.CG_60};
    text-align:left;
    width:100%;

    &.warning {
      margin-top:6px;
      font: ${Fonts.B3_Medium};
      line-height:20px;
      letter-spacing:-0.36px;
      color:${colors.ERROR};
    }
  }
`;

const CheckList = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  .content {
    font: ${Fonts.B3_Medium};
    color: ${colors.ERROR};

    b {
      font: ${Fonts.B3_Bold};
    }
  }
`;

interface IPasswordChangeFormValues {
  currentPassword:string;
  newPassword:string;
  reNewPassword:string;
}

const validationSchema = yup.object().shape({
  currentPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  }),
  newPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  }),
  reNewPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  })
    .test('equalPassword', '비밀번호가 일치하지 않습니다.', ((value:string, record:any) => (value === (record.parent as IPasswordChangeFormValues).newPassword)) as any),
});

const MyPageAccount:FC<ILoginPageProps> = ({ login = new Login() }) => {
  const history = useHistory();
  const { setToastObject } = useToast();

  useEffect(() => {
    login.loadAccountRs();
    return () => {
      login.accountRs = null;
    };
  }, [login]);

  const useFormed = useForm<IPasswordChangeFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { formState, setError, handleSubmit, reset } = useFormed;

  const onSubmit = useCallback(async (data:IPasswordChangeFormValues) => {
    try {
      const res = await login.updatePassword(data.currentPassword, data.newPassword);
      reset({
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
      });
      if (!res)
        throw new Error('failed');
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '변경사항이 저장되었습니다.' });
    } catch (e) {
      const err = e as { response: { data: { errorCode: string; message: string } } };
      console.error(err);
      switch (err.response?.data?.errorCode) {
        case ErrorCode.B901:
          setError('currentPassword', { message: ErrorCodeText.put_users_password.B901 });
          break;
        case ErrorCode.B902:
          setError('newPassword', { message: ErrorCodeText.put_users_password.B902 });
          break;
        default:
          setError('reNewPassword', { message: err.response?.data?.message || '알 수 없는 에러입니다.' });
      }
    }
  }, [login, setError, reset]);

  if (!login.accountRs)
    return <></>;
  return (
    <Frame>
      <FormProvider {...useFormed}>
        <div className='frame-form'>
          <div className='row-info'>
            <div className='title'>아이디</div>
            <div className='frame-value'>{login.accountRs.id}</div>
          </div>
          <SpacingBlock size={24} vertical />
          <div className='row-info full-line'>
            <div className='title'>현재 비밀번호</div>
            <div className='frame-value'>
              <JDPasswordInput
                name='currentPassword'
                placeholder='현재 비밀번호를 입력해 주세요.'
              />
            </div>
          </div>
          <SpacingBlock size={24} vertical />
          <div className='row-info full-line'>
            <div className='title'>새 비밀번호</div>
            <div className='frame-value'>
              <JDPasswordInput
                name='newPassword'
                placeholder='새 비밀번호를 입력해 주세요.'
              />
            </div>
            <div className='frame-value'>
              <JDPasswordInput
                name='reNewPassword'
                placeholder='새 비밀번호를 한번 더 입력해 주세요.'
              />
            </div>
          </div>
          {/* 이걸 V2Button로 바꿔야함 */}
          {/* <button type='button' className='btn-submit' disabled={!formState.isValid} onClick={handleSubmit(onSubmit)}>
            변경사항 저장
          </button> */}
          <SpacingBlock size={32} vertical />
          <V2Button
            type={V2ButtonOption.type.Fill}
            color={V2ButtonOption.color.inverse}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            fill={V2ButtonOption.fillType.brand_strong_default}
            buttonProps={{ onClick: () => handleSubmit(onSubmit), disabled: !formState.isValid }}
            size={V2ButtonOption.size.L}
            styles={{ width: '100%' }}
          >
            변경사항 저장
          </V2Button>
        </div>
        <Divider16G />
        <div className='frame-form'>
          <V2Button
            type={V2ButtonOption.type.OutLine}
            color={V2ButtonOption.color.subtle}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            buttonProps={{
              onClick: async () => {
                await login.logout();
                history.push(RoutePaths.root);
              },
            }}
            size={V2ButtonOption.size.L}
            styles={{ width: '100%' }}
          >
            로그아웃
          </V2Button>
        </div>
        <Divider16G />
        <div className='frame-form'>
          <div className='row-info full-line'>
            <div className='title'>계정 및 계정정보 삭제</div>
            <SpacingBlock size={16} vertical />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtle}
                styles={{ marginRight: '8px' }}
              >
                •
              </V2Text>
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtle}
              >
                서비스 부정 참여 방지를 위해 본인인증 정보 및 역량검사 응시 횟수는 90일간 보관됩니다.{' '}
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.default}
                  styles={{ display: 'inline' }}
                >
                  따라서 90일 안에 재가입하실 경우, 계정 삭제 이전의 역량검사 응시 횟수가 유지됩니다.
                </V2Text>
              </V2Text>
            </div>
            <SpacingBlock size={8} vertical />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtle}
                styles={{ marginRight: '8px' }}
              >
                •
              </V2Text>
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.subtle}
              >
                그 외의{' '}
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.default}
                  styles={{ display: 'inline' }}
                >
                  모든 정보(역량검사 결과, 내 프로필, 지원 이력 등)
                </V2Text>
                는 완전히 삭제되며 복구될 수 없습니다.
              </V2Text>
            </div>
          </div>
          <SpacingBlock size={32} vertical />
          <V2Button
            type={V2ButtonOption.type.OutLine}
            color={V2ButtonOption.color.subtle}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            buttonProps={{
              onClick: async () => {
                history.push(RoutePaths.deleteAccount);
              },
            }}
            size={V2ButtonOption.size.L}
            styles={{ width: '100%' }}
          >
            계정 삭제
          </V2Button>
        </div>
      </FormProvider>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MyPageAccount));
