import React from 'react';
import classnames from 'classnames/bind';
import styles from './join.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const Join = ({ fadeIn }: FlowStoryProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    <div className={cx('mainTitle')}>
      <p className={cx('light')}>JOBDA matching 참여하고</p>
      나의 역량만으로 취업하세요
    </div>
    <span className={cx('subTitle')}>
      아래 두 가지만 진행하고
      <br />
      자동으로 JOBDA matching 참여하세요.
      <br />
      나의 역량을 기반으로 기업 인사담당자가
      <br />
      곧 입사 제안을 드립니다.
    </span>

    <div className={cx('joinInner')}>
      {/* 역량검사 응시 */}
      <div className={cx('joinBox')}>
        <div className={cx('joinTitle', 'step1')}>
          <p className={cx('step')}>STEP 1</p>
          <span className={cx('subTitle')}>단 15분으로 성향 분석!</span>
          <p className={cx('mainTitle')}>매칭 검사 완료</p>
        </div>
      </div>

      {/* 초간단 프로필 입력 */}
      <div className={cx('joinBox')}>
        <div className={cx('joinTitle', 'step2')}>
          <p className={cx('step')}>STEP 2</p>
          <span className={cx('subTitle')}>5분이면 취업준비 끝!</span>
          <p className={cx('mainTitle')}>초간단 매칭 프로필 입력</p>
        </div>
      </div>
    </div>

  </div>
);

export default Join;
