/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, FC } from 'react';
import Icon from '__designkit__/icon/Icon';

import classnames from 'classnames/bind';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import DisableScroll from 'components/common/DisableScroll';
import useToast from 'hooks/useToast';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import { postSignIn } from '__pc__/page/timeattack_marketer/fetch';
import styles from './layerModal.module.scss';

const cx = classnames.bind(styles);
interface ILayerModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

const LayerModal: FC<ILayerModalProps> = ({ isModalVisible, setIsModalVisible }) => {
  const [code, setCode] = useState('');
  const [agree, setAgree] = useState(false);
  const { setToastObject } = useToast();

  const onClose = () => {
    setIsModalVisible(false);
  };

  const handleAgreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(e.target.checked);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onSubmit = async () => {
    if (!agree) {
      setToastObject({ message: '개인정보 수집 및 이용에 동의해주세요', type: 'INFO', isOpen: true });
      return;
    }

    const companyCode = code.trim();
    if (companyCode === '') {
      setToastObject({ message: '참여코드를 입력해주세요', type: 'INFO', isOpen: true });
      return;
    }

    try {
      const body = {
        'company-code': companyCode,
        agree,
      };

      await postSignIn(body, {
        onSuccess: (data) => {
          if (!data || data.length === 0) {
            setToastObject({ message: '유효한 참여코드가 아닙니다', type: 'ERROR', isOpen: true });
            return;
          }

          TodayRenderCheckUtil.setValue('company-info', data[0]);
          sessionStorage.setItem('company-code', companyCode);
          onClose();
        },
      });
    } catch (e) {
      setToastObject({ message: '유효한 참여코드가 아닙니다', type: 'ERROR', isOpen: true });
    }
  };

  return (
    <>
      <JDBaseModal
        dimmed
        isOpen={isModalVisible}
        onClickClose={() => {
          setIsModalVisible(false);
        }}
      >
        <div className={cx('modalOverlay')}>
          <div className={cx('modal')}>
            <div className={cx('modalContent')}>
              <p className={cx('title')}>마케터 집중 채용 프로젝트</p>
              <p className={cx('subTitle')}>일잘러 마케터를 잡아라</p>
              <div className={cx('code')}>
                참여코드
                <input type='text' className={cx('inputCode')} placeholder='참여코드를 입력해주세요' onChange={handleCodeChange} />
                {/* [D] 에러시에 inputCode class에 .error class 추가/ 메세지는 '유효한 참여코드가 아닙니다' */}
              </div>

              {/* 개인정보 수집 이용 동의 */}
              <div className={cx('agree')}>
                <div className={cx('agreeContent')}>
                  구직자(인재풀)의 개인정보를 임의 처리하거나<br />
                  외부 유출 시 법적 처벌을 받을 수 있습니다.
                </div>
                <input type='checkbox' id='agree' className={cx('agreeCheck')} onChange={handleAgreeChange} />
                <label htmlFor='agree' className={cx('agreeLabel')}>
                  유의 사항을 이해했으며, 이에 동의합니다
                </label>
              </div>

              {/* [D] 지금은 닫힘 기능 -> 후에 수정 필요 */}
              <button type='submit' className={cx('btnBasic')} onClick={onSubmit}>
                입장하기
              </button>

              <div className={cx('enjoy')}>
                <span className={cx('text')}>참여코드가 없다면?</span>
                <a href='https://hubs.ly/Q02swJFT0' className={cx('link')} target='_blank' rel='noreferrer'>
                  참여코드 신청하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </JDBaseModal>
    </>
  );
};

export default LayerModal;
