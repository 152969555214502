import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { IconArrowBack, IconLogo } from 'consts/assets/icons/iconPages';
import NoticeDialog, { DialogType } from 'components/modals/NoticeDialog';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { useHistory, useLocation } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import ScrollUtil from 'utils/ScrollUtil';
import { useCheckMatchApplicantMatchingInformationFieldExists } from 'query/match/applicants/useMatchApplicantsQuery';
import VideoRecordIntro, { VideoAndImagesType } from './UploadIntro';
import VideoRecordCheck from './VideoRecordCheck';
import VideoRecorder from './VideoRecorder';
import {
  VideoRecordNotice1,
  VideoRecordNotice2,
  VideoRecordNotice3,
  VideoRecordNotice4,
} from './VideoRecordNotice';
import UploadDailyPhotosGuide from './UploadDailyPhotosGuide';
import UploadProfilePhotosGuide from './UploadProfilePhotosGuide';
import UploadProfilePhotos from './UploadProfilePhotos';
// import UploadDailyPhotos from './UploadDailyPhotos';

const Frame = styled.div`
  position: relative;
  .btn-back {
    position: absolute;
    display: block;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 40px;
    z-index: 9;

    &:disabled {
      display: none;
    }
  }

  .step-content {
    .desc {
      padding: 96px ${SpaceValue.L}px 128px ${SpaceValue.L}px;
    }
  }

  .action-buttons {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.XL}px
      ${SpaceValue.L}px;
    background-color: white;
  }
`;

const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${V2BackgroundColors.surface_default};
  z-index: 1;
  top: 0;
`;

const UploadStep = () => {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const positionSn = Number(queryParams.get('positionSn')) || -1;
  const totalSteps = 14; // 총 10단계
  const [currentStep, setCurrentStep] = useState(0);
  const [isChecklistComplete, setIsChecklistComplete] = useState(false);
  const [videoType, setVideoType] = useState<VideoAndImagesType | null>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [isVideoMoveOutModal, setIsVideoMoveOutModal] = useState(false);
  const { data: matchingInformationFieldExists, isError } = useCheckMatchApplicantMatchingInformationFieldExists(positionSn);
  const isFinalSubmit = matchingInformationFieldExists?.isFinalSubmit;
  useEffect(() => {
    if (isFinalSubmit) {
      history.push(RoutePaths.matchingGuide(positionSn));
    }
  }, [isFinalSubmit]);
  useEffect(() => {
    if (positionSn) {
      if (positionSn === -1) {
        history.push(RoutePaths.matchingGuide(positionSn));
      }
    } else {
      history.push(RoutePaths.matchingGuide(positionSn));
    }
  }, [positionSn]);

  const stepButtonNames = [
    '시작하기',
    '모든 내용을 확인했어요',
    '모든 내용을 확인했어요',
    '모든 내용을 확인했어요',
    '촬영 시작',
    '다음',
    '제출하기',
    '제출하기',
    '확인',
    '프로필 시작',
    '촬영 완료',
    '다음',
    '제출하기',
  ];

  const handleNextStep = () => {
    if (currentStep === 0) {
      history.push(RoutePaths.matchingGuide(positionSn));
      return;
    }
    setCurrentStep((prevStep) => {
      if ([6, 7, 8].includes(prevStep)) return 5; // 6, 7, 8단계는 5로 이동
      if (prevStep === 5) return 9; // 5단계는 9로 이동
      return prevStep + 1;
    });
  };
  const handlePreviousStep = () => {
    if (currentStep === 0) {
      history.push(RoutePaths.matchingGuide(positionSn));
    }
    if (currentStep < 5) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
    if (currentStep > 0 && currentStep < 8) {
      if (currentStep === 6) {
        setIsVideoMoveOutModal(true);
      }
    } else if (currentStep === 10) {
      setCurrentStep((prevStep) => prevStep - 1);
    } else if (currentStep === 12) {
      setCurrentStep((prevStep) => prevStep - 1);
    } else setCurrentStep(0);
  };

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    ScrollUtil.scrollTo({ top: 0 });
  }, [currentStep]);

  return (
    <Frame>
      {currentStep !== 5 && (
        <Header>
          <IconLogo className='logo' />
          <button className='btn-back'>
            <IconArrowBack onClick={handlePreviousStep} />
          </button>
        </Header>
      )}
      <div className='step-content'>
        {/* 각 단계의 콘텐츠 */}
        {currentStep === 0 && (
          <VideoRecordIntro
            setVideoType={setVideoType}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 1 && (
          <div className='desc'>
            <VideoRecordNotice1 />
          </div>
        )}
        {currentStep === 2 && (
          <div className='desc'>
            <VideoRecordNotice2 />
          </div>
        )}
        {currentStep === 3 && (
          <div className='desc'>
            <VideoRecordNotice3 />
          </div>
        )}
        {currentStep === 4 && (
          <div className='desc'>
            <VideoRecordNotice4 onChecklistChange={setIsChecklistComplete} />
          </div>
        )}
        {currentStep === 5 && (
          <VideoRecorder
            videoBlob={videoBlob}
            setVideoBlob={setVideoBlob}
            setCurrentStep={setCurrentStep}
            mediaBlobUrl={mediaBlobUrl}
            setMediaBlobUrl={setMediaBlobUrl}
          />
        )}
        {currentStep === 6 && (
          <VideoRecordCheck
            videoType={videoType}
            setIsVideoMoveOutModal={setIsVideoMoveOutModal}
            setMediaBlobUrl={setMediaBlobUrl}
            videoBlob={videoBlob}
            setVideoBlob={setVideoBlob}
            setCurrentStep={setCurrentStep}
            mediaBlobUrl={mediaBlobUrl}
          />
        )}
        {currentStep === 9 && <UploadProfilePhotosGuide />}
        {currentStep === 10 && (
          <UploadProfilePhotos setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 11 && <UploadDailyPhotosGuide />}
        {/* {currentStep === 12 && (
          <UploadDailyPhotos setCurrentStep={setCurrentStep} />
        )} */}
      </div>

      {currentStep !== 0
        && currentStep !== 5
        && currentStep !== 6
        && currentStep !== 12
        && currentStep !== 10 && (
          <div className='action-buttons'>
            <V2Button
              type={V2ButtonOption.type.Fill}
              size={V2ButtonOption.size.L}
              buttonProps={{
                onClick: handleNextStep,
                disabled: currentStep === 4 && !isChecklistComplete,
              }}
              fill={V2ButtonOption.fillType.brand_strong_default}
              fontStyle={V2ButtonOption.fontStyle.body_1_sb}
              color={V2ButtonOption.color.inverse}
              styles={{ width: '100%' }}
            >
              {stepButtonNames[currentStep]}
            </V2Button>
          </div>
      )}
      <NoticeDialog
        dialogType={DialogType.ALERT}
        title='이전으로 돌아가시겠습니까?'
        content='촬영한 영상은 저장되지 않습니다.'
        isOpen={isVideoMoveOutModal}
        onCancel={() => {
          setMediaBlobUrl(null);
          setVideoBlob(null);
          setIsVideoMoveOutModal(false);
          setCurrentStep(5);
        }}
        onOk={() => setIsVideoMoveOutModal(false)}
        firstButtonText='취소'
        secondButtonText='나가기'
      />
    </Frame>
  );
};

export default inject(
  injectStore.login,
  injectStore.profileModel,
  injectStore.context,
)(observer(UploadStep));
