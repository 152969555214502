import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import TalentpoolMissionModal from 'components/_v2/_common/modals/TalentpoolMissionModal';
import MatchApplyTab from 'components/_v2/matchApply/MatchApplyTab';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import NoticeDialog, { DialogType } from 'components/modals/NoticeDialog';
import { DisplayNotificationType } from 'interfaces/_v2/matchHome/IMatchHomeRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useUserDisplayNotification, usePutUsersDisplayNotification } from 'query/match/useMatchQuery';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import RoutePaths from 'consts/RoutePaths';
import { useMatchApplicantFiles, useMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';

import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';

const Frame = styled.div`
  position: relative;
  min-height: 100vh;
  padding-bottom: calc(64px + env(safe-area-inset-bottom)); // 배너높이 + 바텀네비게이션 높이
`;

const MatchApply = ({ login = new Login() }) => {
  const history = useHistory();
  const query = CommonUtils.parseQuery(history.location.search);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const [isOpenTalentpoolMissionModal, setIsOpenTalentpoolMissionModal] = useState<boolean>(false);
  const [isCompleteProfile, setIsCompleteProfile] = useState<boolean>(false);
  const [isCompleteAcc, setIsCompleteAcc] = useState<boolean>(false);
  const [isRender, setIsRender] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { data: profileFilesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO, !!login.userInfo);
  const { data: dailyFilesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO, !!login.userInfo);
  const { data: introductionData } = useMatchApplicantIntroduction(!!login.userInfo);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  useEffect(() => {
    if (query.fromToggle) {
      setIsOpenConditionInductionModal(true);
      history.replace({ search: '' });
    }
  }, [query]);
  useEffect(() => {
    if (login.userInfo && profileConditionsData && (profileConditionsData?.jobGroups.length === 0 || profileConditionsData?.locations.length === 0 || profileConditionsData?.salary === null)) {
      history.push(RoutePaths.preferred);
    } else if (profileFilesData?.files.length === 0 && dailyFilesData?.files.length === 0 && introductionData?.title === null) {
      history.push(RoutePaths.selfBranding);
    }
  }, [pathname, login.userInfo, profileConditionsData, profileFilesData, dailyFilesData, introductionData, history]);
  useEffect(() => {
    (async () => {
      if (!login.matchConfigStatus) await login.getMatchConfigStatus();
      setIsRender(!(await RenderCheckUtil.getRenderCheck('talentpool-induce')));
      setIsCompleteProfile(login.matchConfigStatus!.matchingConfigCondition.matchingProfileRequiredCompleteYn);
      if (login.matchConfigStatus!.generalYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.accPersonalityCompleteYn);
      } else if (login.matchConfigStatus!.developerYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.paccPersonalityCompleteYn && login.matchConfigStatus!.matchingConfigCondition.pstCompleteYn);
      }
    })();
  }, [login]);

  useEffect(() => {
    if (isCompleteProfile && isCompleteAcc && !login.matchConfigStatus?.matchingConfigYn && isRender) setIsOpenTalentpoolMissionModal(true);
    else setIsOpenTalentpoolMissionModal(false);
  }, [isCompleteProfile, isCompleteAcc, isRender, login]);

  return (
    <Frame>
      <MatchApplyTab />

      <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />
      { isOpenTalentpoolMissionModal && <TalentpoolMissionModal onClickClose={() => setIsOpenTalentpoolMissionModal(false)} isCompleteProfile={isCompleteProfile} isCompleteAcc={isCompleteAcc} /> }
      {/* <BannerWrapper>
        <MatchingBanner />
      </BannerWrapper> */}
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApply));
