import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import JDSwiper, { JDFractions, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import MainBanner1 from 'assets/_v2/profile/img_company_logo_flow.png';
import MainBanner2 from 'assets/_v2/profile/img_camera_for_banner.png';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import styled from 'styled-components';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { useHistory } from 'react-router-dom';
import V2Text from '__pc__/components/common/v2Design/text';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import RoutePaths from 'consts/RoutePaths';

const HeaderBannerFrame = styled.div`
  padding:${SpaceValue.XS}px 0;
`;
const BannerFrame = styled.div<{backgroundColor:string, isSmall:boolean}>`
  width: calc(100vw - 32px);
  height: 88px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding:21px ${SpaceValue.XL}px;
  margin-left: 16px;;
  overflow: hidden;
  position: relative;
  .subtitle-frame{
    display: flex;
    gap:2px;

  }
  .profile-banner-img{
    width: ${({ isSmall }) => (isSmall ? '88px' : '140px')};
    height: auto;
    position: absolute;
    right:5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface IMainBanner {
    key:number;
    title: string;
    subTitle: string;
    imageUrl: string;
    backgroundColor: string;
  }

const MainBannerList:IMainBanner[] = [
  {
    key: 0,
    title: '가고 싶었던 기업에서 제안받기',
    subTitle: '잡다매칭 자세히 알아보기',
    imageUrl: MainBanner1,
    backgroundColor: `${V2BackgroundColors.accent_gray_strong}`,
  },
  {
    key: 1,
    title: '이력서 대신, 나를 어필하는 공간',
    subTitle: '셀프 브랜딩 작성하기',
    imageUrl: MainBanner2,
    backgroundColor: `${V2BackgroundColors.accent_green_default}`,
  },
];

const MatchApplyHomeBanner = () => {
  const history = useHistory();
  const ProfileBannerList = (item:IMainBanner) => {
    const handleClickBanner = () => {
      if (item.key === 0) history.push(RoutePaths.match_introduce);
      else if (item.key === 1) history.push(`${RoutePaths.match_profile}?moveToSelfIntroduction=true`);
    };
    return (
      <BannerFrame backgroundColor={item.backgroundColor} onClick={handleClickBanner} isSmall={item.key === 1}>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_2_b}
          color={V2TextOption.color.inverse}
        >
          {item.title}
        </V2Text>
        <div className='subtitle-frame'>
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.inverse}
          >
            {item.subTitle}
          </V2Text>
          <V2Icon name={V2IconOption.name.arrowRight} size={V2IconOption.size.XS} fill={V2IconOption.fill.inverse} />
        </div>
        <img src={item.imageUrl} alt='profile-banner' className='profile-banner-img' />
      </BannerFrame>
    );
  };
  return (

    <HeaderBannerFrame>
      <JDSwiper
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={JDFractions}
        loop
      >
        {
              MainBannerList.map((item, index) => (
                <JDSwiperSlide key={index} className='main-custom-slide'>
                  {ProfileBannerList(item)}
                </JDSwiperSlide>
              ))
            }

      </JDSwiper>

    </HeaderBannerFrame>
  );
};

export default MatchApplyHomeBanner;
