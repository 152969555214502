import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { inject, observer } from 'mobx-react';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    z-index:1;
    justify-content: center;
    bottom: calc(140px + env(safe-area-inset-bottom));
    position: fixed;
    right: 16px;
    width: 200px;
    padding: 4px;
    gap:4px;
    background-color: ${colors.WHITE_100};
    border: 1px solid ${V2BorderColors.default};
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    li{
      padding:8px 12px;
    }

    .text {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
        white-space: nowrap;
        width: 192px
    }

    &[aria-hidden=true] {
        visibility: hidden;
    }
`;

interface IProfileSelectSnb {
  isOpen: boolean;
  onClickClose: () => void;
  onClickSuccess?: () => void;
  onClickResume?: () => void;
}

const ProfileSelectSnb: FC<IProfileSelectSnb> = ({ isOpen, onClickClose, onClickSuccess, onClickResume }) => {
  const wrapperRef = useRef<any>(null);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setVisibleOpen(true);
    }
  }, [isOpen]);

  const useOutsideClicker = (ref: any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
        onClickClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  return (
    <>
      {visibleOpen && (
        <Frame ref={wrapperRef} aria-hidden={!isOpen}>
          <ul>
            <li
              onClick={() => {
                onClickSuccess?.();
              }}
              role='presentation'
              className='text'
            >
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.default}
              >
                매칭 프로필 불러오기
              </V2Text>
            </li>
            <li
              onClick={() => {
                onClickResume?.();
              }}
              role='presentation'
              className='text'
            >
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_m}
                color={V2TextOption.color.default}
              >
                지원서 불러오기
              </V2Text>
            </li>
          </ul>
        </Frame>
      )}

    </>
  );
};

export default inject(injectStore.matchHomeDashboardModel)(observer(ProfileSelectSnb));
