import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;   
    height: 100vh;
    padding: 64px ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.L}px;
    background-color: ${colors.WHITE_100};

    .title-area {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: ${SpaceValue.XL}px 0;
    }

    .modal-content-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            border-bottom: 1px solid ${colors.CG_50};

            .title {
                flex: 1;
                text-align: left;
                font: ${Fonts.B3_Bold};
                color: ${colors.CG_90};
            }

            .content {
                flex: 2;
                text-align: right;
                font: ${Fonts.B3_Medium};
                color: ${colors.CG_90};
            }
        }
    }
`;

const Table = styled.div`
  display: table;
  width: 100%;
  border-bottom: 1px solid ${V2BorderColors.subtle};
  border-collapse: collapse;
  margin-bottom: ${SpaceValue.XS}px;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableCell = styled.div`
  display: inline-flex;
  padding: 8px;

  &.title {
    min-width: 100px;
    height: 54px;
    text-align: left;
    padding: ${SpaceValue.L}px;
    background-color: ${V2BackgroundColors.surface_alternative};
    border: 1px solid ${V2BorderColors.subtle};
    border-bottom: none;
  }

  &.content {
    width: calc(100% - 100px);
    height: 54px;
    text-align: left;
    padding: ${SpaceValue.L}px;
    border: 1px solid ${V2BorderColors.subtle};
    border-left: none;
    border-bottom: none;
  }
`;

const SubmitButtonFrame = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    bottom: calc(0px + env(safe-area-inset-bottom));
    display: flex;
    gap: ${SpaceValue.XS}px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.WHITE_100};

    button {
      width: 100%;
    }
    .preview-btn {
      margin-right: 8px;
    }

`;
interface IPositionApplySuccessModalProps extends IJDBaseModal {
    positionName: string;
    companyName: string;
    positionSn: number;
}
const PositionApplySuccessModal: FC<IPositionApplySuccessModalProps> = ({ isOpen, onClickClose, positionName, companyName, positionSn }) => {
  const history = useHistory();
  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} isDimClickClose isDimCloseBtn>
      <Frame>
        <div className='title-area'>
          <Icon className='icon' name='check-circle-filled' size={32} color={colors.G_100} />
          <JDModalTitle>지원이 완료되었습니다</JDModalTitle>
        </div>
        <div className='modal-content-frame'>
          <Table>
            <TableRow>
              <TableCell className='title'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.subtle}
                  ellipsis={1}
                >
                  포지션명
                </V2Text>
              </TableCell>
              <TableCell className='content'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.default}
                  ellipsis={1}
                >
                  {positionName}
                </V2Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='title'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.subtle}
                  ellipsis={1}
                >
                  기업
                </V2Text>
              </TableCell>
              <TableCell className='content'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.default}
                  ellipsis={1}
                >
                  {companyName}
                </V2Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='title'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.subtle}
                  ellipsis={1}
                >
                  지원일시
                </V2Text>
              </TableCell>
              <TableCell className='content'>
                <V2Text
                  element={V2TextOption.element.span}
                  fontStyle={V2TextOption.fontStyle.body_2_m}
                  color={V2TextOption.color.default}
                  ellipsis={1}
                >
                  {DateFormatYYYYMMDDCCHHMMUnit(DateTime.local().toISO())}
                </V2Text>
              </TableCell>
            </TableRow>
          </Table>
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_3_m}
            color={V2TextOption.color.subtlest}
            ellipsis={1}
          >
            제출한 지원서는 지원 현황 → 해당 포지션 클릭시 확인 가능합니다.
          </V2Text>
        </div>
        <SubmitButtonFrame>
          <V2Button
            type={V2ButtonOption.type.OutLine}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.subtle}
            buttonProps={{ onClick: onClickClose }}
          >
            취소
          </V2Button>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            fill={V2ButtonOption.fillType.brand_strong_default}
            buttonProps={{ onClick: () => history.push(RoutePaths.mypage_apply_Message(positionSn)) }}
          >
            내역 확인하기
          </V2Button>
        </SubmitButtonFrame>
      </Frame>
    </JDBaseModal>
  );
};

export default PositionApplySuccessModal;
