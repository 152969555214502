import { IMyProfileProps } from 'components/_v2/myProfile/MyProfileBoxComponent';
import MyProfilePhsResultModal from 'components/_v2/myProfile/MyProfilePhsResultModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import JDRegisterItemButton from 'components/_v2/_common/button/JDRegisterItemButton';
import useToast from 'hooks/useToast';
import { PhsAccType } from 'consts/MatchingMessageType';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MyProfileModel from 'models/_v2/MyProfileModel';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import CommonUtils from 'utils/CommonUtils';

const Frame = styled.div``;

const ListFrame = styled.ul`
  padding: 0 16px;

  li:not(:last-child) {
    margin-bottom:12px;
  }
`;

const MyProfilePhsPstResults: FC<IMyProfileProps> = ({ myProfileModel = new MyProfileModel() }) => {
  const { search } = useLocation();
  const { paccProgressList, pstProgressList } = myProfileModel;
  const [isOpenPhsResultModal, setIsOpenPhsResultModal] = useState(false);
  const [phsType, setPhsType] = useState<PhsAccType>(PhsAccType.PACC);
  const { setToastObject } = useToast();
  const init = async () => {
    await myProfileModel.loadPaccProgressList();
    await myProfileModel.loadPstProgressList();
    const paramList = CommonUtils.parseQuery(search);
    if (Number(paramList.sn)) {
      const paccResultItem = myProfileModel.paccProgressList?.find((accResult) => accResult.sn === Number(paramList.sn));
      const pstResultItem = myProfileModel.pstProgressList?.find((pstResult) => pstResult.sn === Number(paramList.sn));
      if (paccResultItem) {
        setPhsType(PhsAccType.PACC);
        setIsOpenPhsResultModal(true);
      } else if (pstResultItem) {
        setPhsType(PhsAccType.PST);
        setIsOpenPhsResultModal(true);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <div>
      <Frame>
        <ProfileEditTitleComponent
          title='개발자 검사 결과를 선택해 주세요.'
          subtitle='결과 확인을 원하는 검사를 선택해 주세요. '
          divider={false}
        />
        <ListFrame>
          <li>
            <JDRegisterItemButton
              title='개발자 역량검사'
              mode='SELECT'
              disabled={paccProgressList?.length === 0}
              onClick={() => {
                if (paccProgressList?.length === 0)
                  setToastObject({ isOpen: true, type: 'ERROR', message: '확인 가능한 결과가 없습니다.', duration: 2000 });
                else {
                  setIsOpenPhsResultModal(true);
                  setPhsType(PhsAccType.PACC);
                }
              }}
            />
          </li>
          <li>
            <JDRegisterItemButton
              title='개발 구현 능력 검사'
              mode='SELECT'
              disabled={pstProgressList?.length === 0}
              onClick={() => {
                if (pstProgressList?.length === 0)
                  setToastObject({ isOpen: true, type: 'ERROR', message: '확인 가능한 결과가 없습니다.', duration: 2000 });
                else {
                  setIsOpenPhsResultModal(true);
                  setPhsType(PhsAccType.PST);
                }
              }}
            />
          </li>
        </ListFrame>
      </Frame>

      {isOpenPhsResultModal ? (
        <MyProfilePhsResultModal
          isOpen={isOpenPhsResultModal}
          onClose={() => setIsOpenPhsResultModal(false)}
          type={phsType}
        />
      ) : null }
    </div>
  );
};

export default inject(injectStore.myProfileModel)(observer(MyProfilePhsPstResults));
