const newColors = {
  JOBDA_BLACK: '#121619',
  JOBDA_WHITE: '#F9F9FA',
  BLACK_100: '#000000',
  WHITE_100: '#ffffff',
  ERROR: '#FB4E4E',
  GUIDE_LINE: '#00C8FF',
  DIMMED: 'rgba(0, 0, 0, 0.6)',
  CG_90: '#25282B',
  CG_80: '#44474B',
  CG_80_20: 'rgba(68, 71, 75, 0.2)',
  CG_70: '#656A71',
  CG_60: '#9FA4AB',
  CG_50: '#CFD1D5',
  CG_50_40: 'rgba(207, 209, 213, 1)',
  CG_40: '#E7E8EA',
  CG_40_40: 'rgba(231, 232, 234, 0.4)',
  CG_30: '#F4F4F5',
  G_300: '#41AC4D',
  G_200: '#52C564',
  G_150: '#4BD667',
  G_100: '#6CDD83',
  G_90: '#92E6A3',
  G_70: '#B3EEC1',
  G_30: '#EFFDF3',
  Y_300: '#D8CA2C',
  Y_100: '#FCF16C',
  Y_30: '#FFFEF2',
  S_300: '#46B2AF',
  S_100: '#7ADEDA',
  S_30: '#F3FDFC',
  B_100: '#2B53AE',
  B_90: '#315FC7',
  B_80: '#5878CF',
  B_30: '#E1E7F8',
  LG_100: '#75E78C',
  LB_100: '#AFC6FF',
} as const;

const oldColors = {
  cffffff_0: 'rgba(255, 255, 255, 0)',
  c000000_06: 'rgba(0, 0, 0, 0.06)',
  c000000_10: 'rgba(0, 0, 0, 0.1)',
  c000000_70: 'rgba(0, 0, 0, 0.7)',
  c121619_10: 'rgba(18, 22, 25, 0.1)',
  c44474B_20: 'rgba(68, 71, 75, 0.2)',
  c44474B_80: 'rgba(68, 71, 75, 0.8)',
  c6CDD83_30: 'rgba(108, 221, 131, 0.3)',
  cDBDDE0: '#DBDDE0',
  cCACCCF: '#CACCCF',
  c00C145: '#00C145',
  cE8E8E8: '#E8E8E8',
  cD1D1D1: '#D1D1D1',
  cA4A4A4: '#A4A4A4',
  c6A6A6A: '#6A6A6A',
  c474747: '#474747',
  c232323: '#232323',
  cEFEFEF: '#EFEFEF',
  cF6F6F6: '#F6F6F6',
  cD4D4D4: '#D4D4D4',
  cD9D9D9: '#D9D9D9',
  cE93C3C: '#E93C3C',
  cF6A609: '#F6A609',
  c3ed160: '#3ed160',
  c787120: '#787120',
  c938A29: '#938A29',
  cB3A82A: '#B3A82A',
  cE7DA4B: '#E7DA4B',
  cFDF69E: '#FDF69E',
  cFEF8B1: '#FEF8B1',
  cFEF9BD: '#FEF9BD',
  cFEFCCB: '#FEFCCB',
  cFFD600: '#FFD600',
  c377538: '#377538',
  c3C8E42: '#3C8E42',
  c41AC4D: '#41AC4D',
  cA2EBB4: '#A2EBB4',
  cC3F2CD: '#C3F2CD',
  cD0F5DA: '#D0F5DA',
  cE0F9E4: '#E0F9E4',
  c3ED160: '#3ED160',
  c718DD7: '#718DD7',
  c668A33: '#668A33',
  c79A837: '#79A837',
  c8EC93D: '#8EC93D',
  cA4D95A: '#A4D95A',
  cBFEF7B: '#BFEF7B',
  cD0F894: '#D0F894',
  cDCFAAD: '#DCFAAD',
  cE2FBB9: '#E2FBB9',
  cE7FCC6: '#E7FCC6',
  cF9FFEB: '#F9FFEB',
  c3A7977: '#3A7977',
  c409390: '#409390',
  c5EC7C3: '#5EC7C3',
  cAFECEA: '#AFECEA',
  cBBEFED: '#BBEFED',
  cC5F1EF: '#C5F1EF',
  cD1F5F3: '#D1F5F3',
  cDCF7F6: '#DCF7F6',
  c553B94: '#553B94',
  c7753CA: '#7753CA',
  c9375DB: '#9375DB',
  cB49BF0: '#B49BF0',
  cC7B4F4: '#C7B4F4',
  cD5C7F7: '#D5C7F7',
  cE8E0FB: '#E8E0FB',
  cEEE7FC: '#EEE7FC',
  cDC9868: '#DC9868',
  c838486: '#838486',
} as const;

const Colors = {
  ...newColors,
  ...oldColors,
} as const;

export default Colors;
