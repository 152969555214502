import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SmsAPI from 'api/smsAPI';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import JDAPopover from 'components/_v2/_common/popover/JDPopover';
import JDInput from 'components/_v2/join/JDInput';
import JDInputSelector from 'components/_v2/join/JDInputSelector';
import MatchApplySelfBrandingProfile from 'components/_v2/matchApply/matchApplySelfBranding/MatchApplySelfBrandingProfile';
import ProfilePhoto from 'components/_v2/profile/baseInfo/ProfilePhoto';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { countryList } from 'consts/_v2/join/countryList';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IDENTIFICATION_TYPE_STR, IPutProfileBasicInfoRq, IdentificationTypeStr } from 'interfaces/_v2/profile/IBasicInfoRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import IdentificationSelectModal from 'pages/myPage/IdentificationSelectModal';
import { useMatchApplicantFiles, useUploadMatchApplicantProfilePhoto } from 'query/match/applicants/useMatchApplicantsQuery';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { make15YearsOldDate } from 'utils/DateUtils';
import { convertMsToMMSS } from 'utils/_v2/timeUtils';
import * as yup from 'yup';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 138px);
  padding: 0 16px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & p {
    font: ${Fonts.B3_Medium};
  }
`;

const PhoneBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const InnerButtonInput = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  > div.postFix {
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const InnerButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${Colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${Colors.WHITE_100};

  &:active {
    background-color: ${Colors.CG_70};
  }

  &:disabled {
    background-color: ${Colors.CG_40};
    color: ${Colors.CG_60};
  }
`;

const Timer = styled.div`
  margin-right: 12px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.ERROR};
`;

const VerificationBox = styled.div``;

const PopOverFrame = styled.div<{ whiteMode?: boolean }>`
  font: ${Fonts.B3_Medium};
  color: ${({ whiteMode }) => (whiteMode ? Colors.CG_70 : Colors.WHITE_100)};
  background-color: ${({ whiteMode }) => (whiteMode ? Colors.WHITE_100 : Colors.CG_70)};
`;

const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;

const HeaderTitleFrame = styled.div`
  display: flex;
  gap:4px;
  flex-direction: column;
`;

const Divider8G = styled.div`
  
`;

interface IBasicInfoEditModalProps extends IComponentProps {
  onClose: () => void;
  profileBasicInfoModel?: ProfileBasicInfoModel;
  isSelfBranding?: boolean;
}

const BasicInfoEditModal:FC<IBasicInfoEditModalProps> = ({ onClose, profileBasicInfoModel = new ProfileBasicInfoModel(), isSelfBranding = false }) => {
  const yupSchema = yup.object({
    // name: yup.string().required('').matches(/^[a-zA-Z가-힣\s]*$/, '잘못된 이름 형식이에요.'),
    email: yup.string().required('').matches(/^[0-9a-z]+([-_.]*[0-9a-z-_])*@[a-z]+(\.[a-z]{2,})+$/, '잘못된 이메일 형식이에요.'),
    mobile: yup.string().when('identificationType', {
      is: (identificationType: IdentificationTypeStr) => identificationType === IDENTIFICATION_TYPE_STR.NONE,
      then: yup.string().required('').matches(/^[0-9]*$/, '잘못된 휴대폰 번호 형식이에요.').min(10, '잘못된 휴대폰 번호 형식이에요.')
        .max(11, '잘못된 휴대폰 번호 형식이에요.'),
    }),
    verificateCode: yup.string(),
  });

  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      profileImageUid: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.profileImageUid : '',
      name: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.name : '',
      birthDate: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.birthDate : '',
      email: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.email : '',
      countryCode: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.countryCode : '82',
      mobile: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.mobile : '',
      token: '',
      identificationType: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.userIdentificationType === null ? (profileBasicInfoModel.baseInfo.verified ? IDENTIFICATION_TYPE_STR.CI : IDENTIFICATION_TYPE_STR.NONE) : IDENTIFICATION_TYPE_STR[profileBasicInfoModel.baseInfo.userIdentificationType] : null,
    },
  });
  const { handleSubmit, errors, watch, getValues, formState, reset } = formMethods;
  const { dirtyFields } = formState;
  const [isOpenIdentificationModal, setIsOpenIdentificationModal] = useState<boolean>(false);
  const [isDiableSendBtn, setIsDiableSendBtn] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);
  const [isVerificate, setIsVerificate] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const timerRef = useRef<number>(0);
  const [sendMobile, setSendMobile] = useState<string>('');
  const imageUid = watch('profileImageUid');
  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const token = watch('token');
  const identificationType = watch('identificationType');
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const { refetch } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_PROFILE_PHOTO);

  const { mutate: uploadProfilePhoto } = useUploadMatchApplicantProfilePhoto(
    async () => {
      await refetch();
    },
    (error) => {
      console.error('Failed to upload profile photo:', error);
    },
  );

  const onClickSendBtn = async () => {
    if (countryCode && mobile && !(errors.countryCode || errors.mobile)) {
      try {
        if (await profileBasicInfoModel.checkMobileDuplicate({ mobile, countryCode })) {
          setToastObject({ isOpen: true, type: 'ERROR', message: '이미 사용중인 휴대폰 번호예요.' });
          return;
        }
        setSendMobile(mobile);
        const res = await SmsAPI.sendVerificationSmsToUser({ countryCode, mobile });
        if (res) {
          window.clearTimeout(timerRef.current);
          setIsSend(true);
          setTimer(180000);
          setIsVerificate(false);
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증번호가 발송되었습니다.' });
        } else setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호가 발송에 실패했습니다.' });
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else setToastObject({ isOpen: true, type: 'INFO', message: '필수 값들을 모두 입력해 주세요.' });
  };

  const onClickCheckBtn = async () => {
    if (token.length !== 6) setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호를 확인해 주세요.' });
    else if (countryCode && mobile && token && !errors.token) {
      try {
        const res = await SmsAPI.chackVerificationSmsToUser({ countryCode, mobile, token });
        if (res) setIsVerificate(true);
        else {
          setIsVerificate(false);
          setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.' });
        }
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };
  const onSubmit = async () => {
    if (isSelfBranding) {
      if (profileImage) {
        const profileFormData = new FormData();
        profileFormData.append('multipartFile', profileImage);
        await uploadProfilePhoto(profileFormData);
        setToastObject({ isOpen: true, type: 'SUCCESS', message: '매칭 프로필을 업데이트했습니다.' });
      }
    }
    if (!(errors.profileImageUid || errors.name || errors.birthDate || errors.email || errors.countryCode || errors.mobile)) {
      if (identificationType === IDENTIFICATION_TYPE_STR.NONE && !mobile) {
        setToastObject({ isOpen: true, type: 'ERROR', message: '휴대폰 번호를 입력해 주세요.' });
        return;
      }
      try {
        const data: IPutProfileBasicInfoRq = {
          ...getValues(),
          profileImageUid: profileBasicInfoModel.uploadImageUid,
          sourcePage,
        };
        const res = await profileBasicInfoModel.saveBaseInfo(data);
        await refetch();

        if (res) {
          if (!isSelfBranding) {
            setToastObject({ isOpen: true, type: 'SUCCESS', message: '기본 정보가 저장되었습니다.' });
          }
          reset(data);
          onClose();
        }
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else {
      setToastObject({ isOpen: true, type: 'ERROR', message: '입력 값들을 확인해 주세요.' });
    }
  };

  const onError = (e: any) => {
    console.error(e);
  };

  useEffect(() => {
    if (dirtyFields.mobile) setIsDirty(true);
    else setIsDirty(false);
  }, [dirtyFields.mobile]);

  useEffect(() => {
    if (imageUid !== profileBasicInfoModel.uploadImageUid || dirtyFields.name || dirtyFields.birthDate || dirtyFields.email || dirtyFields.countryCode || dirtyFields.mobile) setIsDirty(true);
    else setIsDirty(false);
  }, [imageUid, profileBasicInfoModel.uploadImageUid, dirtyFields.name, dirtyFields.birthDate, dirtyFields.email, dirtyFields.countryCode, dirtyFields.mobile]);

  useEffect(() => {
    if (mobile !== sendMobile) {
      setIsSend(false);
      setTimer(0);
      setIsDiableSendBtn(false);
      setIsDirty(true);
      setSendMobile('');
    }
    if (timer && !isVerificate && sendMobile) timerRef.current = window.setTimeout(() => setTimer(timer - 1000), 1000);
  }, [timer, isVerificate, mobile, sendMobile]);

  useEffect(() => {
    if (!(countryCode && mobile && timer < 120000) || !isDirty) setIsDiableSendBtn(true);
    else setIsDiableSendBtn(false);
  }, [countryCode, mobile, isVerificate, dirtyFields.mobile, timer]);

  return (
    <JDFullModal handleClose={onClose} title='기본 정보'>
      <Frame>
        <FormProvider {...formMethods}>
          <HeaderTitleFrame>
            <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.default}>
              기본 정보를 확인해 주세요.
            </V2Text>
            <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
              이름, 휴대폰을 변경하고 싶다면 본인인증을 시도해 주세요.
            </V2Text>
          </HeaderTitleFrame>

          <SpacingBlock size={24} vertical />
          {
            isSelfBranding ? (
              <MatchApplySelfBrandingProfile onImageChange={setProfileImage} />
            ) : (
              <ProfilePhoto />
            )
          }
          <SpacingBlock size={16} vertical />
          <FormProfileValueTitle required>이름</FormProfileValueTitle>
          <JDInput name='name' label='이름' type='text' interaction={false} style={{ height: '48px' }} inputMode='text' disabled={identificationType !== IDENTIFICATION_TYPE_STR.NONE} />
          <SpacingBlock size={24} vertical />
          <FormProfileValueTitle required>생년월일</FormProfileValueTitle>
          <JDDatePickerInput
            name='birthDate'
            placeholder='연도. 월. 일'
            defaultValue={profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.birthDate : ''}
            maxDate={make15YearsOldDate()}
          />
          <SpacingBlock size={24} vertical />
          <FormProfileValueTitle required>이메일</FormProfileValueTitle>
          <JDInput name='email' label='이메일' type='text' interaction={false} style={{ height: '48px' }} inputMode='email' />
          <SpacingBlock size={24} vertical />
          <div style={{ display: 'flex' }}>
            <FormProfileValueTitle>
              휴대폰
            </FormProfileValueTitle>
            <SpacingBlock size={4} />
            <JDAPopover whiteMode useOutsideClick position='non_pony_under' popoverMargin={-8} anchorIcon={<Icon name='information' size={20} />}>
              <PopOverFrame whiteMode>
                휴대폰 번호가 없으면 포지션 추천 알림을 받을 수 없어요.
              </PopOverFrame>
            </JDAPopover>
          </div>
          <PhoneBox>
            <JDInputSelector name='countryCode' label='국가' options={countryList} width='calc((100% - 12px) * .3)' interaction={false} />
            <InnerButtonInput style={{ width: 'calc((100% - 12px) * .7)' }}>
              <JDInput type='tel' name='mobile' label='휴대폰 번호' maxLength='11' inputMode='numeric' interaction={false} placeholder='휴대폰 번호' disabled={identificationType === IDENTIFICATION_TYPE_STR.SMS || identificationType === IDENTIFICATION_TYPE_STR.CI} />
              <div className='postFix'>
                <InnerButton type='button' disabled={isDiableSendBtn} onClick={onClickSendBtn}>
                  { timer === 0 ? '인증' : '재인증' }
                </InnerButton>
              </div>
            </InnerButtonInput>
          </PhoneBox>
          <SpacingBlock size={16} vertical />
          { isSend && (
          <VerificationBox>
            <InnerButtonInput>
              <JDInput type='string' name='token' label='인증번호' maxLength='6' successText={isVerificate ? '인증이 완료되었어요.' : ''} inputMode='numeric' interaction={false} />
              <div className='postFix'>
                { timer !== 0 && !isVerificate ? <Timer>{ convertMsToMMSS(timer) }</Timer> : null }
                <InnerButton type='button' disabled={timer === 0 || isVerificate} onClick={onClickCheckBtn}>
                  확인
                </InnerButton>
              </div>
            </InnerButtonInput>
            <SpacingBlock size={16} vertical />
          </VerificationBox>
          )}
          <FormProfileValueTitle>본인인증</FormProfileValueTitle>
          <InnerButtonInput>
            <JDInput type='string' name='identificationType' label='본인인증' interaction={false} disabled />
            <div className='postFix'>
              <InnerButton type='button' onClick={() => setIsOpenIdentificationModal(true)}>
                변경
              </InnerButton>
            </div>
          </InnerButtonInput>
          <SpacingBlock size={24} vertical />
        </FormProvider>
      </Frame>
      <ButtonFrame>
        <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' disabled={!isVerificate || !isDirty} onClick={handleSubmit(onSubmit, onError)} />
      </ButtonFrame>
      <IdentificationSelectModal
        isOpen={isOpenIdentificationModal}
        onClickClose={() => { setIsOpenIdentificationModal(false); }}
      />
    </JDFullModal>
  );
};

export default inject(injectStore.profileBasicInfoModel)(observer(BasicInfoEditModal));
