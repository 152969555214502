import styled from 'styled-components';
import ImgBgIntro from 'assets/_v2/profile/bg_intro.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2BorderColors, V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { IconLogo } from 'consts/assets/icons/iconPages';
import { Link, useHistory, useLocation } from 'react-router-dom';
import RoutePaths from 'consts/RoutePaths';
import { useEffect, useMemo, useState } from 'react';
import Login from 'models/Login';
import request from 'utils/request';
import URIs from 'consts/URIs';
import { isBeforeDateOnly, overDateNow } from 'utils/DateUtils';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import Dialog from '__designkit__/components/Dialog';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { useCheckMatchApplicantMatchingInformationFieldExists, useSaveMatchApplicantFinalApply } from 'query/match/applicants/useMatchApplicantsQuery';
import { MatchingInformationFieldTypeText, MatchingInformationFieldType } from 'interfaces/_v2/MatchApplicants/IMatchApplicants';

import NoticeDialog, { DialogType } from 'components/modals/NoticeDialog';
import SupportMail from 'consts/_v2/GlobalText';
import ImgGuide from './imgGuide.png';
import MatchingGuideEmpty from './MatchingGuideEmpty';

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  z-index: 1;
`;

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${SpaceValue.XXL}px 0;
  
  &::before {
    content: '';
    position: absolute;
    display: flex;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0.28;
    width: 100%;
    height: 400px;
    background: #02C551;
    filter: blur(120px);
    z-index: -1;
  }

  .support-mail {
    color: #2497F3;
    text-decoration: underline;
  }

  img {
    margin-bottom: ${SpaceValue.XL}px;
    width: fit-content;
    height: 160px;
  }

  /* 버튼 리스트 */
  .btn-list {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XXXL}px 0 ${SpaceValue.XL}px 0;
    width: 100%;
    padding: 0 ${SpaceValue.L}px;

    .apply-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ${SpaceValue.XL}px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${SpaceValue.M}px;

      .badge {
        display: flex;
        align-items: center;
        gap: ${SpaceValue.XXXS}px;
      }
    }
  }

  .btn-area {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
    background: ${colors.WHITE_100};

    button {
      width: 100%;
    }
  }
`;

const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const AgreeFrame = styled.div`
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px 96px ${SpaceValue.L}px;
  width: 100%;

  &::before {
    content: '';
    display: flex;
    position: relative;
    top: -24px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${V2BorderColors.default};
  }

  .agree-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const CheckFrame = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${SpaceValue.XS}px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const InfoTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const InfoTh = styled.th`
  vertical-align: middle;
  background: #F4F4F5;
  padding: 10px;
  border: 1px solid #E7E8EA;
  white-space: nowrap;
`;

const InfoTd = styled.td`
  padding: 10px;
  border: 1px solid #E7E8EA;
`;

const MatchingGuideMainPage = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [openPrivacyNotice, setOpenPrivacyNotice] = useState(false);
  const [isOverSubmitDeadline, setIsOverSubmitDeadline] = useState(false);
  const [isFinalSubmitModal, setIsFinalSubmitModal] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const positionSn = Number(queryParams.get('positionSn')) || -1;
  const { data: matchingInformationFieldExists, isError, refetch } = useCheckMatchApplicantMatchingInformationFieldExists(positionSn);
  const { mutate: submitMatchingGuide } = useSaveMatchApplicantFinalApply();
  const handleSubmitMatchingGuide = async () => {
    try {
      await submitMatchingGuide(positionSn, {
        onSuccess: async () => {
          // 성공 후 즉시 상태 업데이트
          await refetch();
          setIsFinalSubmitModal(false);
        },
        onError: (error) => {
          console.error('Submit error:', error);
          setIsFinalSubmitModal(false);
        },
      });
    } catch (error) {
      console.error('Error:', error);
      setIsFinalSubmitModal(false);
    }
  };

  useEffect(() => {
    if (matchingInformationFieldExists?.isFinalSubmit) {
      setIsChecked(true);
    }
  }, [matchingInformationFieldExists]);
  const handleNextStep = (field: MatchingInformationFieldType) => {
    if (matchingInformationFieldExists?.isFinalSubmit) {
      return;
    }
    switch (field) {
      case MatchingInformationFieldType.SELF_INTRODUCTION:
        history.push(RoutePaths.matchingGuideIntoduce(positionSn));
        break;
      case MatchingInformationFieldType.PHOTO:
        history.push(RoutePaths.matchingGuideUpload(positionSn));
        break;
      default:
        break;
    }
  };
  const getColorType = (field: MatchingInformationFieldType) => {
    if (matchingInformationFieldExists?.isFinalSubmit) {
      return V2TextColorsType.disabled;
    }
    switch (field) {
      case MatchingInformationFieldType.SELF_INTRODUCTION:
        return V2TextColorsType.default;
      case MatchingInformationFieldType.PHOTO:
        return V2TextColorsType.default;
      default:
        return V2TextColorsType.disabled;
    }
  };
  const isAllFieldsCompleted = matchingInformationFieldExists?.requiredFields?.every(
    ({ isCompleted }) => isCompleted,
  ) ?? false;
  useEffect(() => {
    if (matchingInformationFieldExists?.submitDeadline) {
      const subDeadline = overDateNow(matchingInformationFieldExists.submitDeadline);
      if (subDeadline) {
        setIsOverSubmitDeadline(true);
      }
    }
  }, [matchingInformationFieldExists]);
  if (positionSn === -1 || isError) return <MatchingGuideEmpty />;
  return (
    <>
      <Header>
        <IconLogo className='logo' />
      </Header>
      <Frame>
        <ContentsFrame>
          <img src={ImgGuide} alt='지원 가이드' />
          <V2Text fontStyle={V2FontStyleType.heading_3_b} color={V2TextColorsType.default}>2025년 상반기<br />마이다스 수시 채용</V2Text>
          <SpacingBlock size={SpaceValue.XL} vertical />
          <V2Text className='intro-desc' fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
            아래 항목을 준비하여 마감일까지
            <br />
            제출해 주시기 바랍니다. 제출된 자료는
            <br />
            마이다스 그룹 리더들이 검토하며
            <br />
            면접 대상자 선정을 위한 중요한 자료로 사용됩니다.
          </V2Text>
        </ContentsFrame>
        <div className='btn-list'>
          {matchingInformationFieldExists?.requiredFields.map(({ field, isCompleted }) => (
            <div
              role='button'
              onClick={() => handleNextStep(field)}
              className='apply-title'
              key={field}
            >
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_1_b}
                color={getColorType(field)}
              >
                {MatchingInformationFieldTypeText[field]}
              </V2Text>
              <div className='badge'>
                {isCompleted ? (
                  <>
                    <V2Icon
                      name={V2IconOption.name.checkCircleFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.primary}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.success}
                    >
                      완료
                    </V2Text>
                  </>
                ) : (
                  <>
                    <V2Icon
                      name={V2IconOption.name.errorFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.accent_red}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.danger}
                    >
                      미완료
                    </V2Text>
                  </>
                )}
              </div>
            </div>
          ))}
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_3_m}
            color={V2TextOption.color.subtlest}
          >
            개발자 성향검사, 개발 구현 능력 검사는 PC 환경에서 가능합니다.
            <br />
            전형 관련 문의 사항은 <a className='support-mail' href={`mailto:${SupportMail}`}>{SupportMail}</a>로 문의해 주세요.
          </V2Text>
        </div>

        {/* 동의문 */}
        <AgreeFrame>
          <div className='agree-text'>
            <CheckFrame onClick={() => {
              if (!matchingInformationFieldExists?.isFinalSubmit) {
                setIsChecked(!isChecked);
              }
            }}
            >
              <V2Icon name={isChecked ? 'check-circle-filled' : 'check-circle'} size={V2IconOption.size.S} fill={isChecked ? V2IconOption.fill.accent_green : V2IconOption.fill.subtlest} />
              <V2Text className='intro-desc' fontStyle={V2FontStyleType.body_2_m} color={V2TextColorsType.subtle}>
                개인정보 제3자 제공에 동의합니다. (필수)
              </V2Text>
            </CheckFrame>
            <div role='button' onClick={() => setOpenPrivacyNotice(true)}>
              <V2Icon name={V2IconOption.name.arrowRight} size={V2IconOption.size.S} fill={V2IconOption.fill.default} />
            </div>
          </div>

          {/* 모달 */}
          {openPrivacyNotice && (
          <ModalOverlay onClick={() => setOpenPrivacyNotice(false)}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <CloseButton onClick={() => setOpenPrivacyNotice(false)}>
                <V2Icon
                  name={V2IconOption.name.close}
                  size={V2IconOption.size.M}
                  fill={V2IconOption.fill.default}
                />
              </CloseButton>
              <V2Text fontStyle={V2FontStyleType.body_1_sb} color={V2TextColorsType.default}>개인정보 제3자 제공 동의</V2Text>
              <SpacingBlock size={SpaceValue.M} vertical />
              <div className='text'>
                <V2Text fontStyle={V2FontStyleType.body_3_sb} color={V2TextColorsType.subtle}>회사는 이용자의 개인정보를 &apos;개인정보 수집 및 이용목적&apos; 에서 고지한 범위 내 사용하며, 이용자의 사전 동의 없이 범위를 초과하여 이용하거나 타인 또는 타 기업, 기관에 제공하지 않습니다.
                  <br />
                  다만, 회사는 이용자의 지원서 제출 시 합당한 절차를 통해 이용자의 동의를 얻어 개인정보를 제공할 수 있습니다
                </V2Text>
                <SpacingBlock size={SpaceValue.M} vertical />
                <InfoTable>
                  <tbody>
                    <tr>
                      <InfoTh>
                        <V2Text fontStyle={V2FontStyleType.body_3_b} color={V2TextColorsType.default}>개인정보를 제공받는 자</V2Text>
                      </InfoTh>
                      <InfoTd>
                        <V2Text fontStyle={V2FontStyleType.body_3_m} color={V2TextColorsType.default}>마이다스인</V2Text>
                      </InfoTd>
                    </tr>
                    <tr>
                      <InfoTh>
                        <V2Text fontStyle={V2FontStyleType.body_3_b} color={V2TextColorsType.default}>개인정보 이용 목적</V2Text>
                      </InfoTh>
                      <InfoTd>
                        <V2Text fontStyle={V2FontStyleType.body_3_m} color={V2TextColorsType.default}>채용 목적에 따른 지원서 검토</V2Text>
                      </InfoTd>
                    </tr>
                    <tr>
                      <InfoTh>
                        <V2Text fontStyle={V2FontStyleType.body_3_b} color={V2TextColorsType.default}>제공하는 개인정보 항목</V2Text>
                      </InfoTh>
                      <InfoTd>
                        <V2Text fontStyle={V2FontStyleType.body_3_m} color={V2TextColorsType.default}>
                          [JOBDA 회원 정보]<br />
                          성명, 이메일, 휴대전화번호, 프로필 사진/일상 사진
                        </V2Text>
                      </InfoTd>
                    </tr>
                    <tr>
                      <InfoTh>
                        <V2Text fontStyle={V2FontStyleType.body_3_b} color={V2TextColorsType.default}>개인정보 보유 및 이용 기간</V2Text>
                      </InfoTh>
                      <InfoTd>
                        <V2Text fontStyle={V2FontStyleType.body_3_m} color={V2TextColorsType.default}>목적 달성시까지 또는 정보 주체의 동의 철회시까지</V2Text>
                      </InfoTd>
                    </tr>
                  </tbody>
                </InfoTable>
                <SpacingBlock size={SpaceValue.M} vertical />
                <V2Text fontStyle={V2FontStyleType.body_3_sb} color={V2TextColorsType.subtle}>이용자는 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다. 다만, 개인정보 제3자 제공에 동의하지 않을 경우에는 입사 지원이 불가합니다.
                </V2Text>
              </div>
            </ModalContent>
          </ModalOverlay>
          )}
        </AgreeFrame>

        {/* 최종 버튼 */}
        <div className='btn-area'>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            fill={isChecked ? V2ButtonOption.fillType.brand_strong_default : V2ButtonOption.fillType.brand_default}
            buttonProps={{ disabled: !isChecked || !isAllFieldsCompleted || isOverSubmitDeadline || matchingInformationFieldExists?.isFinalSubmit, onClick: () => setIsFinalSubmitModal(true) }}
          >
            { matchingInformationFieldExists?.isFinalSubmit ? '🎉 최종 제출이 완료되었습니다.' : isOverSubmitDeadline ? '지원 마감되었습니다' : '최종 제출'}
          </V2Button>
        </div>
        <NoticeDialog
          dialogType={DialogType.NOTICE}
          title='전형을 모두 마무리하셨나요?'
          content={`최종 제출 후에는 수정이 불가능합니다. \n제출 전에 반드시 모든 전형을 확인하고 필요한 내용을 수정해 주세요.`}
          isOpen={isFinalSubmitModal}
          onCancel={() => {
            setIsFinalSubmitModal(false);
          }}
          onOk={() => {
            handleSubmitMatchingGuide();
          }}
          firstButtonText='최종 제출'
          secondButtonText='취소'
        />
      </Frame>
    </>
  );
};

export default MatchingGuideMainPage;
