import { RecruitmentType } from 'consts/_v2/position/PositionType';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import BusinessSize from 'consts/BusinessSize';
import { JobSearchStatusType } from 'consts/MatchType';
import { IAttachFile, IProfileEducation } from '../profile/IProfileEducation';

export interface IMatchingHomeDashboardRs {
    matchingProfilePercent: number
    isApplied: boolean
    bookmarkCount: number
    matchingProposalCount: number
    applicationStatusCount: number
    matchingUseYn: boolean
    jobSearchStatus: JobSearchStatusType
  }

export interface IMatchingHomeMatchingSettingUpdateRq{
    jobSearchStatus: JobSearchStatusType
    rejectPeriodMonth:number | null
  }

export interface IMatchingHomeAccJobPostingRs {
    accJobPostings: MatchingHomeJobPostingDto[]
  }

export interface IMatchingHomeNowJobPostingRs{
    nowJobPostings: MatchingHomeJobPostingDto[]
  }

export interface MatchingHomeJobPostingDto {
    sn: number
    companyName: string
    title: string
    closingDateTime: string
    logoImageUrl: string
    representativeImageUrl: string
  }

export interface IMatchingHomeMatchingCompanyRs {
    matchingCompanyCount: number
    matchingCompanyList: MatchingHomeMatchingCompanyDetailDto[]
  }

export interface MatchingHomeMatchingCompanyDetailDto {
    companySn: number
    companyName: string
    companyBusinessSize: BusinessSize
    companyLocationCode: number
    logoImageUrl: string
  }

export interface MatchingHomeMatchingPositionDetailDto{
    userName:string;
    companyName:string;
}
export interface IMatchingHomeMatchingPositionRs{
    matchingPositionList:MatchingHomeMatchingPositionDetailDto[]
}

export interface IMatchingHomeMatchingQuestStatusRs{
  matchingProfilePercent:number;
    matchingTestCompleted:boolean
    tutorialExperienced:boolean
}

export interface IMatchingHomeRecommendPositionsRs {
    recommendPositions: MatchingHomeRecommendPositionDto[]
  }

export interface MatchingHomeRecommendPositionDto {
    positionSn: number
    positionName: string
    thumbnailImageUrl: string
    companyName: string
    recruitmentType: RecruitmentType
    locationCode: number
    closingDateTime: string
  }

export interface IMatchingHomeJobPostingsBookmarkRs{
  bookmarkList:MatchingHomeJobPostingsBookmarkDto[]
}

export interface MatchingHomeJobPostingsBookmarkDto{
  positionSn:number;
  isBookmark:boolean;
}

export interface IMatchingHomeRs {
  matchingHomeCareers: IMatchingHomeCareer[];
  education: IMatchingHomeEducation;
  matchingProfileImageUrl: string;
  introductionTitle: string;
  condition: IMatchingHomeCondition;
}

export interface IMatchingHomeCareer {
  sn: number;
  companySn: number;
  companyName: string;
  employmentStatus: string; // 'WORK' 등의 값
  startDate: string;
  endDate: string;
  jobGroupCode: number;
  jobGroupName: string;
  jobTitleCode: number;
  jobTitleName: string;
  employmentType: string; // 'FULL_TIME' 등의 값
  performance: string;
  role: string;
  isVerified: boolean;
  auditItems?: ProfileAuditItemType[];
}

export interface IMatchingHomeEducation extends IProfileEducation {
  // IProfileEducation의 모든 필드를 상속
}

export interface IMatchingHomeCondition {
  jobTitleNames: string[];
  locations: number[];
  salary: number;
  workingPreference: string;
}

export interface IMatchingHomeAttachFile extends IAttachFile {
  fileSize: number;
  fileUrl: string;
}

export interface IUserDisplayNotificationRs{
  displayNotifications:DisplayNotificationDto[]
}
export interface IUserDisplayNotificationRq{
  displayNotificationTypes:DisplayNotificationType[]
}

export enum DisplayNotificationType{
  MATCHING_PROFILE_MIGRATION = 'MATCHING_PROFILE_MIGRATION',
  MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY = 'MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY',
  MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY = 'MATCHING_GENERAL_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY',
  MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY = 'MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_SELECTED_MATCHING_APPLY',
  MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY = 'MATCHING_DEVELOPER_PERSONALITY_APPLY_MIGRATION_FROM_LATEST_APPLY',
}
export interface DisplayNotificationDto{
  migrationTime:string;
  type:DisplayNotificationType;

}
