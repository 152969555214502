import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import styled, { keyframes } from 'styled-components';
import { injectStore } from 'models/store';
import JoinModel from 'models/_v2/JoinModel';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { Button, ButtonMode } from '__designkit__/button/Button';
import { TermType, TERM_TYPE } from 'consts/TermType';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  height: fit-content;
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  & .open {
    transform: rotate(180deg);
  }
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  padding: 17px;
  border-bottom: 1px solid ${Colors.CG_40};
  font: ${Fonts.B1_Bold};
  text-align: center;
`;

const Contents = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 32px;
`;

const AllTerms = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  margin-bottom: 8px;
  font: ${Fonts.B3_Medium};

  > div {
    transition: .3s;

    & > input {
      ${JDCheckBoxInput}
    }

    & > span {
      margin-left: 8px;
    }
  }
`;

const TermsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_80};

  &.depth {
    margin-left: 24px;
  }

  > div {
    display: flex;
    align-items: center;

    > div {
      transition: .3s;
    }
    
    & > input {
      ${JDCheckBoxInput}
    }
  }
`;

const BtnFrame = styled.footer`
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: ${Colors.WHITE_100};
`;

interface IJoinTermsModalProps extends IComponentProps {
  onClickComplete?: () => void;
  onClickClose?: () => void;
  joinModel?: JoinModel;
  setIsCheckAllTerms: (value: boolean) => void;
  isCheckAllTerms: boolean;
}

const JoinTermsModal: FC<IJoinTermsModalProps> = ({ onClickComplete, onClickClose, joinModel = new JoinModel(), setIsCheckAllTerms, isCheckAllTerms }) => {
  const formMethods = useFormContext();
  const { register, reset, getValues } = formMethods;

  const [isOpenAllTerms, setIsOpenAllTerms] = useState<boolean>(false);
  const [isOpenMarketingTerms, setIsOpenMarketingTerms] = useState<boolean>(false);

  const [termsOfService, setTermsOfService] = useState<boolean>(false);
  const [privacyCollection, setPrivacyCollection] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
  const [marketingSmsConsent, setMarketingSmsConsent] = useState<boolean>(false);
  const [marketingEmailConsent, setMarketingEmailConsent] = useState<boolean>(false);

  const onClickAllTerms = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setTermsOfService(true);
      setPrivacyCollection(true);
      setMarketingSmsConsent(true);
      setMarketingEmailConsent(true);
    } else {
      setTermsOfService(false);
      setPrivacyCollection(false);
      setMarketingSmsConsent(false);
      setMarketingEmailConsent(false);
    }
  };

  const onClickMarketingTerms = () => {
    if (marketingConsent) {
      setMarketingSmsConsent(false);
      setMarketingEmailConsent(false);
    } else {
      setMarketingSmsConsent(true);
      setMarketingEmailConsent(true);
    }
  };

  const onClickDetail = (termType: TermType) => {
    joinModel.activeTermType = termType;
  };

  // 전체 약관 동의 보기 닫기 시, 마케팅 약관 동의 보기도 닫히도록
  useEffect(() => {
    if (!isOpenAllTerms) {
      setIsOpenMarketingTerms(false);
    }
  }, [isOpenAllTerms]);

  useEffect(() => {
    if (termsOfService && privacyCollection && marketingSmsConsent && marketingEmailConsent) {
      setIsCheckAllTerms(true);
    } else {
      setIsCheckAllTerms(false);
    }
  }, [termsOfService, privacyCollection, marketingSmsConsent, marketingEmailConsent]);

  useEffect(() => {
    if (marketingSmsConsent && marketingEmailConsent) {
      setMarketingConsent(true);
    } else {
      setMarketingConsent(false);
    }
  }, [marketingSmsConsent, marketingEmailConsent]);

  useEffect(() => {
    if (marketingConsent) {
      setMarketingSmsConsent(true);
      setMarketingEmailConsent(true);
    }
  }, [marketingConsent]);

  useEffect(() => {
    reset({
      ...getValues(),
      agreeTermsOfServiceYn: termsOfService,
      agreePrivacyCollectionYn: privacyCollection,
      marketingConsentYn: marketingConsent,
      marketingSmsConsentYn: marketingSmsConsent,
      marketingEmailConsentYn: marketingEmailConsent,
    });
  }, [reset, getValues, termsOfService, privacyCollection, marketingConsent, marketingSmsConsent, marketingEmailConsent]);

  return (
    <JDDimModal onClickClose={onClickClose}>
      <Frame>
        <Header>
          서비스 이용 약관 동의
        </Header>
        <SpacingBlock size={12} vertical />
        <Contents>
          <AllTerms>
            <div>
              <input type='checkbox' checked={isCheckAllTerms} onChange={onClickAllTerms} />
              <span>모든 약관 사항에 전체 동의합니다.</span>
            </div>
            <Icon name='arrow-bottom' size={32} className={isOpenAllTerms ? 'open' : ''} onClick={() => setIsOpenAllTerms(!isOpenAllTerms)} />
          </AllTerms>
          { isOpenAllTerms && (
            <div>
              <TermsItem>
                <div>
                  <input type='checkbox' aria-hidden ref={register} name='agreeTermsOfServiceYn' />
                  <Icon name='check' size={32} color={termsOfService ? Colors.G_150 : Colors.CG_60} onClick={() => setTermsOfService(!termsOfService)} />
                  <span>서비스 이용약관 동의 (필수)</span>
                </div>
                <span role='button' onClick={() => onClickDetail(TERM_TYPE.TERMS_OF_SERVICE)}>자세히</span>
              </TermsItem>
              <TermsItem>
                <div>
                  <input type='checkbox' aria-hidden ref={register} name='agreePrivacyCollectionYn' />
                  <Icon name='check' size={32} color={privacyCollection ? Colors.G_150 : Colors.CG_60} onClick={() => setPrivacyCollection(!privacyCollection)} />
                  <span>개인정보 수집 및 이용 동의 (필수)</span>
                </div>
                <span role='button' onClick={() => onClickDetail(TERM_TYPE.PRIVACY_COLLECTION)}>자세히</span>
              </TermsItem>
              <TermsItem>
                <div>
                  <input type='checkbox' aria-hidden ref={register} name='marketingConsentYn' />
                  <Icon name='check' size={32} color={marketingConsent ? Colors.G_150 : Colors.CG_60} onClick={onClickMarketingTerms} />
                  <span>마케팅 정보 수신 동의 (선택)</span>
                  <Icon name='arrow-bottom' size={24} className={isOpenMarketingTerms ? 'open' : ''} onClick={() => setIsOpenMarketingTerms(!isOpenMarketingTerms)} />
                </div>
                <span role='button' onClick={() => onClickDetail(TERM_TYPE.MARKETING_POLICY)}>자세히</span>
              </TermsItem>
            </div>
          )}
          {isOpenAllTerms && isOpenMarketingTerms && (
            <div>
              <TermsItem className='depth'>
                <div>
                  <input type='checkbox' aria-hidden ref={register} name='marketingSmsConsentYn' />
                  <Icon name='check' size={24} color={marketingSmsConsent ? Colors.G_150 : Colors.CG_60} onClick={() => setMarketingSmsConsent(!marketingSmsConsent)} />
                  <span>SMS</span>
                </div>
              </TermsItem>
              <TermsItem className='depth'>
                <div>
                  <input type='checkbox' aria-hidden ref={register} name='marketingEmailConsentYn' />
                  <Icon name='check' size={24} color={marketingEmailConsent ? Colors.G_150 : Colors.CG_60} onClick={() => setMarketingEmailConsent(!marketingEmailConsent)} />
                  <span>Email</span>
                </div>
              </TermsItem>
            </div>
          )}
        </Contents>
        <SpacingBlock size={12} vertical />
        <BtnFrame>
          <Button label='가입 완료' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickComplete} disabled={!(termsOfService && privacyCollection)} />
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.joinModel)(observer(JoinTermsModal));
