import { useState, useRef, useEffect } from 'react';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import imgAlarm1 from '../img/imgAlarm1.png';
import imgAlarm2 from '../img/imgAlarm2.png';
import imgAlarm3 from '../img/imgAlarm3.png';
import imgAlarm4 from '../img/imgAlarm4.png';
import imgAlarm5 from '../img/imgAlarm5.png';
import imgAlarm6 from '../img/imgAlarm6.png';
import imgAlarm7 from '../img/imgAlarm7.png';
import imgAlarm8 from '../img/imgAlarm8.png';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface AnimationProps {
  fadeIn: boolean;
}

const AlarmVisual = ({ fadeIn }: AnimationProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const moveToMatching = () => {
    const currentUrl = window.location.search;
    history.push(`${RoutePaths.match_event_login}${currentUrl}`);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // 이미지 배열 선언
  const alarmImages = [
    imgAlarm1,
    imgAlarm2,
    imgAlarm3,
    imgAlarm4,
    imgAlarm5,
    imgAlarm6,
    imgAlarm7,
    imgAlarm8,
  ];

  return (
    <div className={cx('wrapper', { on: fadeIn })} ref={elementRef}>
      <div className={cx('contentInner')}>
        <V2Text
          fontStyle={V2TextOption.fontStyle.heading_3_b}
          color={V2TextOption.color.subtlest}
          styles={{ opacity: 0.64 }}
        >
          그리고 쏟아지는 면접 제안!
        </V2Text>
        <V2Text fontStyle={V2TextOption.fontStyle.heading_3_b} color={V2TextOption.color.default}>
          수락하면, 취업 성공!
        </V2Text>
        <button id='eventBtn-5' className={cx('btnApply')} onClick={moveToMatching}>
          <V2Text fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.inverse}>
            프로필 입력하고 쉽게 취업하기
          </V2Text>
        </button>
      </div>
      {/* 알람 리스트 */}
      <div className={cx('alarmList', { visible: isVisible })}>
        {alarmImages.map((image, index) => (
          <img key={`alarm-${index}`} src={image} alt='alarmList' />
        ))}
      </div>
    </div>
  );
};

export default AlarmVisual;
