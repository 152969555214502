/* eslint-disable prefer-destructuring */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { useMatchApplicantFiles, useUploadMatchApplicantPhotosSelfExpression } from 'query/match/applicants/useMatchApplicantsQuery';
import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import IconChange from 'assets/_v2/match/icon_change.svg';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import request from 'utils/request';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import URIs from 'consts/URIs';
import MatchApplySelfBrandingDailyPhotosExampleModal from './MatchApplySelfBrandingDailyPhotosExampleModal';

const HeaderFrame = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-left: ${SpaceValue.L}px;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SpaceValue.XL}px 0;
  .file-wrap{
    display: flex;
    gap: ${SpaceValue.L}px;
    width:100%;
    overflow-x:scroll;
    overflow-y:hidden;
    padding-left: ${SpaceValue.L}px;

    &:last-child {
      padding-right: ${SpaceValue.L}px;
    }

    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .file-upload-container{
      display: flex;
      flex-direction: column;
      gap: ${SpaceValue.L}px;
    }
    .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XL}px 0 ${SpaceValue.L}px 0;
    height: 240px;
    width: 216px;
    border: 1px solid ${V2BorderColors.default};
    border-radius: ${RadiusValue.L}px;
    background-color: white;
    overflow: hidden;

    &.has-upload {
      background-color: black;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .file-change-icon {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }
      /* 한문장 소개 */
    .input-container {
      position: relative;
      width: 100%;
      height: 104px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
      margin-bottom: 32px;
    }

    .custom-input {
      width: 100%;
      height: 100%;
      padding: ${SpaceValue.M}px;
      padding-right: 40px; /* 버튼 공간 확보 */
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }
    .length-title {
      position: absolute;
      right: 0;
      bottom: -28px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }

    .length-text {
      position: absolute;
      right: 12px;
      bottom: 7px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }
  }
  

  .file-change {
    position: absolute;
    right: 12px;
    bottom: 12px;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

interface Props {
  photos: (File | null)[];
  descriptions: string[];
  onPhotosChange: (photos: (File | null)[]) => void;
  onDescriptionsChange: (descriptions: string[]) => void;
  uploadFileUid: (string | null)[];
  setUploadFileUid: (uploadFileUid: (string | null)[]) => void;
}

const MatchApplySelfBrandingDailyPhotos: FC<Props> = ({
  photos,
  descriptions,
  onPhotosChange,
  onDescriptionsChange,
  uploadFileUid,
  setUploadFileUid,
}) => {
  const [previewUrls, setPreviewUrls] = useState<(string | null)[]>([null, null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const examplePalceHolder = [
    '나눌수록 더 크게 채워진다고 믿어요.',
    '궁금한 건 끝까지 파고드는 편이에요.',
    '사람들과 대화하며 배우는 걸 좋아해요.',
  ];
  const { data: filesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO);

  useEffect(() => {
    const init = async () => {
      try {
        if (filesData?.files) {
          const newPhotos = [...photos];
          const newUrls = [...previewUrls];
          const newDescriptions = [...descriptions];
          const newUploadFileUid = [...uploadFileUid];
          for (let i = 0; i < filesData.files.length; i++) {
            const file = filesData.files[i];
            const { fileUrl, fileUid } = file;
            // const fileUrl = file.fileUrl;
            // const fileName = file.fileName;
            // const fileExt = fileName.split('.').pop()?.toLowerCase();
            // const mimeType = fileExt === 'png' ? 'image/png'
            //   : fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg'
            //     : 'image/jpeg';

            // const existingFile = await FileUtil.urlToFile(fileUrl, fileName, mimeType);
            // newPhotos[i] = existingFile;
            newUrls[i] = fileUrl;
            newDescriptions[i] = file.fileDescription || '';
            newUploadFileUid[i] = fileUid;
          }

          setUploadFileUid(newUploadFileUid);
          setPreviewUrls(newUrls);
          onDescriptionsChange(newDescriptions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [filesData]);

  const handleImageUpload = (index: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const data = new FormData();
      data.append('file', file);
      try {
        const res = await request<IAttachFile>({
          method: 'post',
          url: URIs.post_profiles_file_upload,
          data,
        });
        if (res.fileUid) {
          const newUid = [...uploadFileUid];
          newUid[index] = res.fileUid;
          setUploadFileUid(newUid);
        }
      } catch (error) {
        console.error('Failed to upload photos:', error);
        setIsLoading(false);
      }

      const newUrls = [...previewUrls];
      newUrls[index] = URL.createObjectURL(file);
      setPreviewUrls(newUrls);
    }
  };

  const handleDescriptionChange = (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = e.target.value;
    onDescriptionsChange(newDescriptions);
  };

  // const handleUpload = async () => {
  //   if (!isAllInputsCompleted()) {
  //     return;
  //   }
  //   setIsLoading(true);

  //   const formData = new FormData();
  //   photos.forEach((image) => {
  //     if (image) {
  //       formData.append('multipartFiles', image);
  //     }
  //   });

  //   try {
  //     uploadPhotos({
  //       formData,
  //       descriptions: descriptions.filter((_, index) => photos[index] !== null),
  //     });
  //   } catch (error) {
  //     console.error('Failed to upload photos:', error);
  //     setIsLoading(false);
  //   }
  // };
  const isAllInputsCompleted = () => {
    return !photos.includes(null) && descriptions.every((desc) => desc.trim() !== '');
  };

  return (
    <Frame>
      <HeaderFrame>
        <V2Text fontStyle={V2TextOption.fontStyle.body_1_sb} color={V2TextColorsType.default}>
          나를 표현하는 사진 및 문구
        </V2Text>
        <V2Button
          type={V2ButtonOption.type.Text}
          size={V2ButtonOption.size.M}
          fontStyle={V2ButtonOption.fontStyle.body_2_sb}
          color={V2ButtonOption.color.subtle}
          buttonProps={{ onClick: () => setIsOpen(true) }}
        >
          가이드 보기
        </V2Button>
      </HeaderFrame>

      {/* 업로드 Input */}
      <div className='file-wrap'>
        {[0, 1, 2].map((index) => (
          <div className='file-upload-container' key={index}>
            <div className={`file-upload ${previewUrls[index] ? 'has-upload' : ''}`}>
              {previewUrls[index] ? (
                <img src={previewUrls[index] ?? undefined} alt='Uploaded Preview' />
              ) : (
                <>
                  <V2Icon name='add' />
                  <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
                    사진 추가하기
                  </V2Text>
                </>
              )}
              <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
              {previewUrls[index] && (
              <div className='file-change'>
                <img className='file-change-icon' src={IconChange} alt='사진 수정' />
                <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
              </div>
              )}
            </div>
            <div className='input-container'>
              <textarea
                className='custom-input'
                value={descriptions[index]}
                onChange={handleDescriptionChange(index)}
                placeholder={examplePalceHolder[index]}
                maxLength={40}
              />
              <div className='length-title'>
                {descriptions[index].length}/40
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className='action-buttons'>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: () => handleUpload(), disabled: !isAllInputsCompleted() }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          isLoading={isLoading}
        >
          제출하기
        </V2Button>
      </div> */}
      <MatchApplySelfBrandingDailyPhotosExampleModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Frame>
  );
};

export default MatchApplySelfBrandingDailyPhotos;
