/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import URIs from 'consts/URIs';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/request';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import { IconArrowBack } from 'consts/assets/icons/iconPages';
import { ReactComponent as IconGuide } from './icon_guide.svg';
import VideoRecordGuideHorizontalModal from './VideoRecordGuideHorizontalModal';
import IconSmile from './icon_smile.png';

const FullscreenCameraWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100); /* 100vh 대신 동적 높이 */
  overflow: hidden;
  background-color: black;

  .dim-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 80px;
    z-index: 15;
    pointer-events: none; /* 클릭 방지 */
    .countdown-text{
      transform: rotate(270deg);
    }
  }
  .btn-back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-top: 48px;
      z-index:14;
      cursor: pointer; 
    }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1); /* 좌우 반전 */
  }
  .icon-guide {
    position: absolute;
    top: 50%;
    left: 60%;
    
    z-index: 10;
    pointer-events: none; /* 클릭이 비디오에 전달되도록 */
    width: 192px; /* 아이콘 크기 조절 */
    height: auto; /* 비율 유지 */
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  .second-background{
    position: absolute;
    left:40%;
    top:28%;
    display: flex;
    padding:4px 12px;
    background-color: #f0f9ff;
    border-radius: 4px;
    gap:1px;
    width: max-content;

    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    z-index: 10;
    .text-guide {
      white-space: nowrap;
    color: white;
    text-align: center;
    pointer-events: none; /* 클릭 방지 */
  }
  .icon-smile{
    width: 20px;
    height: 20px;
  }
  &:before{
    content: '';
    position: absolute;
    display: flex;
    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    width: 4px;
    height: 150px;
    align-items: center;
    background: repeating-linear-gradient(
    #2497F3 0,
    #2497F3 4px, /* 점선의 두께 */
    transparent 4px,
    transparent 8px /* 점선 간격 */
  );   
    left:-81px;
    top:14px;
  }
  }
  .third-background{
    position: absolute;
    left:65%;
    top:28%;
    display: flex;
    padding:4px 12px;
    background-color: #f0f9ff;
    border-radius: 4px;
    gap:1px;
    width: max-content;

    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    z-index: 10;
    .text-guide {
      white-space: nowrap;
    color: white;
    text-align: center;
    pointer-events: none; /* 클릭 방지 */
  }
  .icon-smile{
    width: 20px;
    height: 20px;
  }
  &:before{
    content: '';
    position: absolute;
    display: flex;
    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    width: 4px;
    height: 150px;
    align-items: center;
    background: repeating-linear-gradient(
    #2497F3 0,
    #2497F3 4px, /* 점선의 두께 */
    transparent 4px,
    transparent 8px /* 점선 간격 */
  );    
  left:-94px;
    top:14px;
  }
  }
  .background-frame{
    position: absolute;
    left:40px;
    top:50%;
    display: flex;
    padding:4px 12px;
    background-color: #f0f9ff;
    border-radius: 4px;
    gap:1px;

    transform: translate(-50%, -50%) rotate(-90deg); /* 텍스트 회전 */
    z-index: 10;
    .text-guide {

    color: white;
    text-align: center;
    pointer-events: none; /* 클릭 방지 */
  }
  .icon-smile{
    width: 20px;
    height: 20px;
  }
  }

  .controls {
    position: absolute;
    bottom: calc(30px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 10;
    .record-button{
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${SpaceValue.XS}px;
      border-radius: 28px;
      border:2px solid #fff;
      .record-inner{
        width: 100%;
        height: 100%;
        border-radius: 9999px;
        background-color: #fff;
      }
    }
    .recording-button{
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${SpaceValue.XS}px;
      border-radius: 28px;
      border:2px solid #fff;
      .recording-inner{
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: #F1392c;
      }
    }
    button {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .timer-progress {
  position: absolute;
  top: calc(22px + env(safe-area-inset-top));
  left: 0;
  width: 100%;
  display: flex;
  align-items: center; /* 세로 정렬 */
  justify-content: space-between; /* 좌우 숫자를 양쪽 끝으로 정렬 */
  padding: 0 16px; /* 좌우 여백 추가 */
  z-index: 10;

  .time {
    font-size: 14px;
    font-weight: bold;
    color: white;
    flex-shrink: 0; /* 숫자가 작아지지 않도록 설정 */
  }

  .progress-bar {
    position: relative;
    flex: 1; /* 남은 공간을 차지 */
    height: 4px; /* 프로그레스바 높이 */
    background-color: rgba(255, 255, 255, 0.5); /* 배경색 */
    margin: 0 8px; /* 좌우 숫자와 간격 */
    border-radius: 2px; /* 둥근 모서리 */

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0%; /* 초기 진행 상태 */
      background-color: #00b840;
      border-radius: 2px; /* 둥근 모서리 */
      transition: width 0.2s linear, background-color 0.3s ease;
    }
  }
}

`;
const setDynamicVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
interface IVideoRecorder{
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  mediaBlobUrl: string | null;
  setMediaBlobUrl: React.Dispatch<React.SetStateAction<string | null>>;
  videoBlob: Blob | null;
  setVideoBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
}
const VideoRecorder: React.FC<IVideoRecorder> = ({ setCurrentStep, mediaBlobUrl, setMediaBlobUrl, videoBlob, setVideoBlob }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const recordingInterval = useRef<NodeJS.Timeout | null>(null);
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(true);
  const [countdown, setCountdown] = useState<number | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // 동적 vh 설정
    setDynamicVH();
    window.addEventListener('resize', setDynamicVH);

    return () => {
      window.removeEventListener('resize', setDynamicVH);
    };
  }, []);

  // 카메라 설정 및 프리뷰
  const setupPreview = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user', width: 1280, height: 720 },
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Failed to access camera:', error);
      alert('Camera access is required.');
    }
  };
  useEffect(() => {
    if (recordingTime >= 90) {
      stopRecording();
    }
  }, [recordingTime]);
  // 녹화 시작
  const startRecording = async () => {
    setMediaBlobUrl(null);
    setRecordingTime(0); // 타이머 초기화
    recordingInterval.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);

    const stream = videoRef.current?.srcObject as MediaStream;
    if (!stream) return;

    const mimeType = MediaRecorder.isTypeSupported('video/mp4')
      ? 'video/mp4'
      : 'video/webm';
    const mediaRecorder = new MediaRecorder(stream, { mimeType });

    mediaRecorderRef.current = mediaRecorder;

    recordedChunks.current = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunks.current, { type: mimeType });
      const url = URL.createObjectURL(blob);
      setMediaBlobUrl(url);
      setVideoBlob(blob);

      if (recordingInterval.current) {
        clearInterval(recordingInterval.current);
      }
    };

    mediaRecorder.start(100);
    setRecording(true);
  };
  const handleStartRecording = () => {
    if (countdownIntervalRef.current) return; // ✅ 중복 실행 방지

    setCountdown(5); // 카운트다운 시작
    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(countdownIntervalRef.current!);
          setCountdown(null);
          startRecording(); // 녹화 시작
          return null;
        }
        return prev! - 1;
      });
    }, 1000);
  };
  useEffect(() => {
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
        countdownIntervalRef.current = null;
      }
    };
  }, []);
  // 녹화 중지
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      if (recordingInterval.current) {
        clearInterval(recordingInterval.current);
      }
    }
  };
  useEffect(() => {
    if (mediaBlobUrl) {
      setCurrentStep(6);
    }
  }, [mediaBlobUrl]);
  useEffect(() => {
    setupPreview();

    return () => {
      if (videoRef.current?.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  const progressPercentage = Math.min((recordingTime / 90) * 100, 100); // 90초 기준
  const onBackBtnClickCallback = () => {
    setCurrentStep(4);
  };
  return (
    <FullscreenCameraWrapper>
      {countdown !== null && (
      <div className='dim-overlay'>
        <V2Text
          fontStyle={V2TextOption.fontStyle.display_1_sb}
          color={V2TextOption.color.inverse}
          className='countdown-text'
        >
          {countdown}
        </V2Text>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_1_sb}
          color={V2TextOption.color.inverse}
          className='countdown-text'
        >
          미리 미소를 지어주세요.
        </V2Text>
      </div>
      )}

      <div className='timer-progress'>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.inverse}
        >
          {formatTime(recordingTime)}
        </V2Text>
        <div className='progress-bar'>
          <div
            className='progress'
            style={{
              width: `${progressPercentage}%`,
              backgroundColor: recordingTime >= 60 ? '#f1392c' : '#00b840',
            }}
          />
        </div>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_3_m}
          color={V2TextOption.color.inverse}
        >
          01:30
        </V2Text>
      </div>
      <button className='btn-back' onClick={onBackBtnClickCallback}>
        <IconArrowBack fill='white' />
      </button>
      {
        recording
          ? (
            <div className='background-frame'>
              <img src={IconSmile} alt='Smile' className='icon-smile' />
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_sb}
                color={V2TextOption.color.information}
                className='text-guide'
              >미소를 계속 유지해주세요.
              </V2Text>
            </div>
          )
          : (
            <div className='background-frame'>
              <img src={IconSmile} alt='Smile' className='icon-smile' />
              <V2Text
                fontStyle={V2TextOption.fontStyle.body_2_sb}
                color={V2TextOption.color.information}
                className='text-guide'
              >얼굴 가이드라인에 맞춰 촬영을 해주세요.
              </V2Text>
            </div>
          )
      }
      {
  !recording && (
  <>
    <div className='second-background'>
      <img src={IconSmile} alt='Smile' className='icon-smile' />
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_2_sb}
        color={V2TextOption.color.information}
        className='text-guide'
      > 머리카락 끝을 맞춰주세요.
      </V2Text>
    </div>
    <div className='third-background'>
      <img src={IconSmile} alt='Smile' className='icon-smile' />
      <V2Text
        fontStyle={V2TextOption.fontStyle.body_2_sb}
        color={V2TextOption.color.information}
        className='text-guide'
      > 턱 끝을 맞춰주세요.
      </V2Text>
    </div>
  </>
  )
}

      <IconGuide className='icon-guide' />

      {/* 원본 비디오 */}
      <video ref={videoRef} muted playsInline />

      {/* 컨트롤 버튼 */}
      <div className='controls'>
        {!recording ? (
          <div className='record-button' role='button' onClick={handleStartRecording}>
            <div className='record-inner' />
          </div>
        ) : (
          <div className='recording-button' role='button' onClick={stopRecording}>
            <div className='recording-inner' />
          </div>
        )}
      </div>
      <VideoRecordGuideHorizontalModal isOpen={isGuideModalOpen} onOk={() => { setIsGuideModalOpen(false); }} />
    </FullscreenCameraWrapper>
  );
};

export default VideoRecorder;
