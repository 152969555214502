import { Divider1G40 } from 'components/divider/Divider';
import PositionTagList from 'components/_v2/positionJd/PositionTagList';
import { IconLocationMark } from 'consts/assets/icons/iconPages';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IPositionCompanyCardDto } from 'interfaces/_v2/positionJd/IPositionCompanyCardListRs';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PositionMatchingCompanyModal from './PositionMatchingCompanyModal';

const Frame = styled.div`
  padding: 0px 16px 32px 16px;

  .divider {
    margin-bottom: 32px;
  }

  .head {
    font: ${Fonts.H5_Bold};
    color: ${colors.CG_60};

    >b {
      color: ${colors.JOBDA_BLACK};
    }
  }

  >ul {
    margin: 16px 0;
  }

  .link[data-disabled=true] {
    pointer-events: none;
  }
`;

const AllViewButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    border-radius: 4px;
`;

const CardFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid ${colors.CG_40};
    border-radius: 6px;
    margin-bottom: 12px;

    .job-group-name {
      max-width: 280px;
      white-space:nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};
    }
`;

const CompanyDescFrame = styled.div`
    display: flex;
    margin: 8px 0;

    .company-name {
        font: ${Fonts.B3_Medium};
        margin-right: 8px;
        color: ${colors.JOBDA_BLACK};
        max-width: 180px;
        white-space:nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .company-location {
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_60};
        margin-left: 4px;
    }
`;

interface IMatchingCompanyCardProps {
    positionInfo: IPositionCompanyCardDto;
}

export const MatchingCompanyCard:FC<IMatchingCompanyCardProps> = ({ positionInfo }) => (
  <CardFrame>
    <p className='job-group-name'>{positionInfo.positionName}</p>
    <CompanyDescFrame>
      <span className='company-name'>{positionInfo.companyName}</span>
      <IconLocationMark />
      <span className='company-location'>{LocationCode(positionInfo.locationCode)}</span>
    </CompanyDescFrame>
    <PositionTagList
      recruitmentType={positionInfo.recruitmentType}
      jobTitleNames={positionInfo.jobTitleNames}
      closingDateTime={positionInfo.closingDateTime}
      minSalary={positionInfo.minSalary}
      maxSalary={positionInfo.maxSalary}
    />
  </CardFrame>
);

interface IPositionMatchingCompanyProps extends IPositionJdProps{
  preview?: boolean;
}

const PositionJdMatchingCompany:FC<IPositionMatchingCompanyProps> = ({ positionJdModel = new PositionJdModel(), preview }) => {
  const companyName = positionJdModel.companyInfo?.companyName;
  const companyList = positionJdModel.positionMatchingCompanyList;
  const ref = useRef<HTMLDivElement>(null);
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const refOffSetTop = ref.current?.offsetTop;
    positionJdModel.positionScrollOffSet = refOffSetTop || 0;
  }, [ref]);

  return (
    <>
      <Frame ref={ref}>
        <Divider1G40 className='divider' />
        <p className='head'><b>{companyName}에서 채용중</b>인 <br />다른 포지션을 만나보세요.</p>
        <ul>
          {companyList?.map((data, index) => (
            index < 3
            && (
            <>
              <div role='presentation' onClick={() => { history.push(RoutePaths.position_jd(data.positionSn)); }} className='link' data-disabled={!!preview}>
                <li key={`matching-company-card-${data.positionSn}`}><MatchingCompanyCard positionInfo={data} /></li>
              </div>
            </>
            )
          ))}
        </ul>
        { companyList && companyList.length > 3
      && (
      <AllViewButton disabled={preview} onClick={() => setIsAllViewOpen(true)}>
        포지션 {companyList?.length }개 모두보기
      </AllViewButton>
      )}
      </Frame>
      <PositionMatchingCompanyModal
        isOpen={isAllViewOpen}
        onClose={() => setIsAllViewOpen(false)}
        companyName={companyName || ''}
        companyList={companyList || []}
      />
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJdMatchingCompany));
