import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import MatchSingleMenuItem from 'components/_v2/matchApply/matchApplySettings/MatchSingleMenuItem';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`
  width: 343px;
  height: fit-content;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
`;

const TitleFrame = styled.div`
  padding: ${SpaceValue.XL}px;
  gap:8px;
`;

const ButtonFrame = styled.div`
  padding: ${SpaceValue.M}px ${SpaceValue.XL}px ${SpaceValue.XL}px ${SpaceValue.XL}px;
`;

const MissionFrame = styled.div`
display: flex;
flex-direction: column;
  padding: 0 ${SpaceValue.XL}px ${SpaceValue.M}px ${SpaceValue.XL}px;
  gap:${SpaceValue.L}px;
  .right-not-badge{
                    display: flex;
                    padding: 4px 8px;
                    border-radius: 4px;
                    background-color: ${V2BackgroundColors.accent_red_subtle};
                }
                .right-badge{
                    display: flex;
                    padding: 4px 8px;
                    border-radius: 4px;
                    background-color: ${V2BackgroundColors.accent_green_subtle};
                }
`;

interface IMatchMissionGuideModalProps extends IComponentProps {
  isOpen: boolean;
  onClickClose: () => void;
  login?: Login;
  sourcePage?: string;
}

const MatchMissionGuideModal: FC<IMatchMissionGuideModalProps> = ({ isOpen, onClickClose, login = new Login(), sourcePage }) => {
  const history = useHistory();
  const { matchConfigStatus } = login;
  const isGeneralPassed = (matchConfigStatus?.generalYn && matchConfigStatus?.matchingConfigCondition.accPersonalityCompleteYn) || !matchConfigStatus?.generalYn;
  const isDeveloperPassed = (matchConfigStatus?.developerYn && matchConfigStatus?.matchingConfigCondition.paccPersonalityCompleteYn && matchConfigStatus?.matchingConfigCondition.pstCompleteYn) || !matchConfigStatus?.developerYn;
  // if (!isGeneralPassed || !isDeveloperPassed || !matchConfigStatus?.matchingConfigCondition.matchingProfileRequiredCompleteYn) {
  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} dimmed isDimClickClose>
      <Frame>
        <TitleFrame>
          <V2Text fontStyle={V2TextOption.fontStyle.title_3_b} color={V2TextOption.color.default}>
            매칭 참여를 위한 필수 항목 안내
          </V2Text>
          <V2Text fontStyle={V2TextOption.fontStyle.body_2_m} color={V2TextOption.color.subtle}>
            매칭 프로필 작성하고, 매칭검사를 응시해 주세요.
          </V2Text>
        </TitleFrame>
        <MissionFrame>
          <MatchSingleMenuItem
            title='매칭 프로필 필수 항목 작성'
            leftIcon={<Icon name='info-user' size={48} />}
            subBadge={(
              <>
                {
                matchConfigStatus && matchConfigStatus.matchingConfigCondition.matchingProfileRequiredCompleteYn ? (
                  <div className='right-badge'>
                    <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_green_default}>완료</V2Text>
                  </div>
                ) : (
                  <div className='right-not-badge'>
                    <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_red_default}>미완료</V2Text>
                  </div>
                )
              }
              </>
            )}
            rightIcon={<Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />}
            onClick={() => { history.push(RoutePaths.match_profile); }}
          />
          <MatchSingleMenuItem
            title='매칭 검사 응시'
            leftIcon={<Icon name='test-acca' size={48} />}
            subBadge={(
              <>
                {
              isGeneralPassed && isDeveloperPassed ? (
                <div className='right-badge'>
                  <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_green_default}>완료</V2Text>
                </div>
              ) : (
                <div className='right-not-badge'>
                  <V2Text fontStyle={V2TextOption.fontStyle.caption_1_sb} color={V2TextOption.color.accent_red_default}>미완료</V2Text>
                </div>
              )
            }
              </>
)}
            rightIcon={<Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />}
            onClick={() => { history.push(RoutePaths.match_test); }}
          />
        </MissionFrame>

        <ButtonFrame>
          <V2Button
            type={V2ButtonOption.type.Fill}
            size={V2ButtonOption.size.L}
            buttonProps={{ onClick: onClickClose }}
            fill={V2ButtonOption.fillType.brand_strong_default}
            fontStyle={V2ButtonOption.fontStyle.body_1_sb}
            color={V2ButtonOption.color.inverse}
            styles={{ width: '100%' }}
          >
            확인
          </V2Button>
        </ButtonFrame>
      </Frame>
    </JDBaseModal>
  );
};

export default inject(injectStore.login)(observer(MatchMissionGuideModal));
