/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import styled from 'styled-components';
import { FC, useEffect, useState, useRef } from 'react';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import Colors from '__designkit__/common/colors';
import HxKeyFeature from 'components/_v2/hxIntroduce/HxKeyFeature';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import HxVideoSection from 'components/_v2/hxIntroduce/HxVideoSection';
import HxReservation from 'components/_v2/hxIntroduce/HxReservation';
import HxFaq from 'components/_v2/hxIntroduce/HxFaq';
import HxBottomBanner from 'components/_v2/hxIntroduce/HxBottomBanner';
import HxFooter from 'components/_v2/hxIntroduce/HxFooter';
import { RoutePaths } from 'consts/RoutePaths';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import IconRemove from 'components/_v2/hxIntroduce/icon_remove.png';
import HxTextVisual from 'components/_v2/hxIntroduce/textVisual';
import IconHx from './img/icon_hx.png';
import ImgKeyFeature0 from './img/imgMoKeyFeature0.png';
import ImgKeyFeature1 from './img/imgMoKeyFeature1.png';
import ImgKeyFeature2 from './img/imgMoKeyFeature2.png';
import ImgKeyFeature3 from './img/imgMoKeyFeature3.png';
import ImgKeyFeature4 from './img/imgMoKeyFeature4.png';
import ImgKeyFeature5 from './img/imgMoKeyFeature5.png';
import ImgKeyFeature6 from './img/imgMoKeyFeature6.png';
import ImgKeyFeature7 from './img/imgMoKeyFeature7.png';
import IconRightUp from './img/icon_right_up.png';
import VideoMain from './video/video_main.mp4';
import IconExpand from './img/icon_expand.png';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px ${SpaceValue.L}px;
`;
const FloatingButton = styled.div<{ isFixed: boolean }>`
  display: flex;
  padding: ${SpaceValue.XS}px ${SpaceValue.L}px ${SpaceValue.XS}px
    ${SpaceValue.XL}px;
  background-color: #fff;
  border-radius: 99999px;
  box-shadow: 0px 7px 29px 0px rgba(68, 68, 68, 0.24);
  position: ${(props) => (props.isFixed ? 'fixed' : 'absolute')};
  left: 50%;
  transform: translateX(-50%);
  bottom: ${(props) => (props.isFixed ? 'calc(24px + env(safe-area-inset-bottom))' : '0')};
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: fit-content;
  min-width: 185px;
`;
const SearchFrame = styled.div`
  height: 560px;
  display: flex;
  padding: 80px ${SpaceValue.L}px;
  justify-content: center;
  align-items: center;
`;
const AccordionFrame = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
 
  .title-frame {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: ${(props) => (props.isOpen ? '24px 0 12px 0' : '24px 0')};
    transition: padding 0.3s ease-in-out;
  }
 
  .content-container {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
 
  .content-frame {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
 
    img {
      margin: ${SpaceValue.M}px 0 ${SpaceValue.XL}px 0;
    }
  }
 
  .content-frame.visible {
    opacity: 1;
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${V2BorderColors.default};
`;
const VideoFrame = styled.div`
  width: 100%;
  height: 210px;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 2;
  }
`;
const MainHeaderFrame = styled.div`
  display: flex;
  padding: 64px 16px 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
interface IAccordionSectionProps {
  title: string;
  content: string;
  image: string;
  isOpen: boolean;
  onToggle: () => void;
}
const AccordionSection: FC<IAccordionSectionProps> = ({
  title,
  content,
  image,
  isOpen,
  onToggle,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) {
      const newHeight = isOpen ? contentRef.current.scrollHeight : 0;
      setHeight(newHeight);
    }
  }, [isOpen, content, contentRef]);

  return (
    <AccordionFrame isOpen={isOpen}>
      <div className='title-frame' onClick={onToggle} role='button'>
        <V2Text
          styles={{ alignSelf: 'center' }}
          fontStyle={V2TextOption.fontStyle.body_1_sb}
          color={V2TextOption.color.default}
        >
          {title}
        </V2Text>
        {isOpen ? (
          <img
            src={IconRemove}
            alt='icon_remove'
            style={{ width: '24px', height: '24px' }}
          />
        ) : (
          <V2Icon name={V2IconOption.name.add} size={V2IconOption.size.S} />
        )}
      </div>
      <div className='content-container' style={{ height }}>
        <div
          className={`content-frame ${isOpen ? 'visible' : ''}`}
          ref={contentRef}
        >
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtle}
          >
            {content}
          </V2Text>
          <img src={image} alt='imageFeature' />
        </div>
      </div>
      <Divider />
    </AccordionFrame>
  );
};
const HxIntroduce = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const bottomBannerRef = useRef<HTMLDivElement>(null);
  const floatingButtonRef = useRef<HTMLDivElement>(null);
  const [isBottomBannerVisible, setIsBottomBannerVisible] = useState(false);
  const [isFloatingButtonFixed, setIsFloatingButtonFixed] = useState(true);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-2);

  useEffect(() => {
    // 비디오 요소가 로드된 후 재생 시도
    if (videoRef.current) {
      const playVideo = async () => {
        try {
          if (!videoRef.current) return; // null 체크 추가
          // 모바일에서 자동 재생을 위한 속성 추가
          videoRef.current.muted = true;
          videoRef.current.playsInline = true;
          videoRef.current.setAttribute('playsinline', '');

          // 비디오 로드 완료 후 재생 시도
          videoRef.current.load();

          // 사용자 상호작용 없이 자동 재생을 위한 지연 추가
          setTimeout(async () => {
            try {
              const playPromise = videoRef.current?.play();
              if (playPromise !== undefined) {
                await playPromise;
              }
            } catch (error) {
              console.log('자동 재생 실패:', error);
            }
          }, 100);
        } catch (error) {
          console.log('비디오 설정 실패:', error);
        }
      };

      playVideo();

      // 비디오 메타데이터 로드 완료 시 재생 시도
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current
          ?.play()
          .catch((err) => console.log('메타데이터 로드 후 재생 실패:', err));
      });
    }
  }, []);

  useEffect(() => {
    // Intersection Observer 설정
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBottomBannerVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }, // 10% 이상 보이면 감지
    );

    const currentRef = bottomBannerRef.current; // 현재 ref 값을 변수에 저장

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        // 저장된 변수 사용
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
      if (window.scrollY >= 800 && openAccordionIndex === -2) {
        setOpenAccordionIndex(0);
      }

      if (bottomBannerRef.current && floatingButtonRef.current) {
        const bottomBannerRect = bottomBannerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // 바텀 배너가 화면에 들어오기 시작할 때
        if (bottomBannerRect.top < windowHeight + 30) {
          setIsFloatingButtonFixed(false);
          // 플로팅 버튼을 바텀 배너 위에 위치시킴
          const newTopPosition = document.documentElement.scrollTop + bottomBannerRect.top - 100;
          floatingButtonRef.current.style.top = `${newTopPosition}px`;
          floatingButtonRef.current.style.position = 'absolute';
        } else {
          setIsFloatingButtonFixed(true);
          // fixed 상태일 때는 top 스타일 제거
          floatingButtonRef.current.style.top = '';
          floatingButtonRef.current.style.position = '';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // 초기 로드 시 한 번 실행
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [openAccordionIndex]);

  const handleFullScreen = () => {
    if (videoRef.current) {
      try {
        // 모바일 브라우저에서 전체화면 지원을 위한 다양한 메서드 시도
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if ((videoRef.current as any).webkitRequestFullscreen) {
          // Safari
          (videoRef.current as any).webkitRequestFullscreen();
        } else if ((videoRef.current as any).webkitEnterFullscreen) {
          // iOS Safari
          (videoRef.current as any).webkitEnterFullscreen();
        } else if ((videoRef.current as any).msRequestFullscreen) {
          // IE11
          (videoRef.current as any).msRequestFullscreen();
        }
      } catch (error) {
        console.log('전체화면 전환 실패:', error);
      }
    }
  };

  const handleAccordionToggle = (index: number) => {
    setOpenAccordionIndex(openAccordionIndex === index ? -1 : index);
  };

  return (
    <>
      <MainHeaderFrame>
        <img
          src={IconHx}
          alt='icon_hx'
          style={{ width: '32px', height: '12px' }}
        />
        <div>
          <V2Text
            styles={{ textAlign: 'center' }}
            fontStyle={V2TextOption.fontStyle.heading_3_sb}
            color={V2TextOption.color.strong}
          >
            팀 리더로부터 시작되는
          </V2Text>
          <V2Text
            fontStyle={V2TextOption.fontStyle.heading_3_sb}
            color={V2TextOption.color.strong}
          >
            혁신적인 채용으로의 전환.
          </V2Text>
        </div>
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_1_m}
          color={V2TextOption.color.default}
        >
          H.X는 팀 리더와 인재를 다이렉트로 연결합니다.
        </V2Text>
      </MainHeaderFrame>
      <VideoFrame>
        <video
          ref={videoRef}
          src={VideoMain}
          autoPlay
          muted
          playsInline
          loop
          preload='auto'
          controls={false}
          onClick={handleFullScreen}
        />
        <img src={IconExpand} alt='icon_expand' onClick={handleFullScreen} />
      </VideoFrame>
      <HxKeyFeature
        title={`인재 검색,\n떠올린 그대로 입력하세요.`}
        content={`원하는 인재를 자유롭게 검색해보세요.
입력된 내용을 정확하게 해석하여 맥락을 이해하고
사용자가 원하는 인재를 추천합니다.`}
        image={ImgKeyFeature0}
      />
      <HxVideoSection />
      <Frame>
        <V2Text
          fontStyle={V2TextOption.fontStyle.title_1_sb}
          color={V2TextOption.color.strong}
        >
          인재를 보는 새로운 방식
        </V2Text>
        <SpacingBlock size={32} vertical />
        <Divider />
        <AccordionSection
          key={0}
          title='한눈에 보는 첫인상'
          content='자기소개 한 줄과 일상 사진으로, 첫인상을 한눈에 느껴보세요. 어떤 성향과 가치관을 가진 인재인지 감각적으로 파악해 보세요.'
          image={ImgKeyFeature1}
          isOpen={openAccordionIndex === 0}
          onToggle={() => handleAccordionToggle(0)}
        />
        <AccordionSection
          key={1}
          title='내가 찾던 인재인지 확인해 보세요'
          content='현업 리더가 설정한 JD를 기반으로 인재 적합도에 대한 분석 리포트를 제공합니다. 직무 역량, 경력, 기술 스택, 업무 경험 등 종합적으로 분석된 내용을 한눈에 파악할 수 있습니다.'
          image={ImgKeyFeature2}
          isOpen={openAccordionIndex === 1}
          onToggle={() => handleAccordionToggle(1)}
        />
        <AccordionSection
          key={2}
          title='업무 스타일을 상상해 보세요'
          content='단순한 스펙이 아닌, 우리 조직에서 함께 일할 수 있을지 먼저 확인해 보세요. 선호하는 업무 방식, 가치관, 기업 선택 기준 등을 분석하여 업무 성향을 확인할 수 있습니다.'
          image={ImgKeyFeature3}
          isOpen={openAccordionIndex === 2}
          onToggle={() => handleAccordionToggle(2)}
        />
      </Frame>
      <HxTextVisual />
      {/* <SearchFrame>
        <V2Text fontStyle={V2TextOption.fontStyle.title_1_sb} color={V2TextOption.color.strong}>검색을 넘어.</V2Text>
      </SearchFrame> */}
      <HxKeyFeature
        title='남다른 인재풀'
        content={`진정성 있는 스토리로, 새로운 기회를 찾고 있는 인재들.
H.X는 열정과 의지가 가득한 인재만을 연결합니다.`}
        contentList={[
          '특별한 스토리가 담긴 프로필',
          '적극적으로 구직 중인 활성화 인재풀',
        ]}
        image={ImgKeyFeature4}
      />
      <HxKeyFeature
        title='차원이 다른 역량'
        content={`역검 점수가 우수한 인재만 추천되며, 성향 분석을 통해
컬쳐FIT까지 고려한 최적의 추천을 제공합니다.`}
        contentList={[
          '일정 수준 이상의 검증된 인재만 추천',
          '역검 결과 기반의 컬쳐FIT 제공',
        ]}
        image={ImgKeyFeature5}
      />
      <HxKeyFeature
        title='타협없는 정확도'
        content={`H.X의 인재 추천 정확도는 업계 최고 헤드헌팅
전문가 수준을 자랑합니다. Knowledge Graph와
Full-LLM 기술을 결합해 원하는 조건에
딱 맞는 인재를 정확하게 추천합니다.`}
        image={ImgKeyFeature6}
        isModal
      />
      <HxKeyFeature
        title='원하는 인재를 만날 때까지'
        content={`H.X는 원하는 인재와 가장 유사한 프로필을
자동으로 찾아, 입사의지까지 확인된
최적의 인재풀을 함께 추천해드립니다.`}
        contentList={[
          '검색 조건 기반, 유사한 인재 자동 추천',
          '입사의지가 확인된 구직자 추가 제공',
          '지원자가 부족한 기업에게 꼭 필요해요',
        ]}
        image={ImgKeyFeature7}
      />
      <HxReservation />
      <HxFaq />

      <FloatingButton
        ref={floatingButtonRef}
        isFixed={isFloatingButtonFixed}
        onClick={() => windowOpenUtils(
          'https://share.hsforms.com/1r0nqAkLIRPuU2znmznX7_Qcvjg5',
          '_blank',
        )}
      >
        <V2Text
          fontStyle={V2TextOption.fontStyle.body_1_sb}
          color={V2TextOption.color.default}
        >
          무료로 사용하기
        </V2Text>
        <img
          src={IconRightUp}
          alt='icon_right_up'
          style={{ width: '32px', height: '32px' }}
        />
      </FloatingButton>

      <HxBottomBanner />

      <div ref={bottomBannerRef}>
        <HxFooter />
      </div>
    </>
  );
};
export default HxIntroduce;
