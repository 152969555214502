import JDALink from 'components/JDALink';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC } from 'react';

const urlRegex = /https?:\/\/[^\s]+/g;

interface LinkifyStringProps {
  text: string;
  login?: Login;
}

const LinkifyString: FC<LinkifyStringProps> = ({ text, login = new Login() }) => {
  const linkify = (inputText: string) => {
    // URL과 "$성명$" 문자열을 모두 찾아서 위치 순서대로 처리
    const tokens: Array<{
      type: 'url' | 'name' | 'text';
      content: string;
      index: number;
    }> = [];

    // URL 찾기
    const foundUrls = inputText.match(urlRegex);
    foundUrls?.forEach((url) => {
      const index = inputText.indexOf(url);
      tokens.push({ type: 'url', content: url, index });
    });

    // "$성명$" 찾기
    let nameIndex = inputText.indexOf('$성명$');
    while (nameIndex !== -1) {
      tokens.push({ type: 'name', content: '$성명$', index: nameIndex });
      nameIndex = inputText.indexOf('$성명$', nameIndex + 1);
    }

    // 토큰을 위치 순서대로 정렬
    tokens.sort((a, b) => a.index - b.index);

    if (tokens.length === 0) {
      return inputText;
    }

    const result = [];
    let lastIndex = 0;

    tokens.forEach((token) => {
      // 토큰 이전의 일반 텍스트 추가
      result.push(inputText.substring(lastIndex, token.index));

      if (token.type === 'url') {
        // URL 처리
        result.push(
          <JDALink to={token.content} key={`url-${token.index}`} className='hyper-link'>
            {token.content}
          </JDALink>,
        );
      } else if (token.type === 'name') {
        // "$성명$" 처리
        result.push(
          <span key={`name-${token.index}`}>
            {login?.userInfo?.name || ''}
          </span>,
        );
      }

      lastIndex = token.index + token.content.length;
    });

    // 남은 문자열 추가
    result.push(inputText.substring(lastIndex));

    return result;
  };

  return <>{linkify(text)}</>;
};

export default inject(injectStore.login)(observer(LinkifyString));
