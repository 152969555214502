import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import ProfilePreferential from 'components/_v2/profile/preferential/ProfilePreferential';
import IModalProps from 'interfaces/props/IModalProps';
import React, { FC } from 'react';

const ProfilePreferentialEditModal:FC<IModalProps> = ({ isOpen, onClose, isMatching = false }) => (
  !isOpen ? <></> : (
    <JDFullModal handleClose={onClose} title='병역 · 취업 우대'>
      <ProfilePreferential isMatching={isMatching} onClose={onClose} />
    </JDFullModal>
  )
);

export default ProfilePreferentialEditModal;
